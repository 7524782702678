import { VjsAgofPluginOptions } from '../../../../../../../@types/VjsAgofPluginOptions';
import * as videojs from 'video.js';
import { AGOF_CONTENT_TYPE, AgofEnum } from '../../../../../enum/agof.enum';
import { environment } from '../../../../../environments/environment';

// @ts-ignore
const Plugin = window.videojs.getPlugin('plugin');

export default class AGOFPlugin extends Plugin {
    private agofRequestSentForContentType: AGOF_CONTENT_TYPE[] = [];
    private options: VjsAgofPluginOptions;

    constructor(player: videojs.default.Player, options: VjsAgofPluginOptions) {
        super(player, options);
        this.options = options;
    }

    public reset() {
        this.agofRequestSentForContentType = [];
    }

    public sendAgofRequest(contentType: AGOF_CONTENT_TYPE = AgofEnum.CONTENT_TYPE_VIDEO) {
        // only send the agof request once per video and only if ads are enabled
        if (this.agofRequestSentForContentType.includes(contentType) || (this.options && this.options.ads === false)) {
            return;
        }
        this.agofRequestSentForContentType.push(contentType);
        let agofEnumPage;
        if (window.location.pathname === '/') {
            if (environment.appName === 'sportdeutschland') {
                agofEnumPage = AgofEnum.HOMEPAGE_YES;
            } else {
                agofEnumPage = AgofEnum.HOMEPAGE_OF_THIRD_PARTY_DOMAIN;
            }
        } else {
            agofEnumPage = AgofEnum.HOMEPAGE_NO;
        }

        let coSecondPath;

        if (window.location.pathname === '/') {
            coSecondPath = '/';
        } else {
            coSecondPath = this.options.liveAsset
                ? '/livestream' + window.location.pathname + '/'
                : window.location.pathname + '/';
        }

        this.options.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.options.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        contentType,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        agofEnumPage,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        this.options.freeAsset ? AgofEnum.PAID_NO : AgofEnum.PAID_YES,
                        AgofEnum.CONTENT_TOPIC_Sports
                    ),
                    cpChannel: this.options.liveAsset ? AgofEnum.CHANNEL_LIVE : AgofEnum.CHANNEL_DEFAULT,

                    coSecondPart: coSecondPath,
                    survey: false
                }
            }
        });
    }
}
