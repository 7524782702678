import { TileType } from './../asset-tile-slider/asset-tile-slider.component';
import { Component, Input, OnInit } from '@angular/core';
import { SectionsService } from '../../../services/api/methods/sections.service';
import { Section } from '../../../entities/section.entity';
import { map, switchMap } from 'rxjs/operators';
import { zip } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-section-sliders',
    templateUrl: './section-sliders.component.html'
})
export class SectionSlidersComponent implements OnInit {
    @Input() public excludedSectionUUIDs: string[] = [];

    public loading = false;
    public sections: Section[] = [];
    public currentPage = 0;
    public elements: boolean[] = [];

    public TileType = TileType;

    constructor(private sectionsService: SectionsService, public router: Router) {}

    public ngOnInit(): void {
        this.load(1);
    }

    public loadMore(event) {
        this.load(this.currentPage + 1);
    }

    public onIntersection({ visible }: { visible: boolean }, id: number) {
        this.elements[id] = visible;
    }

    private load(page: number) {
        this.loading = true;
        this.sectionsService
            .getSections(10, page)
            .pipe(
                map(sections => {
                    if (Array.isArray(sections)) {
                        return sections.filter(section => !this.excludedSectionUUIDs.includes(section.uuid));
                    } else {
                        return [];
                    }
                }),
                switchMap(sections => {
                    return zip(
                        ...sections.map((section: Section) =>
                            this.sectionsService
                                .getAssetsForSectionID(section.uuid, 15, 1)
                                .pipe(map(assetData => ({ ...section, assets: assetData.items })))
                        )
                    );
                })
            )
            .subscribe(
                sections => {
                    this.sections.push(...sections);
                    this.currentPage++;
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                }
            );
    }
}
