import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertNotificationsService } from '../services/alert-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertType } from '../entities/alert.entity';
import { environment } from '../environments/environment';
import { AuthenticationService } from '../services/api/methods/authentication.service';
import { map } from 'rxjs/operators';

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(
        protected alertService: AlertNotificationsService,
        protected authenticationService: AuthenticationService,
        protected translate: TranslateService,
        protected router: Router
    ) {}

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authenticationService.isLoggedIn().pipe(
            map((isLoggedin: boolean) => {
                if (!isLoggedin) {
                    this.translate.get('alert.notLoggedIn').subscribe(trans => {
                        this.alertService.alert(AlertType.Error, trans, true);
                    });
                    this.router.navigateByUrl(
                        '/' + environment.urlSlugs.login + '?redirect=' + encodeURIComponent(state.url)
                    );
                }
                return isLoggedin;
            })
        );
    }
}
