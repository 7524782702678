import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { AppName } from 'src/common/enum/app-name.enum';

@Injectable({
    providedIn: 'root'
})
export class CanonicalTagService {
    public updateCanonicalURL(permalink: string): void {
        const link = this.getHtmlElement();
        if (permalink.startsWith('http')) {
            link.setAttribute('href', permalink);
        } else {
            let origin;
            switch (environment.appName) {
                case AppName.turn:
                case AppName.tischtennis:
                case AppName.handball:
                    origin = 'https://sportdeutschland.tv';
                    break;
                default:
                    origin = window.location.origin;
            }
            link.setAttribute('href', origin + '/' + permalink);
        }
    }

    private getHtmlElement(): HTMLLinkElement {
        if (document.querySelector('link[rel=canonical]')) {
            return document.querySelector('link[rel=canonical]');
        }
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        return link;
    }

    constructor(@Inject(DOCUMENT) private dom) {}
}
