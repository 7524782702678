import { Section } from '../../../entities/section.entity';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../../services/analytics.service';
import { PlayerComponent } from '../player/player.component';
import { LiveEvent } from '../../../entities/live-event.entity';
import { LiveEventsService } from '../../../services/api/methods/live-events.service';
import { map, switchMap } from 'rxjs/operators';
import { AssetsService } from '../../../services/api/methods/assets.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { SidebarService } from '../../../services/sidebar.service';
import * as ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { Asset } from '../../../entities/asset.entity';
import { VideoState } from '../player/states';

@Component({
    selector: 'app-opener',
    templateUrl: './opener.component.html'
})
export class OpenerComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() public ads = true;
    @Input() public section: Section = null;
    public selectedAsset: Asset;
    public assetsInPlayer: Asset[];
    public playerHeight = 400;
    public autoplay = false;
    public muted = false;
    public stopAutoRotation = false;
    public playerEnabled = false;
    public updateInterval: any;
    public environment;
    public multiTeaserMainClicked = false;
    public isCollapsed = true;
    public videoInfoShown = true;
    @ViewChild('player') public player: PlayerComponent;
    @ViewChild('playerContainer', { static: true }) public playerContainer: ElementRef;

    constructor(
        private analyticsService: AnalyticsService,
        private assetService: AssetsService,
        private liveEventsService: LiveEventsService,
        private cdr: ChangeDetectorRef,
        private sidebarService: SidebarService
    ) {
        this.environment = environment;
    }

    get selectedAssetIndex(): number {
        return this._assets.findIndex(asset => asset === this.selectedAsset);
    }

    public _assets: Asset[] = [];

    @Input('assets') set assets(assets: Asset[]) {
        this._assets = assets;
        if (!assets) {
            return;
        }
        this.changeAsset(this._assets[0]);
    }

    public ngOnInit(): void {
        this.updateInterval = setInterval(() => {
            if (!this.stopAutoRotation) {
                this.playNext();
            }
        }, 10000);
    }

    public ngOnDestroy() {
        clearInterval(this.updateInterval);
    }

    public ngAfterViewInit() {
        // ElementQueries.listen();
        // @ts-ignore
        const sensor = new ResizeSensor(this.playerContainer.nativeElement, () => {
            this.playerHeight = this.playerContainer.nativeElement.clientHeight;
        });
    }

    public changeAsset(
        asset: Asset,
        autoplayFromClick: boolean = false,
        index = null,
        disableInterval = false,
        fromHover = false
    ) {
        if (fromHover && this.playerEnabled) {
            return;
        }

        if (disableInterval) {
            this.stopAutoRotation = true;
        }
        this.cdr.detach();
        if (autoplayFromClick === true) {
            this.muted = false;
            this.playerEnabled = true;
        }
        this.autoplay = autoplayFromClick;
        this.selectedAsset = asset;
        this.assetsInPlayer = [asset];
        this.ads = true;

        if (asset.flags.includes('autoplay') && asset.flags.includes('noad')) {
            if (!this.autoplay) {
                this.muted = true;
            }
            this.playerEnabled = true;
            this.autoplay = true;
            this.ads = false;

            this.stopAutoRotation = true;
            this.cdr.detectChanges();
            this.player.play();
        }

        if (asset.live === true && asset.channel) {
            this.liveEventsService
                .getLiveEventsForTagFilter(
                    [
                        {
                            callname: asset.channel.callname,
                            values: asset.channel.values
                        }
                    ],
                    10
                )
                .pipe(
                    map((liveEvents: LiveEvent[]) => {
                        return liveEvents.filter((liveEvent: LiveEvent) => {
                            return liveEvent.now === true && liveEvent.asset_uuid !== asset.uuid;
                        });
                    }),
                    switchMap((liveEvents: LiveEvent[]) => {
                        return forkJoin(
                            liveEvents.map(liveEvent => {
                                return this.assetService.getAsset(liveEvent.asset_uuid);
                            })
                        );
                    })
                )
                .subscribe((allLiveAssets: Asset[]) => {
                    this.assetsInPlayer = [asset, ...allLiveAssets];
                });
        }
        this.cdr.reattach();
        if (index !== null && !fromHover) {
            this.analyticsService.trackEvent('Multiteaser Click', {
                category: 'Multiteaser Click',
                label: 'pos: ' + (index + 1) + ', label: ' + asset.label,
                gtmCustom: {
                    googleAnalyticsEventAction: window.location.hostname + window.location.pathname
                }
            });
        }
    }

    public onVideoStateChanged(state: VideoState) {
        switch (state) {
            case 'started':
                clearInterval(this.updateInterval);
                break;
            case 'ended':
                this.playNext();
                break;
        }
    }

    public playNext(autoplay: boolean = false) {
        this.playerEnabled = false;
        if (this.selectedAsset === this._assets[this._assets.length - 1]) {
            this.changeAsset(this._assets[0], autoplay);
        } else {
            this.changeAsset(this._assets[this.selectedAssetIndex + 1], autoplay);
        }
    }

    public onPlayerClick(event) {
        this.cdr.markForCheck();
        this.cdr.detach();
        clearInterval(this.updateInterval);
        this.muted = false;
        this.stopAutoRotation = true;
        this.playerEnabled = true;
        this.cdr.detectChanges();
        this.player.play();
        this.cdr.reattach();
        if (!this.multiTeaserMainClicked) {
            this.multiTeaserMainClicked = true;
            this.analyticsService.trackEvent('Multiteaser Click', {
                category: 'Multiteaser Click',
                label: 'pos: null, label: ' + this.selectedAsset.label,
                gtmCustom: {
                    googleAnalyticsEventAction: window.location.hostname + window.location.pathname
                }
            });
        }
    }
}
