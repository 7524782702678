import { LiveEvent } from './live-event.entity';
import { SocialLinkEntity } from './social-link.entity';
import { Asset } from './asset.entity';
import { TagFilter } from './tag-filter.entity';
import { Section } from './section.entity';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, Subscriber } from 'rxjs';

export enum VisualisationTypeEnum {
    opener = 'opener',
    html = 'html',
    imageSlider = 'imageSlider',
    liveEvents = 'liveEvents',
    bannerImage = 'bannerImage',
    ctaBanner = 'ctaBanner',
    contentTeaser = 'contentTeaser',
    pageTeasers = 'pageTeasers',
    socialLinks = 'socialLinks',
    tileSliderBigTiles = 'tileSliderBigTiles',
    tileSliderSmallTiles = 'tileSliderSmallTiles',
    tileSliderBigTilesSponsored = 'tileSliderBigTilesSponsored',
    tileGridBigTiles = 'tileGridBigTiles',
    tileGridSmallTiles = 'tileGridSmallTiles',
    somtagTrackingDisclaimer = 'somtagTrackingDisclaimer',
    videoPlayer = 'videoPlayer',
    tileSliderRoundImageTile = 'tileSliderRoundImageTile',
    tileSliderDonations = 'tileSliderDonations'
}

export enum ContentTypeEnum {
    assets = 'assets',
    asset = 'asset',
    section = 'section',
    playlist = 'playlist',
    html = 'html',
    live_events = 'live_events',
    page = 'page',
    image = 'image'
}

export interface ExtendedPageContent {
    clients: string[];
    content_type: ContentTypeEnum;
    content_uuid: string;
    description: string;
    html_content: string;
    html_content_plain: string;
    metatags: string[];
    path?: string;
    position: number;
    published: boolean;
    tag_filters: Array<TagFilter>;
    images?: Array<{
        size?: 'sm' | 'md' | 'lg';
        url?: string;
    }>;
    title: string;
    teaser: string;
    visualization_options: { key: string; value: any }[];
    visualization_type: VisualisationTypeEnum;
    page_teasers?: { image: string; text: string; url: string }[];

    social_links?: SocialLinkEntity[];

    // NOT SET BY API. HAS TO BE FETCHED FIRST
    assets?: BehaviorSubject<{ items: Asset[]; total_assets_count: number }>;
    // todo the totals_assets count ist not correct, see permalink service
    liveEvents?: BehaviorSubject<{ items: LiveEvent[]; total_assets_count: number }>;

    page?: number;
    per_page?: number;
    subscriber?: Subscriber<{ items: Asset[]; total_assets_count: number }>;

    /** @deprecated */
    assets_count?: number;
    section?: Observable<Section>;

    loadMore();
}
