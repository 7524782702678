import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '../../../../services/analytics.service';
import { AssetsService } from '../../../../services/api/methods/assets.service';
import { EmbeddedModeService } from '../../../../services/embedded-mode.service';
import { environment } from '../../../../environments/environment';
import { Asset } from '../../../../entities/asset.entity';
import { Meta } from '@angular/platform-browser';
import { AppName } from 'src/common/enum/app-name.enum';

@Component({
    selector: 'app-embedded-player',
    templateUrl: './embedded-player.component.html',
    styleUrls: ['./embedded-player.component.scss']
})
export class EmbeddedPlayerComponent implements OnInit, OnDestroy {
    public asset: Asset;
    public nologo: boolean;
    public clicked = false;

    public environment = environment;
    public AppName = AppName;

    constructor(
        private meta: Meta,
        private route: ActivatedRoute,
        private assetService: AssetsService,
        private analyticsService: AnalyticsService,
        private embeddedModeService: EmbeddedModeService
    ) {}

    public ngOnInit(): void {
        this.embeddedModeService.enable();
        if (window.location !== window.parent.location) {
            this.embeddedModeService.setSyndicationOrigin(document.referrer);
        }

        this.assetService.getAsset(this.route.snapshot.paramMap.get('assetId')).subscribe(asset => {
            this.asset = asset;
            if (asset.flags && asset.flags.includes('nologo')) {
                this.nologo = true;
            }
        });

        this.analyticsService.trackEvent('EmbeddedPlayerImpression', {
            category: 'EmbeddedPlayerImpression',
            event: 'EmbeddedPlayerImpression',
            noninteraction: false,
            gtmCustom: {
                inSyndicationPlayer: true,
                wholesaler: document.referrer
            }
        });

        this.meta.updateTag({ name: 'robots', content: 'noindex,follow' }, 'name=robots');
        // @ts-ignore
        window.prerenderReady = true;
    }

    public onPlayerClick() {
        if (this.clicked === true) {
            return;
        }
        this.clicked = true;

        this.analyticsService.trackEvent('EmbeddedPlayerClick', {
            category: 'EmbeddedPlayerClick',
            event: 'EmbeddedPlayerClick',
            noninteraction: false,
            gtmCustom: {
                inSyndicationPlayer: true,
                wholesaler: document.referrer
            }
        });
    }

    public ngOnDestroy() {
        this.embeddedModeService.disable();
    }
    public openConsent() {
        window['usercentrics'].updateBannerIsVisible(true);
    }
}
