import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Playlist } from '../../../entities/playlist.entity';
import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';
import { Asset } from '../../../entities/asset.entity';

@Injectable()
export class PlaylistsService extends ApiService {
    private endpoint = 'playlists';

    public getPlaylist(uuid, page: number = 1, per_page: number = 10): Observable<Playlist> {
        const url = this.endpoint + '/' + uuid;
        return this.http
            .get<Playlist>(url, {
                params: { per_page: per_page.toString(), page: page.toString() }
            })
            .pipe(
                map((playlist: Playlist) => {
                    playlist.assets = playlist.assets.map((asset: RawAssetFromApi) => new Asset(asset));
                    return playlist;
                })
            );
    }
}
