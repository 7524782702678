import { AfterViewChecked, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AlertNotificationsService } from '../../../services/alert-notifications.service';
import { Alert, AlertType } from '../../../entities/alert.entity';
import { MenuService } from '../../../services/api/methods/menu.service';

@Component({
    moduleId: module.id,
    selector: 'app-alert',
    templateUrl: 'alert.component.html'
})
export class AlertComponent implements OnInit, AfterViewChecked {
    public alerts: Alert[] = [];

    @ViewChild('alertContainer', { static: true }) public alertContainer: ElementRef;

    constructor(private alertService: AlertNotificationsService, private menuService: MenuService) {}

    @HostListener('window:resize')
    public onResize() {
        this.emitHeight();
    }

    public ngAfterViewChecked() {
        this.emitHeight();
    }

    public ngOnInit(): void {
        this.alertService.getAlert().subscribe((alert: Alert) => {
            if (!alert) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                this.emitHeight();
                return;
            }
            // add alert to array
            this.alerts.push(alert);
            this.emitHeight();
        });
    }

    public removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }

    public cssClass(alert: Alert) {
        if (!alert) {
            return;
        }

        // return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                return 'alert alert-success';
            case AlertType.Error:
                return 'alert alert-danger';
            case AlertType.Info:
                return 'alert alert-info';
            case AlertType.Warning:
                return 'alert alert-warning';
        }
    }

    private emitHeight() {
        this.menuService.setAlertHeight(this.alertContainer.nativeElement.offsetHeight);
    }
}
