export enum AgofEnum {
    LANGUAGE_GERMAN = 'd', // German
    LANGUAGE_OTHER_CONTENT_VERIFIED = 'p', // Other language, content is verifiable
    LANGUAGE_OTHER_CONTENT_NOT_VERIFIED = 'n', // Other language, content is not verifiable

    CONTENT_TYPE_IMAGE_TEXT = 'Tx', // The page contains display ads (e.g. Skyscraper).
    CONTENT_TYPE_AUDIO = 'Ax', // The page contains ads for audio files.
    CONTENT_TYPE_VIDEO = 'Vx', // The page is a player page and is marketed with instream ads (e.g. Preroll).
    CONTENT_TYPE_OTHER = 'Dx', //

    CONTENT_CREATOR_EDITOR = 'Rc',
    CONTENT_CREATOR_USER = 'Uc',
    CONTENT_CREATOR_UNKOWN = 'Nc',

    HOMEPAGE_YES = 'Hp',
    HOMEPAGE_NO = 'Cp',
    HOMEPAGE_OF_THIRD_PARTY_DOMAIN = 'Fp', // In case of a so-called Multi-FQDN-Offer

    DISTRIBUTION_ONLINE = 'Oq',
    DISTRIBUTION_MOBILE = 'Mq',
    DISTRIBUTION_CONNECTED_TV = 'Tq',

    DEVICE_APP = 'Ay',
    DEVICE_NO_APP = 'Ky',

    PAID_YES = 'Pz',
    PAID_NO = 'Fz',

    CONTENT_TOPIC_News = '01',
    CONTENT_TOPIC_Sports = '02',
    CONTENT_TOPIC_Entertainment_Boulevard_Stars_Films_Music = '03',
    CONTENT_TOPIC_Fashion_Beauty = '04',
    CONTENT_TOPIC_Family_Children_Living_Advice = '05',
    CONTENT_TOPIC_Love_Psychology_Relationships = '06',
    CONTENT_TOPIC_Car_Traffic_Mobility = '07',
    CONTENT_TOPIC_Travel_Tourism = '08',
    CONTENT_TOPIC_Computers = '09',
    CONTENT_TOPIC_Consumer_Electronics = '10',
    CONTENT_TOPIC_Telecommunications_Internet_Services = '11',
    CONTENT_TOPIC_Games = '12',
    CONTENT_TOPIC_Residential_Living_Real_Estate_Garden_Household = '13',
    CONTENT_TOPIC_Economy_Finances_Jobs_Careers = '14',
    CONTENT_TOPIC_Health = '15',
    CONTENT_TOPIC_Food_Beverages = '16',
    CONTENT_TOPIC_Arts_Culture_Literature = '17',
    CONTENT_TOPIC_Erotic = '18',
    CONTENT_TOPIC_Science_Education_Nature_Environment = '19',
    CONTENT_TOPIC_Information_about_the_website_Service = '20',
    CONTENT_TOPIC_Mixed_multi_thematic = '21',
    CONTENT_TOPIC_Miscellaneous_mono_thematic = '22',
    CONTENT_TOPIC_General_games_page = '23',
    CONTENT_TOPIC_Casual_Games = '24',
    CONTENT_TOPIC_Core_Games = '25',
    CONTENT_TOPIC_Miscellaneous_games_sector = '26',
    CONTENT_TOPIC_Social_networking_private = '27',
    CONTENT_TOPIC_Social_networking_business = '28',
    CONTENT_TOPIC_Partner_search_Dating = '29',
    CONTENT_TOPIC_Newsletter = '30',
    CONTENT_TOPIC_EMail_SMS_ECards = '31',
    CONTENT_TOPIC_Messenger_Chat = '32',
    CONTENT_TOPIC_Miscellaneous_networking_communications_sector = '33',
    CONTENT_TOPIC_Search_engines = '34',
    CONTENT_TOPIC_Directories_Information_services = '35',
    CONTENT_TOPIC_Miscellaneous_search_engine_directories_sector = '36',
    CONTENT_TOPIC_Online_Shops_Shopping_Mall_Auctions_B2B_Market_Places = '37',
    CONTENT_TOPIC_Real_estate_classified_markets_small_ads = '38',
    CONTENT_TOPIC_Jobs_classified_markets_small_ads = '39',
    CONTENT_TOPIC_Vehicles_classified_markets_small_ads = '40',
    CONTENT_TOPIC_Miscellaneous_classified_markets_small_ads = '41',
    CONTENT_TOPIC_Miscellaneous_ecommerce_sector = '42',

    CHANNEL_DEFAULT = 'default',
    CHANNEL_HOME = 'home',
    CHANNEL_SERIVCE = 'service',
    CHANNEL_LIVE = 'live',
    CHANNEL_FALLBACK = 'fallback'
}

export type AGOF_CONTENT_TYPE =
    | AgofEnum.CONTENT_TYPE_IMAGE_TEXT
    | AgofEnum.CONTENT_TYPE_AUDIO
    | AgofEnum.CONTENT_TYPE_VIDEO
    | AgofEnum.CONTENT_TYPE_OTHER;

export type AGOF_LANGUAGE =
    | AgofEnum.LANGUAGE_GERMAN
    | AgofEnum.LANGUAGE_OTHER_CONTENT_NOT_VERIFIED
    | AgofEnum.LANGUAGE_OTHER_CONTENT_VERIFIED;

export type AGOF_CONTENT_CREATOR =
    | AgofEnum.CONTENT_CREATOR_EDITOR
    | AgofEnum.CONTENT_CREATOR_USER
    | AgofEnum.CONTENT_CREATOR_UNKOWN;

export type AGOF_CONTENT_TOPIC =
    | AgofEnum.CONTENT_TOPIC_News
    | AgofEnum.CONTENT_TOPIC_Sports
    | AgofEnum.CONTENT_TOPIC_Entertainment_Boulevard_Stars_Films_Music
    | AgofEnum.CONTENT_TOPIC_Fashion_Beauty
    | AgofEnum.CONTENT_TOPIC_Family_Children_Living_Advice
    | AgofEnum.CONTENT_TOPIC_Love_Psychology_Relationships
    | AgofEnum.CONTENT_TOPIC_Car_Traffic_Mobility
    | AgofEnum.CONTENT_TOPIC_Travel_Tourism
    | AgofEnum.CONTENT_TOPIC_Computers
    | AgofEnum.CONTENT_TOPIC_Consumer_Electronics
    | AgofEnum.CONTENT_TOPIC_Telecommunications_Internet_Services
    | AgofEnum.CONTENT_TOPIC_Games
    | AgofEnum.CONTENT_TOPIC_Residential_Living_Real_Estate_Garden_Household
    | AgofEnum.CONTENT_TOPIC_Economy_Finances_Jobs_Careers
    | AgofEnum.CONTENT_TOPIC_Health
    | AgofEnum.CONTENT_TOPIC_Food_Beverages
    | AgofEnum.CONTENT_TOPIC_Arts_Culture_Literature
    | AgofEnum.CONTENT_TOPIC_Erotic
    | AgofEnum.CONTENT_TOPIC_Science_Education_Nature_Environment
    | AgofEnum.CONTENT_TOPIC_Information_about_the_website_Service
    | AgofEnum.CONTENT_TOPIC_Mixed_multi_thematic
    | AgofEnum.CONTENT_TOPIC_Miscellaneous_mono_thematic
    | AgofEnum.CONTENT_TOPIC_General_games_page
    | AgofEnum.CONTENT_TOPIC_Casual_Games
    | AgofEnum.CONTENT_TOPIC_Core_Games
    | AgofEnum.CONTENT_TOPIC_Miscellaneous_games_sector
    | AgofEnum.CONTENT_TOPIC_Social_networking_private
    | AgofEnum.CONTENT_TOPIC_Social_networking_business
    | AgofEnum.CONTENT_TOPIC_Partner_search_Dating
    | AgofEnum.CONTENT_TOPIC_Newsletter
    | AgofEnum.CONTENT_TOPIC_EMail_SMS_ECards
    | AgofEnum.CONTENT_TOPIC_Messenger_Chat
    | AgofEnum.CONTENT_TOPIC_Miscellaneous_networking_communications_sector
    | AgofEnum.CONTENT_TOPIC_Search_engines
    | AgofEnum.CONTENT_TOPIC_Directories_Information_services
    | AgofEnum.CONTENT_TOPIC_Miscellaneous_search_engine_directories_sector
    | AgofEnum.CONTENT_TOPIC_Online_Shops_Shopping_Mall_Auctions_B2B_Market_Places
    | AgofEnum.CONTENT_TOPIC_Real_estate_classified_markets_small_ads
    | AgofEnum.CONTENT_TOPIC_Jobs_classified_markets_small_ads
    | AgofEnum.CONTENT_TOPIC_Vehicles_classified_markets_small_ads
    | AgofEnum.CONTENT_TOPIC_Miscellaneous_classified_markets_small_ads
    | AgofEnum.CONTENT_TOPIC_Miscellaneous_ecommerce_sector;

export type AGOF_HOMEPAGE = AgofEnum.HOMEPAGE_YES | AgofEnum.HOMEPAGE_NO | AgofEnum.HOMEPAGE_OF_THIRD_PARTY_DOMAIN;

export type AGOF_DISTRIBUTION =
    | AgofEnum.DISTRIBUTION_CONNECTED_TV
    | AgofEnum.DISTRIBUTION_MOBILE
    | AgofEnum.DISTRIBUTION_ONLINE;

export type AGOF_DEVICE = AgofEnum.DEVICE_APP | AgofEnum.DEVICE_NO_APP;

export type AGOF_PAID = AgofEnum.PAID_YES | AgofEnum.PAID_NO;
