import { PendingVideo, Video } from './video.entity';
import { Tag } from './tag.entity';
import { RawAssetFromApi } from './raw-asset-from-api.entity';
import { environment } from '../environments/environment';
import { Publisher } from './publisher.entity';
import { LiveEvent } from './live-event.entity';
import { AppName } from 'src/common/enum/app-name.enum';
import { AssetAuthorizationEntity } from './asset-authorization.entity';

export class Asset implements RawAssetFromApi {
    public contents_freely_accessible: boolean;
    public description: string;
    public duration: string; // "00:21"
    public external_ids?: string[];
    public geo?: string;
    public ics: string;
    public image_mediafile_uuids?: string[];
    public images: {
        url: string;
        uuid: string;
    }[];
    public label: string;
    public live?: boolean;
    public live_at?: string;
    public permalink: string;
    public player: string;
    public published: boolean;
    public published_at: string;
    public seconds: number;
    public section?: {
        permalink: string;
        uuid: string;
    };
    public tags: Tag[];
    public teaser: string;
    public url: string;
    public uuid: string;
    public version_number: number;
    public video_mediafile_uuids: string[];
    public videos?: Video[];
    public views: number;
    public youth_protection_category: any;

    /**
     * If a live event object for this asset exists, it can be fetched and attached to it.
     * NOT SET BY API
     */
    public liveEvent?: LiveEvent;

    public publisher?: Publisher; // read-only

    /**
     * @deprecated
     */
    public match_id?: string;

    /// generated from tag_filters, not included from server side api
    public sportart?: Tag;
    public channel?: Tag;
    public products?: Tag;

    public production_partner: string[] = [];
    public esport_games: string[] = [];
    public esport_events: string[] = [];
    public wettbewerb_event: string[] = [];
    public genre: string[] = [];
    public video_kategorie: string[] = [];
    public ort: string[] = [];
    public saison: string[] = [];
    public teams: string[] = [];
    public som_targeting_keywords?: string[] = [];
    public licence_owner?: string[] = [];
    public auto_marke: string[] = [];
    public auto_modell: string[] = [];
    public auto_typ: string[] = [];
    public auto_krafstoff: string[] = [];
    public auto_farbe: string[] = [];
    public flags?: string[] = [];

    public opener_title?: string;
    public opener_body?: string;
    public opener_image?: string;

    public is_moment_of_the_year_clip = false;

    public player_branding_logo: string = null;

    // Flags
    public noembed = false;
    public norelated = false;
    public noad = false;
    public noshare = false;
    public nologo = false; // no branding in player
    public nomidroll = false;
    public novideo = false;
    public notags = false;
    public hide_title_in_embedded_player = false;
    public player_branding_left = false;
    public player_branding_bottom = false;
    public player_branding_none = false;

    public tags_by_callname: Tag[] = [];

    // not set by api
    public authorization_token?: AssetAuthorizationEntity;

    constructor(asset: RawAssetFromApi) {
        this.contents_freely_accessible = asset.contents_freely_accessible;
        this.publisher = asset.publisher;
        this.external_ids = asset.external_ids;
        this.geo = asset.geo;
        this.ics = asset.ics;
        this.image_mediafile_uuids = asset.image_mediafile_uuids;
        this.images = asset.images;
        this.label = asset.label;
        this.permalink = asset.permalink;
        this.player = asset.player;
        this.published = asset.published;
        this.published_at = asset.published_at;
        this.section = asset.section;
        this.tags = asset.tags;
        this.teaser = asset.teaser;
        this.url = asset.url;
        this.uuid = asset.uuid;
        this.version_number = asset.version_number;
        this.video_mediafile_uuids = asset.video_mediafile_uuids;
        this.views = asset.views;
        this.youth_protection_category = asset.youth_protection_category;
        this.published_at = asset.published_at;
        this.description = asset.description;
        this.duration = asset.duration;
        this.seconds = asset.seconds;
        this.live_at = asset.live_at;
        this.live = asset.live;

        this.videos = [];
        asset.videos.forEach((video: Video | PendingVideo) => {
            if (this.isPendingVideo(video)) {
                console.warn('Stripped pending video from asset. video.uuid: ', video.uuid);
                return;
            }
            this.videos.push(video);
        });
        // demo for  corona campaign

        this.tags.forEach((tag: Tag) => {
            this.tags_by_callname[tag.callname] = tag;

            if (tag.callname === 'sportart') {
                this.sportart = tag;
            }

            if (tag.callname === 'channel') {
                this.channel = tag;
            }
            if (tag.callname === 'bereich') {
                this.channel = tag;
            }

            if (tag.callname === '_flags' && tag.values[0]) {
                this.flags = tag.values;
                this.noembed = tag.values.includes('noembed');
                this.norelated = tag.values.includes('norelated');
                this.noad = tag.values.includes('noad');
                this.noshare = tag.values.includes('noshare');
                this.hide_title_in_embedded_player = tag.values.includes('notitle');
                this.nologo = tag.values.includes('nologo');
                this.nomidroll = tag.values.includes('nomidroll');
                this.novideo = tag.values.includes('novideo');
                this.notags = tag.values.includes('notags');
                this.player_branding_left = tag.values.includes('player_branding_left');
                this.player_branding_bottom = tag.values.includes('player_branding_bottom');
                this.player_branding_none = tag.values.includes('player_branding_none');
            }

            if (tag.callname === 'keywords-keyword-text') {
                this.sportart = tag;
            }

            if (tag.label === '_$openerTitle' && tag.values[0]) {
                this.opener_title = tag.values[0];
            }
            if (tag.label === '_$openerImage' && tag.values[0]) {
                this.opener_image = tag.values[0].replace('http:', 'https:');
            }
            if (tag.label === '_$openerBody' && tag.values[0]) {
                this.opener_body = tag.values[0];
            }

            if (tag.label === '_$playerLogo' && tag.values[0]) {
                if (tag.values[0] === 'sportdeutschland') {
                    this.player_branding_logo = 'sportdeutschland.png';
                } else if (tag.values[0] === 'autodeutschland') {
                    this.player_branding_logo = 'auto_deutschland.png';
                } else if (tag.values[0] === 'esports') {
                    this.player_branding_logo = 'ran_esports.png';
                }
            }

            if (tag.label === 'Wettbewerb, Event' && tag.values[0]) {
                this.wettbewerb_event = tag.values;
            }

            if (tag.label === 'Genre (z.B. FPS, MOBA, etc.)' && tag.values[0]) {
                this.genre = tag.values;
            }

            if (tag.label === 'Ort (z.B. Köln, London)' && tag.values[0]) {
                this.ort = tag.values;
            }

            if (tag.label === 'Video Kategorie (z.B. Top5, Highlights, Interviews, etc.)' && tag.values[0]) {
                this.video_kategorie = tag.values;
            }

            if (tag.label === 'Teams' && tag.values[0]) {
                this.teams = tag.values;
            } else if (tag.label === 'Spielername/Team' && tag.values[0]) {
                this.teams = tag.values;
            }

            if (tag.label === 'Saison (Liga) / Termin (Event)' && tag.values[0]) {
                this.saison = tag.values;
            }

            if (tag.label === '_som_targeting_keyword' && tag.values[0]) {
                this.som_targeting_keywords = tag.values;
            }

            if (tag.label === '_Lizenzgeber' && tag.values[0]) {
                this.licence_owner = tag.values;
            }

            if (tag.callname === 'products') {
                this.products = tag;
            }

            if (tag.callname === 'format' && tag.values[0]) {
                this.is_moment_of_the_year_clip = tag.values[0] === 'moment';
            }

            if (tag.label === '_Produktionspartner') {
                this.production_partner = tag.values;
            }

            if (environment.appName === AppName.esports) {
                if (tag.callname === 'test-2') {
                    this.esport_games = tag.values;
                }
                if (tag.callname === 'event-z-b-esl-one-cologne-iem-katowice') {
                    this.esport_events = tag.values;
                }
            }

            if (environment.appName === AppName.auto) {
                if (tag.callname === 'hersteller') {
                    this.auto_marke = tag.values;
                }
                if (tag.callname === 'modell') {
                    this.auto_modell = tag.values;
                }
                if (tag.callname === 'kraftstoff') {
                    this.auto_krafstoff = tag.values;
                }

                if (tag.callname === 'farbe') {
                    this.auto_krafstoff = tag.values;
                }
            }
        });

        if (
            this.channel &&
            (this.channel.values.includes('2. HBL') ||
                this.channel.values.includes('DHB-Pokal') ||
                this.channel.values.includes('HBF') ||
                this.channel.values.includes('2. HBF'))
        ) {
            this.player_branding_left = true;
            if (this.channel.values.includes('HBF') || this.channel.values.includes('2. HBF')) {
                this.player_branding_bottom = true;
            }
        }
    }

    private isPendingVideo(video: Video | PendingVideo): video is PendingVideo {
        return (<PendingVideo>video).pending !== undefined;
    }
}
