import ads from 'videojs-contrib-ads';
import { SomtagService } from '../../../../../services/somtag.service';

import * as moment from 'moment';
import VPAIDHTML5CLIENT from 'vpaid-html5-client';
import { environment } from '../../../../../environments/environment';
import { VAST, VastClient, VastCreative, VastResponse, VastTracker } from '../../../../../../../@types/vast-client';
import { VjsAdsPluginOptions } from '../../../../../../../@types/VjsAdsPluginOptions';
import { CustomPlayer } from '../../../../../../../@types/CustomPlayer';
import { AnalyticsService } from '../../../../../services/analytics.service';
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { interval } from 'rxjs/internal/observable/interval';
import { of } from 'rxjs/internal/observable/of';
import { Asset } from '../../../../../entities/asset.entity';
import videojs from 'video.js';
import SourceObject = videojs.Tech.SourceObject;

const mywindow: any = window;
const Plugin = mywindow.videojs.getPlugin('plugin');

declare interface MyWindow extends Window {
    somtag?: any;
    VAST?: VAST;
}

export default class AdsPlugin extends Plugin {
    public playPreroll: boolean;
    public playMidroll: boolean;
    public playPostroll: boolean;
    public player: CustomPlayer;
    public myWindow: MyWindow = <any>window;
    public somtag: any = null;
    public VAST: VAST = null;
    public controlBarTextAdvertisement;
    public controlBarTextRemaining;
    private state: {
        vpaidAdUnit?: any;
        inventory?: Array<{
            media: SourceObject;
            adParameters?: string;
            tracker: VastTracker;
        }>;
        startTime?: any;
        lastMidrollTime?: any;
        prerollPlayed?: boolean;
        midrollPlayed?: boolean;
        postrollPlayed?: boolean;
        numberOfVideosPlayer?: number;
        currentAdBlock?: string;
        indexOfCurrentAdInCurrentBlock?: number;
        numberOfMidRollsPlayed?: number;
    };
    private somtagService: SomtagService;
    private analyticsService: AnalyticsService;
    private secondsBetweenMidrollBlocks: number;
    private secondsBeforeFirstMidrollBlock: number;
    private longplay: boolean;
    private adVideoDidStart: boolean;
    private playerPausedBySystem;
    private asset: Asset;
    private somtagResponseTimedOut = false;
    private adControlsShown = false;
    private blocksToRequestAfterAdBlockFinished;
    private options: VjsAdsPluginOptions;

    constructor(player, options: VjsAdsPluginOptions) {
        super(player, options);
        this.player = player;
        this.state = {};
        this.state.numberOfMidRollsPlayed = 0;
        window.addEventListener('scroll', this.onWindowScroll.bind(this));
        // accept initialization options
        this.playPreroll = options && options.playPreroll !== undefined ? options.playPreroll : true;
        this.playMidroll = options && options.playMidroll !== undefined ? options.playMidroll : true;
        this.playPostroll = options && options.playPostroll !== undefined ? options.playPostroll : true;
        this.somtagService = options.somtagService;
        this.analyticsService = options.analyticsService;

        this.secondsBetweenMidrollBlocks =
            options && options.secondsBetweenMidrollBlocks !== undefined ? options.secondsBetweenMidrollBlocks : 60 * 6;
        this.secondsBeforeFirstMidrollBlock =
            options && options.secondsBeforeFirstMidrollBlock !== undefined
                ? options.secondsBeforeFirstMidrollBlock
                : 60 * 12;
        this.longplay = options && options.longplay !== undefined ? options.longplay : false;
        this.asset = options.asset;
        this.options = options;

        if (this.asset.flags && this.asset.flags.includes('nomidroll')) {
            this.playMidroll = false;
        }

        if (mywindow.videojs.getPlugin('ads') === undefined) {
            mywindow.videojs.registerPlugin('ads', ads);
        }

        // initialize the ads plugin, passing in any relevant options
        player.ads(options);

        this.somtag = this.myWindow.somtag;
        this.VAST = this.myWindow.VAST;
        this.state.numberOfVideosPlayer = 0;
        // request ad inventory whenever the player gets content to play
        player.on('contentchanged', () => {
            this.state.numberOfVideosPlayer += 1;
            this.player.trigger('adsready');
            const number = environment.ads.somtag.numberOfVideosBeforePreRollAgainInPlaylist;
            if (number !== 0 && this.state.numberOfVideosPlayer % number === 0) {
                this.state.prerollPlayed = false;
            }
        });

        player.on('readyforpostroll', () => {
            if (!this.player.options().adsActive) {
                player.trigger('nopostroll');
                return;
            }
            if (!this.state.postrollPlayed && this.playPostroll) {
                if (
                    this.player.hasPlugin('playlistPlugin') &&
                    this.asset.videos.length - 1 === this.player.playlist.currentIndex()
                ) {
                    this.state.postrollPlayed = true;
                    this.playAd('post');
                } else if (!this.player.hasPlugin('playlistPlugin')) {
                    this.state.postrollPlayed = true;
                    this.playAd('post');
                } else {
                    player.trigger('nopostroll');
                }
            } else {
                player.trigger('nopostroll');
            }
        });

        // play an ad the first time there's a preroll opportunity
        player.on('readyforpreroll', () => {
            if (!this.player.options().adsActive) {
                player.trigger('nopreroll');
                return;
            }
            if (!this.state.prerollPlayed && this.playPreroll) {
                this.playAd('pre');
                this.state.prerollPlayed = true;
            } else {
                player.trigger('nopreroll');
            }
        });

        player.on('play', () => {
            if (!this.state.startTime) {
                this.state.startTime = moment();
            }
        });

        player.on('readyforscheduledmidroll', (test, data?: { amountOfClips: number | null }) => {
            if (!this.player.options().adsActive) {
                player.trigger('nomidroll');
                return;
            }
            let amount = null;
            if (data && data.amountOfClips) {
                amount = data.amountOfClips;
            }
            if (amount > 4) {
                amount = 4;
            }
            this.playAd('mid', amount);
        });
        // watch for time to pass 15 seconds, then play an ad
        // if we haven't played a midroll already
        if (this.playMidroll) {
            /// the video must be loaded to the timeupdate event to fire!!!
            //  see https://stackoverflow.com/questions/29618851/how-can-i-listen-for-the-timeupdate-event-from-my-video-js-player
            player.on('timeupdate', () => {
                if (!this.player.options().adsActive) {
                    return;
                }
                // todo: do not use the real time. this way the time of the preroll ads also counts!
                const currentTime = moment();
                let opportunity: boolean;
                // did already play an midroll?
                if ('lastMidrollTime' in this.state) {
                    opportunity =
                        currentTime >
                        this.state.lastMidrollTime.clone().add(this.secondsBetweenMidrollBlocks, 'seconds');
                } else if (
                    currentTime > this.state.startTime.clone().add(this.secondsBeforeFirstMidrollBlock, 'seconds')
                ) {
                    opportunity = true;
                }

                if (opportunity) {
                    this.state.numberOfMidRollsPlayed += 1;
                    this.state.lastMidrollTime = currentTime;
                    this.playAd('mid');
                }
            });
        }

        // video-contrib-ads redispatches ({eventName} -> ad{eventName}) all player events, so some ad-events have to be handled manually
        this.player.on('adtimeupdate', () => {
            if (this.player.controlBar.getChild('CustomRemainingTimeDisplay')) {
                (this.player.controlBar.getChild('CustomRemainingTimeDisplay') as any).throttledUpdateContent();
            }

            this.player.controlBar.remainingTimeDisplay.throttledUpdateContent();
        });
        this.player.on('advolumechange', () => {
            this.player.controlBar.volumePanel.volumeControl.volumeBar.update();
            this.player.controlBar.volumePanel.muteToggle.update();
        });

        this.player.on('adtimeout', event => {
            this.somtagResponseTimedOut = true;
            console.error('SOM Request timed out. VJS PluginAds.state');
        });

        document.addEventListener('custom-vjs-ads-skip-ad', () => {
            this.playNextAdOrStopPlayingAds();
        });

        document.addEventListener('custom-vjs-ads-play-midroll', () => {
            this.triggerScheduledMidroll(1);
        });
        this.player.trigger('adsready');
    }

    public playAd(block: 'mid' | 'pre' | 'post', amountOfClips: number = 1) {
        if (!this.player.options().adsActive) {
            return;
        }
        this.state.currentAdBlock = block;

        this.state.indexOfCurrentAdInCurrentBlock = 0;

        if (block === 'pre') {
            // do not pause in midrolls!
            this.player.pause();
        }

        this.player.trigger('ads-ad-willfetch');

        this.somtagService.initVideoAds(this.asset, this.longplay).subscribe(
            initialized => {
                this.somtagResponseTimedOut = false;
                this.blocksToRequestAfterAdBlockFinished = amountOfClips - 1;
                this.somtagService.loadVideoAds(block, 1, this.loadVideoAdsCallback.bind(this));
            },
            err => {
                console.error('init videoAds Error');
                console.error(err);
            }
        );
    }

    public queueLiveMidroll(scheduledMidrollMediaSequence: string, amountOfClips: number) {
        /// the video must be loaded to the this.player.on(timeupdate) event to fire!!!
        //  see https://stackoverflow.com/questions/29618851/how-can-i-listen-for-the-timeupdate-event-from-my-video-js-player

        const triggerSequence = parseInt(scheduledMidrollMediaSequence, 10);

        const unsub: Subject<any> = new Subject();

        // emit the current sequence every seconds
        const interv = interval(1000);
        interv
            .pipe(
                takeUntil(unsub),
                // @FIXME why are we using DASH instead of HLS???? The Stream is HLS not Dash!
                // On Safari there is no player.dash since it is using native HLS Support => so we directly trigger the ad
                // @see https://github.com/videojs/http-streaming/issues/214#issuecomment-417342641
                switchMap(second =>
                    of(this.player.dash ? this.player.dash.playlists.media_.mediaSequence : triggerSequence)
                ),
                distinctUntilChanged()
            )
            .subscribe((currentSequence: number) => {
                if (this.player.options().adsActive === false) {
                    console.warn('ads disabled, but requested midroll');
                    return;
                }
                if (currentSequence >= triggerSequence) {
                    unsub.next(true);
                    this.triggerScheduledMidroll(amountOfClips);
                }
            });
    }

    private triggerScheduledMidroll(amountOfClips = null) {
        if (this.player && this.playerIsPlaying() && this.player.options().adsActive) {
            this.player.trigger('readyforscheduledmidroll', {
                amountOfClips: amountOfClips
            });
        } else {
            console.warn('did not play live midroll, player not playing or ad playing');
        }
    }

    private showAdModeControls() {
        if (this.adControlsShown) {
            return;
        }
        this.adControlsShown = true;
        this.addCustomRemainingTime();
        this.addControlBarText('AdText', this.controlBarTextAdvertisement, 'vjs-ad-text');
        this.addControlBarText('RemainingTimeText', this.controlBarTextRemaining, 'vjs-remaining-time-text');
    }

    private playerIsPlaying(): boolean {
        if (!this.player) {
            return false;
        }
        try {
            return !this.player.paused();
        } catch (e) {
            return false;
        }
    }

    private hideAdModeControls() {
        this.player.getChild('controlBar').removeChild(this.player.getChild('controlBar').getChild('AdText'));
        this.player
            .getChild('controlBar')
            .removeChild(this.player.getChild('controlBar').getChild('RemainingTimeText'));
        this.player
            .getChild('controlBar')
            .removeChild(this.player.getChild('controlBar').getChild('CustomRemainingTimeDisplay'));
        this.adControlsShown = false;
    }

    private playNextAdOrStopPlayingAds() {
        if (this.state && this.state.inventory && this.state.inventory.length > 0) {
            const inventoryToPlay = { ...this.state.inventory[0] };

            this.adVideoDidStart = false;

            /// register play next for LINEAR ADS
            // PLAY NEXT FOR VAST Ads is done in the Listener for AdVideoComplete

            // on source load the player is seeking to the end of the video and sending
            // adended too early
            // this is a bug in videojs or chrome
            this.player.on('adtimeupdate', adtimeupdateEvent => {
                // only when timeupdate is not 0 or length of the player we
                // can securely listen to the adended event event to trigger the next src
                if (
                    this.adVideoDidStart === false &&
                    this.player.currentTime() !== 0 &&
                    // @ts-ignore
                    this.player.currentTime() < inventoryToPlay.tracker.assetDuration
                ) {
                    // video did start now
                    this.adVideoDidStart = true;
                    this.player.one('adended', ev => {
                        this.state.indexOfCurrentAdInCurrentBlock += 1;
                        // adended triggering  playNextAdOrStopPlayingAds
                        this.player.trigger('custom-ad-ended');
                        this.playNextAdOrStopPlayingAds();
                    });
                }
            });

            // for VAST we need to listen to the AdVideoComplete event
            this.playInventory(inventoryToPlay);

            return;
        }

        if (this.blocksToRequestAfterAdBlockFinished > 0) {
            this.blocksToRequestAfterAdBlockFinished--;
            this.somtagService.loadVideoAds('mid', 1, this.loadVideoAdsCallback);
            return;
        }

        this.hideAdModeControls();

        if (!this.state.vpaidAdUnit) {
            // vpaid ad units end linear ad mode by themself
            // console.log('endLinearAdMode');
            this.player.ads.endLinearAdMode();
        }
        this.player.trigger('ads-ad-ended');

        this.somtagService.loadDisplayAds();
    }

    private loadVideoAdsCallback = (error: Error, vastResponses: (VastResponse | {})[], vastClient: VastClient) => {
        if (!vastResponses || this.somtagResponseTimedOut) {
            console.warn('No inventory to play (no vast response)');
            let reason = 'unknown';
            if (!vastResponses) {
                reason = 'no vast response';
            }
            if (this.somtagResponseTimedOut) {
                reason = 'somtag-response-timed-out' + this.options.timeout;
            }
            this.player.trigger('ads-no-inventory-to-play', {
                block: this.state.currentAdBlock,
                reason: reason
            });
            this.player.ads.skipLinearAdMode();
            this.player.trigger('ads-ad-ended');
            return;
        }
        const validVastResponses = vastResponses.filter(vast => vast.hasOwnProperty('ads'));
        if (validVastResponses.length === 0) {
            console.warn('No inventory to play. emtpy vast response');
            this.player.trigger('ads-no-inventory-to-play', {
                block: this.state.currentAdBlock,
                reason: 'vast response is empty'
            });
            this.player.ads.skipLinearAdMode();
            this.player.trigger('ads-ad-ended');
            return;
        }
        if (error) {
            console.error('Somtag Error', error.message);
        }
        this.analyticsService.trackEvent('DebugAdsPlayAdBlock', {
            category: 'DebugAdsPlayAdBlock',
            event: 'DebugAdsPlayAdBlock',
            noninteraction: true,
            gtmCustom: {
                label: this.state.currentAdBlock || 'unknown'
            }
        });

        this.showAdModeControls();

        this.state.inventory = [];

        validVastResponses.forEach((vast: VastResponse) => {
            vast.ads.forEach(ad => {
                ad.creatives.forEach((creative: VastCreative) => {
                    if (creative.type !== 'linear') {
                        console.warn('can not load companion creative');
                        return;
                    }
                    // a creative can have more than one mediafile, but we do not care
                    const mediaFile = creative.mediaFiles.find(
                        file => file.mimeType === 'video/mp4' || file.mimeType === 'application/javascript'
                    );

                    if (mediaFile && mediaFile.fileURL.startsWith('https://ad.71i.de/images/fallback/')) {
                        mediaFile.mimeType = 'fallback';
                    }
                    const tracker: VastTracker = new mywindow.VAST.VASTTracker(vastClient, ad, creative);
                    if (mediaFile) {
                        this.state.inventory.push({
                            media: {
                                src: mediaFile.fileURL,
                                type: mediaFile.mimeType
                            },
                            adParameters: creative.adParameters,
                            tracker: tracker
                        });
                    } else {
                        console.warn('media file not playable', creative);
                    }
                });
            });
        });
        if (this.state.inventory.length === 0) {
            console.warn('No inventory to play (no inventory)');
            this.player.trigger('ads-no-inventory-to-play', {
                block: this.state.currentAdBlock,
                reason: 'vast response empty after parsing'
            });
            this.player.ads.skipLinearAdMode();
            this.player.trigger('ads-ad-ended');

            return;
        }

        // tell videojs to load the ad
        // tell ads plugin we're ready to play our ad
        this.player.ads.startLinearAdMode();

        this.player.trigger('ads-ad-started');
        this.playNextAdOrStopPlayingAds();
    };

    private onVPAIDLoad(err, adUnit, adParameters, vjsTechEl) {
        this.state.vpaidAdUnit = adUnit;
        if (err) {
            console.error(err);
            return;
        }

        const adLoaded = () => {
            this.player.pause();
            adUnit.startAd(function (error) {
                if (error) {
                    console.error(error);
                }
            });
        };
        adUnit.subscribe('AdLoaded', adLoaded);

        [
            'AdStopped',
            'AdSkipped',
            'AdLinearChange',
            'AdDurationChange',
            'AdExpandedChange',
            'AdRemainingTimeChange', // [Deprecated in 2.0] but will be still fired for backwards compatibility
            'AdVolumeChange',
            'AdImpression',
            'AdVideoStart',
            'AdVideoFirstQuartile',
            'AdVideoMidpoint',
            'AdVideoThirdQuartile',
            'AdVideoComplete',
            'AdInteraction',
            'AdUserAcceptInvitation',
            'AdUserMinimize',
            'AdUserClose',
            'AdPaused',
            'AdPlaying',
            'AdLog',
            'AdError',
            'AdSkippableStateChange',
            'AdClickThru',
            'AdStarted',
            'AdLoaded'
        ].forEach(event => {
            const play = this.player;
            adUnit.subscribe(event, function () {
                play.trigger('vpaid-' + event, arguments);
            });
        });

        adUnit.subscribe('AdSizeChange', () => {
            this.player.trigger('vpaid-AdSizeChange', window.innerHeight === screen.height);
        });

        adUnit.subscribe('AdLoaded', () => {
            adUnit.startAd();
        });

        adUnit.subscribe('AdVideoComplete', () => {
            this.state.vpaidAdUnit = null;
            const vpaidContainer = document.getElementById('VPAID-container');
            vpaidContainer.parentElement.removeChild(vpaidContainer);
            // do not end linearAdMode here.. there could be another ad
            this.playNextAdOrStopPlayingAds();
        });

        adUnit.subscribe('AdStopped', () => {
            // gets called when ad is skipped?
            this.state.vpaidAdUnit = null;
            const vpaidContainer = document.getElementById('VPAID-container');
            vpaidContainer.parentElement.removeChild(vpaidContainer);
            // do not end linearAdMode here.. there could be another ad!
            this.player.trigger('adended');
        });

        adUnit.handshakeVersion('2.0', (error, result) => {
            if (error) {
                console.error('handshake error', error);
            }
            const callback = function (err2) {
                if (err2) {
                    console.error(err2);
                }
            };
            //    width, height, viewMode, desiredBitrate, creativeData, environmentVars, errorCallback
            adUnit.initAd(
                this.player.currentWidth(),
                this.player.currentHeight(),
                'normal',
                -1,
                { AdParameters: adParameters },
                {},
                callback
            );
        });

        this.player.on('playerresize', () => {
            adUnit.resizeAd(this.player.currentWidth(), this.player.currentHeight(), 'normal');
        });
    }

    private addControlBarText(name: string, text: string, cssClass: string) {
        const DefaultComponent = mywindow.videojs.getComponent('Component');
        const AdText = mywindow.videojs.extend(DefaultComponent, {
            options: {},
            constructor: function (_, options) {
                this.options = options;
                DefaultComponent.apply(this, arguments);
                if (options.text) {
                    this.options = options;
                    this.updateTextContent(options.text);
                }
            },
            createEl: function () {
                return mywindow.videojs.dom.createEl('div', {
                    className: this.options.cssClass
                });
            },
            updateTextContent: function (controlBarText) {
                if (typeof controlBarText !== 'string') {
                    controlBarText = 'Text Unknown';
                }
                mywindow.videojs.dom.emptyEl(this.el());
                mywindow.videojs.dom.appendContent(this.el(), controlBarText);
            }
        });
        mywindow.videojs.registerComponent(name, AdText);
        this.player.getChild('controlBar').addChild(name, { text, cssClass }, 0);
    }

    private addCustomRemainingTime() {
        const RemainingTimeDisplay = mywindow.videojs.getComponent('RemainingTimeDisplay');
        const CustomRemainingTimeDisplay = mywindow.videojs.extend(RemainingTimeDisplay, {
            constructor: function () {
                RemainingTimeDisplay.apply(this, arguments);
            },
            formatTime_: function (time) {
                return mywindow.videojs.formatTime(Math.abs(time));
            },
            buildCSSClass() {
                return 'vjs-custom-remaining-time';
            }
        });
        mywindow.videojs.registerComponent('CustomRemainingTimeDisplay', CustomRemainingTimeDisplay);
        this.player.getChild('controlBar').addChild('CustomRemainingTimeDisplay', {}, 0);
    }

    private onWindowScroll() {
        if (this.state === null || !this.player.ads.isInAdMode()) {
            return;
        }

        if (this.state.vpaidAdUnit) {
            this.state.vpaidAdUnit.resumeAd();
        }
        if (!this.isElementInViewport(this.player.el().querySelector('.vjs-tech'))) {
            if (!this.player.paused()) {
                this.playerPausedBySystem = true;
            }
            this.player.pause();
            if (this.state.vpaidAdUnit) {
                this.state.vpaidAdUnit.pauseAd();
            }
        } else if (this.playerPausedBySystem) {
            this.player.play();
            this.playerPausedBySystem = false;
        }
    }

    private isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /*or $(window).height() */ &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
        );
    }

    private playInventory(inventory: { tracker: VastTracker; media: SourceObject; adParameters?: string }) {
        this.player.trigger('set-tracker', inventory.tracker);

        if (inventory.media.type === 'fallback') {
            inventory.tracker.trackImpression();
            inventory.tracker.complete();

            this.analyticsService.trackEvent('DebugAdsFallbackAdSkipped', {
                category: 'DebugAdsFallbackAdSkipped',
                event: 'DebugAdsFallbackAdSkipped',
                noninteraction: true,
                gtmCustom: {
                    label: this.state.currentAdBlock || 'unknown'
                }
            });
            this.state.inventory.shift();
            this.state.indexOfCurrentAdInCurrentBlock += 1;
            this.playNextAdOrStopPlayingAds();

            return;
        } else if (inventory.media.type === 'video/mp4') {
            this.player.src(inventory.media);

            setTimeout(() => {
                this.player.play();
            }, 10);

            this.player.trigger('adstarted', {
                inventory: inventory,
                block: this.state.currentAdBlock,
                indexOfCurrentAdInCurrentBlock: this.state.indexOfCurrentAdInCurrentBlock,
                numberOfMidRollsPlayed: this.state.numberOfMidRollsPlayed
            });
        } else if (inventory.media.type === 'application/javascript') {
            let params;
            try {
                params = JSON.parse(inventory.adParameters);
            } catch {
                console.warn('no JSON adParameters, trying XML');
                const domParser = new DOMParser();
                params = domParser.parseFromString(inventory.adParameters, 'text/xml');
            }

            const vjsTechEl = this.player.el().querySelector('.vjs-tech');
            const containerEl = document.createElement('div');
            containerEl.setAttribute('id', 'VPAID-container');

            this.player.el().appendChild(containerEl);
            const vpaid = new VPAIDHTML5CLIENT(containerEl, vjsTechEl, {}, { timeout: 10000, ...params });
            vpaid.loadAdUnit(inventory.media.src, (err, adUnit) =>
                this.onVPAIDLoad(err, adUnit, inventory.adParameters, vjsTechEl)
            );
            this.player.trigger('adstarted', {
                inventory: inventory,
                block: this.state.currentAdBlock,
                indexOfCurrentAdInCurrentBlock: this.state.indexOfCurrentAdInCurrentBlock,
                numberOfMidRollsPlayed: this.state.numberOfMidRollsPlayed
            });
        } else {
            console.warn('invalid ad inventory mime type skipping ad', inventory.media.type);
        }

        // finally remove the inventory from state
        this.state.inventory.shift();
    }
}
