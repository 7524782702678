import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from '../../../entities/page.entity';
import { Section } from '../../../entities/section.entity';
import { environment } from '../../../environments/environment';
import { Asset } from '../../../entities/asset.entity';
import { ExtendedPageContent } from '../../../entities/extended-page-content';
import { Playlist } from '../../../entities/playlist.entity';
import { HttpClient } from '@angular/common/http';
import { LiveEventsService } from './live-events.service';
import { PlaylistsService } from './playlists.service';
import { SectionsService } from './sections.service';
import { AssetsService } from './assets.service';
import { LiveEvent } from '../../../entities/live-event.entity';

@Injectable()
export class PermalinkService extends ApiService {
    private endpoint = 'permalinks';

    constructor(
        public http: HttpClient,
        private liveEventsService: LiveEventsService,
        private playlistsService: PlaylistsService,
        private sectionsService: SectionsService,
        private assetsService: AssetsService
    ) {
        super(http);
    }

    public getLink(path): Observable<{ page?: Page; asset?: Asset; section?: Section }> {
        const url = this.endpoint;
        return this.http.get<unknown>(url + path + '?domain=' + environment.api.domain).pipe(
            map((result: { page?: Page; asset?: Asset; section?: Section }) => {
                if (result.page && result.page.use_extended_page_contents === true) {
                    result.page = this.createDynamicPageForPage(result.page);
                }
                return result;
            }),
            map((result: { page?: Page; asset?: Asset; section?: Section }) => {
                if (result.asset) {
                    result.asset = new Asset(result.asset);
                }
                return result;
            })
        );
    }

    private createDynamicPageForPage(page: Page): Page {
        page.extended_page_contents.map((content: ExtendedPageContent, index) => {
            const numberOfElementsOption = content.visualization_options.find(
                (option: { key: string; value: any }) => option.key === 'number_of_elements'
            ) || {
                key: 'number_of_elements',
                value: 20
            };

            switch (content.content_type) {
                case 'assets':
                    page.extended_page_contents[index].page = 1;
                    page.extended_page_contents[index].per_page = numberOfElementsOption.value;
                    page.extended_page_contents[index].assets = new BehaviorSubject<{
                        items: Asset[];
                        total_assets_count: number;
                    }>(null);

                    this.assetsService
                        .getAssetsWithTagFilters(content.tag_filters, 1, numberOfElementsOption.value)
                        .subscribe((res: { assets: Asset[]; total_assets_count: number }) => {
                            page.extended_page_contents[index].assets.next({
                                items: res.assets,
                                total_assets_count: res.total_assets_count
                            });
                        });

                    page.extended_page_contents[index].loadMore = () => {
                        this.assetsService
                            .getAssetsWithTagFilters(
                                content.tag_filters,
                                page.extended_page_contents[index].page + 1,
                                page.extended_page_contents[index].per_page
                            )
                            .pipe(
                                map((newRes: { assets: Asset[]; total_assets_count: number }) => {
                                    return {
                                        items: [
                                            ...page.extended_page_contents[index].assets.getValue().items,
                                            ...newRes.assets
                                        ],
                                        total_assets_count: newRes.total_assets_count
                                    };
                                })
                            )
                            .subscribe((res: { items: Asset[]; total_assets_count: number }) => {
                                page.extended_page_contents[index].page++;
                                page.extended_page_contents[index].assets.next(res);
                            });
                    };

                    break;

                case 'asset':
                    page.extended_page_contents[index].page = 1;
                    page.extended_page_contents[index].per_page = numberOfElementsOption.value;
                    page.extended_page_contents[index].assets = new BehaviorSubject<{
                        items: Asset[];
                        total_assets_count: number;
                    }>(null);
                    this.assetsService.getAsset(content.content_uuid).subscribe((res: Asset) => {
                        page.extended_page_contents[index].assets.next({ items: [res], total_assets_count: 1 });
                    });

                    page.extended_page_contents[index].loadMore = () => {
                        console.error('load more is not possible for single asset');
                    };
                    break;

                case 'section':
                    page.extended_page_contents[index].page = 1;
                    page.extended_page_contents[index].per_page = numberOfElementsOption.value;
                    page.extended_page_contents[index].assets = new BehaviorSubject<{
                        items: Asset[];
                        total_assets_count: number;
                    }>(null);

                    this.sectionsService
                        .getAssetsForSectionID(content.content_uuid, numberOfElementsOption.value, 1)
                        .subscribe((res: { items: Asset[]; total: number }) => {
                            page.extended_page_contents[index].assets.next({
                                items: res.items,
                                total_assets_count: res.total
                            });
                        });
                    page.extended_page_contents[index].loadMore = () => {
                        this.sectionsService
                            .getAssetsForSectionID(
                                content.content_uuid,
                                page.extended_page_contents[index].per_page,
                                page.extended_page_contents[index].page + 1
                            )
                            .pipe(
                                map((newRes: { items: Asset[]; total: number }) => {
                                    return {
                                        items: [
                                            ...page.extended_page_contents[index].assets.getValue().items,
                                            ...newRes.items
                                        ],
                                        total_assets_count: newRes.total
                                    };
                                })
                            )
                            .subscribe((res: { items: Asset[]; total_assets_count: number }) => {
                                page.extended_page_contents[index].page++;
                                page.extended_page_contents[index].assets.next(res);
                            });
                    };

                    page.extended_page_contents[index].section = this.sectionsService.getSection(content.content_uuid);
                    break;

                case 'playlist':
                    page.extended_page_contents[index].page = 1;
                    page.extended_page_contents[index].per_page = numberOfElementsOption.value;
                    page.extended_page_contents[index].assets = new BehaviorSubject<{
                        items: Asset[];
                        total_assets_count: number;
                    }>(null);
                    this.playlistsService.getPlaylist(content.content_uuid).subscribe((playlist: Playlist) => {
                        page.extended_page_contents[index].assets.next({
                            items: playlist.assets,
                            total_assets_count: playlist.assets_count
                        });
                    });

                    page.extended_page_contents[index].loadMore = () => {
                        console.error('load more is not possible for playlist');
                    };
                    break;

                case 'live_events':
                    page.extended_page_contents[index].page = 1;
                    page.extended_page_contents[index].per_page = numberOfElementsOption.value;
                    page.extended_page_contents[index].liveEvents = new BehaviorSubject<{
                        items: LiveEvent[];
                        total_assets_count: number;
                    }>(null);

                    if (content.tag_filters && content.tag_filters.length) {
                        this.liveEventsService
                            .getLiveEventsForTagFilter(content.tag_filters, numberOfElementsOption.value, 1, true)
                            .pipe(
                                // todo the totals_assets count ist not correct
                                map(res => ({ items: res, total_assets_count: res.length }))
                            )
                            .subscribe(res => {
                                page.extended_page_contents[index].liveEvents.next(res);
                            });
                    } else {
                        this.liveEventsService
                            .getLiveEvents(numberOfElementsOption.value, 1, true)
                            .pipe(
                                // todo the totals_assets count ist not correct
                                map(res => ({ items: res, total_assets_count: res.length }))
                            )
                            .subscribe(res => {
                                page.extended_page_contents[index].liveEvents.next(res);
                            });
                    }
                    page.extended_page_contents[index].loadMore = () => {
                        console.error('load more is not possible for live events');
                    };
                    break;

                case 'html':
                    page.extended_page_contents[index] = content;
                    break;
                default:
                    page.extended_page_contents[index] = content;
                    console.warn('unknown extended page content type', content.content_type);
                    break;
            }
        });
        return page;
    }
}
