import { Observable } from 'rxjs/internal/Observable';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { BotInfo, BrowserInfo, detect as detectBrowser, NodeInfo, SearchBotDeviceInfo } from 'detect-browser';
import { Subject } from 'rxjs/internal/Subject';
import { PlayerErrorEnum } from '../../../enum/player-error.enum';
import { PlayerStateEnum } from '../../../enum/player-state.enum';
import { environment } from '../../../environments/environment';
import { AdblockDetectionService } from '../../../services/adblock-detection.service';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';
import { AdState, VideoState } from './states';
import { VideoComponent } from './video/video.component';
import { Asset } from '../../../entities/asset.entity';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AssetAuthorizationEntity } from '../../../entities/asset-authorization.entity';
import { HttpErrorResponse } from '@angular/common/http';
import { WebsocketService } from '../../../services/websocket.service';
import * as moment from 'moment';
import { AssetsService } from '../../../services/api/methods/assets.service';

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html'
})
export class PlayerComponent implements OnDestroy {
    @Input() public autoplay = false;
    @Input() public muted = false;
    @Input() public preload: 'none' | 'auto' | 'metadata' = 'auto';
    @Input() public ads: boolean = environment.ads.useAds;
    @Input() public showTitle = false;
    @Input() public showBody = false;
    @Input() public showLogo = false;
    @Input() public nextAsset: Asset;
    @Input() public useRevolverPlay = false;
    @Input() public shareButton = environment.player.showShareButton;
    @Input() public playlistButton = environment.player.showPlaylistButton;
    @Input() public chapterButton = environment.player.showChapterButton;
    @Input() public playlistArrows = environment.player.showPlaylistArrows;
    @Input() public conferenceMode = environment.player.conferenceMode;
    @Output() public width: EventEmitter<number> = new EventEmitter<number>();
    @Output() public reload: EventEmitter<number> = new EventEmitter<number>();
    @Output() public mainAsset: EventEmitter<Asset> = new EventEmitter<Asset>();
    @Output() public videoStateChanged: EventEmitter<VideoState> = new EventEmitter<VideoState>();
    @Output() public adStateChanged: EventEmitter<AdState> = new EventEmitter<AdState>();
    @Output() public emitFullScreenChangeToPlayer: EventEmitter<boolean> = new EventEmitter<boolean>();
    /**
     *  @TODO requestControlsUpdate was used to update the player control bar,
     *   - currently not subscribers
     *   - must be tested with Conference mode
     */
    @Output() public requestControlsUpdate: EventEmitter<void> = new EventEmitter<void>();
    @ViewChildren(VideoComponent) public videoComponents: QueryList<VideoComponent>;
    @ViewChild('container') public container: ElementRef<HTMLDivElement>;
    public fallbackVideoFullScreenEventEmitter: EventEmitter<void>;
    public richSnippet;
    public inConferenceMode = false;
    public environment = environment;
    public playerError: PlayerErrorEnum;
    public errorMessage: string;
    public playerState: PlayerStateEnum;
    public playerStateEnum = PlayerStateEnum;
    public inFullscreen = false;
    public browser: BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | null;
    public playEvent: Subject<void> = new Subject<void>();
    public assetAuthorizationToken: AssetAuthorizationEntity;
    public adMode = false;
    public bigVideoIndex = 0;
    public isOpenerBodyCollapsed = true;
    public videoInfoShown = true;

    constructor(
        private assetsService: AssetsService,
        private authService: AuthenticationService,
        private adBlockDetectionService: AdblockDetectionService,
        private socketService: WebsocketService,
        private cdr: ChangeDetectorRef
    ) {
        this.fallbackVideoFullScreenEventEmitter = new EventEmitter();
        this.browser = detectBrowser();

        window.document.addEventListener('fullscreenchange', () => {
            this.inFullscreen = !!document.fullscreenElement;
            this.emitFullScreenChangeToPlayer.next(this.inFullscreen);
            this.requestControlsUpdate.emit();
            this.cdr.detectChanges();
        });
    }

    public _assets: Asset[] = [];

    @Input('assets') set assets(assets: Asset[]) {
        this.setAssets(assets);
    }

    public refreshAssetAfterCountdown() {
        this.reload.next();
    }

    public updatePlayerState(from): void {
        this.getPlayerStateForAsset(this._assets[0]).subscribe((playerState: PlayerStateEnum) => {
            this.playerState = playerState;
            this.cdr.detectChanges();
        });
    }

    public play() {
        this.autoplay = true;
        this.playEvent.next();
    }

    public markVideoAsPlaying(index: number) {
        this.videoComponents.map((vidComp, i) => {
            if (i !== index) {
                vidComp.play();
            }
        });
    }

    public onVideoStateChange(state: VideoState) {
        this.videoStateChanged.emit(state);
        if (state === VideoState.ended && environment.player.useRevolverPlay) {
            if (this.nextAsset && this.nextAsset.uuid !== this._assets[this.bigVideoIndex].uuid) {
                this.playerState = PlayerStateEnum.REVOLVERPLAY;
                this.cdr.detectChanges();
            }
        }
    }

    public abortRevolverPlay() {
        this.playerState = this.playerStateEnum.SUCCESS;
    }

    public contextMenu(event: MouseEvent) {
        if (environment.production) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
        }
    }

    public onMediaError(error: PlayerErrorEnum, index: number) {
        if (index === this.bigVideoIndex) {
            this.playerState = PlayerStateEnum.PLAYER_ERROR;
            this.playerError = error;
        }
    }
    public onPlayerStateChange(playerState: PlayerStateEnum, index: number) {
        if (index === this.bigVideoIndex) {
            this.playerState = playerState;
        }
    }

    public onAdStateChange(state: AdState): void {
        this.adStateChanged.emit(state);
        this.adMode = state === AdState.started;
        this.cdr.detectChanges();
    }

    public makeVideoBig(index: number): void {
        if (index === this.bigVideoIndex) {
            return;
        }
        this.mainAsset.emit(this._assets[index]);
        this.bigVideoIndex = index;
    }

    public ngOnDestroy(): void {
        this._assets.map((asset: Asset) => {
            this.socketService.leave('asset-' + asset.uuid);
        });
    }

    public toggleFullscreen() {
        if (
            (window.document.fullscreenEnabled ||
                // @ts-ignore
                window.document.webkitFullscreenEnabled ||
                // @ts-ignore
                window.document.mozFullScreenEnabled ||
                // @ts-ignore
                window.document.msFullscreenEnabled) &&
            // even though fullscreen api is enabled: im Chrome 69 it is not possible to use it in iframes (syndication player)
            this.container.nativeElement.requestFullscreen !== undefined
        ) {
            if (this.inFullscreen) {
                window.document.exitFullscreen().then(() => {
                    this.inFullscreen = false;
                    this.emitFullScreenChangeToPlayer.next(false);
                    this.cdr.detectChanges();
                });
            } else {
                // end any other full screen element
                // window.document.exitFullscreen();
                this.container.nativeElement.requestFullscreen().then(() => {
                    this.inFullscreen = true;
                    this.emitFullScreenChangeToPlayer.next(true);
                    this.cdr.detectChanges();
                });
            }
        } else {
            // no playerInFullscreen api
            // just send a playerInFullscreen event to the main video
            this.fallbackVideoFullScreenEventEmitter.next();
        }
    }

    public toggleConferenceMode() {
        this.inConferenceMode ? this.leaveConferenceMode() : this.enterConferenceMode();
    }

    private enterConferenceMode() {
        const limit = this.conferenceMode === true ? 7 : 1;
        if (this._assets.length > limit) {
            this._assets = this._assets.slice(0, limit);
        }

        this.inConferenceMode = true;

        setTimeout(() => {
            this.playEvent.next();
        }, 200);
    }

    private leaveConferenceMode() {
        this.inConferenceMode = false;
        this.makeVideoBig(0);
    }

    private setSeoRichSnippet(asset: Asset) {
        let contentUrl = '';
        let thumbnailUrl = '';
        if (asset.videos && asset.videos.length > 0) {
            contentUrl = this._assets[0].videos[0].url;
        }
        if (asset.images && asset.images.length > 0) {
            thumbnailUrl = asset.images[0].url;
        }

        this.richSnippet = {
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            contentURL: contentUrl,
            description: asset.teaser,
            // 'duration': 'PT37M14S', TODO: find formater
            embedUrl: asset.player, // todo only include embed url if flag noembed is not set
            // 'interactionCount': '4756',
            name: asset.label,
            thumbnailUrl: thumbnailUrl,
            uploadDate: asset.published_at
        };
        if (asset.live) {
            this.richSnippet = {
                ...this.richSnippet,
                publication: [
                    {
                        '@type': 'BroadcastEvent',
                        isLiveBroadcast: true,
                        startDate: asset.live_at
                        // 'endDate': '2016-10-27T14:37:14+00:00' // todo add a default of 2 hours?
                    }
                ]
            };
        }
    }

    private getPlayerStateForAsset(asset: Asset): Observable<PlayerStateEnum> {
        return of(asset).pipe(
            switchMap(() => this.assetCountdownAllows(asset)),
            switchMap(() => this.geoBlockerAllows(asset)),
            switchMap(() => this.authorizationServiceAllows(asset)),
            switchMap(() => this.adBlockBlockerAllows(asset)),

            catchError((err: Error) => {
                if (Object.keys(this.playerStateEnum).includes(err.message)) {
                    return of(err.message as PlayerStateEnum);
                } else {
                    console.error(err);
                    this.errorMessage = err.message;
                    return of(PlayerStateEnum.API_ERROR);
                }
            }),
            map((response: Asset | PlayerStateEnum) => {
                if (response instanceof Asset) {
                    return PlayerStateEnum.SUCCESS;
                }
                return response;
            })
        );
    }

    private geoBlockerAllows(asset: Asset): Observable<Asset> {
        return this.assetsService.shouldBeGeoBlocked(asset).pipe(
            map((shouldBeBlocked: boolean) => {
                if (shouldBeBlocked) {
                    throw new Error(PlayerStateEnum.CONTENT_NOT_ALLOWED_FOR_REGION);
                }
                return asset;
            })
        );
    }

    private assetCountdownAllows(asset: Asset): Observable<Asset> {
        return of(asset).pipe(
            map(() => {
                if (!!asset && !!asset.live_at && !asset.live && moment().isBefore(moment(asset.live_at))) {
                    // display the countdown
                    throw new Error(PlayerStateEnum.COUNTDOWN);
                }
                return asset;
            })
        );
    }

    private authorizationServiceAllows(asset: Asset): Observable<Asset> {
        if (asset.contents_freely_accessible) {
            return of(asset);
        }
        return this.assetsService.getAssetAuthorizationToken(asset).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.error.error && Object.keys(this.playerStateEnum).includes(err.error.error)) {
                    throw new Error(err.error.error);
                }
                throw err;
            }),
            tap((authToken: AssetAuthorizationEntity) => (asset.authorization_token = authToken)),
            map(() => asset)
        );
    }

    private adBlockBlockerAllows(asset: Asset): Observable<Asset> {
        if (this.environment.ads.blockVideosForAdBlocker === false || this.environment.ads.useAds === false) {
            return of(asset);
        }
        return this.authService.adsDisabledForUser().pipe(
            switchMap((adsDisabled: boolean) => {
                if (adsDisabled) {
                    return of(false);
                } else {
                    return this.adBlockDetectionService.adBlockerActive();
                }
            }),
            map((adBlockerActive: boolean) => {
                if (adBlockerActive) {
                    throw new Error(PlayerStateEnum.AD_BLOCKER_ENABLED);
                }
                return asset;
            })
        );
    }

    private setAssets(assets: Asset[]) {
        this.playerState = PlayerStateEnum.LOADING;
        if (!assets || assets[0] === null || assets[0] === undefined) {
            return;
        }
        // limit the conference mode to 6 videos + 1 main video
        const limit = this.conferenceMode === true ? 7 : 1;
        if (assets.length > limit) {
            assets = assets.slice(0, limit);
        }

        if (this._assets.length > 0) {
            this._assets.map((asset: Asset) => {
                this.socketService.leave('asset-' + asset.uuid);
            });
        }
        this._assets = assets;

        assets.map((asset: Asset) => {
            this.socketService.join('asset-' + asset.uuid);
        });

        this.assetAuthorizationToken = null;
        this.cdr.detectChanges();
        this.getPlayerStateForAsset(this._assets[0]).subscribe(
            (playerState: PlayerStateEnum) => {
                this.playerState = playerState;
                this.requestControlsUpdate.emit();
                if (this._assets[0]) {
                    this.setSeoRichSnippet(this._assets[0]);
                }
                this.cdr.detectChanges();
            },
            err => {
                this.cdr.detectChanges();
                console.error('playerError', err);
            }
        );
    }
}
