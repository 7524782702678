import { AnalyticsService } from '../../../services/analytics.service';
import { Action, ObjectType } from '../../../entities/user-event.entity';
import { UserProfileService } from '../../../services/user-profile.service';
import { Asset } from '../../../entities/asset.entity';
import { environment } from '../../../environments/environment';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { TagFilter } from '../../../entities/tag-filter.entity';
import smoothscroll from 'smoothscroll-polyfill';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export enum TileType {
    BIG_ASSET_TILE,
    BIG_LIVE_EVENT_TILE,
    BIG_SPONSOR_TILE,
    LIVE_EVENT_TILE,
    SMALL_ASSET_TILE,
    EXTRA_SMALL_ASSET_TILE,
    ROUND_IMAGE_TILE,
    DONATION_TILE = 7
}

@Component({
    selector: 'app-asset-tile-slider',
    templateUrl: './asset-tile-slider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetTileSliderComponent {
    @Input() public tileType: TileType = TileType.SMALL_ASSET_TILE;
    @Input() public items: Asset[] | { image: string; text: string; url: string }[];
    @Input() public title?: string;
    @Input() public moreLink: string;
    @Input() public tagFilter: TagFilter;
    @Input() public tileOptions: { dateFormat: string } = { dateFormat: 'short' };
    @Output() public assetClicked: EventEmitter<Asset> = new EventEmitter();
    public environment = environment;
    public TileType = TileType;
    public page = 1;
    public numberOfPages: number;
    private nativeTileElement;
    private nativeSliderElement;

    constructor(
        private cdr: ChangeDetectorRef,
        private analyticsService: AnalyticsService,
        private userProfileService: UserProfileService
    ) {
        smoothscroll.polyfill();
    }

    @ViewChild('tile')
    public set tile(value: ElementRef) {
        if (!value) {
            return;
        }
        this.nativeTileElement = value.nativeElement;
    }

    @ViewChild('slider')
    public set slider(value: ElementRef) {
        if (!value) {
            return;
        }
        this.nativeSliderElement = value.nativeElement;
        fromEvent(value.nativeElement, 'scroll')
            .pipe(debounceTime(100))
            .subscribe((e: Event) => {
                const sliderElement = e.target as HTMLElement;
                this.page = (sliderElement.scrollLeft + sliderElement.clientWidth) / sliderElement.clientWidth;
                this.cdr.detectChanges();
            });
    }

    public prev() {
        if (this.page <= 1) {
            return;
        }
        this.nativeSliderElement.scrollBy({
            left: -this.nativeSliderElement.clientWidth,
            behavior: 'smooth'
        });
    }

    public next() {
        this.numberOfPages =
            this.items.length / (this.nativeSliderElement.clientWidth / this.nativeTileElement.clientWidth);
        if (this.page >= this.numberOfPages) {
            return;
        }
        this.nativeSliderElement.scrollBy({
            left: this.nativeSliderElement.clientWidth,
            behavior: 'smooth'
        });
    }

    public trackByFn(index, item) {
        return item.uuid;
    }

    public trackShowMoreClick(title: string = 'more') {
        this.analyticsService.trackEvent('Teaser Click', {
            category: 'Teaser Click',
            gtmCustom: {
                title: title,
                targetURL: this.moreLink,
                listTitle: this.title,
                index: this.items.length
            }
        });
        this.userProfileService.trackEvent(ObjectType.teaser, Action.click, { title: title, targetURL: this.moreLink });
    }
}
