import { VastCreative, VastTracker } from '../../../../../../../@types/vast-client';
import { GtmOptions, VjsTrackingPluginOptions } from '../../../../../../../@types/VjsTrackingPluginOptions';
import { v4 as uuidv4 } from 'uuid';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Action, ObjectType, Teaser } from '../../../../../entities/user-event.entity';
import { environment } from '../../../../../environments/environment';

import { CustomPlayer } from '../../../../../../../@types/CustomPlayer';
import { RawAssetFromApi } from '../../../../../entities/raw-asset-from-api.entity';
import { Playlist } from '../../../../../entities/playlist.entity';
import { Section } from '../../../../../entities/section.entity';
import { Page } from '../../../../../entities/page.entity';

// @ts-ignore
const Plugin = window.videojs.getPlugin('plugin');

export default class TrackingPlugin extends Plugin {
    private videoStarted = false;
    private centiles: { [key: string]: number } = {};
    private alreadyTriggeredCentiles: { [key: string]: boolean } = {};
    private minuteTimer: any;
    private fiveMinuteTimer: any;
    private player: CustomPlayer;
    private currentVastTracker: VastTracker;
    private playingVPAID = false;
    private gtmOptions: GtmOptions;
    private viewTimeUntilAdBlock = 0;
    private stopViewTimeInterval: Subject<any>;

    constructor(player: CustomPlayer, options: VjsTrackingPluginOptions) {
        super(player, options);
        this.player = player;

        const asset = options.asset;
        const trackingVideotype = asset.live ? 'live' : 'full';

        this.gtmOptions = {
            videotype: trackingVideotype,
            showFormat: options.showFormat,
            domain: environment.tracking.customDomain ? environment.tracking.customDomain : window.location.hostname,
            channel: options.channel,
            format: options.format,
            kanal: options.kanal,
            title: asset.label,
            id: asset.uuid,
            adfree: !this.player.options().adsActive,
            inSyndicationPlayer: options.inSyndicationPlayer,
            wholesaler: options.syndicationOrigin,
            videoLifecycleID: uuidv4(),
            clipLength: asset.seconds,
            videoViews: 0,
            viewTime: 0,
            teams: asset.teams.toString(),
            season: asset.saison.toString(),
            leaguesCups: asset.wettbewerb_event.toString(),
            genre: asset.genre.toString(),
            playingVenue: asset.ort.toString(),
            clipSubType: asset.video_kategorie.toString(),
            subscriptionModel: options.userHasSubscriptionWhichRemovesAds ? 'Abo' : 'noSub',
            payContent: !asset.contents_freely_accessible,
            positionTime: 0,
            sourceCompany: options.sourceCompany,
            productionPartner: asset.production_partner.toString()
        };

        this.emitToAnalyticsService('Init', {
            category: 'Init',
            noninteraction: true,
            gtmCustom: this.gtmOptions
        });

        // PLAYER EVENTS (should not be triggered during ad break)

        this.player.on('playing', () => {
            // first isPlaying event is used to track video start (triggered after preroll if adsPlugin is registered).
            if (!this.player.seeking() && !this.isInAdMode && !this.videoStarted) {
                this.videoStarted = true;
                this.gtmOptions.viewTime = 0;
                (this.gtmOptions.videoLifecycleID = uuidv4()),
                    (this.gtmOptions.clipLength = isFinite(this.player.duration())
                        ? Math.round(this.player.duration())
                        : 0);
                this.emitToAnalyticsService('Video Start', {
                    category: 'Video Start',
                    gtmCustom: this.gtmOptions
                });

                this.emitToUserProfileService(ObjectType.asset, Action.play, options.asset, {
                    position: this.gtmOptions.positionTime,
                    total_view_time: this.gtmOptions.viewTime,
                    ads: this.player.options().adsActive,
                    full_screen: this.player.isFullscreen()
                });

                if (this.gtmOptions.videotype === 'live') {
                    this.minuteTimer = setInterval(() => {
                        this.emitToAnalyticsService('Position Update', {
                            category: 'Position Update',
                            gtmCustom: this.gtmOptions
                        });
                        if (this.player.currentTime() > 60 * 5) {
                            clearInterval(this.minuteTimer);
                            this.fiveMinuteTimer = setInterval(() => {
                                this.emitToAnalyticsService('Position Update', {
                                    category: 'Position Update',
                                    gtmCustom: this.gtmOptions
                                });
                            }, 1000 * 60 * 5);
                        }
                    }, 1000 * 60);
                } else if (this.gtmOptions.videotype === 'full') {
                    this.alreadyTriggeredCentiles = {};
                    for (let i = 1; i <= 10; i++) {
                        this.centiles = {
                            ...this.centiles,
                            [i * 10]: Math.round(i * 10 * this.player.duration()) / 100
                        };
                    }
                }
            }
        });

        this.stopViewTimeInterval = new Subject();
        const interv = interval(1000);
        interv.pipe(takeUntil(this.stopViewTimeInterval)).subscribe(() => {
            if (!this.player) {
                return;
            }
            let viewTime = this.viewTimeUntilAdBlock;
            for (let i = 0; i < this.player.played().length; i++) {
                viewTime += this.player.played().end(i) - this.player.played().start(i);
            }
            this.gtmOptions.viewTime = Math.round(viewTime);
            const progress = this.player.currentTime();
            this.gtmOptions.positionTime = Math.round(progress);
            if (this.gtmOptions.videotype === 'full') {
                for (const centile in this.centiles) {
                    if (this.justPassedCentil(this.centiles[centile], progress, centile)) {
                        this.emitToAnalyticsService(`Video XX%`, {
                            category: `Video XX%`,
                            gtmCustom: this.gtmOptions,
                            value: centile
                        });
                        this.alreadyTriggeredCentiles[centile] = true;
                    }
                }
            }
        });

        // selected another clip from the playlist
        this.player.on('playlistitem', () => {
            this.gtmOptions.videoLifecycleID = uuidv4();
            this.videoStarted = false;
        });
        this.player.on('contentchanged', () => {
            this.videoStarted = false;
        });

        this.player.one('click', () => {
            this.emitToAnalyticsService('Videostart Click', {
                category: 'Videostart Click',
                gtmCustom: this.gtmOptions
            });
        });

        this.player.on('click', event => {
            if (this.isInAdMode && event.target.classList.contains('vjs-tech') && this.currentVastTracker) {
                this.currentVastTracker.click();
            }
        });
        this.player.on('touchend', event => {
            if (this.isInAdMode && event.target.classList.contains('vjs-tech') && this.currentVastTracker) {
                this.currentVastTracker.click();
            }
        });

        this.player.on('ended', event => {
            // If ads are active we will use the  readyforpostroll event.
            if (!this.player.options().adsActive) {
                this.videoStarted = false;
                clearInterval(this.minuteTimer);
                clearInterval(this.fiveMinuteTimer);
                this.emitToAnalyticsService('Video Completed', {
                    category: 'Video Completed',
                    gtmCustom: this.gtmOptions
                });
            }
        });

        this.player.on('fullscreenchange', (event: Event) => {
            if (this.currentVastTracker) {
                this.currentVastTracker.setFullscreen(this.player.isFullscreen());
            }

            this.emitToUserProfileService(ObjectType.asset, Action.fullscreen_change, options.asset, {
                position: this.gtmOptions.positionTime,
                total_view_time: this.gtmOptions.viewTime,
                ads: this.player.options().adsActive,
                full_screen: this.player.isFullscreen()
            });
        });

        this.player.on('lifecycleabort', () => {
            clearInterval(this.minuteTimer);
            clearInterval(this.fiveMinuteTimer);
            this.stopViewTimeInterval.next(true);
            this.gtmOptions.error = 'user induced';
            this.emitToAnalyticsService('Lifecycle Abort', {
                category: 'Lifecycle Abort',
                gtmCustom: this.gtmOptions
            });
        });

        // // AD BREAK EVENTS (fired once per ad break, not per ad)

        this.player.on('ads-ad-started', () => {
            this.viewTimeUntilAdBlock = this.gtmOptions.viewTime;
        });

        // AD EVENTS (fired once per ad)

        // While ads are beeing played, all player events are catched by the videojs-contrib-ads plugin and forwarded as 'ad${event}'

        this.player.on('adcanplay', () => {
            if (this.currentVastTracker) {
                try {
                    this.currentVastTracker.setDuration(this.player.duration());
                    this.currentVastTracker.trackImpression();
                } catch (e) {
                    console.error('vast tracker catched playerError in adcanplay', e);
                }
            }
        });

        // triggered for each ad in ad-block
        this.player.on(
            'adstarted',
            (
                event,
                data: {
                    inventory: any;
                    block: string;
                    indexOfCurrentAdInCurrentBlock: number;
                    numberOfMidRollsPlayed: number;
                }
            ) => {
                this.currentVastTracker = data.inventory.tracker;

                try {
                    const registeredEvents = [];
                    data.inventory.tracker.ad.creatives.forEach((creative: VastCreative) => {
                        Object.keys(creative.trackingEvents).forEach(trEvent => {
                            creative.trackingEvents[trEvent].forEach(eventUrl => {
                                registeredEvents.push({
                                    action: trEvent,
                                    url: eventUrl
                                });
                            });
                        });
                    });
                } catch (e) {
                    console.error(e);
                }

                this.currentVastTracker.on('clickthrough', url => {
                    if (this.isInAdMode && !this.playingVPAID) {
                        const win = window.open();
                        // win.opener = null;
                        win.location = url;
                    }
                });

                let adBlock = data.block;
                if (adBlock === 'mid') {
                    adBlock += 'roll' + data.numberOfMidRollsPlayed;
                } else {
                    adBlock += 'roll1';
                }
                if (data.indexOfCurrentAdInCurrentBlock !== 0) {
                    adBlock += String.fromCharCode(97 + data.indexOfCurrentAdInCurrentBlock);
                }
                this.gtmOptions.adBlock = adBlock;
                this.currentVastTracker.setPaused(false);
                this.emitToAnalyticsService('Ad Start', {
                    category: 'Ad Start',
                    gtmCustom: this.gtmOptions
                });
                this.gtmOptions.adBlock = undefined;
            }
        );

        this.player.on('adtimeupdate', event => {
            if (this.currentVastTracker) {
                this.currentVastTracker.setProgress(this.player.currentTime());
            }
        });

        this.player.on('adpause', event => {
            if (this.currentVastTracker) {
                this.currentVastTracker.setPaused(true);
            }
        });

        this.player.on('custom-ad-ended', () => {
            if (this.currentVastTracker) {
                this.currentVastTracker.complete();
                this.currentVastTracker = null;
            }
        });

        this.player.on('advolumechange', () => {
            if (this.currentVastTracker) {
                this.currentVastTracker.setMuted(this.player.muted());
            }
        });

        this.player.on('readyforpostroll', () => {
            // Track video completed before postroll starts. Only when adsPlugin is registered, otherwise ended event is used.
            if (this.player.options().adsActive) {
                this.emitToAnalyticsService('Video Completed', {
                    category: 'Video Completed',
                    gtmCustom: this.gtmOptions
                });
            }
        });

        this.player.on('ads-no-inventory-to-play', (event, data: { block: string; reason: string }) => {
            this.gtmOptions.adBlock = data.block;
            this.emitToAnalyticsService('DebugAdsError', {
                category: 'DebugAdsError',
                event: 'DebugAdsError',
                noninteraction: true,
                gtmCustom: {
                    label: data.block + '_' + data.reason
                }
            });

            this.gtmOptions.adBlock = undefined;
        });

        // only log ONE playerError per player.. this could flood gtm
        this.player.one('error', (error, mess, test) => {
            this.emitToAnalyticsService('Player Error', {
                gtmCustom: {
                    // @ts-ignore
                    error: this.player.error_.message || 'Unknown Player Error'
                }
            });
        });

        this.player.on('custom-overlay-plugin-shown', (event, data) => {
            this.emitToAnalyticsService('Player Overlay Teaser Shown', {
                category: 'Player',
                event: 'Player Overlay Teaser Shown',
                noninteraction: true,
                gtmCustom: this.gtmOptions
            });
        });

        /**
         * Register the VPAID events and link them to the currentVastTracker Actions
         */

        // this.player.on('vpaid-AdStopped', (args) => {});
        this.player.on('vpaid-AdSkipped', args => this.currentVastTracker.skip());
        this.player.on('vpaid-AdSizeChange', (event, isFullScreeen) => {
            // even tough this callback gets called twice, vasttracker only sends one request :)
            this.currentVastTracker.setFullscreen(isFullScreeen);
        });

        // this.player.on('vpaid-AdLinearChange', (args) => this.currentVastTracker.)
        // this.player.on('vpaid-AdDurationChange', (args) => this.currentVastTracker.)
        // this.player.on('vpaid-AdExpandedChange', (args) => this.currentVastTracker.)
        // this.player.on('vpaid-AdRemainingTimeChange',  args() => this.currentVastTracker)
        // this.player.on('vpaid-AdVolumeChange', (args) => this.currentVastTracker.))
        this.player.on('vpaid-AdImpression', args => this.currentVastTracker.trackImpression());
        // this.player.on('vpaid-AdVideoStart', (args) => {);
        this.player.on('vpaid-AdVideoFirstQuartile', args => this.currentVastTracker.setProgress(25));
        this.player.on('vpaid-AdVideoMidpoint', args => this.currentVastTracker.setProgress(50));
        this.player.on('vpaid-AdVideoThirdQuartile', args => this.currentVastTracker.setProgress(75));

        // this.player.on('vpaid-AdInteraction', (args) => this.currentVastTracker.)
        // this.player.on('vpaid-AdUserAcceptInvitation', (args) => this.currentVastTracker.)
        // this.player.on('vpaid-AdUserMinimize', (args) => this.currentVastTracker.)
        // this.player.on('vpaid-AdUserClose', (args) => this.currentVastTracker.)
        this.player.on('AdPaused', args => this.currentVastTracker.setPaused(true));
        this.player.on('AdPlaying', args => this.currentVastTracker.setPaused(false));
        // this.player.on('AdLog', (args) => this.currentVastTracker.)
        // this.player.on('AdError', (args) => this.currentVastTracker.setExpand())
        // this.player.on('AdSkippableStateChange', (args) => this.currentVastTracker.)

        this.player.on('vpaid-AdClickThru', args => {
            this.currentVastTracker.click();
        });

        this.player.on('vpaid-AdLoaded', () => {
            this.playingVPAID = true;
        });
        this.player.on('vpaid-AdVideoComplete', () => {
            this.playingVPAID = false;
        });

        window.onbeforeunload = () => {
            if (this.isInAdMode) {
                this.currentVastTracker.close();
            }
            // prevent the browsers alert window
            return undefined;
        };

        /**
         * List of known Player Events:

         'play',
         'pause',  // also triggered when seeking (pause -> isPlaying). not triggered before ad-block starts.
         'ended',
         'emptied',
         'loadeddata', // Fired when the player has downloaded data at the current playback position
         'loadstart', // Fired when the user agent begins looking for media data
         'canplay', // The media has a readyState of HAVE_FUTURE_DATA or greater.
         'stalled',
         'waiting',
         'seeked',
         'ratechange',
         'ads-ad-ended',
         'ads-ad-started',
         'ads-ad-willfetch',
         'adstarted',
         'adsready',
         'adplay',
         'adpause',
         'adend',
         'adended',
         'adcanplay',
         'contentresumed',
         'custom-ad-ended',
         'adcontentchanged',
         'adscontentchanged',
         'contentchanged',
         'adtimeout',
         'readyforpostroll',
         'readyformidroll',
         'readyforpreroll',
         'nopreroll',
         'contentplayback',
         'playlist-item-changed',
         'lifecycleabort',
         'isPlaying',
         'resumeended', // contrib-ads: Snapshot restore is done, so now we're really finished.
         'contentcanplay', // contrib-ads
         'fullscreenchange',
         'playlistitem',
         'timeupdate', // NOT reliable
         */
    }

    get isInAdMode() {
        if (this.player && this.player.hasOwnProperty('ads')) {
            return this.player.ads.isInAdMode();
        } else {
            return false;
        }
    }

    private justPassedCentil(centilTime: number, progress: number, centil: string): boolean {
        return centilTime >= progress - 1 && centilTime <= progress + 1 && !this.alreadyTriggeredCentiles[centil];
    }

    private emitToAnalyticsService(action: string, properties: any) {
        this.player.trigger('analytics-service', {
            action: action,
            properties: properties
        });
    }

    private emitToUserProfileService(
        objectType: ObjectType,
        action: Action,
        object: RawAssetFromApi | Playlist | Section | Page | Teaser | any,
        extras?: {
            position?: number;
            total_view_time?: number;
            ads?: boolean;
            full_screen?: boolean;
        },
        custom?: JSON
    ) {
        this.player.trigger('user-profile-service', {
            objectType: objectType,
            action: action,
            object: object,
            extras: extras,
            custom: custom
        });
    }
}
