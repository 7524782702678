import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AgofEnum } from '../../../enum/agof.enum';
import { environment } from '../../../environments/environment';
import { AgofService } from '../../../services/agof.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';

@Component({
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {
    public email = '';
    public isLoading = false;
    public successMessage: string;
    public errorMessage: string;
    public environment = environment;

    constructor(
        private authenticationService: AuthenticationService,
        private analyticsService: AnalyticsService,
        private agofService: AgofService,
        private activatedRoute: ActivatedRoute,
        private translation: TranslateService,
        private toastr: ToastrService
    ) {}

    public ngOnInit(): void {
        this.analyticsService.trackPageview();

        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        AgofEnum.HOMEPAGE_NO,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        AgofEnum.PAID_NO,
                        AgofEnum.CONTENT_TOPIC_Information_about_the_website_Service
                    ),
                    cpChannel: AgofEnum.CHANNEL_DEFAULT,
                    coSecondPart: window.location.pathname,
                    survey: true
                }
            }
        });

        this.activatedRoute.queryParams.subscribe(params => {
            if (params.hasOwnProperty('email')) {
                this.email = params.email;
            }
        });
    }

    public onSubmit() {
        this.isLoading = true;
        this.authenticationService.requestRegistrationConfirmationMail(this.email).subscribe(
            (response: { message: string; status: number }) => {
                this.isLoading = false;
                if (response.status !== 200) {
                    this.errorMessage = response.message;
                } else {
                    this.errorMessage = null;
                    this.successMessage = response.message;
                }
            },
            err => {
                this.isLoading = false;
                this.translation.get('toasts.errorTitle').subscribe(trans => {
                    this.toastr.error(err.error.message, trans);
                });
                console.error(err);
            }
        );
    }
}
