import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-language-select',
    templateUrl: './language-select.component.html',
    styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
    public languages: { name: string; value: string }[] = [];
    public currentLanguage: string;

    constructor(protected translate: TranslateService) {
        translate.getLangs().map((lang: string) => {
            this.languages.push({
                name: lang,
                value: lang
            });
        });
        this.currentLanguage = translate.currentLang;
    }

    public setLanguage(lang) {
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
    }

    public ngOnInit(): void {}
}
