import { ValidationService } from './form/validation.service';
import { MenuService } from './api/methods/menu.service';
import { LiveMidrollService } from './api/methods/live-midroll.service';
import { LiveEventsService } from './api/methods/live-events.service';
import { AssetsService } from './api/methods/assets.service';
import { AgeVerificationService } from './api/methods/age-verification.service';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AuthenticationService } from './api/methods/authentication.service';
import { EpgService } from './api/methods/epg.service';
import { OrderService } from './api/methods/order.service';
import { PagesService } from './api/methods/pages.service';
import { PermalinkService } from './api/methods/permalink.service';
import { PlaylistsService } from './api/methods/playlists.service';
import { ProductsService } from './api/methods/products.service';
import { SearchService } from './api/methods/search.service';
import { SectionsService } from './api/methods/sections.service';
import { SubscriptionService } from './api/methods/subscription.service';
import { SyndicationPlayerService } from './api/methods/syndication-player.service';
import { YouthProtectionPinService } from './api/methods/youth-protection-pin.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './api/api.interceptor';
import { environment } from '../environments/environment';
import { HttpCacheService } from './api/http-cache.service';
import { AdblockDetectionService } from './adblock-detection.service';
import { AgofService } from './agof.service';
import { AlertNotificationsService } from './alert-notifications.service';
import { BrowserWarningService } from './browser-warning.service';
import { AnalyticsService } from './analytics.service';
import { EmbeddedModeService } from './embedded-mode.service';
import { ExternalScriptsLoaderService } from './external-scripts-loader.service';
import { InactivityService } from './inactivity.service';
import { PageVisibilityService } from './page-visibility.service';
import { PlaylistHandlerService } from './playlist-handler.service';
import { RoutingHelperService } from './routing-helper.service';
import { SomtagService } from './somtag.service';
import { VersionCheckService } from './version-check.service';
import { APP_BASE_HREF } from '@angular/common';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SidebarService } from './sidebar.service';
import { UserProfileService } from './user-profile.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ApiUserProfileService } from './api/methods/api-user-profile.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { CanonicalTagService } from './canonical-tag.service';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LoginFormService } from '../components/elements/form/login-form/login-form.service';
import { RegisterFormService } from '../components/elements/form/register-form/register-form.service';
import { AdContainerService } from '../components/elements/ad-container/ad-container.service';
import { WebsocketService } from './websocket.service';
import { DonationService } from './donation.service';

@NgModule({
    imports: [DeviceDetectorModule, OverlayModule],
    declarations: [],
    exports: [],
    providers: [
        AgeVerificationService,
        AssetsService,
        AuthenticationService,
        EpgService,
        LiveEventsService,
        LiveMidrollService,
        MenuService,
        OrderService,
        PagesService,
        PermalinkService,
        PlaylistsService,
        ProductsService,
        SearchService,
        SectionsService,
        SubscriptionService,
        SyndicationPlayerService,
        ApiUserProfileService,
        YouthProtectionPinService,
        CanonicalTagService,
        HttpCacheService,
        ValidationService,
        AdblockDetectionService,
        AgofService,
        AlertNotificationsService,
        AnalyticsService,
        BrowserWarningService,
        EmbeddedModeService,
        DonationService,
        ExternalScriptsLoaderService,
        InactivityService,
        PageVisibilityService,
        PlaylistHandlerService,
        RoutingHelperService,
        SomtagService,
        VersionCheckService,
        SidebarService,
        UserProfileService,
        ConfirmationDialogService,
        LoginFormService,
        RegisterFormService,
        AdContainerService,
        WebsocketService,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            deps: [StorageMap],
            multi: true
        },
        /*{
            provide: HTTP_INTERCEPTORS,
            useClass: FakeApiInterceptor,
            deps: [StorageMap],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiTestErrorInterceptor,
            deps: [StorageMap],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestCacheInterceptor,
            multi: true
        },*/
        { provide: LOCALE_ID, useValue: environment.i18n.defaultLocale },

        {
            provide: APP_BASE_HREF,
            useValue: '/'
        },
        {
            provide: 'externalUrlRedirectResolver',
            useValue: (route: ActivatedRouteSnapshot) => {
                window.location.href = (route.data as { externalUrl: string }).externalUrl;
            }
        }
    ]
})
export class ServicesModule {}
