import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Page } from '../../../entities/page.entity';
import { ApiService } from '../api.service';
import { environment } from '../../../environments/environment';
import { AppName } from 'src/common/enum/app-name.enum';

@Injectable()
export class PagesService extends ApiService {
    private endpoint = 'pages';

    public getPages(perPage: number = 100): Observable<Page[]> {
        let url = this.endpoint + '?domain=' + environment.api.domain + '&per_page=' + perPage;
        if (environment.appName === AppName.tischtennis) {
            url = this.endpoint + '&per_page=' + perPage;
        }
        return this.http.get<{ items: Page[] }>(url).pipe(
            map(response => response.items),
            catchError(this.handleError('getPages', []))
        );
    }
}
