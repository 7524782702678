import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';
import { AssetsService } from '../../../services/api/methods/assets.service';
import { Router } from '@angular/router';
import { Section } from '../../../entities/section.entity';
import { PlayerComponent } from '../../elements/player/player.component';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-test-live',
    templateUrl: './test-live.component.html'
})
export class TestLiveComponent implements OnInit {
    public _assets: RawAssetFromApi[] = [];

    public relatedAssets: RawAssetFromApi[];
    public mainAsset: RawAssetFromApi;

    public environment;
    public tags = [];
    public autoplay = false;
    public hasAds = false;
    public showBottomPlaylist = environment.player.showBottomPlaylist;

    @Input() public section: Section | undefined;

    @ViewChild('player') public player: PlayerComponent;

    constructor(protected assetService: AssetsService, protected router: Router, protected cdr: ChangeDetectorRef) {
        this.environment = environment;
    }

    public ngOnInit(): void {
        this.mainAsset = this._assets[0];

        this.assetService.getAssetsForTag('Sportart', 'tischtennis', 1, 18).subscribe((assets: RawAssetFromApi[]) => {
            this.relatedAssets = assets;
        });

        this.tags = this.getTags(this.mainAsset);
    }

    public getTags(asset: RawAssetFromApi): { routerLink: string; name: string; queryParams: any }[] {
        if (!asset || !asset.tags) {
            console.warn('No RawAssetFromApi Tags found in asset', asset);
            return [];
        }
        const res = [];
        for (const key of Object.keys(asset.tags)) {
            if (key.indexOf('_') === 0) {
                continue;
            }
            if (key.indexOf('Kanal') === 0) {
                res.push({
                    routerLink: '/' + asset.section.permalink,
                    name: asset.tags[key],
                    queryParams: {}
                });
            } else {
                asset.tags[key].map(tag => {
                    res.push({
                        routerLink: '/' + environment.urlSlugs.search,
                        name: tag,
                        queryParams: { q: tag }
                    });
                });
            }
        }
        return res;
    }

    public share(network) {
        switch (network) {
            case 'facebook':
                window.open('https://www.facebook.com/sharer.php?u=' + encodeURI(window.location.href));
                return;
            case 'twitter':
                window.open('https://twitter.com/share?url=' + encodeURI(window.location.href));
                return;
            case 'google':
                window.open('https://plus.google.com/share?url=' + encodeURI(window.location.href));
                return;
        }
    }
}
