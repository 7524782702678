import { ConfirmationDialogService } from '../../../../services/confirmation-dialog.service';
import { environment } from '../../../../environments/environment';
import { User } from '../../../../entities/user.entity';
import { RawAssetFromApi } from '../../../../entities/raw-asset-from-api.entity';
import { Order } from '../../../../entities/order.entity';
import { AgofEnum } from '../../../../enum/agof.enum';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { OrderProduct } from '../../../../entities/order-product.entity';
import { ToastrService } from 'ngx-toastr';
import { filter, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { AgofService } from '../../../../services/agof.service';
import { AnalyticsService } from '../../../../services/analytics.service';
import { AssetsService } from '../../../../services/api/methods/assets.service';
import { OrderService } from '../../../../services/api/methods/order.service';
import { AuthenticationService } from '../../../../services/api/methods/authentication.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { FullOrder } from '../../../../entities/full-order.entity';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html'
})
export class OrdersComponent implements OnInit {
    public environment;

    public me: User;
    public assets: RawAssetFromApi[] = [];

    /**
     * orders only contains orders with status payment_authorized
     */
    public orders: FullOrder[];

    constructor(
        private title: Title,
        private modalService: BsModalService,
        private trans: TranslateService,
        private authenticationService: AuthenticationService,
        private orderService: OrderService,
        private assetsService: AssetsService,
        private storage: StorageMap,
        private analyticsService: AnalyticsService,
        private agofService: AgofService,
        private activeRoute: ActivatedRoute,
        private toastr: ToastrService,
        private confirmationDialogService: ConfirmationDialogService
    ) {
        this.environment = environment;
        moment.locale(this.environment.defaultLocale);

        this.loadOrders();
        this.loadMe();
    }

    public ngOnInit(): void {
        this.analyticsService.trackPageview();
        this.activeRoute.url
            .pipe(switchMap((url: UrlSegment[]) => this.storage.set('redirectAfterSetPin', url[0])))
            .subscribe(() => {});

        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        AgofEnum.HOMEPAGE_NO,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        AgofEnum.PAID_NO,
                        AgofEnum.CONTENT_TOPIC_Information_about_the_website_Service
                    ),
                    cpChannel: AgofEnum.CHANNEL_DEFAULT,
                    coSecondPart: window.location.pathname,
                    survey: true
                }
            }
        });
    }

    public loadOrders() {
        this.orderService.getOrders().subscribe(orders => {
            this.orders = orders.filter(
                (order: Order) => order.order_status !== 'created' && order.order_status !== 'setup_payment'
            );

            this.orders.forEach((order: Order) => {
                order.products.forEach((product: OrderProduct) => {
                    if (product.recurring_mode === 'non_recurring') {
                        this.assetsService.getAsset(product.asset_id).subscribe((asset: RawAssetFromApi) => {
                            if (asset) {
                                this.assets[asset.uuid] = asset;
                            }
                        });
                    }
                });
            });
        });
    }

    public loadMe() {
        this.authenticationService.me().subscribe((data: User) => {
            this.me = data;
        });
    }

    public canGetCancelled(order: Order): boolean {
        // already cancelled?
        if (order.hasOwnProperty('cancelled_at') && order.cancelled_at !== null) {
            return false;
        }
        if (order.order_status === 'payment_authorized') {
            // order has recurring product
            if (order.products.filter((prod: OrderProduct) => prod.recurring_mode === 'recurring').length > 0) {
                return true;
            }
        }

        return false;
    }

    public cancelOrder(order: Order) {
        forkJoin([
            this.trans.get('dashboard.btnCancelOrder'),
            this.trans.get('dashboard.modalConfirmCancelOrder'),
            this.trans.get('dashboard.modalYes'),
            this.trans.get('dashboard.modalNo')
        ])
            .pipe(
                switchMap(([title, text, ok, no]) => this.confirmationDialogService.confirm(title, text, ok, no)),
                filter(res => res),
                switchMap(() => this.orderService.deleteOrder(order)),
                switchMap(() =>
                    forkJoin([this.trans.get('dashboard.orderDeletedMessage'), this.trans.get('toasts.successTitle')])
                )
            )
            .subscribe(
                ([message, title]) => {
                    this.toastr.success(message, title);
                    this.loadOrders();
                    this.loadMe();
                },
                err => {
                    this.trans.get('toasts.errorTitle').subscribe(title => {
                        this.toastr.error(err, title);
                    });
                    console.error(err);
                }
            );
    }

    public getOrderActiveUntil(order: Order) {
        // todo use a pipe
        return moment(order.created_at).add(order.products[0].duration_amount, order.products[0].duration_unit);
    }

    public getAssetLink(asset: RawAssetFromApi): string {
        let link = '/';
        if (asset.section && asset.section.permalink) {
            link += asset.section.permalink + '/';
        }
        if (asset.permalink) {
            link += asset.permalink;
        }
        return link;
    }
}
