import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { environment } from '../environments/environment';
import { ConsentService } from '../modules/consent/consent.service';
import { filter, take, takeUntil } from 'rxjs/operators';

export interface ScriptModel {
    src: string;
    name: string;
    loaded?: boolean;
    loading?: boolean;
    integrity?: string;
    crossorigin?: string;
    async?: boolean;
    allowMultiple?: boolean;
    attributes?: { name: string; value: string }[];
    usercentricsTemplateId: string;
}

@Injectable()
export class ExternalScriptsLoaderService {
    private scripts: ScriptModel[] = [];

    public constructor(private consentService: ConsentService) {
        consentService.initialized$
            .pipe(
                filter(init => init === true),
                take(1)
            )
            .subscribe(init => {
                environment.loadOnInit.map((script: ScriptModel) => {
                    if (consentService.consentGiven(script.usercentricsTemplateId)) {
                        this.load(script).subscribe(() => {
                            console.log('script loaded after initial load', script.name);
                        });
                    } else {
                        consentService.consentChanged(script.usercentricsTemplateId).subscribe(consentGiven => {
                            if (consentGiven === true && !this.isScriptLoaded(script)) {
                                this.load(script).subscribe(() => {
                                    console.log('script loaded after consent change', script.name);
                                });
                            } else if (consentGiven === false && this.isScriptLoaded(script)) {
                                window.location.reload();
                            }
                        });
                    }
                });
            });
    }
    private isScriptLoaded(script: ScriptModel): boolean {
        const existingScript = this.scripts.find(s => s.src === script.src);
        return existingScript && (existingScript.loaded || existingScript.loading);
    }

    public load(script: ScriptModel): Observable<ScriptModel> {
        return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
            const existingScript = this.scripts.find(s => s.src === script.src);

            // Complete if already loaded
            if (existingScript && (existingScript.loaded || existingScript.loading) && script.allowMultiple !== true) {
                observer.next(existingScript);
                observer.complete();
            } else {
                // Add the script
                script.loaded = false;
                script.loading = true;
                this.scripts = [...this.scripts, script];

                // Load the script
                const scriptElement = document.createElement('script');
                scriptElement.type = 'text/javascript';
                scriptElement.id = 'script-' + btoa(script.name);
                scriptElement.src = script.src;
                if (script.async) {
                    scriptElement.async = true;
                }
                if (script.attributes) {
                    script.attributes.forEach((attribute: { name: string; value: string }) => {
                        scriptElement.setAttribute(attribute.name, attribute.value);
                    });
                }
                if (script.integrity) {
                    scriptElement.integrity = script.integrity;
                }
                if (script.crossorigin) {
                    scriptElement.crossOrigin = script.crossorigin;
                }

                scriptElement.onload = () => {
                    script.loaded = true;
                    script.loading = false;
                    observer.next(script);
                    observer.complete();
                };

                scriptElement.onerror = (error: any) => {
                    console.error('error loading script');
                    observer.error('Could not load script ' + script.src);
                    script.loading = false;
                    observer.error(error);
                };

                document.head.appendChild(scriptElement);
            }
        });
    }
}
