import { Component, Input, OnInit } from '@angular/core';
import { Asset } from '../../../../../entities/asset.entity';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-share-modal',
    templateUrl: './share-modal.component.html'
})
export class ShareModalComponent implements OnInit {
    public shareModalMode: 'share' | 'embed';
    public codeCopied = false;
    public embedCode: string;
    public canBeEmbedded: boolean;

    @Input() public asset: Asset;

    public shareUrls = {
        url: window.location.href,
        fb: 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href),
        twitter: 'http://twitter.com/share?url=' + window.location.href
    };

    constructor() {
        this.shareModalMode = 'share';
    }

    public ngOnInit(): void {
        if (
            this.asset.contents_freely_accessible === false ||
            (this.asset.flags && this.asset.flags.includes('noembed'))
        ) {
            this.canBeEmbedded = false;
            this.asset = null;
            return;
        }
        this.canBeEmbedded = true;

        let height = 360;
        if (environment.player.embedded.showTitle && !this.asset.hide_title_in_embedded_player) {
            height += 60;
        }
        this.embedCode =
            '<iframe src="https://' +
            document.location.host +
            '/play/' +
            this.asset.uuid +
            '" width=640 height="' +
            height +
            '" frameborder=0 allowfullscreen></iframe>';
    }
}
