export class Alert {
    public type: AlertType;
    public message: string;
    public buttonText?: string;
    public callback?: () => void; // function
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}
