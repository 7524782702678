import { AuthenticationService } from '../../../services/api/methods/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
    selector: 'app-email-confirmed-page',
    templateUrl: './email-confirmed-page.component.html',
    styleUrls: ['./email-confirmed-page.component.scss']
})
export class EmailConfirmedPageComponent implements OnInit {
    public error: boolean;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private analyticsService: AnalyticsService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.route.queryParams
            .pipe(
                switchMap(params => this.authenticationService.confirmRegistrationEmail(params['confirmation_token']))
            )
            .subscribe(
                res => {
                    if (!res.confirmed) {
                        this.error = true;
                        return;
                    }

                    this.analyticsService.trackEvent('Register Double Opt-In', {
                        category: 'Register Double Opt-In',
                        label: this.router.url
                    });
                    this.error = false;
                    this.router.navigate(['/login'], { queryParams: { email: res.email } });
                },
                err => {
                    console.error('Could not confirm E-Mail Address', err);
                    this.error = true;
                }
            );
    }
}
