import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'app-extended-page-content-modal',
    templateUrl: './extended-page-content-modal.component.html',
    styleUrls: ['./extended-page-content-modal.component.scss']
})
export class ExtendedPageContentModalComponent implements OnInit {
    @Input() public content: any;
    @Input() public id: string;

    @ViewChild('modalTemplate', { static: true }) public modalTemplate: BsModalRef;

    public modal: BsModalRef;

    constructor(private localStorage: StorageMap, private modalService: BsModalService) {}

    public hide() {
        if (this.modal) {
            this.modal.hide();
        }
    }

    public ngOnInit(): void {
        this.localStorage.get(`extended-page-content-modal-id-${this.id}`).subscribe((showCount: number) => {
            if (showCount === undefined) {
                this.localStorage
                    .set(`extended-page-content-modal-id-${this.id}`, 0, { type: 'integer' })
                    .subscribe(() => {
                        this.modal = this.modalService.show(this.modalTemplate, {
                            class: 'modal-dialog-centered',
                            animated: false
                        });
                    });
            } else {
                this.localStorage
                    .set(`extended-page-content-modal-id-${this.id}`, showCount + 1, { type: 'integer' })
                    .subscribe(() => {});
            }
        });
    }
}
