import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterValidators } from '../../../pages/register-page/form/RegisterValidators';
import { delay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../services/api/methods/authentication.service';
import { AnalyticsService } from '../../../../services/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { fromPromise } from 'rxjs/internal-compatibility';
import { of } from 'rxjs/internal/observable/of';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-register-form',
    templateUrl: './register-form.component.html'
})
export class RegisterFormComponent implements OnInit {
    public environment = environment;
    public errorMessage: string;
    public success = false;
    public showUntouchedFieldErrors = false;

    public user = {
        first_name: '',
        last_name: '',
        sex: 'M',
        age_verification_policy_confirmation: '',
        privacy_policy_confirmation: '',
        terms_of_service_confirmation: ''
    };

    public config = {
        newsletter: false
    };

    public form: FormGroup;
    public redirect: null;

    public submitted = false;

    constructor(
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        private analyticsService: AnalyticsService,
        private router: Router,
        private title: Title,
        private trans: TranslateService,
        private route: ActivatedRoute,
        private toastr: ToastrService
    ) {
        this.trans.get('register.pageTitle').subscribe(res => this.title.setTitle(res + environment.pageTitleAppendix));

        if (environment.appName === 'auto') {
            this.config.newsletter = true;
        }
        this.buildForm();

        const query = this.route.snapshot.queryParams;
        if (query.redirect) {
            this.authenticationService.pathToRedirectAfterLogin = query.redirect;
        }
    }

    public ngOnInit(): void {
        this.analyticsService.trackPageview();
        this.buildForm();
    }

    public buildForm(): void {
        let formData: any = {
            first_name: [this.user.first_name, [Validators.required]],
            last_name: [this.user.first_name, [Validators.required]],
            sex: [this.user.sex, [Validators.required]],
            toc_and_dp_confirmation: [
                this.user.privacy_policy_confirmation && this.user.terms_of_service_confirmation,
                [Validators.required, RegisterValidators.equal(true)]
            ],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            newsletter: ['', []]
        };
        if (environment.youthProtection.active) {
            formData = {
                ...formData,
                age_verification_policy_confirmation: [
                    this.user.age_verification_policy_confirmation,
                    [Validators.required]
                ]
            };
        }

        this.form = this.fb.group(formData);
    }

    public register() {
        this.showUntouchedFieldErrors = true;
        if (!this.form.valid) {
            return;
        }

        const data = this.form.value;
        if (data.toc_and_dp_confirmation === true) {
            data.terms_of_service_confirmation = 'confirmed';
            data.privacy_policy_confirmation = 'confirmed';
        }
        if (data.age_verification_policy_confirmation === true) {
            data.age_verification_policy_confirmation = 'confirmed';
        }
        if (data.newsletter !== true) {
            data.newsletter = false;
        }

        data.return_to_after_registration =
            'https://' +
            window.location.host +
            '/' +
            environment.urlSlugs.login +
            '?email=' +
            encodeURIComponent(data.email);

        if (this.authenticationService.pathToRedirectAfterLogin) {
            data.return_to_after_registration +=
                '&redirect=' + encodeURIComponent(this.authenticationService.pathToRedirectAfterLogin);
        }

        this.errorMessage = null;
        this.authenticationService
            .register(data)
            .pipe(
                tap(() => {
                    this.analyticsService.trackEvent('Register Single Opt-In', {
                        category: 'Register Single Opt-In',
                        label: this.router.url
                    });
                    this.toastr.info(
                        'Dein Account wird angelegt. Bitte habe noch einen Moment Geduld.',
                        'Bitte warten',
                        {
                            timeOut: 3000
                        }
                    );
                }),
                delay(1000),
                tap(() => {
                    this.toastr.info('Dein Account wurde angelegt.', 'Bitte warten', {
                        timeOut: 1000
                    });
                }),
                switchMap((resp: { email: string; password: string }) => {
                    if (resp.email && resp.password) {
                        return this.authenticationService.login(resp.email, resp.password).pipe(
                            switchMap((loggedIn: boolean) => {
                                if (loggedIn) {
                                    this.toastr.success('Du bist nun angemeldet');
                                    if (
                                        this.authenticationService.pathToRedirectAfterLogin !== null &&
                                        this.authenticationService.pathToRedirectAfterLogin !== window.location.pathname
                                    ) {
                                        if (
                                            this.authenticationService.pathToRedirectAfterLogin.startsWith(
                                                'https://'
                                            ) ||
                                            this.authenticationService.pathToRedirectAfterLogin.startsWith('http://')
                                        ) {
                                            window.location.href = this.authenticationService.pathToRedirectAfterLogin;
                                        } else {
                                            return fromPromise(
                                                this.router.navigateByUrl(
                                                    this.authenticationService.pathToRedirectAfterLogin
                                                )
                                            );
                                        }
                                    }
                                    return of(true);
                                }
                            })
                        );
                    }
                    return of(false);
                })
            )
            .subscribe(
                () => {
                    this.success = true;
                },
                error => {
                    this.errorMessage = error.error.message;
                    this.trans.get('toasts.errorTitle').subscribe(res => {
                        this.toastr.error(error.error.message, res);
                    });
                }
            );
    }
}
