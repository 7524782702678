import { RoutingHelperService } from './../services/routing-helper.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Asset } from '../entities/asset.entity';

@Pipe({
    name: 'assetLink'
})
export class AssetLinkPipe implements PipeTransform {
    constructor(private routingHelperService: RoutingHelperService) {}

    public transform(value: Asset, args?: any): string {
        return this.routingHelperService.getLinkFromAsset(value);
    }
}
