import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { SearchService } from '../../../services/api/methods/search.service';
import { environment } from '../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { of } from 'rxjs/internal/observable/of';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-menu-searchbar',
    templateUrl: './menu-searchbar.component.html'
})
export class MenuSearchbarComponent implements OnInit {
    public suggestions$: Observable<string[]>;
    public searchInput = new FormControl(null, { updateOn: 'change' }, null);
    public hide = false;
    public environment = environment;

    constructor(private searchService: SearchService, private router: Router, private cdr: ChangeDetectorRef) {}

    public search() {
        this.router.navigate([this.environment.urlSlugs.search], {
            queryParams: { q: this.searchInput.value, trigger: 'header-input' }
        });
        this.searchInput.reset();
    }

    public ngOnInit(): void {
        this.suggestions$ = this.searchInput.valueChanges.pipe(
            debounceTime(30),
            distinctUntilChanged(),
            switchMap(value => (value ? this.searchService.suggest(value) : of({ items: [] }))),
            map(res => res.items)
        );

        this.router.events.subscribe(evt => {
            if (evt instanceof NavigationEnd) {
                this.hide = evt.url.includes(this.environment.urlSlugs.search);
                this.cdr.detectChanges();
            }
        });
    }
}
