import { ConfirmationDialogService } from '../../../../services/confirmation-dialog.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../../entities/user.entity';
import { AuthenticationService } from '../../../../services/api/methods/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-data',
    templateUrl: './user-data.component.html'
})
export class UserDataComponent implements OnInit {
    public showUntouchedFieldErrors = false;
    public user: User;
    public form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
        private confirmationDialogService: ConfirmationDialogService
    ) {}

    public ngOnInit(): void {
        this.authenticationService.me().subscribe((me: User) => {
            this.user = me;
            this.buildForm();
        });
    }

    public buildForm(): void {
        this.form = this.fb.group({
            real_name: [this.user.real_name, [Validators.required]],
            street: [this.user.street],
            zip_code: [this.user.zip_code],
            city: [this.user.city],
            alpha2_country_code: [this.user.alpha2_country_code, [Validators.required]],
            phone: [this.user.phone],
            sex: [this.user.sex, [Validators.required]],
            newsletter: [this.user.newsletter]
            /* publisher: this.fb.group({
                 name: [this.user.publisher.name],
             })*/
        });
    }

    public save() {
        this.authenticationService
            .updateMe(this.form.getRawValue())
            .pipe(
                switchMap(() =>
                    forkJoin([
                        this.translate.get('toasts.successTitle'),
                        this.translate.get('toasts.genericSaveMessage')
                    ])
                )
            )
            .subscribe(
                ([title, message]) => {
                    this.toastr.success(message, title);
                },
                err => {
                    this.translate.get('toasts.errorTitle').subscribe(title => {
                        this.toastr.error(null, title);
                    });
                }
            );
    }

    public deleteMe(): void {
        forkJoin([
            this.translate.get('dashboard.deleteBtn'),
            this.translate.get('dashboard.deleteAccountBtn'),
            this.translate.get('dashboard.deleteUserAccountModalText'),
            this.translate.get('confirmationDialogs.cancel')
        ])
            .pipe(
                switchMap(([btnText, title, text, no]) =>
                    this.confirmationDialogService.confirm(title, text, btnText, no, 'lg').pipe(catchError(r => r))
                ),
                filter((res: boolean) => res === true),
                switchMap(() =>
                    forkJoin([
                        this.translate.get('dashboard.deleteBtn'),
                        this.translate.get('dashboard.deleteAccountBtn'),
                        this.translate.get('dashboard.deleteUserLastWarning'),
                        this.translate.get('confirmationDialogs.cancel')
                    ])
                ),
                switchMap(([btnText, title, text, no]) =>
                    this.confirmationDialogService.confirm(title, text, btnText, no, 'lg').pipe(catchError(r => r))
                ),
                filter((res: boolean) => res === true),
                switchMap(() => this.authenticationService.deleteMe()),
                // switchMap(() => this.authenticationService.logout()),
                switchMap(() =>
                    forkJoin([
                        this.translate.get('toasts.successTitle'),
                        this.translate.get('dashboard.deleteUserConfirmation')
                    ])
                )
            )
            .subscribe(
                ([title, message]) => {
                    this.toastr.success(message, title);
                    this.router.navigate(['']);
                },
                err => {
                    this.translate.get('toasts.errorTitle').subscribe(res => {
                        this.toastr.error(err, res);
                    });
                }
            );
    }
}
