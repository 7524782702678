import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../../../../services/form/validation.service';

@Component({
    selector: 'app-control-messages',
    template: ` <div *ngIf="errorMessage !== null">{{ errorMessage }}</div> `
})
export class ControlMessagesComponent {
    @Input() public control: FormControl;
    @Input() public showErrorWhenUntouched: boolean;

    constructor() {}

    get errorMessage() {
        if (this.control !== undefined) {
            for (const propertyName in this.control.errors) {
                if (
                    this.control.errors.hasOwnProperty(propertyName) &&
                    (this.control.touched || this.showErrorWhenUntouched === true)
                ) {
                    return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
                }
            }
        }

        return null;
    }
}
