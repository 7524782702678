import { Component, Input, OnInit } from '@angular/core';
import { Page } from '../../../../entities/page.entity';
import { AgofService } from '../../../../services/agof.service';
import { AgofEnum } from '../../../../enum/agof.enum';
import { AnalyticsService } from '../../../../services/analytics.service';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-server-error',
    templateUrl: './server-error.component.html'
})
export class ServerErrorComponent implements OnInit {
    @Input() public page: Page;

    constructor(public agofService: AgofService, private analyticsService: AnalyticsService, private meta: Meta) {}

    public ngOnInit(): void {
        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        AgofEnum.HOMEPAGE_NO,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        AgofEnum.PAID_NO,
                        AgofEnum.CONTENT_TOPIC_Information_about_the_website_Service
                    ),
                    cpChannel: AgofEnum.CHANNEL_DEFAULT,
                    coSecondPart: '/playerError/',
                    survey: true
                }
            }
        });

        this.meta.updateTag({ property: 'prerender-status-code', content: '500' }, 'property="prerender-status-code"');

        // @ts-ignore
        window.prerenderReady = true;
    }
}
