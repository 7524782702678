import { OrdersComponent } from './components/pages/dashboard-page/orders/orders.component';
import { MenuSearchbarComponent } from './components/elements/menu-searchbar/menu-searchbar.component';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LivestreamsComponent } from './components/pages/livestreams-page/livestreams.component';
import { PlayerComponent } from './components/elements/player/player.component';
import { RegisterComponent } from './components/pages/register-page/register.component';
import { CheckoutComponent } from './components/pages/checkout-page/checkout.component';
import { SearchComponent } from './components/pages/search-page/search.component';
import { PlayerLoginComponent } from './components/elements/player/login/player-login.component';
import { AlertComponent } from './components/atoms/alert/alert.component';
import { YouthProtectionComponent } from './components/elements/player/youth-protection/youth-protection.component';
import { NewsletterComponent } from './components/pages/newsletter-page/newsletter.component';
import { LoginComponent } from './components/pages/login-page/login.component';
import { VideoComponent } from './components/elements/player/video/video.component';
import { ProductPriceComponent } from './components/elements/product-price/product-price.component';
import { PlayerErrorComponent } from './components/elements/player/error/player-error.component';
import { PaywallComponent } from './components/elements/player/paywall/paywall.component';
import { PlayerCountdownComponent } from './components/elements/player/player-countdown/player-countdown.component';
import { AgeVerificationComponent } from './components/pages/age-verification-page/age-verification.component';
import { PageComponent } from './components/pages/dynamic-page/page/page.component';
import { ControlMessagesComponent } from './components/elements/form/control-messages/control-messages.component';
import { AssetPageComponent } from './components/pages/dynamic-page/asset-page/asset-page.component';
import { FooterComponent } from './components/elements/footer/footer.component';
import { DashboardComponent } from './components/pages/dashboard-page/dashboard.component';
import { SectionComponent } from './components/pages/dynamic-page/section/section.component';
import { ExtendedPageComponent } from './components/pages/dynamic-page/extended-page/extended-page.component';
import { AssetTileSliderComponent } from './components/elements/asset-tile-slider/asset-tile-slider.component';
import { UserDataComponent } from './components/pages/dashboard-page/user-data/user-data.component';
import { BsDropdownModule, CarouselModule, ModalModule } from 'ngx-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from './environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Angulartics2Module } from 'angulartics2';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { VideoPlaylistComponent } from './components/elements/player/video/video-playlist/video-playlist.component';
import { BigAssetTileComponent } from './components/atoms/tiles/big-asset-tile/big-asset-tile.component';
import { TileTeaserGridComponent } from './components/elements/tile-teaser-grid/tile-teaser-grid.component';
import { AdComponent } from './components/atoms/ad/ad.component';
import { AppComponent } from './components/app.component';
import { EmbeddedPlayerComponent } from './components/pages/embedded/embedded-player/embedded-player.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ShareModalComponent } from './components/elements/player/video/share-modal/share-modal.component';
import { SyndicationPlayerComponent } from './components/pages/embedded/syndication-player/syndication-player.component';
import { NavItemComponent } from './components/atoms/nav-item/nav-item.component';
import { PasswordResetComponent } from './components/pages/password-reset-page/password-reset.component';
import { PasswordForgotComponent } from './components/pages/password-forgot-page/password-forgot.component';
import { OpenerComponent } from './components/elements/opener/opener.component';
import { NotFoundComponent } from './components/pages/dynamic-page/not-found/not-found.component';
import { ButtonComponent } from './components/atoms/button/button.component';
import { SubscribeButtonComponent } from './components/atoms/subscribe-button/subscribe-button.component';
import { DynamicPageComponent } from './components/pages/dynamic-page/dynamic-page.component';
import { ImagesliderComponent } from './components/elements/imageslider/imageslider.component';
import { VideoBottomPlaylistComponent } from './components/elements/player/video/video-bottom-playlist/video-bottom-playlist.component';
import { TestLiveComponent } from './components/pages/test-live/test-live.component';
import { EmptyComponent } from './components/pages/empty/empty.component';
import { ConfirmEmailComponent } from './components/pages/confirm-email-page/confirm-email.component';
import { CtaBannerComponent } from './components/atoms/cta-banner/cta-banner.component';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { ServerErrorComponent } from './components/pages/dynamic-page/server-error/server-error.component';
import { NavLinkComponent } from './components/atoms/nav-link/nav-link.component';
import { AdContainerComponent } from './components/elements/ad-container/ad-container.component';
import { ContainerComponent } from './components/elements/container/container.component';
import { EpgComponent } from './components/pages/dynamic-page/asset-page/epg/epg.component';
import { PipesModule } from './pipes/pipes.module';
import { ServicesModule } from './services/services.module';
import { LoggedInGuard } from './guards/logged-in.guard';
// tslint:disable-next-line:max-line-length
import { ChangeYouthprotectionPinComponent } from './components/pages/dashboard-page/change-youthprotection-pin/change-youthprotection-pin.component';
import { SidebarComponent } from './components/elements/sidebar/sidebar.component';
import { RelatedAssetComponent } from './components/pages/dynamic-page/asset-page/related-asset/related-asset.component';
import { SomtagDisclaimerComponent } from './components/elements/somtag-disclaimer/somtag-disclaimer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocialLinksComponent } from './components/atoms/social-links/social-links.component';
import { TagCloudComponent } from './components/pages/dynamic-page/asset-page/tag-cloud/tag-cloud.component';
import { SectionSlidersComponent } from './components/elements/section-sliders/section-sliders.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LanguageSelectComponent } from './components/atoms/language-select/language-select.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RevolverPlayComponent } from './components/elements/player/revolver-play/revolver-play.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import {
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
    PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { AssetSubtitleComponent } from './components/atoms/asset-subtitle/asset-subtitle.component';
import { BigLiveEventTileComponent } from './components/atoms/tiles/big-live-event-tile/big-live-event-tile.component';
import { HeaderComponent } from './components/elements/header/header.component';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { AdaptiveRouterLinkDirective } from './directives/adaptive-router-link.directive';
import { EmailConfirmedPageComponent } from './components/pages/email-confirmed-page/email-confirmed-page.component';
import { InViewportModule } from 'ng-in-viewport';
import { SmallSectionTileComponent } from './components/atoms/tiles/small-section-tile/small-section-tile.component';
import { SectionTileComponent } from './components/atoms/tiles/section-tile/section-tile.component';
import { BigSponsorTileComponent } from './components/atoms/tiles/big-sponsor-tile/big-sponsor-tile.component';
import { ExtraSmallAssetTileComponent } from './components/atoms/tiles/extra-small-asset-tile/extra-small-asset-tile.component';
import { LiveEventTileComponent } from './components/atoms/tiles/live-event-tile/live-event-tile.component';
import { LoginFormComponent } from './components/elements/form/login-form/login-form.component';
import { SubscribedChannelsComponent } from './components/pages/dashboard-page/subscribed-channels/subscribed-channels.component';
import { SmallAssetTileComponent } from './components/atoms/tiles/small-asset-tile/small-asset-tile.component';
import { SportIconComponent } from './components/atoms/sport-icon/sport-icon.component';
import { ConfirmationDialogComponent } from './components/elements/confirmation-dialog/confirmation-dialog.component';
import { RegisterFormComponent } from './components/elements/form/register-form/register-form.component';
import { ExtendedPageContentModalComponent } from './components/elements/extended-page-content-modal/extended-page-content-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { RoundImageTileComponent } from './components/atoms/tiles/round-image-tile/round-image-tile.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OverlayModalComponent } from './components/elements/player/video/overlay-modal/overlay-modal.component';
import { LoadingIndicatorComponent } from './components/elements/loading-indicator/loading-indicator.component';
import { MaterialModule } from './material.module';
import { DonationTileComponent } from './components/atoms/tiles/donation-tile/donation-tile.component';
import { DonationBarComponent } from './components/atoms/donation-bar/donation-bar.component';
import { DonationButtonComponent } from './components/atoms/donation-button/donation-button.component';
import { DonationHeaderComponent } from './components/atoms/donation-header/donation-header.component';
import { DonationPageComponent } from './components/pages/donation-page/donation-page.component';
import { DonationCheckoutPageComponent } from './components/pages/donation-checkout-page/donation-checkout-page.component';
import { ConsentModule } from './modules/consent/consent.module';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

const components = [
    AppComponent,
    LivestreamsComponent,
    SearchComponent,
    FooterComponent,
    AssetPageComponent,
    PageComponent,
    PlayerComponent,
    LoginComponent,
    RegisterComponent,
    AlertComponent,
    DashboardComponent,
    SubscribedChannelsComponent,
    AgeVerificationComponent,
    SectionComponent,
    PlayerCountdownComponent,
    YouthProtectionComponent,
    VideoComponent,
    PaywallComponent,
    CheckoutComponent,
    DonationCheckoutPageComponent,
    AssetTileSliderComponent,
    ControlMessagesComponent,
    PlayerLoginComponent,
    PlayerErrorComponent,
    UserDataComponent,
    ProductPriceComponent,
    NewsletterComponent,
    VideoPlaylistComponent,
    TileTeaserGridComponent,
    AdComponent,
    EmbeddedPlayerComponent,
    SyndicationPlayerComponent,
    ShareModalComponent,
    OverlayModalComponent,
    PasswordResetComponent,
    PasswordForgotComponent,
    OpenerComponent,
    NotFoundComponent,
    ButtonComponent,
    SubscribeButtonComponent,
    DynamicPageComponent,
    ImagesliderComponent,
    VideoBottomPlaylistComponent,
    TestLiveComponent,
    EmptyComponent,
    ConfirmEmailComponent,
    CtaBannerComponent,
    ExtendedPageComponent,
    NavItemComponent,
    ServerErrorComponent,
    NavLinkComponent,
    AdContainerComponent,
    ContainerComponent,
    LoginFormComponent,
    RegisterFormComponent,
    EpgComponent,
    ChangeYouthprotectionPinComponent,
    SomtagDisclaimerComponent,
    SidebarComponent,
    MenuSearchbarComponent,
    RelatedAssetComponent,
    SocialLinksComponent,
    DonationBarComponent,
    DonationButtonComponent,
    DonationHeaderComponent,
    DonationTileComponent,
    DonationPageComponent,
    TagCloudComponent,
    SectionSlidersComponent,
    RevolverPlayComponent,
    LanguageSelectComponent,
    AssetSubtitleComponent,
    HeaderComponent,
    EmailConfirmedPageComponent,
    SmallAssetTileComponent,
    BigAssetTileComponent,
    SmallSectionTileComponent,
    SectionTileComponent,
    BigSponsorTileComponent,
    LiveEventTileComponent,
    AdaptiveRouterLinkDirective,
    ExtraSmallAssetTileComponent,
    BigLiveEventTileComponent,
    ConfirmationDialogComponent,
    OrdersComponent,
    SportIconComponent,
    ExtendedPageContentModalComponent,
    RoundImageTileComponent,
    LoadingIndicatorComponent
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        HttpClientModule,
        MaterialModule,
        CarouselModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ReactiveFormsModule,
        ClipboardModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: environment.production
        }),
        // @ts-ignore
        Angulartics2Module.forRoot([Angulartics2GoogleTagManager]),
        PipesModule,
        ServicesModule,
        InfiniteScrollModule,
        DeviceDetectorModule.forRoot(),
        CollapseModule.forRoot(),
        PerfectScrollbarModule,
        NgxJsonLdModule,
        InViewportModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true,
            progressAnimation: 'increasing',
            easeTime: 100,
            // Fires changeDetectorRef.detectChanges() when activated.
            // Helps show toast from asynchronous events outside of Angular's change detection
            onActivateTick: true
        }),
        ScrollingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                // AoT requires an exported function for factories
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ConsentModule
    ],
    declarations: [...components],
    exports: [
        ...components,
        CommonModule,
        BrowserModule,
        FormsModule,
        TranslateModule,
        HttpClientModule,
        CarouselModule,
        BsDropdownModule,
        ModalModule,
        ReactiveFormsModule,
        ServiceWorkerModule,
        Angulartics2Module,
        PipesModule,
        ServicesModule,
        InViewportModule
    ],
    providers: [
        LoggedInGuard,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates (cmp-banner)
    ]
})
export class AppCommonModule {}
