import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { EpgEntry } from '../../../entities/epg-entry.entity';

@Injectable()
export class EpgService extends ApiService {
    private endpoint = 'guide_programs';

    public getEpg(): Observable<EpgEntry[]> {
        return this.http.get<{ items: EpgEntry[] }>(this.endpoint).pipe(map(response => response.items));
    }
}
