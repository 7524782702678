import { Component, Input } from '@angular/core';
import { Section } from '../../../../entities/section.entity';

@Component({
    selector: 'app-section-tile',
    templateUrl: './section-tile.component.html'
})
export class SectionTileComponent {
    @Input() public image: string;
    @Input() public permalink: string;
    @Input() public title: string;

    @Input('section') set section(section: Section) {
        this.image = section.image;
        this.permalink = section.permalink;
        this.title = section.title;
    }
}
