import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../../services/analytics.service';
import { AgofEnum } from '../../../enum/agof.enum';
import { Playlist } from '../../../entities/playlist.entity';
import { AgofService } from '../../../services/agof.service';
import { PlaylistsService } from '../../../services/api/methods/playlists.service';
import { ActivatedRoute } from '@angular/router';
import { AlertNotificationsService } from '../../../services/alert-notifications.service';
import { AlertType } from '../../../entities/alert.entity';
import { DonationService } from '../../../services/donation.service';
import { SidebarService } from '../../../services/sidebar.service';

@Component({
    selector: 'app-donation-page',
    templateUrl: './donation-page.component.html'
})
export class DonationPageComponent implements OnInit {
    public playlist: Playlist;
    public environment = environment;
    public totalAmount: number;
    public showMoreText = false;
    private currentAllAssetsPage = 1;
    private playlistUUID = '6d75b26050130138953a448a5b29e908';

    constructor(
        private donationService: DonationService,
        private analyticsService: AnalyticsService,
        private notificationService: AlertNotificationsService,
        private route: ActivatedRoute,
        private sidebarService: SidebarService,
        private playlistsService: PlaylistsService,
        private agofService: AgofService
    ) {}

    public ngOnInit(): void {
        this.sidebarService.close();

        this.analyticsService.trackPageview();

        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        AgofEnum.HOMEPAGE_NO,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        AgofEnum.PAID_NO,
                        AgofEnum.CONTENT_TOPIC_Sports
                    ),
                    cpChannel: AgofEnum.CHANNEL_DEFAULT,
                    coSecondPart: window.location.pathname + '/',
                    survey: true
                }
            }
        });

        this.playlistsService.getPlaylist(this.playlistUUID, 1, 100).subscribe((playlist: Playlist) => {
            this.playlist = playlist;
        });

        this.donationService.getTotalAmount().subscribe(res => {
            this.totalAmount = res;
            // @ts-ignore
            window.prerenderReady = true;
        });

        this.route.queryParams.subscribe(params => {
            if (params.success) {
                this.notificationService.alert(AlertType.Success, 'Vielen Dank für deine Spende!');
            }
        });
    }

    public loadMoreOfAllAssets() {
        if (this.playlist.assets.length < this.playlist.assets_count) {
            this.playlistsService
                .getPlaylist(this.playlistUUID, this.currentAllAssetsPage + 1, 20)
                .subscribe((res: Playlist) => {
                    this.playlist.assets.push(...res.assets);
                    this.currentAllAssetsPage++;
                });
        }
    }
}
