import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertNotificationsService } from './alert-notifications.service';
import { environment } from '../environments/environment';
import { Router, RouterEvent } from '@angular/router';
import { timer } from 'rxjs/internal/observable/timer';

declare const COMMIT_REF: string;

@Injectable()
export class VersionCheckService {
    private currentHash: string;

    private url = `${window.location.protocol}//${window.location.host}/version_check`;
    private updateHandled = false;

    constructor(private http: HttpClient, private alertService: AlertNotificationsService, private router: Router) {
        this.currentHash = COMMIT_REF;
    }

    // default refresh every 5 minutes
    public initVersionCheck(frequency = 1000 * 60) {
        this.currentHash = COMMIT_REF;

        const source = timer(frequency, frequency);

        const subscribe = source.subscribe(val => {
            this.checkVersion();
        });
    }

    private checkVersion() {
        if (environment.envName === 'local' || this.updateHandled === true) {
            return;
        }

        // timestamp these requests to invalidate caches
        this.http
            .get<{
                version: string;
                version_update_method: 'notice' | 'none' | 'next_route' | 'force';
            }>(this.url + '?t=' + new Date().getTime())
            .subscribe(
                response => {
                    const hashChanged = this.currentHash !== response.version;

                    const version_update_method = response.version_update_method;

                    // If new version, do something
                    if (hashChanged === true) {
                        this.updateHandled = true;
                        switch (version_update_method) {
                            case 'force':
                                window.location.reload();
                                break;

                            case 'none':
                                return;
                            case 'notice':
                                this.alertService.warn(
                                    'Es steht eine neue Version der Seite zur Verfügung. Bitte aktualisiere die Seite.',
                                    true,
                                    'Seite neu laden',
                                    () => {
                                        window.location.reload();
                                    }
                                );
                                break;
                            case 'next_route':
                            default:
                                this.router.events.subscribe((routerEvent: RouterEvent) => {
                                    window.location.reload();
                                });

                                break;
                        }
                    }
                },
                e => {}
            );
    }
}
