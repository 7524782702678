import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { WebsocketMessage } from '../entities/websocket-message.entity';
import { Asset } from '../entities/asset.entity';
import { of } from 'rxjs';

@Injectable()
export class WebsocketService {
    public currentRooms = [];

    public join(room: string): Observable<WebsocketMessage> {
        return;
        /*this.currentRooms.push(room);
        return this.socket.emit('join-room', {room: room}, () => {
            console.log('joined room', room);
        });*/
    }

    public leave(room: string): Observable<WebsocketMessage> {
        return;
        /*if (this.currentRooms.includes(room)) {
            const idx = this.currentRooms.findIndex((elem) => elem === room);
            this.currentRooms.splice(idx, 1);
        }
        return this.socket.emit('leave-room', {room: room}, () => {
            console.log('left room', room);
        });*/
    }

    // todo add a better subscription process
    public viewers(asset: Asset): Observable<{ room: string; amount: number }> {
        return of({ room: 'asset-uuid', amount: 0 });
        /*return this.socket
            .fromEvent('viewers')
            .pipe(
                filter((event: {room: string, amount: number}) => event.room === 'asset-' + asset.uuid)
            );*/
    }

    public connect() {
        return false;
        /*// todo better handling of disconnections
        this.socket.connect();
        this.socket.on('reconnect', () => {
            console.warn('socket reconnected');
            this.enterRooms();
        });*/
    }

    private enterRooms() {
        return;
        this.currentRooms.map(room => this.join(room));
    }
}
