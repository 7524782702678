import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
    ContentTypeEnum,
    ExtendedPageContent,
    VisualisationTypeEnum
} from '../../../../entities/extended-page-content';
import { MetaTag } from '../../../../entities/meta-tag.entity';
import { Page } from '../../../../entities/page.entity';
import { AgofEnum } from '../../../../enum/agof.enum';
import { environment } from '../../../../environments/environment';
import { AgofService } from '../../../../services/agof.service';
import { AnalyticsService } from '../../../../services/analytics.service';
import { AssetsService } from '../../../../services/api/methods/assets.service';
import { PlaylistsService } from '../../../../services/api/methods/playlists.service';
import { SectionsService } from '../../../../services/api/methods/sections.service';
import { TileType } from '../../../elements/asset-tile-slider/asset-tile-slider.component';
import { AppName } from '../../../../enum/app-name.enum';
import { ConsentService } from '../../../../modules/consent/consent.service';
import { delay, filter, take, tap } from 'rxjs/operators';
import { CMP_EVENTS } from '../../../../modules/consent/cmp';
import { pipe } from 'rxjs';

@Component({
    selector: 'app-extended-page',
    templateUrl: './extended-page.component.html'
})
export class ExtendedPageComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public page: Page;
    public gridLoadingMoreMap: boolean[] = [];
    public rectHeights: number[] = [];
    public rectWidths: number[] = [];
    public excludedSectionUUIDs: string[] = [];
    public environment = environment;
    public showMinreadAd = false;
    public showInreadAd = false;
    public inreadHeight = 0;
    public TileType = TileType;
    public VisualisationTypeEnum = VisualisationTypeEnum;
    public ContentTypeEnum = ContentTypeEnum;
    public AppName = AppName;
    private _extendedPageContents: ExtendedPageContent[];

    private usercentricsBannerInterval: number;

    constructor(
        private sectionsService: SectionsService,
        private playlistsService: PlaylistsService,
        private assetsService: AssetsService,
        private analyticsService: AnalyticsService,
        private agofService: AgofService,
        private pageTitle: Title,
        private metaService: Meta,
        private consentService: ConsentService
    ) {
        this.environment = environment;
        this.showMinreadAd = environment.ads.somtag.slots.some(e => e.slot === 'minread1');
        this.showInreadAd = environment.ads.somtag.slots.some(e => e.slot === 'inread1');
    }

    public get extendedPageContents(): ExtendedPageContent[] {
        return this._extendedPageContents;
    }

    @Input()
    public set extendedPageContents(extendedPageContents: ExtendedPageContent[]) {
        this.excludedSectionUUIDs = extendedPageContents
            .filter(content => content.content_type === 'section')
            .map(content => content.content_uuid);
        this._extendedPageContents = extendedPageContents;
    }

    public ngAfterViewInit() {
        // @ts-ignore
        window.iFrameResize({}, '.iframe-auto-height');
    }

    public loadMoreOfGridAtIndex(index) {
        this._extendedPageContents[index].loadMore();
    }

    public handleRectHeight(height: number, index: number) {
        this.rectHeights[index] = height;
    }

    public handleRectWidth(width: number, index: number) {
        this.rectWidths[index] = width;
    }

    public ngOnInit(): void {
        if (this.page.metatags) {
            this.page.metatags.forEach((tag: MetaTag) => {
                if (tag.name === 'title') {
                    this.pageTitle.setTitle(tag.content);
                } else {
                    this.metaService.updateTag(
                        {
                            name: tag.name,
                            property: tag.property,
                            content: tag.content
                        },
                        'property="' + tag.property + '"'
                    );
                }
            });
        } else {
            this.pageTitle.setTitle(this.page.title);
        }
        let cpChannel;
        if (this.page.permalink === 'home') {
            cpChannel = AgofEnum.CHANNEL_HOME;
        } else {
            cpChannel = AgofEnum.CHANNEL_DEFAULT;
        }

        let homepageVar;
        if (this.page.permalink === 'home' && this.environment.appName === AppName.sportdeutschland) {
            homepageVar = AgofEnum.HOMEPAGE_YES;
        } else if (this.page.permalink === 'home') {
            homepageVar = AgofEnum.HOMEPAGE_OF_THIRD_PARTY_DOMAIN;
        } else {
            homepageVar = AgofEnum.HOMEPAGE_NO;
        }

        let survey;
        if (this.page.permalink === 'home' && this.environment.appName === AppName.sportdeutschland) {
            survey = false;
        } else {
            survey = true;
        }

        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.page.ivw_code
                        ? this.page.ivw_code
                        : this.agofService.createCategoryCode(
                              AgofEnum.LANGUAGE_GERMAN,
                              AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                              AgofEnum.CONTENT_CREATOR_EDITOR,
                              homepageVar,
                              AgofEnum.DISTRIBUTION_ONLINE,
                              AgofEnum.DEVICE_NO_APP,
                              AgofEnum.PAID_NO,
                              AgofEnum.CONTENT_TOPIC_Sports
                          ),
                    cpChannel: cpChannel,
                    coSecondPart: this.page.permalink === 'home' ? '/home/' : window.location.pathname + '/',
                    survey: survey
                }
            }
        });

        // @ts-ignore
        window.prerenderReady = true;
    }

    public ngOnDestroy() {
        if (this.usercentricsBannerInterval) {
            clearInterval(this.usercentricsBannerInterval);
        }
    }

    public showContentAsModal(content: ExtendedPageContent): boolean {
        return (
            content.visualization_options &&
            !!content.visualization_options.find(e => e.key === 'modal' && e.value === true)
        );
    }
}
