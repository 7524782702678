import { Component, OnInit } from '@angular/core';
import { LiveEventsService } from '../../../services/api/methods/live-events.service';
import { LiveEvent } from '../../../entities/live-event.entity';
import { AgofService } from '../../../services/agof.service';
import { AgofEnum } from '../../../enum/agof.enum';
import { AnalyticsService } from '../../../services/analytics.service';
import * as moment from 'moment';
import { SomtagService } from '../../../services/somtag.service';
import { environment } from '../../../environments/environment';
import { AppName } from 'src/common/enum/app-name.enum';

@Component({
    selector: 'app-livestreams',
    templateUrl: './livestreams.component.html'
})
export class LivestreamsComponent implements OnInit {
    public currentPage = 1;
    public liveEvents: LiveEvent[] = [];
    public perPage = 30;
    public loading = false;
    public sortedAndFilteredLiveEvents: LiveEvent[][] = [];
    public sportTypes: string[] = ['Alle Sportarten'];
    public activeSportType = 'Alle Sportarten';
    public channels: string[] = ['Alle Kanäle'];
    public activeChannel = 'Alle Kanäle';
    public environment = environment;
    public AppName = AppName;
    private filteredLiveEvents: LiveEvent[] = [];

    constructor(
        protected liveEventsService: LiveEventsService,
        private agofService: AgofService,
        private analyticsService: AnalyticsService,
        private somtagService: SomtagService
    ) {}

    public ngOnInit(): void {
        this.load();

        this.analyticsService.trackPageview();

        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        AgofEnum.HOMEPAGE_NO,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        AgofEnum.PAID_NO,
                        AgofEnum.CONTENT_TOPIC_Sports
                    ),
                    cpChannel: AgofEnum.CHANNEL_LIVE,
                    coSecondPart: '/livestreams/',
                    survey: true
                }
            }
        });
        this.somtagService.setDisplayAdChannelsFromString('livestreams');

        // @ts-ignore
        window.prerenderReady = true;
    }

    public filterSportType(event, index) {
        this.activeSportType = this.sportTypes[index];
        if (this.activeSportType === 'Alle Sportarten') {
            this.filteredLiveEvents = this.liveEvents;
        } else {
            this.filteredLiveEvents = this.liveEvents.filter(
                liveEvent => liveEvent.sportart && liveEvent.sportart.values[0] === this.activeSportType
            );
        }
        this.sortedAndFilteredLiveEvents = this.sortLiveEvents(this.filteredLiveEvents);
    }

    public filterChannel(event, index) {
        this.activeChannel = this.channels[index];
        if (this.activeChannel === 'Alle Kanäle') {
            this.filteredLiveEvents = this.liveEvents;
        } else {
            this.filteredLiveEvents = this.liveEvents.filter(
                liveEvent => liveEvent.sportart && liveEvent.channel.values[0] === this.activeChannel
            );
        }
        this.sortedAndFilteredLiveEvents = this.sortLiveEvents(this.filteredLiveEvents);
    }

    public load(page: number = 1) {
        this.loading = true;
        this.liveEventsService.getLiveEvents(this.perPage, page, true).subscribe((liveEvents: LiveEvent[]) => {
            this.loading = false;
            this.liveEvents = [
                ...this.liveEvents,
                ...liveEvents.filter((liveEvent: LiveEvent) => {
                    return (
                        liveEvent.path !== '/boxen/ran-fighting-tv-exklusiver-tv-kanal' &&
                        liveEvent.path !== '/ran-fighting-tv-exklusiver-tv-kanal'
                    );
                })
            ];
            // get filter from events
            this.liveEvents.forEach((event: LiveEvent) => {
                if (event.sportart && event.sportart.values && !this.sportTypes.includes(event.sportart.values[0])) {
                    this.sportTypes.push(event.sportart.values[0]);
                }
                if (event.channel && event.channel.values && !this.channels.includes(event.channel.values[0])) {
                    this.channels.push(event.channel.values[0]);
                }
            });

            if (this.activeSportType === 'Alle Sportarten') {
                this.filteredLiveEvents = this.liveEvents;
            } else {
                this.filteredLiveEvents = this.liveEvents.filter(
                    liveEvent => liveEvent.sportart && liveEvent.sportart.values[0] === this.activeSportType
                );
            }
            this.sortedAndFilteredLiveEvents = this.sortLiveEvents(this.filteredLiveEvents);
            this.currentPage = page;
        });
    }

    public loadMore() {
        this.load(this.currentPage + 1);
    }

    private sortLiveEvents(events: LiveEvent[]): LiveEvent[][] {
        let sorted: LiveEvent[][] = [];
        events.forEach(event => {
            const start = moment(event.starts_at);
            const sameDay = sorted.find(sortedEvents => moment(sortedEvents[0].starts_at).isSame(start, 'day'));
            if (sameDay) {
                sameDay.push(event);
            } else {
                sorted = [...sorted, [event]];
            }
        });
        return sorted;
    }
}
