import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys'
})
export class KeysPipe implements PipeTransform {
    public transform(value: Object): string[] {
        return Object.keys(value);
    }
}
