import { Injectable } from '@angular/core';
import * as browserUpdate from 'browser-update';

@Injectable()
export class BrowserWarningService {
    public static checkBrowser(): void {
        if (window.navigator.userAgent.includes('Windows NT 6.1')) {
            browserUpdate({
                required: {
                    i: 12
                }
            });
        } else {
            browserUpdate({
                required: {
                    i: 11
                }
            });
        }
    }
}
