import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-somtag-disclaimer',
    templateUrl: './somtag-disclaimer.component.html'
})
export class SomtagDisclaimerComponent implements OnInit {
    public elements: {
        title: string;
        description: string;
        url: string;
    }[] = [];

    constructor() {
        this.somtagDisclaimerCallback = this.somtagDisclaimerCallback.bind(this);
        // @ts-ignore
        window.somtagDisclaimerCallback = this.somtagDisclaimerCallback;
    }

    public ngOnInit(): void {
        this.loadScript();
    }

    private somtagDisclaimerCallback(data) {
        this.elements = data;
    }

    private loadScript() {
        const node = document.createElement('script');
        node.src = 'https://ad.71i.de/consent/tracking-disclaimer.jsonp';
        document.getElementsByTagName('head')[0].appendChild(node);
    }
}
