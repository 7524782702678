import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';
import { Coupon } from '../../../entities/coupon.entity';
import { User } from '../../../entities/user.entity';
import { Order } from '../../../entities/order.entity';
import { Product } from '../../../entities/product.entity';
import { environment } from '../../../environments/environment';
import { AlertNotificationsService } from '../../../services/alert-notifications.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { AssetsService } from '../../../services/api/methods/assets.service';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';
import { OrderService } from '../../../services/api/methods/order.service';
import { ProductsService } from '../../../services/api/methods/products.service';
import { ExternalScriptsLoaderService } from '../../../services/external-scripts-loader.service';
import { forkJoin } from 'rxjs';
import { Asset } from '../../../entities/asset.entity';
import { of } from 'rxjs/internal/observable/of';

declare let chckt: any;

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html'
})
export class CheckoutComponent implements OnInit {
    public environment;
    public me: User;
    public product: Product;
    public asset: Asset;
    public order: Order;
    public showError = false;
    public hasReturnedFromAdyen = false;
    public acceptsToc = false;
    public acceptsRevocation = false;
    public couponAddedManually = false;
    public couponToken = '';
    public coupons: string[] = [];

    constructor(
        private authenticationService: AuthenticationService,
        private assetService: AssetsService,
        private productService: ProductsService,
        private orderService: OrderService,
        private route: ActivatedRoute,
        private alertNotificationService: AlertNotificationsService,
        private translate: TranslateService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private analyticsService: AnalyticsService,
        private scriptService: ExternalScriptsLoaderService,
        private toastr: ToastrService
    ) {
        this.environment = environment;
    }

    public ngOnInit(): void {
        this.analyticsService.trackPageview();

        this.authenticationService.clearCache();

        const query = this.route.snapshot.queryParams;
        if (query.p && query.a) {
            this.startPaymentProcess(query.p, query.a);
            return;
        } else if (query.payload && query.o) {
            this.hasReturnedFromAdyen = true;

            this.orderService.getOrderById(query.o).subscribe(
                (order: Order) => {
                    this.order = order;

                    if (query.resultCode === 'cancelled') {
                        this.hasReturnedFromAdyen = false;
                        this.showErrorMessageAndRestartPayment('Die Zahlung wurde abgebrochen');
                        return;
                    }

                    this.verifyPayment(query.payload);
                },
                error => {
                    this.alertNotificationService.error(JSON.stringify(error.message), false);
                    this.hasReturnedFromAdyen = false;
                }
            );
            return;
        } else {
            // order default product
            this.productService
                .getProducts()
                .pipe(
                    map((prods: Product[]) => prods.filter((prod: Product) => prod.tag === 'freemium')[0]),
                    switchMap((product: Product) => {
                        this.product = product;
                        return this.assetService.getAssetForProduct(product);
                    })
                )
                .subscribe((assets: RawAssetFromApi[]) => {
                    this.startPaymentProcess(this.product.uuid, assets[0].uuid);
                });
        }
    }

    public startPaymentProcess(productId, assetId) {
        forkJoin([
            this.productService.getProduct(productId),
            this.assetService.getAsset(assetId).pipe(
                catchError(() => {
                    return this.productService.getProduct(productId).pipe(
                        switchMap(product => this.assetService.getAssetForProduct(product)),
                        map((assets: Asset[]) => assets[0])
                    );
                })
            ),
            this.authenticationService.me()
        ]).subscribe(
            ([product, asset, me]) => {
                this.product = product;
                this.asset = asset;
                this.me = me;
                if (this.asset !== null && this.product !== null && this.me !== null) {
                    this.me.customer_coupons.forEach((cp: Coupon) => {
                        this.coupons.push(cp.uuid);
                    });
                    // try to create the order
                    this.createOrderAndSetupPayment();
                }
            },
            err => {
                // most likely asset not found

                console.error('someting not found', err);
            }
        );
    }

    public addCoupon() {
        if (this.couponToken !== '') {
            this.coupons.push(this.couponToken);
        }
        this.couponAddedManually = true;

        if (this.order) {
            this.orderService.deleteOrder(this.order).subscribe(
                () => {
                    this.createOrderAndSetupPayment();
                },
                () => {
                    this.createOrderAndSetupPayment();
                }
            );
        } else {
            this.createOrderAndSetupPayment();
        }
    }

    public createOrderAndSetupPayment() {
        this.orderService.createOrder(this.product, this.asset, this.coupons).subscribe(
            (order: Order) => {
                this.order = order;
                this.couponToken = '';
                this.setupPayment();
            },
            (res: HttpErrorResponse) => {
                this.handleOrderCreateError(res);
                this.couponToken = '';
            }
        );
    }

    public handleOrderCreateError(res: HttpErrorResponse) {
        console.error('Order Create Error: ' + res.error.error);

        if (res.error.error === 'COUPON_REDEEMED' || res.error.error === 'COUPON_ALREADY_USED') {
            if (this.couponAddedManually) {
                this.translate
                    .get('checkout.start.couponRedeemed')
                    .subscribe(message => this.alertNotificationService.warn(message));
            }
            this.coupons = [];
            this.createOrderAndSetupPayment();
        } else if (res.error.error === 'COUPON_NOT_FOR_PRODUCT') {
            if (this.couponAddedManually) {
                this.translate
                    .get('checkout.start.couponNotForProduct')
                    .subscribe(message => this.alertNotificationService.warn(message));
            }
            this.coupons = [];
            this.createOrderAndSetupPayment();
        } else if (res.error.error === 'COUPON_INVALID' || res.error.error === 'COUPON_EXPIRED') {
            if (this.couponAddedManually) {
                this.translate
                    .get('checkout.start.couponInvalid')
                    .subscribe(message => this.alertNotificationService.warn(message));
            }
            this.coupons = [];
            this.createOrderAndSetupPayment();
        } else if (res.error.error === 'CONTENT_NOT_ALLOWED_FOR_REGION') {
            this.translate
                .get('player.error.notAllowedInRegion')
                .subscribe(message => this.alertNotificationService.error(message));
            return;
        } else if (res.error.error === 'PRODUCT_ALREADY_ORDERED') {
            console.warn('Order Create Error: PRODUCT_ALREADY_ORDERED');

            let subs;
            if (res.error.message.reference.order_id) {
                subs = this.orderService.getOrderById(res.error.message.reference.order_id);
            } else {
                subs = this.orderService.findExistingOrderByProduct(this.product);
            }

            subs.subscribe(
                (order: Order) => {
                    console.warn('Order Existed, found exisiting order', order);
                    this.order = order;
                    this.setupPayment();
                },
                err => {
                    console.error('could not find order for Product');
                    this.order = null;
                }
            );
        } else if (res.error.error === 'PENDING_ORDER_COUPONS') {
            // order must exist find it from db
            this.orderService.findExistingOrderByCoupon(this.coupons).subscribe(
                (order: Order) => {
                    console.warn('findExistingOrderByCoupon', order);
                    this.order = order;
                    this.setupPayment();
                },
                err => {
                    console.error('Could not find order with used coupon... recreate the order without coupon');
                    this.coupons = [];
                    this.createOrderAndSetupPayment();
                }
            );
        } else {
            this.translate.get('toasts.errorTitle').subscribe(trans => {
                this.toastr.error(res.error.error, trans);
            });
        }
    }

    public setupPayment() {
        if (this.order.order_status === 'payment_authorized') {
            console.error('order status payment_authorized');
            this.authenticationService.clearCache();
            this.translate.get('toasts.errorTitle').subscribe(title => {
                this.toastr.error('Du hast dieses Produkt bereits bestellt', title);
                this.router.navigateByUrl(environment.urlSlugs.dashboard + '/' + environment.urlSlugs.orders);
            });
            return;
        }

        if (this.order.order_status === 'no_payment_required') {
            console.warn('order status no_payment_required');
            this.authenticationService.clearCache();
            this.showSuccessMessageAndRedirectToAssetPage();
            return;
        }

        if (this.order.order_status === 'expired') {
            // order need to be recreated to get the payment form
            console.warn('order will get recreated', this.order);

            this.orderService.deleteOrder(this.order).subscribe(
                () => {
                    this.createOrderAndSetupPayment();
                },
                error2 => {
                    console.error('could not delete order', error2);
                    // 'trying to go back to setup_payment step'
                    this.orderService.setupPayment(this.order).subscribe((order: Order) => {
                        this.order = order;
                        this.paymentFormLoaded();
                    });
                }
            );
            return;
        }

        if (this.order.order_status === 'setup_payment') {
            this.paymentFormLoaded();
            return;
        }

        if (this.order.order_status === 'started_payment') {
            this.authenticationService.clearCache();
            this.translate.get('toasts.errorTitle').subscribe(title => {
                this.toastr.error(
                    'Es wurde bereits ein Bezahlvorgang gestartet. Bitte warte 5 Minuten und versuche es dann erneut.',
                    title
                );
                this.router.navigateByUrl(this.asset.permalink);
            });
            return;
        }

        // status is 'created'
        this.orderService.setupPayment(this.order).subscribe(
            (order: Order) => {
                this.order = order;
                this.paymentFormLoaded();
            },
            (err: HttpErrorResponse) => this.handleSetupPaymentError(err)
        );
    }

    public handleSetupPaymentError(err: HttpErrorResponse) {
        console.warn('handleSetupPaymentError', err);

        if (err.error.error === 'PRODUCT_ALREADY_PURCHASED') {
            this.translate.get('toasts.errorTitle').subscribe(trans => {
                this.toastr.error('Sie haben dieses Produkt bereits bestellt', trans);
            });
        }
    }

    public paymentFormLoaded() {
        this.cdr.detectChanges();
        of(this.environment.adyen.mode)
            .pipe(
                map((mode: 'test' | 'live') => {
                    return (
                        'https://checkoutshopper-' +
                        mode +
                        '.adyen.com/checkoutshopper/assets/js/sdk/checkoutSDK.' +
                        this.environment.adyen.version +
                        '.min.js'
                    );
                }),
                switchMap(src => {
                    return this.scriptService.load({
                        name: 'Adyen',
                        src: src,
                        integrity: null,
                        usercentricsTemplateId: this.environment.adyen.usercentricsTemplateId
                    });
                })
            )
            .subscribe(
                () => {
                    // initiate the adyen script
                    chckt.checkout(JSON.stringify(this.order.payment_response), '#payment-form', {
                        context: this.environment.adyen.mode,
                        useDefaultCSS: false,
                        consolidateCards: false,
                        translations: {
                            payButton: {
                                'de-DE': 'Kostenpflichtig bestellen'
                            },
                            'payButton.formatted': {
                                'de-DE': 'Kostenpflichtig bestellen %@'
                            }
                        }
                    });

                    chckt.hooks.onSubmitAction = (actionButton: HTMLButtonElement, extraData: any) => {
                        // Hide the 'pay' & 'show more payment methods' buttons
                        chckt.toggleActionButton(false);
                        chckt.toggleShowMorePMsButton(false);

                        // Give all paymentMethod divs a disabled state
                        chckt.toggleEnableAllPaymentMethods(false);
                        // Block default functionality

                        this.orderService.startPayment(this.order).subscribe(
                            (order: Order) => {
                                this.order = order;
                                chckt.submitPaymentForm();
                            },
                            () => {
                                this.toastr.error('Die Zahlung konnte nicht gestartet werden.', 'Fehler');
                            }
                        );
                        return false;
                    };

                    chckt.hooks.beforeComplete = (node, paymentData) => {
                        // 'node' is a reference to the Checkout container HTML node.
                        // 'paymentData' is the result of the payment. Includes 'payload' variable,
                        // which you should submit to the server for the Checkout API /verify call.

                        this.orderService.verifyPayment(this.order, paymentData.payload).subscribe(
                            res => {
                                this.order = res;
                                this.authenticationService.clearCache();
                                this.showSuccessMessageAndRedirectToAssetPage();
                            },
                            err => {
                                console.error(err);
                                this.translate.get('toasts.errorTitle').subscribe(trans => {
                                    this.toastr.error(JSON.stringify(err.error.message), trans);
                                });
                            }
                        );

                        return false; // return false  to replace the default handling.
                    };
                },
                err => console.error(err)
            );
    }

    public verifyPayment(payload: string) {
        if (!this.order) {
            this.showErrorMessageAndRestartPayment('Payment Error');
        }

        if (this.order.order_status === 'payment_authorized') {
            this.showSuccessMessageAndRedirectToAssetPage();
            return;
        }
        this.orderService.verifyPayment(this.order, payload).subscribe(
            () => {
                this.authenticationService.clearCache();
                this.showSuccessMessageAndRedirectToAssetPage();
            },
            err => {
                console.error('payment refused', err);
                if (err.error.message.errorMessage) {
                    this.showErrorMessageAndRestartPayment(err.error.message.errorMessage);
                } else {
                    this.showErrorMessageAndRestartPayment('Payment Error');
                }
            }
        );
    }

    public showErrorMessageAndRestartPayment(errorMessage) {
        this.toastr.error(errorMessage, 'Fehler');
        this.hasReturnedFromAdyen = false;
        this.authenticationService.clearCache();

        if (errorMessage[0] === 'Payment cancelled' || errorMessage[0] === 'Payment refused') {
            this.orderService.deleteOrder(this.order).subscribe(
                () => {
                    this.startPaymentProcess(this.order.products[0].uuid, this.order.products[0].asset_id);
                },
                () => {
                    console.error('could not delete order after canceling at adyen');
                    this.startPaymentProcess(this.order.products[0].uuid, this.order.products[0].asset_id);
                }
            );
        } else {
            this.startPaymentProcess(this.order.products[0].uuid, this.order.products[0].asset_id);
        }
    }

    public showSuccessMessageAndRedirectToAssetPage() {
        let conversionDiscountCode = '';
        if (this.order.coupons && this.order.coupons[0]) {
            conversionDiscountCode = this.order.coupons[0].uuid;
        }

        // fixme, as soon as api provides values as numbers we can provide it directly
        let totalToPay = this.order.total_to_pay;
        totalToPay = totalToPay.replace('EUR', '');
        totalToPay = totalToPay.replace(',', '.');
        let totalToPayFloat = parseFloat(totalToPay);

        if (totalToPayFloat < 0.01) {
            totalToPayFloat = 1.0;
        }
        const tax = new Date().getFullYear() === 2020 ? 1.16 : 1.19;

        const totalNetToPayFloat = totalToPayFloat / tax;

        this.analyticsService.trackEvent('Conversion', {
            event: 'Conversion',
            gtmCustom: {
                googleAnalyticsEventAction: 'Conversion',
                conversionOrderId: this.order.uuid,
                conversionTotalOrderValue: totalToPayFloat,
                conversionTotalNetOrderValue: totalNetToPayFloat,
                conversionTotalOrderCurrency: 'EUR',
                conversionDiscountCode: conversionDiscountCode
            }
        });

        this.authenticationService.clearCache();
        this.translate.get('checkout.success').subscribe(mes => {
            this.assetService.getAsset(this.order.products[0].asset_id).subscribe((asset: RawAssetFromApi) => {
                this.router.navigateByUrl('/' + asset.permalink).then(() => {
                    this.translate.get('toasts.successTitle').subscribe(trans => {
                        this.toastr.success(mes, trans);
                    });
                });
            });
        });
    }

    public orderHasNewCustomerCoupon() {
        if (!this.order) {
            return false;
        }

        if (!this.order.coupons) {
            return false;
        }
        if (this.order.coupons.length === 0) {
            return false;
        }
        const newCustomerCoupons = this.order.coupons.filter(
            (coupon: Coupon) => coupon.promotional_events && coupon.promotional_events.includes('new_customer')
        );
        return newCustomerCoupons.length > 0;
    }
}
