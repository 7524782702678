import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {
    public static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        const config = {
            required: 'Pflichtfeld',
            minlength: `Mindestens ${validatorValue.requiredLength} Zeichen`,
            email: 'Keine gültige E-Mail Adresse',
            equalTo: 'Stimmt nicht überein',
            equal: 'Pflichtfeld'
        };
        return config[validatorName];
    }
}
