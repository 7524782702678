import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { SyndicationPlayer } from '../../../entities/syndication-player.entity';

@Injectable()
export class SyndicationPlayerService extends ApiService {
    public getSyndicationPlayer(uuid: string): Observable<SyndicationPlayer> {
        return this.http.get<SyndicationPlayer>('syndications/' + uuid);
    }
}
