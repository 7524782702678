import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../services/analytics.service';
import { RoutingHelperService } from '../../../../services/routing-helper.service';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs/internal/Subject';
import { of } from 'rxjs/internal/observable/of';
import { delay, takeUntil } from 'rxjs/operators';
import { Action, ObjectType } from '../../../../entities/user-event.entity';
import { UserProfileService } from '../../../../services/user-profile.service';

@Component({
    selector: 'app-big-live-event-tile',
    templateUrl: './big-live-event-tile.component.html'
})
export class BigLiveEventTileComponent implements OnInit {
    @Input() public link: string;
    @Input() public title: string;
    @Input() public subtitle: string;
    @Input() public date: string;
    @Input() public showLiveBadge: boolean;
    @Input() public backgroundIcon: string;
    @Input() public saveDateButtonLink: string;
    @Input() public useCustomDate = false;

    @Input() public trackingInfo?: {
        listTitle: string;
        index: number;
    };

    public environment = environment;
    public mouseLeave: Subject<void> = new Subject();

    private gtmCustom;

    constructor(
        private analyticsService: AnalyticsService,
        private userProfileService: UserProfileService,
        private routingHelper: RoutingHelperService
    ) {}

    public trackGoToAsset() {
        this.analyticsService.trackEvent('Teaser Click', {
            category: 'Teaser Click',
            gtmCustom: this.gtmCustom
        });
        this.userProfileService.trackEvent(ObjectType.live_event, Action.click, this.gtmCustom);
    }

    public saveCalendar(link: string, event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();

        this.analyticsService.trackEvent('Teaser Click', {
            category: 'Teaser Click',
            gtmCustom: { ...this.gtmCustom, targetURL: link }
        });
        this.userProfileService.trackEvent(ObjectType.live_event, Action.click, { ...this.gtmCustom, targetURL: link });

        window.location.assign(link);
        return false;
    }

    public onAssetClick() {
        this.routingHelper.activateAutoplay();
        this.trackGoToAsset();
    }

    public onMouseEnter() {
        this.mouseLeave = new Subject();
        of({})
            .pipe(delay(this.userProfileService.delayHoverEvents), takeUntil(this.mouseLeave))
            .subscribe(() => {
                this.userProfileService.trackEvent(ObjectType.live_event, Action.hover, this.gtmCustom);
            });
    }

    public onMouseLeave() {
        this.mouseLeave.next();
    }

    public ngOnInit(): void {
        this.gtmCustom = {
            title: this.title,
            targetURL: this.link,
            listTitle: (this.trackingInfo || { listTitle: undefined }).listTitle,
            index: (this.trackingInfo || { index: undefined }).index
        };
    }
}
