import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { interval } from 'rxjs';
import { Action, ObjectType, Teaser, UserEvent } from '../entities/user-event.entity';
import { Playlist } from '../entities/playlist.entity';
import { Section } from '../entities/section.entity';
import { RawAssetFromApi } from '../entities/raw-asset-from-api.entity';
import { Page } from '../entities/page.entity';
import * as Fingerprint2 from 'fingerprintjs2';
import { AuthenticationService } from './api/methods/authentication.service';
import { EmbeddedModeService } from './embedded-mode.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiUserProfileService } from './api/methods/api-user-profile.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { filter, switchMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AssetsService } from './api/methods/assets.service';

@Injectable()
export class UserProfileService {
    public delayHoverEvents = 400;

    private timer: Observable<number>;
    private user_uuid: string;
    private device_id: string;
    private device_type: string;
    private os: string;
    private country: string | null;
    private source: 'web' | 'app';

    constructor(
        protected embeddedModeService: EmbeddedModeService,
        protected userProfileApiService: ApiUserProfileService,
        public auth: AuthenticationService,
        private storageService: StorageMap,
        private assetsService: AssetsService,
        private deviceService: DeviceDetectorService
    ) {}

    /**
     * Track a user Event
     * @param objectType
     * @param action
     * @param object
     * @param extras
     * @param custom
     */
    public trackEvent(
        objectType: ObjectType,
        action: Action,
        object: RawAssetFromApi | Playlist | Section | Page | Teaser | any,
        extras?: {
            position?: number;
            total_view_time?: number;
            ads?: boolean;
            full_screen?: boolean;
        },
        custom?: JSON
    ): void {
        if (!environment.tracking.trackUserEvents) {
            return;
        }
        const event: UserEvent = {
            object_type: objectType,
            action: action,
            object_id: this.getUuid(object, objectType),
            object: this.reduceObject(object, objectType),

            user_id: this.user_uuid,
            device_id: this.device_id,
            device_type: this.device_type,
            os: this.os,
            country: this.country,
            source: this.source,
            ads: extras ? extras.ads : null,
            position: extras ? extras.position : null,
            total_view_time: extras ? extras.total_view_time : null,
            full_screen: extras ? extras.full_screen : null,

            timestamp: +new Date(),

            url: window.location.href,
            custom: custom
        };
        this.addEvent(event);
    }

    public init() {
        if (this.timer !== undefined || !environment.tracking.trackUserEvents) {
            return;
        }

        if (window.hasOwnProperty('requestIdleCallback')) {
            // @ts-ignore
            window.requestIdleCallback(() => {
                this.calculateDeviceHash();
            });
        } else {
            setTimeout(() => {
                this.calculateDeviceHash();
            }, 2000);
        }

        this.auth.me().subscribe(
            me => {
                if (me.uuid) {
                    this.user_uuid = me.uuid;
                }
            },
            () => {}
        );

        const deviceInfo = this.deviceService.getDeviceInfo();

        this.device_type = deviceInfo.device;
        this.os = deviceInfo.os;

        this.auth.getCountryCode().subscribe((res: { country: string | null }) => {
            this.country = res.country;
        });

        this.embeddedModeService.embedded.subscribe((embedded: boolean) => {
            if (embedded) {
                this.source = 'app';
            } else {
                this.source = 'web';
            }
        });
        this.timer = interval(5000);
        this.timer.subscribe(this.checkAndSendEvents.bind(this));
    }

    private getUuid(object: RawAssetFromApi | Playlist | Section | Page | any, objectType: ObjectType) {
        switch (objectType) {
            case ObjectType.live_event:
                return object.asset_uuid;
            case ObjectType.account:
            case ObjectType.asset:
            case ObjectType.section:
            case ObjectType.playlist:
                return object.uuid;
            default:
                if (object.hasOwnProperty('uuid')) {
                    return object.uuid;
                }
                return null;
        }
    }

    private reduceObject(object: RawAssetFromApi | any, objectType: ObjectType) {
        let reduced;
        if (objectType === ObjectType.asset) {
            reduced = {
                uuid: object.uuid,
                tags: object.tags,
                title: object.label,
                duration: object.duration,
                body: object.description,
                id: object.uuid,
                contents_freely_accessible: object.contents_freely_accessible,
                section: object.section,
                version_number: object.version_number,
                date: object.published_at,
                seconds: object.seconds,
                flags: object.flags,
                live_at: object.live_at,
                live: object.live
            };
        } else {
            reduced = object;
        }
        return JSON.parse(JSON.stringify(reduced));
    }

    private addEvent(event: UserEvent) {
        this.storageService
            .get('user-events')
            .pipe(
                switchMap((response: { value: UserEvent[] | undefined }) => {
                    let events = [];
                    if (response && response.value) {
                        events = response.value;
                    }
                    events.push(event);
                    return this.storageService.set('user-events', events);
                })
            )
            .subscribe(() => {});
    }

    private checkAndSendEvents() {
        if (!this.fixedDataCalculated()) {
            return;
        }

        this.storageService
            .get('user-events')
            .pipe(
                filter((events: UserEvent[]) => events && events.length > 0),
                switchMap((events: UserEvent[]) => {
                    return this.userProfileApiService.sendEvents(events);
                }),
                switchMap(() => {
                    return this.storageService.set('user-events', []);
                })
            )
            .subscribe(() => {});
    }

    private fixedDataCalculated(): boolean {
        if (this.device_id === undefined) {
            return false;
        }
        if (this.country === undefined) {
            return false;
        }
        if (this.device_type === undefined) {
            return false;
        }

        if (this.os === undefined) {
            return false;
        }

        return true;
    }

    private calculateDeviceHash(): void {
        Fingerprint2.get({}, (components: { key: any; value: any }[]) => {
            const values = components.map(component => component.value);
            const hash = Fingerprint2.x64hash128(values.join(''), 31);
            this.device_id = hash;

            this.storageService
                .get('device_id')
                .pipe(
                    tap((old: string | undefined) => {
                        if (old !== hash || !old) {
                            this.trackEvent(ObjectType.device_id, Action.update, { old: old, new: hash });
                        }
                    }),
                    switchMap(() => this.storageService.set('device_id', hash))
                )
                .subscribe(() => {});
        });
    }
}
