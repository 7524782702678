import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Product } from '../../../entities/product.entity';
import { Asset } from '../../../entities/asset.entity';
import { TagFacet } from '../../../entities/tag-facet.entity';
import { TagFilter } from '../../../entities/tag-filter.entity';
import { Source } from '../../../entities/video-playlist.entity';
import * as URLParser from 'url';
import { AssetAuthorizationEntity } from '../../../entities/asset-authorization.entity';
import { PlayerErrorEnum } from '../../../enum/player-error.enum';
import { AuthenticationService } from './authentication.service';
import { PlayerStateEnum } from '../../../enum/player-state.enum';
import { of } from 'rxjs/internal/observable/of';

@Injectable({ providedIn: 'root', deps: [HttpClient] })
export class AssetsService extends ApiService {
    private endpoint = 'assets';

    constructor(protected http: HttpClient, private auth: AuthenticationService) {
        super(http);
    }

    public getRelatedAssets(asset: RawAssetFromApi, page: number = 1, perPage: number = 10): Observable<Asset[]> {
        const url = this.endpoint + '/' + asset.uuid + '/related';
        const params: HttpParams = new HttpParams({
            fromObject: { page: page.toString(), per_page: perPage.toString() }
        });
        return this.http
            .get<{ items: RawAssetFromApi[] }>(url, { params: params })
            .pipe(
                map(response => response.items),
                map((assets: RawAssetFromApi[]) => {
                    return assets.map(as => {
                        return new Asset(as);
                    });
                })
            );
    }

    public getAllAssetsWithTagFacets(): Observable<{ assets: Asset[]; tag_facets: TagFacet[] }> {
        const url = this.endpoint + '?include_tag_facets=true';

        return this.http.get<{ items: RawAssetFromApi[]; tag_facets: TagFacet[] }>(url).pipe(
            map((response: { items: RawAssetFromApi[]; tag_facets: TagFacet[] }) => {
                const assets = response.items.map(asset => {
                    return new Asset(asset);
                });
                return {
                    assets: assets,
                    tag_facets: response.tag_facets
                };
            })
        );
    }

    public getAssetForProduct(product: Product): Observable<Asset[]> {
        const url = this.endpoint;

        return this.http
            .get<{ items: RawAssetFromApi[] }>(url, {
                params: {
                    // include_tag_facets: 'true',
                    [`tags[products][]`]: product.tag
                }
            })
            .pipe(
                map(response => response.items),
                map((assets: RawAssetFromApi[]) => {
                    return assets.map(asset => {
                        return new Asset(asset);
                    });
                })
            );
    }

    public getAssetsWithTagFilters(
        tagFilters: Array<TagFilter>,
        page: number = 1,
        perPage: number = 20,
        includeTagFacets: boolean = false
    ): Observable<{ assets: Asset[]; total_assets_count: number; tag_facets?: TagFacet[] }> {
        let url = this.endpoint;

        /**
         * We are using URLSearchParams since the order of the params MUST not change
         * @see https://7sports.atlassian.net/secure/RapidBoard.jspa?rapidView=24&modal=detail&selectedIssue=SD-386
         *
         */
        const params: URLSearchParams = new URLSearchParams();

        params.append('page', page.toString());
        params.append('per_page', perPage.toString());

        if (includeTagFacets) {
            params.append('include_tag_facets', 'true');
        }

        if (tagFilters) {
            for (const tag of tagFilters) {
                params.append('tag_filters[][callname]', tag.callname);

                for (const value of tag.values) {
                    params.append('tag_filters[][values][]', value);
                }
            }
        }

        url = url + '?' + params.toString();

        return this.http.get(url, { observe: 'response' }).pipe(
            map(
                (
                    response: HttpResponse<{
                        items: RawAssetFromApi[];
                        total_assets_count: number;
                        tag_facets?: TagFacet[];
                    }>
                ) => {
                    const re = response.body;
                    re.total_assets_count = parseInt(response.headers.get('API-Total'), 10);
                    return response.body;
                }
            ),

            map((response: { items: RawAssetFromApi[]; total_assets_count: number; tag_facets?: TagFacet[] }) => {
                return {
                    assets: response.items.map(asset => {
                        return new Asset(asset);
                    }),
                    total_assets_count: response.total_assets_count,
                    tag_facets: response.tag_facets
                };
            })
        );
    }

    public getAssetsForTag(
        tagName: string,
        tagValue: string,
        page: number = 1,
        perPage: number = 10
    ): Observable<Asset[]> {
        const url = this.endpoint + '?&include_tag_facets[' + tagName + ']=' + tagValue;
        const params: HttpParams = new HttpParams({
            fromObject: {
                page: page.toString(),
                per_page: perPage.toString(),
                include_tag_facets: 'true'
            }
        });

        return this.http
            .get<{ items: RawAssetFromApi[] }>(url, { params: params })
            .pipe(
                map(response => response.items),
                map((assets: RawAssetFromApi[]) => {
                    return assets.map(asset => {
                        return new Asset(asset);
                    });
                })
            );
    }

    public getAsset(uuid: string): Observable<Asset> {
        const url = this.endpoint;
        return this.http
            .get<RawAssetFromApi>(url + '/' + uuid)
            .pipe(map((response: RawAssetFromApi) => new Asset(response)));
    }

    /**
     * Adds an array of source objects to every Video of an Asset. Adds an empty array, if no source can be found.
     * Only throws one of PlayerErrorEnum Errors
     * @param asset Asset which videos' should be hydrated
     */
    public hydrateVideosOfAssetWithSource(asset: Asset): Observable<Asset> {
        if (!asset.videos || asset.videos.length === 0) {
            return throwError(PlayerErrorEnum.MISSING_VIDEO);
        }

        return of(true).pipe(
            switchMap(() => {
                if (asset.authorization_token) {
                    return of(asset.authorization_token);
                } else {
                    return this.getAssetAuthorizationToken(asset);
                }
            }),
            switchMap(asset_authorization_token => {
                return forkJoin([
                    ...asset.videos.map(video => this.getVideoSourceForUrl(video.url, asset, asset_authorization_token))
                ]).pipe(
                    map(
                        (sourcesArray: Source[][]): Asset => {
                            asset.videos = asset.videos.map((video, index) => ({
                                ...video,
                                sources: sourcesArray[index]
                            }));
                            return asset;
                        }
                    )
                );
            })
        );
    }

    public getAssetAuthorizationToken(asset: RawAssetFromApi): Observable<AssetAuthorizationEntity> {
        if (asset.contents_freely_accessible) {
            return of(null);
        }
        return this.auth.isLoggedIn().pipe(
            map((loggedIn: boolean) => {
                if (!loggedIn) {
                    throw new HttpErrorResponse({
                        error: { message: 'Anmeldung erforderlich', error: PlayerStateEnum.UNAUTHORIZED },
                        status: 401
                    });
                }
            }),
            switchMap(() =>
                this.http
                    .post<AssetAuthorizationEntity>('authorize_content_access', { asset_id: asset.uuid })
                    .pipe(shareReplay())
            )
        );
    }

    public shouldBeGeoBlocked(asset: RawAssetFromApi): Observable<boolean> {
        if (!asset.geo || asset.geo.length === 0) {
            return of(false);
        }
        return this.auth.getCountryCode().pipe(
            map((response: { country: string | null }) => {
                return !asset.geo.includes(response.country);
            })
        );
    }

    private getVideoSourceForUrl(
        url: string,
        asset: Asset,
        asset_authorization_token?: AssetAuthorizationEntity
    ): Observable<Source[]> {
        //url = url.replace('manager.dosbnewmedia.de', 'manager.sportdeutschland.tv');
        url = url.replace('.dash.smil', '.smil');
        url = url.replace('.hls.smil', '.smil');
        url = url.replace('.mp4', '.smil');
        url = url.replace('http:', 'https:');

        // attach the authorization token to the url
        if (asset_authorization_token) {
            const parsedUrl = URLParser.parse(url, true, true);
            parsedUrl.query['authorization_token'] = asset_authorization_token.authorization_token;
            parsedUrl.query['expires_at'] = asset_authorization_token.expires_at.toString();
            url = URLParser.format(parsedUrl);
        }

        if (url.includes('?')) {
            url += '&cors_origin=' + encodeURIComponent(window.location.origin);
        } else {
            url += '?cors_origin=' + encodeURIComponent(window.location.origin);
        }
        return this.http.get(url, { responseType: 'text' }).pipe(
            catchError(() => {
                // Catch http errors and throw player errors
                if (asset.live) {
                    return throwError(PlayerErrorEnum.LIVESTREAM_OFFLINE);
                } else {
                    return throwError(PlayerErrorEnum.MISSING_VIDEO_SOURCE);
                }
            }),

            map(response => {
                if (response === null) {
                    if (asset.live) {
                        // this is the 204 No content playerError / live stream offline
                        throw new Error(PlayerErrorEnum.LIVESTREAM_OFFLINE);
                    } else {
                        throw new Error(PlayerErrorEnum.MISSING_VIDEO_SOURCE);
                    }
                }
                // remove all spaces
                const parsed: HTMLElement = window['jQuery'].parseHTML(response);

                const smil = parsed[0].children;

                const sources: Source[] = [];

                for (let i = 0; i < smil.length; i++) {
                    let src = smil[i].getAttribute('src').replace('http:', 'https:');

                    if (src.includes('?')) {
                        src += '&cors_origin=' + encodeURIComponent(window.location.origin);
                    } else {
                        src += '?cors_origin=' + encodeURIComponent(window.location.origin);
                    }

                    sources.push({
                        type: smil[i].getAttribute('type'),
                        src: src
                    });
                }
                if (sources.length > 0) {
                    return sources;
                } else {
                    if (asset.live) {
                        throw new Error(PlayerErrorEnum.LIVESTREAM_OFFLINE);
                    } else {
                        throw new Error(PlayerErrorEnum.MISSING_VIDEO_SOURCE);
                    }
                }
            })
        );
    }
}
