import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';
import { Component, Input } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap';
import { AnalyticsService } from '../../../services/analytics.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-imageslider',
    templateUrl: './imageslider.component.html',
    providers: [
        {
            provide: CarouselConfig,
            useValue: {
                interval: environment.home.sliderDuration,
                noPause: false,
                showIndicators: false
            }
        }
    ]
})
export class ImagesliderComponent {
    @Input() public assets: RawAssetFromApi[];
    public activeSlideIndex: number;
    public environment;

    constructor(public analyticsService: AnalyticsService) {
        this.environment = environment;
    }

    public carouselClicked(e) {
        this.analyticsService.trackEvent('Multiteaser Slide Click', {
            category: 'Multiteaser Slide Click',
            gtmCustom: {
                googleAnalyticsEventAction: this.assets[this.activeSlideIndex].label
            }
        });
    }

    public getAssetLink(asset: RawAssetFromApi): string {
        let link = '/';

        if (asset.section && asset.section.permalink) {
            link += asset.section.permalink + '/';
        }
        if (asset.permalink) {
            link += asset.permalink;
        }
        return link;
    }
}
