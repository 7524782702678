import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { of } from 'rxjs/internal/observable/of';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../../services/api/methods/authentication.service';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html'
})
export class LoginFormComponent {
    public environment = environment;

    @Input() public email = '';
    public password = '';
    public errorMessage = null;
    public isLoading = false;
    public submitting = false;

    @Input() public inModal = true;

    constructor(
        private authenticationService: AuthenticationService,
        private trans: TranslateService,
        private router: Router,
        private toastr: ToastrService
    ) {}

    public login() {
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        this.errorMessage = null;
        this.authenticationService
            .login(this.email, this.password)
            .pipe(
                tap(() => {
                    this.submitting = false;
                }),
                switchMap((loggedIn: boolean) => {
                    if (loggedIn) {
                        if (
                            this.authenticationService.pathToRedirectAfterLogin !== null &&
                            this.authenticationService.pathToRedirectAfterLogin !== window.location.pathname
                        ) {
                            if (
                                this.authenticationService.pathToRedirectAfterLogin.startsWith('https://') ||
                                this.authenticationService.pathToRedirectAfterLogin.startsWith('http://')
                            ) {
                                window.location.href = this.authenticationService.pathToRedirectAfterLogin;
                            } else {
                                return fromPromise(
                                    this.router.navigateByUrl(this.authenticationService.pathToRedirectAfterLogin)
                                );
                            }
                        }
                        return of(true);
                    }
                })
            )
            .subscribe(
                response => {
                    forkJoin([
                        this.trans.get('login.successMessage'),
                        this.trans.get('toasts.successTitle')
                    ]).subscribe(([message, title]) => this.toastr.success(message, title));
                },
                error => {
                    this.errorMessage = null;
                    this.submitting = false;

                    let mes = error.error.message;
                    if (!mes) {
                        mes = 'Ein Login ist derzeit nicht möglich. Bitte versuchen Sie es später erneut.';
                    }
                    this.errorMessage = mes;
                }
            );
    }
}
