import { Component, Input, OnInit } from '@angular/core';
import { Section } from '../../../../entities/section.entity';

@Component({
    selector: 'app-round-image-tile',
    templateUrl: './round-image-tile.component.html',
    styleUrls: ['./round-image-tile.component.scss']
})
export class RoundImageTileComponent implements OnInit {
    @Input() public image: string;
    @Input() public permalink: string;
    @Input() public title: string;
    @Input() public text: string;

    constructor() {}

    @Input('section') set section(section: Section) {
        this.image = section.image;
        this.permalink = section.permalink;
        this.title = section.title;
    }

    public ngOnInit(): void {}
}
