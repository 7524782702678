import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { UserEvent } from '../../../entities/user-event.entity';

@Injectable()
export class ApiUserProfileService extends ApiService {
    private endpoint = environment.tracking.userEventEnpoint + 'user-events';

    public sendEvents(events: UserEvent[]): Observable<void> {
        return this.http.post<undefined>(this.endpoint, { events: events });
    }
}
