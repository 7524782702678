import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CMP, CMP_EVENTS } from './cmp';
import { Subject } from 'rxjs/internal/Subject';
import { isPlatformBrowser } from '@angular/common';
import { of } from 'rxjs/internal/observable/of';
declare global {
    interface Window {
        cmp: CMP;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ConsentService {
    public initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public confirmed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public events$: Subject<{ event: CMP_EVENTS; data: unknown }> = new Subject();
    private isBrowser = false;

    constructor(@Inject(PLATFORM_ID) platformId: Record<string, unknown>) {
        if (isPlatformBrowser(platformId)) {
            this.isBrowser = true;
        }
        this.events$.subscribe(e => console.log('[consent service]', e));

        this.events$
            .pipe(
                filter(e => e.event === CMP_EVENTS.initialized),
                take(1)
            )
            .subscribe(() => this.initialized$.next(true));

        this.events$
            .pipe(
                filter(e => e.event === CMP_EVENTS.confirmed),
                take(1)
            )
            .subscribe(() => {
                console.log('[consent service] setting confirmed to true');
                this.confirmed$.next(true);
            });

        if (this.isBrowser) {
            this.listenToEvents([
                CMP_EVENTS.consents,
                CMP_EVENTS.confirmed,
                CMP_EVENTS.initialized,
                CMP_EVENTS.saved,
                CMP_EVENTS.showBanner,
                CMP_EVENTS.hideBanner
            ]);

            document.addEventListener(CMP_EVENTS.saved, ev => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                const data = ev.detail?.data;
                console.log('[consent service] consent changed. will reload', data);
                window.location.reload();
            });

            document.addEventListener(CMP_EVENTS.confirmed, ev => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                const data = ev.detail?.data;
                console.log('[consent service] consent changed. will reload', data);
                window.location.reload();
            });
        }
    }

    public allConsentsForSomtagGiven(): boolean {
        if (this.isBrowser) {
            if (this.initialized$.getValue() === false) {
                console.warn('[consent service] Reading consents when not yet initialized');
            }
            return (
                window.cmp.getVendors().filter(cons => cons.consent.status === false && cons.type === 'default')
                    .length === 0
            );
        }
        return false;
    }

    public showConsentBanner(): void {
        if (this.isBrowser) {
            window.cmp.showBanner();
        }
    }

    public showConfigurationStep(): void {
        if (this.isBrowser) {
            window.cmp.showBanner();
            window.cmp.showStep({ step: 'configuration' });
        }
    }

    public hideConsentBanner(): void {
        if (this.isBrowser) {
            window.cmp.hideBanner();
        }
    }

    public consentChanged(serviceName: string): Observable<boolean> {
        if (this.isBrowser) {
            const subject = new Subject<boolean>();
            return subject.asObservable();
        }
        return of(false);
    }

    public consentGiven(serviceName: string): boolean {
        if (this.isBrowser) {
            console.log('consent given? ', serviceName, window.cmp.getConsentStatus(serviceName));
            return window.cmp.getConsentStatus(serviceName);
        }
        return false;
    }

    private listenToEvents(events: CMP_EVENTS[]): void {
        events.map(e => {
            document.addEventListener(e, ev => {
                console.log('[consent service] event ', e, ev);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.events$.next({ event: e as CMP_EVENTS, data: ev.detail?.data });
            });
        });
    }
}
