import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';
import { HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Asset } from '../../../entities/asset.entity';

@Injectable()
export class SearchService extends ApiService {
    private endpoint = 'search';

    public suggest(
        keyword: string,
        itemsPerPage = 20,
        page = 1
    ): Observable<{
        items: string[];
    }> {
        let url = this.endpoint + '/suggestions?q=' + keyword + '&page=' + page + '&per_page=' + itemsPerPage;

        if (
            environment.searchTagFilter.active === true &&
            environment.searchTagFilter.key &&
            environment.searchTagFilter.value
        ) {
            url = url + '&tag_filters[' + environment.searchTagFilter.key + ']=' + environment.searchTagFilter.value;
        }

        return this.http
            .get<{ items: string[] }>(url, { observe: 'response' })
            .pipe(
                map((response: HttpResponse<{ items: string[] }>) => {
                    return response.body;
                }),
                catchError(
                    this.handleError('suggestions', {
                        items: []
                    })
                )
            );
    }

    public search(
        keyword: string,
        itemsPerPage = 200,
        page = 1
    ): Observable<{
        items: Asset[];
        suggestions: string[];
        itemCount: number;
    }> {
        let url = this.endpoint + '?q=' + keyword + '&page=' + page + '&per_page=' + itemsPerPage;

        if (
            environment.searchTagFilter.active === true &&
            environment.searchTagFilter.key &&
            environment.searchTagFilter.value
        ) {
            url = url + '&tag_filters[' + environment.searchTagFilter.key + ']=' + environment.searchTagFilter.value;
        }

        return this.http
            .get<{ items: RawAssetFromApi[]; suggestions: string[]; itemCount: number }>(url, { observe: 'response' })
            .pipe(
                map(
                    (
                        response: HttpResponse<{ items: RawAssetFromApi[]; suggestions: string[]; itemCount: number }>
                    ) => {
                        const re = response.body;
                        re.itemCount = parseInt(response.headers.get('API-Total'));
                        return response.body;
                    }
                ),
                map((result: { items: RawAssetFromApi[]; suggestions: string[]; itemCount: number }) => {
                    return {
                        items: result.items.map((value: RawAssetFromApi) => new Asset(value)),
                        suggestions: result.suggestions,
                        itemCount: result.itemCount
                    };
                }),
                catchError(
                    this.handleError('getSearch', {
                        items: [],
                        suggestions: [],
                        itemCount: 0
                    })
                )
            );
    }
}
