import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class AdblockDetectionService {
    public adBlockerDetected(detected = false): void {
        // todo we had an error with init somtag
    }

    public adBlockerActive(): Observable<boolean> {
        return new Observable(sub => {
            const head = document.getElementsByTagName('head')[0];

            const noAdBlocker = () => {
                sub.next(false);
            };
            const hasAdBlocker = err => {
                sub.next(true);
            };

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = '/assets/ads.js';
            script.onload = noAdBlocker;
            script.onerror = hasAdBlocker;
            head.appendChild(script);
        });
    }
}
