import { Component, Input, OnInit } from '@angular/core';
import { RawAssetFromApi } from '../../../../entities/raw-asset-from-api.entity';

@Component({
    selector: 'app-extra-small-asset-tile',
    templateUrl: './extra-small-asset-tile.component.html'
})
export class ExtraSmallAssetTileComponent implements OnInit {
    public bgImage: string;

    constructor() {}

    private _asset: RawAssetFromApi;

    @Input()
    public get asset() {
        return this._asset;
    }

    public set asset(val: RawAssetFromApi) {
        this._asset = val;

        this.bgImage = this.asset.images[0].url;
    }

    public ngOnInit(): void {}
}
