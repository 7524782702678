import { AssetsService } from './../../../../services/api/methods/assets.service';
import { LiveEvent } from '../../../../entities/live-event.entity';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '../../../../services/analytics.service';
import { RoutingHelperService } from '../../../../services/routing-helper.service';
import { Action, ObjectType } from '../../../../entities/user-event.entity';
import { UserProfileService } from '../../../../services/user-profile.service';
import { delay, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { of } from 'rxjs/internal/observable/of';
import { Asset } from '../../../../entities/asset.entity';

@Component({
    selector: 'app-small-asset-tile',
    templateUrl: './small-asset-tile.component.html'
})
export class SmallAssetTileComponent implements OnInit {
    @Input() public liveEvent: LiveEvent;
    @Input() public trackingInfo?: {
        listTitle: string;
        index: string;
    };
    @Input() public dateFormat: 'custom' | 'short' | 'long' = 'short';
    public date: string;
    public bgImage: string;
    public assetLink: string;
    public environment = environment;
    public mouseLeave: Subject<void> = new Subject();

    constructor(
        private analyticsService: AnalyticsService,
        private userProfileService: UserProfileService,
        private routingHelper: RoutingHelperService,
        private assetService: AssetsService
    ) {}

    private _asset: Asset;

    @Input()
    public get asset() {
        return this._asset;
    }

    public set asset(val: Asset) {
        if (!val) {
            return;
        }
        this._asset = val;
        this.assetLink = this.routingHelper.getLinkFromAsset(val);
        if (val.images && val.images[0] && val.images[0].url) {
            this.bgImage = val.images[0].url;
        }
        this.date = val.live_at || val.published_at;
    }

    public ngOnInit(): void {
        if (this.liveEvent) {
            this.assetService.getAsset(this.liveEvent.asset_uuid).subscribe(res => (this.asset = res));
        }
    }

    public onAssetClick() {
        this.routingHelper.activateAutoplay();
        this.trackGoToAsset();
    }

    public trackGoToAsset() {
        this.analyticsService.trackEvent('Teaser Click', {
            category: 'Teaser Click',
            gtmCustom: {
                title: this.asset.label,
                targetURL: this.assetLink,
                listTitle: (this.trackingInfo || { listTitle: undefined }).listTitle,
                index: (this.trackingInfo || { index: undefined }).index
            }
        });
        this.userProfileService.trackEvent(ObjectType.asset, Action.click, this.asset);
    }

    public onMouseEnter() {
        this.mouseLeave = new Subject();
        of({})
            .pipe(delay(this.userProfileService.delayHoverEvents), takeUntil(this.mouseLeave))
            .subscribe(() => {
                this.userProfileService.trackEvent(ObjectType.asset, Action.hover, this.asset);
            });
    }

    public onMouseLeave() {
        this.mouseLeave.next();
    }
}
