import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentService } from './consent.service';

@NgModule({
  declarations: [],
  providers: [ConsentService],
  imports: [CommonModule],
  exports: [],
})
export class ConsentModule {}
