// This env file is the boilerplate for ALL env files.
// It MUST contain all env values accross all sites
// When creating a new site just copy this file and use it as a boilerplate

import { AppName } from '../enum/app-name.enum';

export const environment = {
    appName: AppName.sportdeutschland,
    appDisplayName: 'Sportdeutschland TV',
    envName: 'production',
    hmr: false,
    production: true,
    pageTitleAppendix: ' | Sportdeutschland TV',

    payableContent: false,
    infiniteScroll: true,
    tracking: {
        trackUserEvents: true,
        userEventEnpoint: 'https://event-logger.sportdeutschland.tv/api/',
        customDomain: '' // keep empty if  window.location.hostname should be used
    },
    searchTagFilter: {
        active: false,
        key: null,
        value: null
    },
    liveEventsFilter: {
        active: false,
        key: null,
        value: null
    },
    adyen: {
        mode: 'live', // "live" or "test" change it to 'live' when going live.
        version: '1.2.3',
        usercentricsTemplateId: 'g6VZ4jYtO'
    },
    ads: {
        useAds: true,
        blockVideosForAdBlocker: false,
        useGothaerSponsoring: true,
        somtag: {
            publisherId: 'sportdeutschlandtv',
            channels: [],
            slots: [
                { slot: 'skyscraper1', container: '#skyscraper1' },
                { slot: 'fullbanner2', container: '#fullbanner2' },
                { slot: 'rectangle1', container: '#rectangle1' },
                { slot: 'mbanner1', container: '#mbanner1' },
                { slot: 'performanceRectangle1', container: '#performanceRectangle1' },
                { slot: 'minread1', container: '#minread1' },
                { slot: 'inread1', container: '#inread1' }
            ],
            blocks: ['pre', 'mid', 'post'],
            secondsBeforeFirstMidrollBlock: 10 * 60,
            secondsBetweenMidrollBlocks: 17 * 60,
            numberOfVideosBeforePreRollAgainInPlaylist: 0 // 0 for just one preRoll
        }
    },
    player: {
        showShareButton: true,
        showPlaylistButton: true,
        showChapterButton: true,
        showPlaylistArrows: false,
        showBottomPlaylist: true,
        conferenceMode: true,
        showPlayerBranding: true,
        playerBrandingLogo: 'sportdeutschland.png',
        showMomentOfTheYearButton: false,
        useRevolverPlay: true,
        embedded: {
            showTitle: true
        }
    },
    api: {
        endpoint: 'https://backend.sportdeutschland.tv/api/',
        endpointUnauthenticated: 'https://proxy.vidibusdynamic.net/ssl/backend.sportdeutschland.tv/api/',
        domain: 'sportdeutschland.tv'
    },
    externalScripts: {
        p7iframe: {
            name: 'ProSieben Iframe API',
            src: 'https://www.prosieben.de/iframe-api.js',
            async: true,
            usercentricsTemplateId: 'ZedDryIeW'
        }
    },
    loadOnInit: [
        {
            name: 'CleverPush',
            src: 'https://static.cleverpush.com/channel/loader/QX3kdsuKmRGyL9MLX.js',
            usercentricsTemplateId: 'Hkq1Xar5V'
        }
    ],
    footer: {
        useFooter: false,
        socialTwitterUrl: 'https://twitter.com/SportDE_TV',
        socialFacebookUrl: 'https://www.facebook.com/Sportdeutschland.TV/',
        socialInstagramUrl: 'https://www.instagram.com/sportdeutschland.tv/',
        sdtvLinks: false,
        logo: 'sdtv_lang.png'
    },
    header: {
        brand: 'Sportdeutschland TV',
        style: 'light',
        useMultiSiteMenu: false,
        useSideMenu: true,
        showFooterContentInSidebar: true,
        logoLg: 'logo_sdtv_klein.png',
        logoSm: 'logo_sdtv_klein.png',
        showLogin: true,
        showLive: true,
        showSearch: true,
        showLanguagePicker: false,
        sideMenuInitiallyClosed: false,
        useSearchbar: false
    },
    home: {
        sliderDuration: 5000 // 10000 = 10 seconds , set to false to disable slider
    },
    i18n: {
        defaultLocale: 'de',
        languages: ['de']
    },
    logger: {
        useSentry: false,
        sentryConfig: 'https://ca62d86cba51457e92b53deebfbd8d2f@sentry.io/1297043'
    },
    liveEvents: {
        refreshInterval: 60000 // 60 seconds
    },
    urlSlugs: {
        newsletter: 'newsletter',
        search: 'suche',
        login: 'login',
        forgotPassword: 'passwort-vergessen',
        resetPassword: 'np/:token',
        register: 'register', // must not link to the server side page
        confirmEmail: 'registrierung-bestaetigen',
        emailConfirmed: 'konto-bestaetigen', // this URL should be set to the server side page
        livestreams: 'livestreams',
        dashboard: 'mein-konto',
        myData: 'meine-daten',
        subscribedChannels: 'meine-kanaele',
        orders: 'bestellungen',
        toc: 'agb', // this URL should be set to the value from the pages API
        dataPrivacy: 'datenschutz',
        dataPrivacyAds: 'datenschutz-werbung',
        verifyAge: 'altersnachweis',
        checkout: 'checkout'
    },
    tiles: {
        showPlayIconOnHover: false
    },
    youthProtection: {
        active: false,
        secondsAfterWhichPinNeedsToBeEnteredAgain: 60 * 5
    },
    subscribeFeature: {
        subscribeFeatureEnabled: false,
        subscribableTagCallNames: ['sportart', 'bereich', 'event', 'teams-mannschaften-z-b-heim-gast']
    }
};
