import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MenuService } from '../../../services/api/methods/menu.service';
import { MenuItem } from '../../../entities/menu-item.entity';
import { SidebarService } from '../../../services/sidebar.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
    public environment = environment;
    public menuItems: MenuItem[];

    public sidebarOpen: boolean;

    constructor(private sidebarService: SidebarService, private menuService: MenuService) {}

    public ngOnInit(): void {
        this.menuService.getFooterLinks().subscribe(links => {
            this.menuItems = links;
        });
        this.sidebarService.isOpen.subscribe(isOpen => {
            this.sidebarOpen = isOpen;
        });
    }
}
