/** Only internal player errors! */
export enum PlayerErrorEnum {
    /** Used when source object can not be fetched from video.url */
    LIVESTREAM_OFFLINE = 'LIVESTREAM_OFFLINE',
    /** Used when source object can not be fetched from video.url */
    MISSING_VIDEO_SOURCE = 'MISSING_VIDEO_SOURCE',
    /** Used when asset has not video prop */
    MISSING_VIDEO = 'MISSING_VIDEO',
    /** Used on videojs player errors (e.g. MEDIA_ERR_NETWORK, MEDIA_ERR_DECODE etc.) */
    MEDIA_ERROR = 'MEDIEA_ERROR',
    /** Used when Player init fails */
    PLAYER_INIT_ERROR = 'PLAYER_INIT_ERROR'
}
