import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import * as moment from 'moment';
import { PlayerStateEnum } from '../../../../enum/player-state.enum';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Product } from '../../../../entities/product.entity';
import { ProductsService } from '../../../../services/api/methods/products.service';
import { AuthenticationService } from '../../../../services/api/methods/authentication.service';
import { User } from '../../../../entities/user.entity';
import { filter, takeWhile, tap } from 'rxjs/operators';
import { interval } from 'rxjs/internal/observable/interval';
import { Asset } from '../../../../entities/asset.entity';
import { Subscription } from 'rxjs';
import { EmbeddedModeService } from '../../../../services/embedded-mode.service';

@Component({
    selector: 'app-player-countdown',
    templateUrl: './player-countdown.component.html'
})
export class PlayerCountdownComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
    public showShareModal = false;
    public playerStateEnum = PlayerStateEnum;
    @Input() public playerState: PlayerStateEnum;
    public hideCountdown = false;
    public hideShare = false;
    public duration: moment.Duration;
    public liveAt = moment();
    public asset: Asset;
    public heightForView: any;
    public intervalSubscription: Subscription;
    public desiredHeight: any;
    public alreadyPurchased = false;
    public allProducts: Product[];
    public availableProducts: Product[] = [];
    @Output() public width: EventEmitter<number> = new EventEmitter<number>();
    @Output() public height: EventEmitter<number> = new EventEmitter<number>();
    @Output() public requestPlayerStateUpdate: EventEmitter<void> = new EventEmitter();
    @ViewChild('playerStage', { static: true }) public playerStage: ElementRef;

    constructor(
        protected router: Router,
        protected productsService: ProductsService,
        protected authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private embeddedModeService: EmbeddedModeService
    ) {}

    @Input('asset') set assetSetter(asset) {
        this.asset = asset;
    }

    public ngOnInit(): void {
        if (this.asset) {
            this.liveAt = moment(this.asset.live_at);
            this.hideCountdown = this.asset.flags.includes('nocountdown');
            this.hideShare = this.asset.flags.includes('noshare');
            this.duration = moment.duration(this.liveAt.diff(moment()));

            this.intervalSubscription = interval(1000)
                .pipe(
                    // subscription is closed as soon as timer is at 0
                    takeWhile(() => this.duration.asSeconds() >= 0),
                    tap(() => {
                        this.duration = moment.duration(this.liveAt.diff(moment()));
                        this.cdr.detectChanges();
                    }),
                    filter(() => {
                        const seconds = this.duration.asSeconds();
                        return this.duration.asMinutes() < 10 && Math.floor(seconds) % 60 === 0;
                    })
                )
                .subscribe(val => {
                    this.requestPlayerStateUpdate.next();
                });

            this.authenticationService.me().subscribe(
                (me: User) => {
                    this.productsService.getProducts().subscribe((products: Product[]) => {
                        this.allProducts = products;
                        this.availableProducts = [];

                        this.getAssetProducts().forEach((assetProduct: string) => {
                            if (
                                (me.current_pay_per_views[assetProduct] &&
                                    me.current_pay_per_views[assetProduct]?.includes(this.asset.uuid)) ||
                                me.current_subscriptions.includes(assetProduct)
                            ) {
                                // has already bought this product
                                this.alreadyPurchased = true;
                            }
                        });
                        if (this.alreadyPurchased === false) {
                            products.forEach((product: Product) => {
                                // product suitable and product not bought
                                if (this.getAssetProducts().includes(product.tag)) {
                                    if (!me.current_subscriptions.includes(product.tag)) {
                                        // no current ppv bought
                                        if (
                                            !me.current_pay_per_views.hasOwnProperty(product.tag) ||
                                            !me.current_pay_per_views[product.tag].includes(this.asset.uuid)
                                        ) {
                                            this.availableProducts.push(product);
                                            return;
                                        } else {
                                            // show already bought ?
                                        }
                                    } else {
                                        // show included in subscription ?
                                    }
                                }
                            });
                        }
                    });
                },
                err => {
                    // user not logged in for example
                    this.productsService.getProducts().subscribe((products: Product[]) => {
                        this.allProducts = products;
                        this.availableProducts = [];
                        products.forEach((product: Product) => {
                            // product suitable and product not bought
                            if (this.getAssetProducts().includes(product.tag)) {
                                this.availableProducts.push(product);
                            }
                        });
                    });
                }
            );
        }
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        this.desiredHeight = (this.playerStage.nativeElement.clientWidth * 9) / 16;
        if (this.heightForView !== this.desiredHeight) {
            this.heightForView = this.desiredHeight;
            this.cdr.detectChanges();
        }
    }

    public ngOnChanges() {
        this.onResize(null);
    }

    public ngAfterViewChecked() {
        this.onResize(null);
    }

    public buyProduct(product: Product): void {
        if (this.embeddedModeService.embedded.getValue()) {
            window.open(
                window.location.origin +
                    '/' +
                    environment.urlSlugs.checkout +
                    '?p=' +
                    product.uuid +
                    '&a=' +
                    this.asset.uuid
            );
        } else {
            this.router.navigate(['/' + environment.urlSlugs.checkout], <NavigationExtras>{
                queryParams: {
                    p: product.uuid,
                    a: this.asset.uuid
                }
            });
        }
    }

    public getAssetProducts(): string[] {
        if (!this.asset) {
            return [];
        }
        if (!this.asset.products) {
            return [];
        }
        return this.asset.products.values || [];
    }

    public ngOnDestroy() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    public saveCalendar(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        window.open(this.asset.ics);
        return false;
    }

    public toggleShareModal(event: MouseEvent) {
        this.showShareModal = !this.showShareModal;
    }
}
