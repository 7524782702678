import { MatchMinutePipe } from './match-minute.pipe';
import { KeysPipe } from './keys.pipe';
import { CustomDatePipe } from './custom-date.pipe';
import { NgModule } from '@angular/core';
import { ImageSizePipe } from './image-size.pipe';
import { TruncateStringPipe } from './truncate-string.pipe';
import { TrustHtmlPipe } from './trust-html.pipe';
import { TrustResourceUrlPipe } from './trust-resource-url.pipe';
import { TrustStylePipe } from './trust-style.pipe';
import { ShuffleArrayPipe } from './shuffle-array.pipe';
import { EPCMoreLinkPipe } from './extended-page-content-more-link.pipe';
import { AssetLinkPipe } from './asset-link.pipe';
import { AssetSubtitlePipe } from './asset-subtitle.pipe';
import { PluckPipe } from './pluk.pipe';

const pipes = [
    CustomDatePipe,
    ImageSizePipe,
    KeysPipe,
    MatchMinutePipe,
    TruncateStringPipe,
    TrustHtmlPipe,
    TrustResourceUrlPipe,
    TrustStylePipe,
    ShuffleArrayPipe,
    EPCMoreLinkPipe,
    AssetLinkPipe,
    AssetSubtitlePipe,
    PluckPipe
];

@NgModule({
    imports: [],
    declarations: [...pipes],
    exports: [...pipes],
    providers: []
})
export class PipesModule {}
