import { VideoPlaylist } from '../../../../../entities/video-playlist.entity';
import * as videojs from 'video.js';
import { ChangeDetectorRef, EmbeddedViewRef } from '@angular/core';
import { PlaylistHandlerService } from '../../../../../services/playlist-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { VjsPlaylistPluginOptions } from '../../../../../../../@types/VjsPlaylistPluginOptions';

const mywindow: any = window;
const Plugin = mywindow.videojs.getPlugin('plugin');

export default class PlaylistPlugin extends Plugin {
    public playlistOpened = false;
    public playlist: VideoPlaylist;
    private sidePlaylistOpened = false;

    private player: videojs.default.Player | any;
    private videojs;
    private currentIndex = 0;
    private playlistHandlerService: PlaylistHandlerService;
    private options: VjsPlaylistPluginOptions;
    private router: Router;
    private activatedRoute: ActivatedRoute;
    private cdr: ChangeDetectorRef;

    constructor(player: videojs.default.Player, options: VjsPlaylistPluginOptions) {
        // @ts-ignore
        super(player, options);
        this.player = player;
        this.options = options;
        this.playlist = options.playlist;
        this.playlistHandlerService = options.playlistHandlerService;
        this.videojs = mywindow.videojs;
        this.router = options.router;
        this.activatedRoute = options.activatedRoute;
        this.cdr = options.cdr;
        // @ts-ignore
        player.playlist(options.playlist);

        this.player.on('playlistitem', () => {
            // triggered when video reaches end -> play next video
            // @ts-ignore
            this.playlistHandlerService.goToPlaylistIndex(player.playlist.currentIndex());
        });
        // @ts-ignore
        player.playlist.autoadvance(0);
        this.addChapterButton();
        if (options.showPlaylistArrows) {
            this.addNextPreviousButtons();
        }
        this.addPlaylistButton();
        this.addPlaylistComponent(options.template);
        // this.addMarkers(options.playlist);

        this.playlistHandlerService.activePlaylistIndex.subscribe((val: { index: number; play: boolean }) => {
            let newUrl = window.location.href;

            if (window.location.search.includes('playlistId')) {
                newUrl = window.location.href.replace(`playlistId=${this.currentIndex}`, `playlistId=${val.index}`);
            } else if (!window.location.search) {
                newUrl += `?playlistId=${val.index}`;
            } else {
                newUrl += `&playlistId=${val.index}`;
            }
            window.history.replaceState({}, '', `${newUrl}`);

            this.currentIndex = val.index;
            if (!this.player) {
                return;
            }
            this.player.playlist.currentItem(val.index);
            this.player.trigger('playlist-item-changed');
            if (val.play === true) {
                this.player.play();
            }
            if (this.currentIndex === 0) {
                if (this.player.getChild('PreviousButton')) {
                    this.player.getChild('PreviousButton').addClass('vjs-addOn-button-hide');
                }
            } else {
                if (this.player.getChild('PreviousButton')) {
                    this.player.getChild('PreviousButton').removeClass('vjs-addOn-button-hide');
                }
            }
            if (this.currentIndex === this.playlist.length - 1) {
                if (this.player.getChild('NextButton')) {
                    this.player.getChild('NextButton').addClass('vjs-addOn-button-hide');
                }
            } else {
                if (this.player.getChild('NextButton')) {
                    this.player.getChild('NextButton').removeClass('vjs-addOn-button-hide');
                }
            }
        });
        this.playlistHandlerService.setPlaylist(options.playlist);
        if (this.activatedRoute.snapshot.queryParams.playlistId) {
            // @ts-ignore
            this.playlistHandlerService.goToPlaylistIndex(Number(this.activatedRoute.snapshot.queryParams.playlistId));
        } else {
            // @ts-ignore
            this.playlistHandlerService.goToPlaylistIndex(player.playlist.currentIndex());
        }

        this.playlistHandlerService.sidePlaylistOpen.subscribe(opened => {
            this.sidePlaylistOpened = opened;
            if (opened) {
                if (this.player.getChild('PlaylistButton')) {
                    this.player.getChild('PlaylistButton').addClass('vjs-addOn-button-hide');
                }
                if (this.player.getChild('PreviousButton')) {
                    this.player.getChild('PreviousButton').addClass('vjs-addOn-button-hide');
                }
                if (this.player.getChild('NextButton')) {
                    this.player.getChild('NextButton').addClass('vjs-addOn-button-hide');
                }
            } else {
                if (this.player.getChild('PlaylistButton')) {
                    this.player.getChild('PlaylistButton').removeClass('vjs-addOn-button-hide');
                }
                if (this.player.getChild('PreviousButton')) {
                    this.player.getChild('PreviousButton').removeClass('vjs-addOn-button-hide');
                }
                if (this.player.getChild('NextButton')) {
                    this.player.getChild('NextButton').removeClass('vjs-addOn-button-hide');
                }
            }
        });

        this.tooglePlaylistButtons();
        this.player.on('fullscreenchange', () => {
            this.tooglePlaylistButtons();
        });
    }

    private tooglePlaylistButtons() {
        if (!this.options.showPlaylistButton) {
            if (this.player.isFullscreen()) {
                if (this.player.getChild('PlaylistButton')) {
                    this.player.getChild('PlaylistButton').removeClass('vjs-addOn-button-hide');
                }
            } else {
                if (this.player.getChild('PlaylistButton')) {
                    this.player.getChild('PlaylistButton').addClass('vjs-addOn-button-hide');
                }
            }
        }
        if (!this.options.showChapterButton) {
            if (this.player.isFullscreen()) {
                this.player.getChild('controlBar').getChild('ChapterButton').removeClass('vjs-addOn-button-hide');
            } else {
                this.player.getChild('controlBar').getChild('ChapterButton').addClass('vjs-addOn-button-hide');
            }
        }
    }

    private addNextPreviousButtons() {
        const Button = this.videojs.getComponent('Button');

        const NextButton = this.videojs.extend(Button, {
            constructor: function () {
                Button.apply(this, arguments);
                this.addClass('vjs-playlist-next-button');
                this.addClass('vjs-addOn-button');
                this.controlText('Playlist');
            },
            handleClick: () => {
                if (this.currentIndex === this.playlist.length - 1) {
                    return;
                }
                this.playlistHandlerService.goToPlaylistIndex(this.currentIndex + 1);
            }
        });
        this.videojs.registerComponent('NextButton', NextButton);
        this.player.addChild('NextButton', {});

        const PreviousButton = this.videojs.extend(Button, {
            constructor: function () {
                Button.apply(this, arguments);
                this.addClass('vjs-playlist-previous-button');
                this.addClass('vjs-addOn-button');
                this.controlText('Playlist');
            },
            handleClick: () => {
                if (this.currentIndex === 0) {
                    return;
                }
                this.playlistHandlerService.goToPlaylistIndex(this.currentIndex - 1);
            }
        });
        this.videojs.registerComponent('PreviousButton', PreviousButton);
        this.player.addChild('PreviousButton', {});
    }

    private addPlaylistButton() {
        const Button = this.videojs.getComponent('Button');
        const PlaylistButton = this.videojs.extend(Button, {
            constructor: function () {
                Button.apply(this, arguments);
                this.addClass('vjs-playlist-button');
                this.addClass('fa');
                this.addClass('fa-bars');
                this.addClass('vjs-addOn-button');
                this.controlText('Playlist');
            },
            handleClick: () => {
                this.playlistHandlerService.toggleSidePlaylist(!this.sidePlaylistOpened);
            }
        });
        this.videojs.registerComponent('PlaylistButton', PlaylistButton);
        this.player.addChild('PlaylistButton', {});
    }

    private addChapterButton() {
        const Button = this.videojs.getComponent('Button');
        const ChapterButton = this.videojs.extend(Button, {
            constructor: function () {
                Button.apply(this, arguments);
                this.addClass('vjs-chapters-button');
                this.controlText('Szenen');
            },

            handleClick: () => {
                this.playlistHandlerService.toggleSidePlaylist(!this.sidePlaylistOpened);
            }
        });

        this.videojs.registerComponent('ChapterButton', ChapterButton);
        this.player.getChild('controlBar').addChild('ChapterButton', {}, 0);
    }

    private addPlaylistComponent(template: EmbeddedViewRef<any>) {
        const VjsComponent = this.videojs.getComponent('Component');
        const PlaylistComponent = this.videojs.extend(VjsComponent, {
            constructor: function () {
                VjsComponent.apply(this, arguments);
                this.addClass('vjs-playlist-container');
            },
            el: function () {
                return template.rootNodes[0];
            }
        });
        this.videojs.registerComponent('PlaylistComponent', PlaylistComponent);
        this.player.addChild('PlaylistComponent');
    }

    private addMarkers(playlist: VideoPlaylist) {
        return;
        // let totalDuration = 0;
        // const markers = [];
        // playlist.forEach((elem: VideoPlaylistElement) => {
        //     totalDuration += elem.duration;
        //     markers.push({ time: totalDuration, text: elem.name });
        // });
        // this.player.markers({
        //     markers: markers
        // });
    }
}
