import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cta-banner',
    templateUrl: './cta-banner.component.html'
})
export class CtaBannerComponent implements OnInit {
    constructor() {}

    public ngOnInit(): void {}
}
