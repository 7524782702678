import { Injectable } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Section } from '../entities/section.entity';
import * as sha1 from 'js-sha1';
import { EmbeddedModeService } from './embedded-mode.service';
import { environment } from '../environments/environment';
import { GtmOptions } from '../../../@types/VjsTrackingPluginOptions';
import { Asset } from '../entities/asset.entity';
import { AppName } from 'src/common/enum/app-name.enum';

@Injectable()
export class AnalyticsService {
    private syndicationDomain = null;

    constructor(
        private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private embeddedModeService: EmbeddedModeService
    ) {
        this.embeddedModeService.syndication_origin.subscribe(
            (syndication_domain: string) => (this.syndicationDomain = syndication_domain)
        );
    }

    public getFormatForAsset(asset: Asset, missingFormatText: string = 'not set'): string {
        if (environment.appName === AppName.auto) {
            return (asset.auto_marke || missingFormatText).toString();
        }
        if (environment.appName === AppName.esports && asset.esport_events) {
            return (asset.esport_events.join(',') || missingFormatText).toString();
        }
        return asset.section ? asset.section.permalink : missingFormatText;
    }

    public getSourceCompanyForAsset(asset: Asset): string {
        if (environment.appName === AppName.auto) {
            let channel = '';
            if (asset.channel && asset.channel.values) {
                channel = asset.channel.values.toString();
            }
            return channel.toString();
        } else {
            return asset.licence_owner.toString();
        }
    }

    public getChannelForAsset(asset: Asset, section?: Section, missingFormatText: string = 'not set'): string {
        if (environment.appName === AppName.esports) {
            if (asset.esport_games.length > 0) {
                return asset.esport_games.join(',');
            }
            return missingFormatText;
        }
        if (section) {
            return section.title;
        }
        if (asset.sportart) {
            return asset.sportart.values[0] || missingFormatText;
        }

        if (asset.section) {
            return asset.section.permalink;
        }
        return '';
    }

    public getKanalForAsset(asset: Asset, section?: Section, missingFormatText: string = 'not set'): string {
        if (asset.channel?.values[0]) {
            return asset.channel.values[0].toLowerCase();
        }
        return missingFormatText;
    }

    /**
     *
     * @param channel Assigns a page or a video to a channel.
     * @param format Assigns a page, a video or an app uniquely to a TV Show or Format.
     * @param additionGtmOptions add additional options to pageView event.
     */
    public trackPageview(
        channel: string = 'not set',
        format: string = 'not set',
        additionGtmOptions: Partial<GtmOptions> = {}
    ): void {
        this.angulartics2GoogleTagManager.eventTrack('Page View', {
            event: 'Page View',
            gtmCustom: {
                domain: environment.tracking.customDomain
                    ? environment.tracking.customDomain
                    : window.location.hostname,
                channel: channel.toLowerCase(),
                kanal: channel.toLowerCase(),
                format: format.toLowerCase(),
                ...additionGtmOptions
            }
        });
    }

    /** Tracks Tagmanager Event
     * @param action
     * @param properties {
            category?: string,
            event?: string,
            label?: string,
            gtmCustom?: any These Properties will appear in GTM.dataLayer,
        }
     */
    public trackEvent(
        action: string,
        properties: {
            category?: string;
            event?: string;
            label?: string;
            value?: string;
            noninteraction?: boolean;
            gtmCustom?: Partial<GtmOptions>;
        } = null
    ): void {
        this.angulartics2GoogleTagManager.eventTrack(action, properties);
    }

    public setUserName(name: string | number): void {
        this.angulartics2GoogleTagManager.setUsername(sha1(name));
    }

    public trackPageviewForAsset(asset: Asset): void {
        const additionGtmOptions: Partial<GtmOptions> = {
            sourceCompany: this.getSourceCompanyForAsset(asset),
            productionPartner: asset.production_partner.toString(),
            teams: asset.teams.toString(),
            season: asset.saison.toString(),
            kanal: this.getKanalForAsset(asset),
            leaguesCups: asset.wettbewerb_event.toString(),
            wholesaler: this.syndicationDomain
        };
        this.trackPageview(this.getFormatForAsset(asset), this.getFormatForAsset(asset), additionGtmOptions);
    }
}
