import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { RegisterFormComponent } from './register-form.component';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';

@Injectable()
export class RegisterFormService {
    private registerComponentPortal: ComponentPortal<RegisterFormComponent>;
    private overlayRef: OverlayRef;

    constructor(private router: Router, private overlay: Overlay) {}

    public showRegisterOverlay($isLoggedInEvent: Observable<boolean>) {
        const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

        let width = 320;
        if (window.innerWidth >= 420) {
            width = 420;
        }
        if (window.innerWidth >= 720) {
            width = 720;
        }
        this.overlayRef = this.overlay.create({
            width: width,
            panelClass: 'modal-content',
            disposeOnNavigation: true,
            positionStrategy: positionStrategy,
            backdropClass: 'cdk-overlay-dark-backdrop',
            hasBackdrop: true
        });
        this.registerComponentPortal = new ComponentPortal(RegisterFormComponent);

        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.dispose();
        });
        this.overlayRef.attach(this.registerComponentPortal);

        const subsToRouterEvent = this.router.events.subscribe(() => {
            this.overlayRef.dispose();
        });
        const subsToLoginEvent = $isLoggedInEvent.subscribe(isLoggedIn => {
            if (isLoggedIn) {
                this.overlayRef.dispose();
            }
        });
        this.overlayRef.detachments().subscribe(() => {
            subsToLoginEvent.unsubscribe();
            subsToRouterEvent.unsubscribe();
        });
    }
}
