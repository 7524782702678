import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Asset } from '../../../entities/asset.entity';

@Component({
    selector: 'app-asset-subtitle',
    templateUrl: './asset-subtitle.component.html'
})
export class AssetSubtitleComponent implements OnInit {
    @Input() public asset: Asset;

    public environment = environment;

    constructor() {}

    public ngOnInit(): void {}
}
