import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../services/analytics.service';
import { RoutingHelperService } from '../../../../services/routing-helper.service';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs/internal/Subject';
import { of } from 'rxjs/internal/observable/of';
import { delay, takeUntil } from 'rxjs/operators';
import { Action, ObjectType } from '../../../../entities/user-event.entity';
import { UserProfileService } from '../../../../services/user-profile.service';
import { LiveEvent } from '../../../../entities/live-event.entity';
import { AssetsService } from '../../../../services/api/methods/assets.service';
import { Asset } from '../../../../entities/asset.entity';

@Component({
    selector: 'app-big-asset-tile',
    templateUrl: './big-asset-tile.component.html'
})
export class BigAssetTileComponent implements OnInit {
    @Input() public trackingInfo?: {
        listTitle: string;
        index: string | number;
    };
    @Input() public liveEvent: LiveEvent;
    public assetLink: string;
    public environment = environment;
    public mouseLeave: Subject<void> = new Subject();
    public bgImage: string;

    constructor(
        private analyticsService: AnalyticsService,
        private userProfileService: UserProfileService,
        private routingHelper: RoutingHelperService,
        private assetService: AssetsService
    ) {}

    private _asset: Asset;

    @Input()
    public get asset() {
        return this._asset;
    }

    public set asset(val: Asset) {
        if (!val) {
            return;
        }
        this._asset = val;
        this.assetLink = this.routingHelper.getLinkFromAsset(this.asset);
        if (val.images && val.images[0] && val.images[0].url) {
            this.bgImage = val.images[0].url;
        }
    }

    public ngOnInit(): void {
        if (this.liveEvent) {
            this.assetService.getAsset(this.liveEvent.asset_uuid).subscribe(res => (this.asset = res));
        }
    }

    public onAssetClick() {
        this.routingHelper.activateAutoplay();
        this.trackgoToAsset();
    }

    public saveCalendar(item: LiveEvent, event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        window.location.assign(item.ics);
        return false;
    }

    public trackgoToAsset() {
        this.analyticsService.trackEvent('Teaser Click', {
            category: 'Teaser Click',
            gtmCustom: {
                title: this._asset.label,
                targetURL: this.routingHelper.getLinkFromAsset(this._asset),
                listTitle: (this.trackingInfo || { listTitle: undefined }).listTitle,
                index: (this.trackingInfo || { index: undefined }).index
            }
        });
        this.userProfileService.trackEvent(ObjectType.asset, Action.click, this._asset);
    }

    public onMouseEnter() {
        this.mouseLeave = new Subject();
        of({})
            .pipe(delay(this.userProfileService.delayHoverEvents), takeUntil(this.mouseLeave))
            .subscribe(() => {
                this.userProfileService.trackEvent(ObjectType.asset, Action.hover, this.asset);
            });
    }

    public onMouseLeave() {
        this.mouseLeave.next();
    }
}
