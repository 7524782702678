import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';
import { SubscriptionService } from '../../../services/api/methods/subscription.service';
import { Subscription } from '../../../entities/subscription';
import { Subscription as RxJsSubscription } from 'rxjs/internal/Subscription';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RegisterFormService } from '../../elements/form/register-form/register-form.service';

@Component({
    selector: 'app-subscribe-button',
    templateUrl: './subscribe-button.component.html'
})
export class SubscribeButtonComponent implements OnInit, OnDestroy {
    public environment = environment;
    public subscriptionActive = false;
    @Input() public title: string;
    @Input() public routerLink: string;
    @Input() public queryParams: { [key: string]: string };
    @Input() public tagCallname: string;
    @Input() public tagValue: string;
    @Input() public reducedVersion = false;
    @Input() public color: 'primary' | 'secondary' | 'light' | 'dark' = 'primary';
    private subscription: Subscription;
    private loggedIn: boolean;

    private subscribeToLoginEvents: RxJsSubscription;

    constructor(
        public authService: AuthenticationService,
        private subscriptionService: SubscriptionService,
        private toastr: ToastrService,
        private registerFormService: RegisterFormService,
        private trans: TranslateService
    ) {
        authService.isLoggedIn().subscribe((loggedIn: boolean) => {
            this.loggedIn = loggedIn;
        });
    }

    public ngOnDestroy() {
        this.subscribeToLoginEvents.unsubscribe();
    }

    public ngOnInit(): void {
        this.subscribeToLoginEvents = this.authService.$isLoggedInEvent.subscribe(isLoggedIn => {
            this.loggedIn = isLoggedIn;
            if (isLoggedIn) {
                this.getSubscriptions();
            }
        });
        this.getSubscriptions();
    }

    public toggle(event: MouseEvent) {
        event.stopPropagation();

        if (!this.loggedIn) {
            this.registerFormService.showRegisterOverlay(this.authService.$isLoggedInEvent);
            return;
        }

        if (!this.subscription) {
            this.subscriptionService
                .create$({
                    tag_filters: [
                        {
                            callname: this.tagCallname,
                            values: [this.tagValue]
                        }
                    ]
                })
                .subscribe(() => {
                    this.trans.get('toasts.successInfo').subscribe(title => {
                        this.toastr.info(this.tagValue + ' abboniert.', title);
                    });
                });

            return;
        }

        if (this.subscriptionActive) {
            // delete the value of the tag filter
            const tagFilter = this.subscription.tag_filters.find(filter => filter.callname === this.tagCallname);
            tagFilter.values = tagFilter.values.filter(value => value !== this.tagValue);
        } else {
            // check if there is already a tag_filter with this callname and add the tagvalue to it
            // or add a new tagfilter to the tag_filter array
            const tagFilter = this.subscription.tag_filters.find(filter => filter.callname === this.tagCallname);
            if (tagFilter) {
                tagFilter.values.push(this.tagValue);
            } else {
                this.subscription.tag_filters.push({
                    callname: this.tagCallname,
                    values: [this.tagValue]
                });
            }
        }
        this.subscriptionService.update$(this.subscription).subscribe(() => {
            if (this.subscriptionActive) {
                this.trans.get('toasts.successInfo').subscribe(title => {
                    this.toastr.info(this.tagValue + ' abboniert.', title);
                });
            }
        });
    }

    private getSubscriptions() {
        this.subscriptionService.subscriptionStore$.subscribe(subs => {
            this.subscription = subs[0];
            this.subscriptionActive = !!subs.find(sub => {
                return !!sub.tag_filters.find(filter => {
                    return filter.callname === this.tagCallname && filter.values.includes(this.tagValue);
                });
            });
        });
    }
}
