import { PlaylistHandlerService } from '../../../../services/playlist-handler.service';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Video } from '../../../../entities/video.entity';
import * as videojs from 'video.js';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AssetAuthorizationEntity } from '../../../../entities/asset-authorization.entity';
import { AuthenticationService } from '../../../../services/api/methods/authentication.service';
import { timer } from 'rxjs/internal/observable/timer';
import AdsPlugin from './plugins/vjs.plugin.ads';
import TrackingPlugin from './plugins/vjs.plugin.tracking';
import PlaylistPlugin from './plugins/vjs.plugin.playlist';
import SharePlugin from './plugins/vjs.plugin.share';
import { InactivityService } from '../../../../services/inactivity.service';
import { SomtagService } from '../../../../services/somtag.service';
import { PageVisibilityService } from '../../../../services/page-visibility.service';
import { Observable } from 'rxjs/internal/Observable';
import { VideoPlaylist, VideoPlaylistElement } from '../../../../entities/video-playlist.entity';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '../../../../services/analytics.service';
import { EmbeddedModeService } from '../../../../services/embedded-mode.service';
import AGOFPlugin from './plugins/vjs.plugin.agof';
import { AgofService } from '../../../../services/agof.service';
import { CustomPlayer } from '../../../../../../@types/CustomPlayer';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AdState, VideoState } from '../states';
import { LiveMidrollService } from '../../../../services/api/methods/live-midroll.service';
import landscapeFullscreen from 'videojs-landscape-fullscreen';
import { PlayerErrorEnum } from '../../../../enum/player-error.enum';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from '../../../../services/user-profile.service';
import { Asset } from '../../../../entities/asset.entity';
import { AssetsService } from '../../../../services/api/methods/assets.service';
import { Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { BrowserInfo } from 'detect-browser';
import { StorageMap } from '@ngx-pwa/local-storage';
import VjsPluginOverlay from './plugins/vjs.plugin.overlay';
import { AgofEnum } from '../../../../enum/agof.enum';
import { Action, ObjectType, Teaser } from '../../../../entities/user-event.entity';
import { RawAssetFromApi } from '../../../../entities/raw-asset-from-api.entity';
import { Playlist } from '../../../../entities/playlist.entity';
import { Section } from '../../../../entities/section.entity';
import { Page } from '../../../../entities/page.entity';
import { GtmOptions } from '../../../../../../@types/VjsTrackingPluginOptions';
import hlsQualitySelector from '../video/plugins/videojs-hls-quality-selector';
import { PlayerStateEnum } from '../../../../enum/player-state.enum';
import { ConsentService } from '../../../../modules/consent/consent.service';

require('videojs-contrib-quality-levels');

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html'
})
export class VideoComponent implements OnDestroy, OnChanges, OnInit {
    public environment = environment;
    public playingAd = false;
    public playingVPAIDAd = false;
    @Input() public browser: BrowserInfo;
    @Input() public assetCount: number;
    @Input() public playlistButton = true;
    @Input() public chapterButton = true;
    @Input() public showOverlay = true;
    @Input() public allowFullscreen = true;
    @Input() public playlistArrows = false;
    @Input() public fullscreenToggleRequestlistener: EventEmitter<void>;
    @Input() public externalFullscreenChangedEmitter: EventEmitter<boolean>;
    @Input() public playEvent: Observable<void> = null;
    @Input() public width?: number;
    @Input() public autoplay = false;
    @Input() public preload: 'none' | 'auto' | 'metadata' = 'auto';
    @Input() public muted = false;
    @ViewChild('videoPlayer', { static: true }) public videoPlayer: ElementRef;
    @ViewChild('container', { static: true }) public container: ElementRef;
    public video: Video;
    public showGameFrame = environment.envName === 'stage' && environment.appName === 'sportdeutschland';
    // public isLoadingVideo = false;
    public player?: CustomPlayer;
    public playerId: string;
    public videojs: any;
    public activityPinger: Subscription;
    public videoAboutToLoad = false;
    public isEmbedded = false;
    @Output() public videoStateChanged: EventEmitter<VideoState> = new EventEmitter<VideoState>();
    @Output() public mediaError: EventEmitter<PlayerErrorEnum> = new EventEmitter<PlayerErrorEnum>();
    @Output() public playerState: EventEmitter<PlayerStateEnum> = new EventEmitter<PlayerStateEnum>();
    @Output() public adStateChanged: EventEmitter<AdState> = new EventEmitter<AdState>();
    @Output() public isPlaying: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public isPlayingAd: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public requestFullscreenToggle: EventEmitter<void> = new EventEmitter<void>();
    @Output() public videoJsEvents: EventEmitter<string> = new EventEmitter<string>();
    @Output() public toggleConferenceMode: EventEmitter<string> = new EventEmitter<string>();
    private customControlBarComponents: videojs.default.Component[] = [];
    private playEventSubscription: Subscription = null;
    private updateToPlayerLang: Subscription = null;
    @ViewChild('shareModalTemplate', { static: true }) private shareModalTemplate: TemplateRef<any>;

    @ViewChild('infoI', { static: true }) private infoI: TemplateRef<any>;
    @ViewChild('overlayTemplate', { static: true }) private overlayTemplate: TemplateRef<any>;
    @ViewChild('playListTemplate', { static: true }) private playListTemplate: TemplateRef<any>;
    @ViewChild('brandingTemplate', { static: true }) private brandingTemplate: TemplateRef<any>;
    private syndicationDomain: string = null;
    private userHasSubscriptionWhichRemovesAds = false;
    private disposeAll: Subject<any> = new Subject();
    private assetTokenRefresher: NodeJS.Timeout;

    constructor(
        protected cdr: ChangeDetectorRef,
        private inactivityService: InactivityService,
        private http: HttpClient,
        private authService: AuthenticationService,
        public somtagService: SomtagService,
        private pageVisibilityService: PageVisibilityService,
        private viewContainer: ViewContainerRef,
        private agofService: AgofService,
        private activatedRoute: ActivatedRoute,
        private playlistHandlerService: PlaylistHandlerService,
        private embeddedModeService: EmbeddedModeService,
        private analyticsService: AnalyticsService,
        private userProfileService: UserProfileService,
        private router: Router,
        private liveMidrollService: LiveMidrollService,
        private translateService: TranslateService,
        private storage: StorageMap,
        private assetsService: AssetsService,
        private consentService: ConsentService
    ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.videojs = window.videojs;

        this.embeddedModeService.embedded.subscribe((is_embedded: boolean) => (this.isEmbedded = is_embedded));
        this.embeddedModeService.syndication_origin.subscribe(
            (syndication_domain: string) => (this.syndicationDomain = syndication_domain)
        );
        this.pageVisibilityService.$onPageVisible.subscribe(() => {
            if (this.playingAd === true && this.playingVPAIDAd === false) {
                this.player.play();
            }
        });
        this.pageVisibilityService.$onPageHidden.subscribe(() => {
            if (this.playingAd === true && this.playingVPAIDAd === false) {
                this.player.pause();
            }
        });

        this.updateToPlayerLang = translateService.onLangChange.subscribe(this.updatePlayerLanguage.bind(this));
    }

    private _shouldShowShareButton = false;

    @Input() set shouldShowShareButton(show: boolean) {
        this._shouldShowShareButton = show;
        if (this.player) {
            this.setShowShareButton(this.player, show);
        }
    }

    private _lowQuality = false;

    @Input() set lowQuality(lowQuality: boolean) {
        this._lowQuality = lowQuality;
        if (this.player) {
            this.useLowQuality(this.player, lowQuality);
        }
    }

    public _asset: Asset;

    @Input()
    public set asset(asset: Asset) {
        this._asset = asset;
        // video will only be loaded if _asset is set and _ads is set
        this.loadVideo();
    }

    public _asset_authorization_token: AssetAuthorizationEntity | null;

    @Input() set asset_authorization_token(token: AssetAuthorizationEntity) {
        this._asset_authorization_token = token;
        this.loadVideo();
    }

    private _controls = true;

    @Input() set controls(controls: boolean) {
        this._controls = controls;
        if (this.player) {
            this.player.controls(controls);
        }
    }

    private _ads: boolean;

    public get ads() {
        return this._ads;
    }

    @Input()
    public set ads(ads: boolean) {
        this._ads = ads;
        this.loadVideo();
    }

    public _showLogo = false;

    @Input()
    public set showLogo(show: boolean) {
        if (show === undefined) {
            return;
        }
        this.setShowLogo(show);
    }

    private get isMobile(): boolean {
        return window.innerWidth < 768;
    }

    public ngOnInit(): void {
        // ping api/me every few seconds to prevent user has to enter youth protection pin again after viewing a video
        // (keep him active during video view)
        // @todo muss man hier wieder unsubscriben? nicht dass das ewig weiter pingt und ein memory leak entsteht?
        this.activityPinger = timer(0, 15000).subscribe(() => {
            if (this.playerIsPlaying()) {
                this.inactivityService.pingActivity();
            }
        });

        if (this.externalFullscreenChangedEmitter) {
            this.externalFullscreenChangedEmitter.subscribe(isInExternalFullscreen => {
                if (this.player) {
                    this.player.isFullscreen(isInExternalFullscreen);
                }
            });
        }
    }

    public ngOnChanges(): void {
        if (!this.videoAboutToLoad && this._asset && (this.ads === true || this.ads === false)) {
            this.loadVideo();
        }

        if (this.playEventSubscription === null && this.playEvent !== null) {
            this.playEventSubscription = this.playEvent.subscribe(() => {
                if (this.player) {
                    this.player.play();
                }
            });
        }
    }

    public registerAssetAuthorizationTokenRefresher(asset: Asset): void {
        if (asset.contents_freely_accessible) {
            return;
        }
        let refreshAt = 0;
        if (asset.authorization_token) {
            refreshAt = asset.authorization_token.expires_at * 1000 - +new Date();
        }
        this.assetTokenRefresher = setTimeout(() => {
            this.refreshAssetAuthToken();
        }, refreshAt);
    }

    public refreshAssetAuthToken(): void {
        this.assetsService.getAssetAuthorizationToken(this._asset).subscribe(
            token => (this._asset.authorization_token = token),
            err => {
                if (err.error.error && Object.keys(PlayerStateEnum).includes(err.error.error)) {
                    this.playerState.next(err.error.error);
                } else {
                    console.error('asset token could not be refreshed');
                    this.mediaError.emit(PlayerErrorEnum.MEDIA_ERROR);
                }
            }
        );
    }

    public loadVideo() {
        if (this.videoAboutToLoad === true) {
            return;
        }
        this.videoAboutToLoad = true;

        if (!this._asset || (this.ads !== true && this.ads !== false)) {
            this.videoAboutToLoad = false;
            return;
        }

        this.assetsService
            .hydrateVideosOfAssetWithSource(this._asset)
            .pipe(
                takeUntil(this.disposeAll.asObservable()),
                tap((asset: Asset) => this.registerAssetAuthorizationTokenRefresher(asset)),
                switchMap((asset: Asset) => {
                    return this.initPlayer().pipe(map(() => asset));
                })
            )
            .subscribe(
                (asset: Asset) => {
                    if (asset.videos.length > 1) {
                        this.createAndSetPlaylist(asset);
                    } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        this.player.src(asset.videos[0].sources, { cacheEncryptionKeys: true });
                    }

                    let posterImage;
                    if (asset.images[0]) {
                        posterImage = asset.images[0].url.replace('http:', 'https:');
                    } else {
                        posterImage = '/assets/images/video_placeholder.png';
                    }
                    if (this.autoplay) {
                        const playPromise = this.player.play();
                        // In browsers that don’t yet support this functionality,
                        // playPromise won’t be defined.
                        if (playPromise !== undefined) {
                            playPromise
                                .then(() => {
                                    // Automatic playback started!
                                })
                                .catch(error => {
                                    console.warn('automatic playback failed.', error);
                                });
                        }

                        // wait a second with setting the poster to prevent flickering

                        setTimeout(() => {
                            this.player.poster(posterImage);
                        }, 1000);
                    } else {
                        this.player.poster(posterImage);

                        this.player.agofPlugin().sendAgofRequest(AgofEnum.CONTENT_TYPE_IMAGE_TEXT);
                    }
                },
                err => {
                    if (err) {
                        console.error('PLAYER ERROR', err);
                        if (
                            err.error &&
                            err.error.error &&
                            err.error.error === PlayerStateEnum.CONTENT_CURRENTLY_NOT_PURCHASED
                        ) {
                            this.playerState.next(PlayerStateEnum.CONTENT_CURRENTLY_NOT_PURCHASED);
                        } else {
                            this.playerState.next(PlayerStateEnum.API_ERROR);
                            this.mediaError.next(err);
                        }
                    }
                }
            );
    }

    public ngOnDestroy() {
        this.disposeAll.next(true);
        this.activityPinger.unsubscribe();
        if (this.player) {
            if (this.player.hasStarted()) {
                this.player.trigger('lifecycleabort');
            }
            this.player.dispose();
        }
        if (this.playEventSubscription) {
            this.playEventSubscription.unsubscribe();
        }
        if (this.assetTokenRefresher) {
            clearTimeout(this.assetTokenRefresher);
        }

        this.updateToPlayerLang.unsubscribe();
        this.liveMidrollService.unsubscribe(this._asset.uuid);
    }

    @HostListener('window:beforeunload', ['$event'])
    public beforeunloadHandler(event) {
        if (this.player.hasStarted()) {
            this.player.trigger('lifecycleabort');
        }
    }

    public longplay(): boolean {
        if (this._asset.live) {
            return true;
        }
        if (this._asset.duration.length === 8) {
            return moment.duration(this._asset.duration).minutes() > 15;
        } else if (this._asset.duration.length === 5) {
            return moment.duration('00:' + this._asset.duration).minutes() > 15;
        } else {
            return false;
        }
    }

    public play() {
        console.log('video component play impression');
        if (this.player) {
            this.player.play();
        }
    }

    public pause() {
        if (this.player) {
            this.player.pause();
        }
    }

    public closeOverlay() {
        this.player.trigger('custom-overlay-plugin-hide');
    }

    public playerIsPlaying(): boolean {
        if (!this.player) {
            return false;
        }
        try {
            return !this.player.paused();
        } catch (e) {
            return false;
        }
    }

    private useLowQuality(player: CustomPlayer, lowQuality: boolean): CustomPlayer {
        /*for (let i = 0; i < player.qualityLevels().length; i++) {
            const level = player.qualityLevels()[i];
            // allow only low quality?
            if (lowQuality) {
                // only qualities lower or equal le 360 are allowed
                level.enabled = level.height <= 360;
            } else {
                // allow all qualis
                level.enabled = true;
            }
        }*/
        return player;
    }

    private registerPlayerPlugins() {
        if (!this.videojs.getPlugin('hlsQualitySelector')) {
            this.videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);
        }
        if (!this.videojs.getPlugin('adsPlugin')) {
            this.videojs.registerPlugin('adsPlugin', AdsPlugin);
        }

        if (!this.videojs.getPlugin('trackingPlugin')) {
            this.videojs.registerPlugin('trackingPlugin', TrackingPlugin);
        }

        if (!this.videojs.getPlugin('trackingPlugin')) {
            this.videojs.registerPlugin('trackingPlugin', TrackingPlugin);
        }

        if (!this.videojs.getPlugin('agofPlugin')) {
            this.videojs.registerPlugin('agofPlugin', AGOFPlugin);
        }
        if (!this.videojs.getPlugin('sharePlugin')) {
            this.videojs.registerPlugin('sharePlugin', SharePlugin);
        }
        if (!this.videojs.getPlugin('landscapeFullscreen')) {
            this.videojs.registerPlugin('landscapeFullscreen', landscapeFullscreen);
        }

        if (!this.videojs.getPlugin('landscapeFullscreen')) {
            this.videojs.registerPlugin('landscapeFullscreen', landscapeFullscreen);
        }

        if (this.showOverlay && !this.videojs.getPlugin('overlayPlugin')) {
            this.videojs.registerPlugin('overlayPlugin', VjsPluginOverlay);
        }
    }

    private registerEventListeners(player: CustomPlayer, asset: Asset): CustomPlayer {
        player.on('error', error => {
            // video playback failed - show a message saying why
            const e = player.error();
            console.error('player  error: ', e, error);
            switch (e.code) {
                case MediaError.MEDIA_ERR_ABORTED:
                case MediaError.MEDIA_ERR_NETWORK:
                case MediaError.MEDIA_ERR_DECODE:
                case MediaError.MS_MEDIA_ERR_ENCRYPTED:
                case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
                default:
                    this.mediaError.next(PlayerErrorEnum.MEDIA_ERROR);
                    break;
            }
        });
        player.on('volumechange', event => {
            let volume = player.volume();
            if (player.muted()) {
                volume = 0;
            }
            if (volume !== 0) {
                this.storage.set('player-volume', volume).subscribe(() => {});
            }
        });

        // emit isPlaying or paused events
        player.on(['waiting', 'pause'], () => {
            this.isPlaying.next(false);
        });

        player.on('playing', () => {
            this.isPlaying.next(true);
        });

        player.on('play', event => {
            let disableAgofTracking = false;
            if (window.history.state?.routedWithoutUserInteraction === true) {
                console.info('did not send agof impression because of automated route change');
                disableAgofTracking = true;
            }

            if (!disableAgofTracking) {
                player.agofPlugin().sendAgofRequest();
            }

            if (asset.live) {
                player.controlBar.liveDisplay.show();
            }
            if (!player.scrubbing() && !this.playingAd) {
                this.videoStateChanged.emit(VideoState.started);
            }
        });
        player.on('pause', event => {
            if (!player.seeking() && !player.ended() && !this.playingAd) {
                this.videoStateChanged.emit(VideoState.paused);
            }
        });

        player.on('ended', event => {
            // only emit event when whole playlist finished
            if (!this.playingAd && player.hasPlugin('playlistPlugin')) {
                if (player.playlistPlugin().currentIndex === player.playlist().length - 1) {
                    this.videoStateChanged.emit(VideoState.ended);
                } else {
                    this.videoStateChanged.emit(VideoState.next);
                }
            } else if (!this.playingAd && !player.hasPlugin('playlistPlugin')) {
                this.videoStateChanged.emit(VideoState.ended);
            }
        });
        player.on('playlistitem', event => {
            // next video in playlist.. could be a good place to load ads...
            this.videoStateChanged.emit(VideoState.next);
        });

        // Ad Plugin Events
        player.on('ads-ad-started', event => {
            this.playingAd = true;
            this.isPlayingAd.next(true);
            this.adStateChanged.emit(AdState.started);
        });
        player.on('ads-ad-ended', event => {
            this.playingAd = false;
            this.isPlayingAd.next(false);
            this.adStateChanged.emit(AdState.ended);
        });

        player.on('vpaid-AdLoaded', () => {
            this.playingVPAIDAd = true;
        });
        player.on('vpaid-AdVideoComplete', () => {
            this.playingVPAIDAd = false;
        });

        return player;
    }

    private createPlayerInstance(adsActive: boolean): Observable<CustomPlayer> {
        this.registerPlayerPlugins();

        this.playerId = 'video-player-' + Math.random();

        const subject = new EventEmitter<CustomPlayer>();

        const token = this.authService.token;

        this.videojs.Hls.xhr.beforeRequest = (options: {
            handleManifestRedirects: boolean;
            responseType: string;
            timeout: number;
            headers: { [key: string]: string };
            uri: string; //'http://vod01-streamer-staging.dosbnewmedia.de/encrypt/5f4e2cfc4e6e1cc64a000008.json/encryption.key?';
            withCredentials: boolean;
        }) => {
            if (token && options.uri.endsWith('.m3u8') && options.uri.includes('index')) {
                options.uri = options.uri + '?asset_id=' + this._asset.uuid;
                options.headers = {
                    'Cache-Control': 'private, no-cache',
                    Authorization: token
                };
            }

            return options;
        };

        let player = this.videojs(
            this.videoPlayer.nativeElement,
            {
                liveui: true,
                id: this.playerId,
                somtagService: this.somtagService,
                controls: this._controls,
                adsActive: adsActive,
                techOrder: ['html5'],
                html: { nativeCaptions: false },
                nativeControlsForTouch: false,
                preload: this.preload,
                html5: {
                    hls: {
                        cacheEncryptionKeys: true
                    }
                },
                controlBar: {
                    fullscreen: false,
                    volumePanel: {
                        inline: false,
                        vertical: true
                    }
                },
                plugins: {
                    reloadSourceOnError: {
                        errorInterval: 10,
                        getSource() {
                            console.info('trying to get new source');
                        }
                    }
                }
            },
            () => {
                player = this.onPlayerCreated(player);
                subject.next(player);
            }
        );
        return subject.asObservable();
    }

    private onExternalFullscreenChange() {
        this.player.trigger('fullscreenchange');
    }

    private initPlayer(): Observable<CustomPlayer> {
        if (this.player) {
            return of(this.player);
        }
        return this.authService.userHasSubscriptionWhichRemovesAds().pipe(
            map(hasAdFreePackage => {
                this.userHasSubscriptionWhichRemovesAds = hasAdFreePackage;
            }),
            switchMap(() => this.adsActive()),
            switchMap(adsActive => {
                return this.createPlayerInstance(adsActive);
            }),
            tap((player: CustomPlayer) => {
                this.player = player;
            }),
            takeUntil(this.disposeAll.asObservable())
        );
    }

    private setShowShareButton(player: CustomPlayer, show: boolean): CustomPlayer {
        if (player.hasPlugin('sharePlugin')) {
            player.sharePlugin().shouldShowShareButton(show);
        }
        return player;
    }

    private adsActive(): Observable<boolean> {
        // generally disabled?
        if (environment.ads.useAds === false) {
            return of(false);
        }
        // disabled by asset?
        if (this._asset.flags && this._asset.flags.includes('noad')) {
            console.warn('Ads disabled by asset');
            return of(false);
        }
        // disable for sportmoment clips
        if (this._asset && this._asset.is_moment_of_the_year_clip) {
            return of(false);
        }
        // disabled by property ?
        if (this.ads === false) {
            return of(false);
        }
        // disabled by user?
        return this.authService.adsDisabledForUser().pipe(
            map((adsDisabled: boolean) => {
                return !adsDisabled;
            })
        );
    }

    private setShowLogo(show?: boolean) {
        if (show === false) {
            show = environment.player.showPlayerBranding;
        }
        if (this._asset && this._asset.flags && this._asset.flags.includes('nologo')) {
            show = false;
        }
        this._showLogo = show;
    }

    private onPlayerCreated(player: CustomPlayer): CustomPlayer {
        {
            // used to go in native player playerInFullscreen, if no playerInFullscreen api is available
            if (this.fullscreenToggleRequestlistener) {
                this.fullscreenToggleRequestlistener.subscribe(() => {
                    if (!player.isFullscreen()) {
                        player.requestFullscreen();
                    } else {
                        player.exitFullscreen();
                    }
                });
            }
            player.qualityLevels();
            player.hlsQualitySelector({
                displayCurrentQuality: false,
                vjsIconClass: 'vjs-icon-cog'
            });

            // disable the double click listener to prevent native playerInFullscreen
            player = this.removeDoubleClickFullscreenListener(player);

            // persist player volume
            this.storage.get('player-volume').subscribe((vol: number) => {
                if (vol !== null && vol >= 0 && vol <= 1) {
                    player.volume(vol);
                }
            });

            // Init Plugins (adsPlugin must be the first one to assure redispatch of events)
            // do not remove the ads plugin - it emits the contentchanged event which is used for the video-bottom-playlist
            player.adsPlugin({
                longplay: this.longplay(),
                somtagService: this.somtagService,
                analyticsService: this.analyticsService,
                playPreroll: true,
                playMidroll: !this._asset.live, // no midroll on live videos
                playPostroll: true,
                debug: true,
                secondsBeforeFirstMidrollBlock: environment.ads.somtag.secondsBeforeFirstMidrollBlock,
                secondsBetweenMidrollBlocks: environment.ads.somtag.secondsBetweenMidrollBlocks,
                asset: this._asset,
                contentIsLive: this._asset.live, // important for ads in live streams!
                postrollTimeout: 15000,
                timeout: this.isMobile ? 15000 : 7000,
                liveCuePoints: false
            });

            this.updatePlayerLanguage(player);

            player.airplayButton({});

            player.trackingPlugin({
                inSyndicationPlayer: this.isEmbedded,
                syndicationOrigin: this.syndicationDomain,
                asset: this._asset,
                userHasSubscriptionWhichRemovesAds: this.userHasSubscriptionWhichRemovesAds,
                channel: this.analyticsService.getChannelForAsset(this._asset).toLowerCase(),
                kanal: this.analyticsService.getKanalForAsset(this._asset),
                format: this.analyticsService.getFormatForAsset(this._asset).toLowerCase(),
                showFormat: this.analyticsService.getFormatForAsset(this._asset),
                sourceCompany: this.analyticsService.getSourceCompanyForAsset(this._asset)
            });
            player.on(
                'user-profile-service',
                (
                    event: Event,
                    data: {
                        objectType: ObjectType;
                        action: Action;
                        object: RawAssetFromApi | Playlist | Section | Page | Teaser | any;
                        extras?: {
                            position?: number;
                            total_view_time?: number;
                            ads?: boolean;
                            full_screen?: boolean;
                        };
                        custom?: JSON;
                    }
                ) => {
                    this.userProfileService.trackEvent(
                        data.objectType,
                        data.action,
                        data.object,
                        data.extras,
                        data.custom
                    );
                }
            );
            player.on(
                'analytics-service',
                (
                    event: Event,
                    data: {
                        action: string;
                        properties: {
                            category?: string;
                            event?: string;
                            label?: string;
                            value?: string;
                            noninteraction?: boolean;
                            gtmCustom?: Partial<GtmOptions>;
                        };
                    }
                ) => {
                    this.analyticsService.trackEvent(data.action, data.properties);
                }
            );
            player.agofPlugin({
                ads: player.options().adsActive,
                activatedRoute: this.activatedRoute,
                freeAsset: this._asset.contents_freely_accessible,
                analyticsService: this.analyticsService,
                agofService: this.agofService,
                liveAsset: this._asset.live
            });
            // send an agof request for every video
            player.agofPlugin().reset();

            player.sharePlugin({
                cdr: this.cdr,
                viewContainer: this.viewContainer,
                shareModalTemplate: this.shareModalTemplate,
                asset: this._asset
            });

            player.landscapeFullscreen({
                fullscreen: {
                    enterOnRotate: true,
                    alwaysInLandscapeMode: false,
                    iOS: false
                }
            });

            if (this.showOverlay) {
                player.overlayPlugin({
                    cdr: this.cdr,
                    viewContainer: this.viewContainer,
                    modalTemplate: this.overlayTemplate,
                    asset: this._asset
                });
            }

            player.enableTouchActivity();

            player = this.registerEventListeners(player, this._asset);

            player = this.useLowQuality(player, this._lowQuality);

            player = this.setCustomControlBarButtons(player);

            player = this.setShowShareButton(player, this._shouldShowShareButton);

            player.controls(this._controls);

            player = this.addBrandingComponent(
                player,
                this.viewContainer.createEmbeddedView(this.brandingTemplate, {})
            );

            if (this._asset.live && player.options().adsActive) {
                this.liveMidrollService
                    .subscribeForMidroll(this._asset.uuid, this.isEmbedded ? 'embed' : 'onsite', player)
                    .subscribe(data => {
                        if (player.options().adsActive && data.amount > 0) {
                            this.player.adsPlugin().queueLiveMidroll(data.segmentId, data.amount);
                        }
                    });

                player.on('ended', event => {
                    // after video is finished we need to unsubscribe to prevent stale users
                    this.liveMidrollService.unsubscribe(this._asset.uuid);
                });
            }

            this.videoJsEvents.emit('init');
            return player;
        }
    }

    private addBrandingComponent(player: CustomPlayer, template: EmbeddedViewRef<any>): CustomPlayer {
        const VjsComponent = this.videojs.getComponent('Component');
        const BrandingComponent = this.videojs.extend(VjsComponent, {
            constructor: function () {
                VjsComponent.apply(this, arguments);
                this.addClass('vjs-branding-container');
            },
            el: function () {
                return template.rootNodes[0];
            }
        });
        this.videojs.registerComponent('BrandingComponent', BrandingComponent);
        player.addChild('BrandingComponent');
        return player;
    }

    private setCustomControlBarButtons(player: CustomPlayer): CustomPlayer {
        const controls = [];
        if (this.allowFullscreen === true) {
            controls.push({
                name: 'Fullscreen',
                icon: 'vjs-fullscreen-control',
                withText: false,
                customClass: 'custom-fullscreen'
            });
        }

        if (this.assetCount > 1 && this.browser && this.browser.name !== 'ios' && this.browser.name !== 'ie') {
            controls.push({ name: 'Konferenzmodus', icon: 'fa-clone', withText: true, hideDuringAd: true });
        }
        if (this.isEmbedded) {
            controls.push({ name: 'Datenschutz', icon: 'fa-info-circle', withText: false, hideDuringAd: true });
        }
        player.removeChild(player.getChild('fullscreen'));
        this.customControlBarComponents.forEach(comp => player.getChild('controlBar').removeChild(comp));
        this.customControlBarComponents = [];
        controls.forEach(button => {
            const Button = this.videojs.getComponent('Button');
            const CustomButton = this.videojs.extend(Button, {
                constructor: function () {
                    Button.apply(this, arguments);
                    if (button.icon.includes('vjs-')) {
                        this.addClass(button.icon);
                    }
                    if (button.hideDuringAd) {
                        this.addClass('hide-during-ad');
                    }
                    if (button.withText) {
                        this.addClass('button-with-text');
                        this.contentEl().innerHTML = `<span class="fa vjs-icon-placeholder ${button.icon}"></span><span class="text-button-text">${button.name}</span>`;
                    }
                    if (button.name === 'Datenschutz') {
                        this.addClass('button-with-text');
                        this.contentEl().innerHTML = `<span class="fa vjs-icon-placeholder ${button.icon}"></span>`;
                    }
                    if (button.customClass) {
                        this.addClass(button.customClass);
                    }
                    this.controlText(button.controlText || button.name);
                },
                handleClick: event => {
                    // send event to videojs (for plugins)
                    player.trigger('custom-control-bar-button-clicked', button);
                    // send to player compoennt (for conference player)
                    if (button.name === 'Konferenzmodus') {
                        this.toggleConferenceMode.emit();
                    }
                    if (button.name === 'Datenschutz') {
                        this.consentService.showConsentBanner();
                    }
                    if (button.name === 'Fullscreen') {
                        this.requestFullscreenToggle.emit();
                    }
                }
            });
            this.videojs.registerComponent(button.name, CustomButton);
            this.customControlBarComponents.push(player.getChild('controlBar').addChild(button.name, {}));
        });

        // the control bar seems to get recomposed, thus we must remove the doubleclick listener;
        return this.removeDoubleClickFullscreenListener(player);
    }

    /**
     *  Creates a playlist, activates the Playlist Plugin and triggers the player to play the first video
     * @param asset should be an Asset with more than one video
     */
    private createAndSetPlaylist(asset: Asset) {
        const playlist: VideoPlaylist = asset.videos.map((video, index) => {
            return <VideoPlaylistElement>{
                name: video.label === null ? 'Teil ' + (index + 1) : video.label, // might be null,
                duration: video.duration,
                description: asset.label,
                sources: video.sources,
                thumbnail: [
                    {
                        src: asset.images[index].url
                    }
                ]
            };
        });

        if (this.videojs.getPlugin('playlistPlugin') === undefined) {
            this.videojs.registerPlugin('playlistPlugin', PlaylistPlugin);
        }
        const tpl: EmbeddedViewRef<any> = this.viewContainer.createEmbeddedView(this.playListTemplate, {});

        this.player.playlistPlugin({
            playlist: playlist,
            template: tpl,
            playlistHandlerService: this.playlistHandlerService,
            showPlaylistButton: this.playlistButton,
            showChapterButton: this.chapterButton,
            showPlaylistArrows: this.playlistArrows,
            router: this.router,
            activatedRoute: this.activatedRoute,
            cdr: this.cdr
        });
    }

    private removeDoubleClickFullscreenListener(player: CustomPlayer): CustomPlayer {
        // @ts-ignore
        player.tech_.off('dblclick');
        player.off('dblclick');

        player.on('dblclick', event => {
            if (event.target.tagName === 'VIDEO') {
                this.requestFullscreenToggle.next();
            }
        });
        return player;
    }

    private updatePlayerLanguage(player: CustomPlayer): CustomPlayer {
        if (player.adsPlugin()) {
            this.translateService.get('player.ads.advertisement').subscribe((text: string) => {
                player.adsPlugin().controlBarTextAdvertisement = text;
            });

            this.translateService.get('player.ads.remaining').subscribe((text: string) => {
                player.adsPlugin().controlBarTextRemaining = text;
            });
        }
        return player;
    }
}
