import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AgofEnum } from '../../../enum/agof.enum';
import { environment } from '../../../environments/environment';
import { AgofService } from '../../../services/agof.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
    public environment;
    public email = '';
    public password = '';
    public isLoading = true;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private title: Title,
        private trans: TranslateService,
        private route: ActivatedRoute,
        private agofService: AgofService,
        private analyticsService: AnalyticsService
    ) {
        this.environment = environment;
        this.isLoading = true;

        this.trans.get('login.pageTitle').subscribe(res => this.title.setTitle(res + environment.pageTitleAppendix));

        const query = this.route.snapshot.queryParams;
        if (query.redirect) {
            this.authenticationService.pathToRedirectAfterLogin = query.redirect;
        } else {
            this.authenticationService.pathToRedirectAfterLogin = environment.urlSlugs.dashboard;
        }

        if (query.email) {
            this.email = query.email;
        }
        this.isLoading = false;
    }

    public ngOnInit(): void {
        console.log('init');
        this.analyticsService.trackPageview();

        this.authenticationService.isLoggedIn().subscribe((isLoggedin: boolean) => {
            if (isLoggedin) {
                this.router.navigateByUrl(this.authenticationService.pathToRedirectAfterLogin);
            }
        });
        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        AgofEnum.HOMEPAGE_NO,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        AgofEnum.PAID_NO,
                        AgofEnum.CONTENT_TOPIC_Sports
                    ),
                    cpChannel: AgofEnum.CHANNEL_DEFAULT,
                    coSecondPart: window.location.pathname + '/',
                    survey: true
                }
            }
        });
    }

    public loginWithToken(token) {
        this.authenticationService.loginWithToken(token).subscribe(
            () => {
                this.redirect();
            },
            err => {
                this.isLoading = false;
                console.error(err);
            }
        );
    }

    public redirect() {
        if (
            this.authenticationService.pathToRedirectAfterLogin.startsWith('https://') ||
            this.authenticationService.pathToRedirectAfterLogin.startsWith('http://')
        ) {
            window.location.href = this.authenticationService.pathToRedirectAfterLogin;
        } else {
            this.router.navigateByUrl(this.authenticationService.pathToRedirectAfterLogin);
        }
    }
}
