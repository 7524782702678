import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../../services/analytics.service';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';

@Component({
    selector: 'app-password-forgot',
    templateUrl: './password-forgot.component.html'
})
export class PasswordForgotComponent implements OnInit {
    public email = '';
    public isLoading = false;
    public successMessage: string;
    public errorMessage: string;
    public environment = environment;

    constructor(
        protected authenticationService: AuthenticationService,
        private analyticsService: AnalyticsService,
        protected activatedRoute: ActivatedRoute,
        private toastr: ToastrService,
        private translation: TranslateService
    ) {}

    public ngOnInit(): void {
        this.analyticsService.trackPageview();
        this.activatedRoute.queryParams.subscribe(params => {
            if (params.hasOwnProperty('email')) {
                this.email = params.email;
            }
        });
    }

    public onSubmit() {
        this.isLoading = true;
        this.authenticationService.requestPasswordResetMail(this.email).subscribe(
            (response: { message: string; status: number }) => {
                this.isLoading = false;
                this.errorMessage = null;
                this.successMessage = response.message;
            },
            err => {
                this.isLoading = false;
                if (err.error.status === 404) {
                    return forkJoin([
                        this.translation.get('forgotPass.emailNotFoundError'),
                        this.translation.get('toasts.errorTitle')
                    ]).subscribe(([message, title]) => {
                        return this.toastr.error(message, title);
                    });
                }
                this.translation.get('toasts.errorTitle').subscribe(trans => {
                    this.toastr.error(err.error.message, trans);
                });
            }
        );
    }
}
