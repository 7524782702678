import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as parseCacheControl from 'parse-cache-control';

@Injectable()
export class HttpCacheService {
    private cache = {};

    public get(req: HttpRequest<unknown>): HttpResponse<unknown> {
        if (req.method !== 'GET') {
            return null;
        }
        return this.cache[req.method + req.urlWithParams];
    }

    public put(req: HttpRequest<unknown>, resp: HttpResponse<unknown>): void {
        if (req.method !== 'GET') {
            return;
        }
        // check if caching is allowed! and how long
        if (resp.headers.get('Cache-Control')) {
            const headers = parseCacheControl(resp.headers.get('Cache-Control'));

            // cache only if response is public
            if (headers.public === true) {
                // cache only if max age header is set
                if (typeof headers['max-age'] === 'number') {
                    this.cache[req.method + req.urlWithParams] = resp;

                    setTimeout(() => {
                        delete this.cache[req.method + req.urlWithParams];
                    }, 1000 * headers['max-age']);
                }
            }
        }

        // Cache-Control: must-revalidate
        // Cache-Control: no-cache
        // Cache-Control: no-store
        // Cache-Control: no-transform
        // Cache-Control: public
        // Cache-Control: private
        // Cache-Control: proxy-revalidate
        // Cache-Control: max-age=<seconds>
        // Cache-Control: s-maxage=<seconds>
    }

    public deleteCache(): void {
        this.cache = {};
    }
}
