import { Tag } from './tag.entity';
import { RawLiveEventFromApi } from './raw-live-event-from-api.entity';
import { Asset } from './asset.entity';

export class LiveEvent {
    public images: string[];
    public now: boolean;
    public asset_version: number;
    public starts_at: Date;
    /**
     *  @var asset_uuid is referencing the asset_id
     */
    public asset_uuid: string;
    public title: string;
    public ends_at: Date;
    public tags?: Tag[];
    public body: string;
    public path: string;
    public ics: string;

    // generated in frontend
    public sportart?: Tag;
    public channel?: Tag;

    // NOT SET BY API
    public asset?: Asset;

    public constructor(raw: RawLiveEventFromApi) {
        this.images = raw.images;
        this.now = raw.now;
        this.asset_version = raw.asset_version;
        this.starts_at = raw.starts_at;
        /**
         *  @var asset_uuid is referencing the asset_id
         */
        this.asset_uuid = raw.asset_uuid;
        this.title = raw.title;
        this.ends_at = raw.ends_at;
        this.body = raw.body;
        this.path = raw.path;
        this.ics = raw.ics;

        if (raw.tags['Sportart']) {
            this.sportart = {
                callname: 'sportart',
                label: 'Sportart',
                uuid: 'unknown',
                values: raw.tags['Sportart']
            };
        }
        if (raw.tags['Sporttype']) {
            this.sportart = {
                callname: 'keywords-keyword-text',
                label: 'Sporttype',
                uuid: 'unknown',
                values: raw.tags['Sporttype']
            };
        }
        if (raw.tags['Kanal']) {
            this.channel = {
                callname: 'channel',
                label: 'Kanal',
                uuid: 'unknown',
                values: raw.tags['Kanal']
            };
        }

        if (Array.isArray(raw.tags)) {
            raw.tags.forEach((tag: Tag | string) => {
                if (typeof tag === 'string') {
                } else {
                    if (tag.callname === 'sportart') {
                        this.sportart = tag;
                    }

                    if (tag.callname === 'channel') {
                        this.channel = tag;
                    }

                    if (tag.callname === 'keywords-keyword-text') {
                        this.sportart = tag;
                    }
                }
            });
        }
    }
}
