import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PlayerStateEnum } from '../../../../enum/player-state.enum';
import { Router } from '@angular/router';
import { EmbeddedModeService } from '../../../../services/embedded-mode.service';

@Component({
    selector: 'app-player-login',
    templateUrl: './player-login.component.html'
})
export class PlayerLoginComponent {
    public environment = environment;
    public currentUrl: string;
    public playerStateEnum = PlayerStateEnum;
    @Input() public playerState: PlayerStateEnum;

    @Input() public backgroundImage: string;

    constructor(protected router: Router, public embeddedModeService: EmbeddedModeService) {
        if (embeddedModeService.embedded.getValue()) {
            console.log(window.top.location);
            this.currentUrl = window.location != window.parent.location ? document.referrer : document.location.href;
        } else {
            this.currentUrl = this.router.url;
        }
    }
}
