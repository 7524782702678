import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { Section } from '../../../../entities/section.entity';
import { AssetsService } from '../../../../services/api/methods/assets.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { PlayerComponent } from '../../../elements/player/player.component';
import { LiveEventsService } from '../../../../services/api/methods/live-events.service';
import { LiveEvent } from '../../../../entities/live-event.entity';
import { AnalyticsService } from '../../../../services/analytics.service';
import { AgofService } from '../../../../services/agof.service';
import { AgofEnum } from '../../../../enum/agof.enum';
import { RoutingHelperService } from '../../../../services/routing-helper.service';
import { Asset } from '../../../../entities/asset.entity';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalTagService } from '../../../../services/canonical-tag.service';
import { TagCloudComponent } from './tag-cloud/tag-cloud.component';
import { AdContainerService } from '../../../elements/ad-container/ad-container.service';
import { WebsocketService } from '../../../../services/websocket.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppName } from '../../../../enum/app-name.enum';
import { AuthenticationService } from '../../../../services/api/methods/authentication.service';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'app-asset-page',
    templateUrl: './asset-page.component.html'
})
export class AssetPageComponent implements OnInit, OnDestroy {
    @Input() public section: Section | undefined;
    @ViewChild('player') public player: PlayerComponent;
    @ViewChild('tagCloudComponent') public tagCloudComponent: TagCloudComponent;
    @ViewChild('container') public container: ElementRef<HTMLDivElement>;
    public containerWidth: number;
    public relatedAssets: Asset[] = [];
    public afterXmas = new Date().getFullYear() > 2020 || new Date().getDate() > 24;
    public _assets: Asset[];
    public mainAsset: Asset;
    public showRelatedAssets = true;
    public notitle: boolean;
    public novideo: boolean;
    public notags: boolean;
    public viewers: number = null;
    public environment = environment;
    public autoplay = false;
    public hasAds = false;
    public showBottomPlaylist = environment.player.showBottomPlaylist;
    public AppName = AppName;
    private viewersSubscription: Subscription;
    private currentRelatedAssetPage = 0;
    private subscriptionToAdContainer;
    public sponsorLink: string;

    constructor(
        private authenticationService: AuthenticationService,
        private assetService: AssetsService,
        private router: Router,
        private liveEventsService: LiveEventsService,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private metaService: Meta,
        private title: Title,
        private analyticsService: AnalyticsService,
        private agofService: AgofService,
        private routingHelperService: RoutingHelperService,
        private canonicalTagService: CanonicalTagService,
        private adContainerService: AdContainerService,
        private websocketService: WebsocketService
    ) {
        this.hasAds = this.environment.ads.useAds;
        this.sponsorLink =
            'https://bs.serving-sys.com/Serving/adServer.bs?cn=trd&pli=1076109348&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_68}&adid=1082195334&ord=' +
            new Date().getTime();
    }

    @Input()
    public set asset(asset: Asset) {
        if (asset.url) {
            this.canonicalTagService.updateCanonicalURL(asset.url);
        }
        if (this.viewersSubscription) {
            this.viewersSubscription.unsubscribe();
        }
        this.viewersSubscription = this.websocketService
            .viewers(asset)
            .subscribe((amount: { room: string; amount: number }) => {
                if (amount.amount !== this.viewers) {
                    this.viewers = amount.amount;
                    this.cdr.markForCheck();
                }
            });
        this.mainAsset = asset;
        this._assets = [asset];
        if (asset.flags && asset.flags.includes('norelated')) {
            this.showRelatedAssets = false;
        }

        if (asset.flags && asset.flags.includes('notitle')) {
            this.notitle = true;
        }
        if (asset.flags && asset.flags.includes('novideo')) {
            this.novideo = true;
        }
        if (asset.flags && asset.flags.includes('notags')) {
            this.notags = true;
        }
        if (asset.live === true && this.mainAsset.channel) {
            this.liveEventsService
                .getLiveEventsForTagFilter(
                    [
                        {
                            callname: 'bereich',
                            values: this.mainAsset.channel.values
                        }
                    ],
                    20
                )
                .subscribe(
                    (liveEvents: LiveEvent[]) => {
                        liveEvents.forEach((liveEvent: LiveEvent) => {
                            if (liveEvent.now === true) {
                                const idx = this._assets.findIndex(ass => ass.uuid === liveEvent.asset_uuid);
                                // only add the asset if it is not in the array already, when it is free to view or when the user has bought it
                                if (idx < 0) {
                                    this.assetService
                                        .getAsset(liveEvent.asset_uuid)
                                        .pipe(
                                            switchMap(liveAsset => {
                                                if (liveAsset.contents_freely_accessible === true) {
                                                    return of(liveAsset);
                                                }
                                                return this.authenticationService
                                                    .userHasSubscriptionOrPpvForAsset(liveAsset)
                                                    .pipe(
                                                        map(hasSub => {
                                                            if (hasSub) {
                                                                return liveAsset;
                                                            }
                                                            return null;
                                                        })
                                                    );
                                            })
                                        )
                                        .subscribe((liveAsset: Asset | null) => {
                                            if (liveAsset !== null) {
                                                const ass = this._assets;
                                                ass.push(liveAsset);
                                                this._assets = [...ass];
                                            }
                                        });
                                }
                            }
                        });
                    },
                    err => console.error('error getting live events for conference mode', err)
                );
        }
    }

    @HostListener('window:resize')
    public setContainerWidth() {
        if (this.container) {
            this.containerWidth = this.container.nativeElement.clientWidth;
            this.cdr.detectChanges();
        }
    }

    public ngOnDestroy() {
        if (this.subscriptionToAdContainer) {
            this.subscriptionToAdContainer.unsubscribe();
        }
    }

    public ngOnInit(): void {
        this.loadRelatedAssets();

        this.subscriptionToAdContainer = this.adContainerService.sizeChange.subscribe(() => {
            this.setContainerWidth();
        });

        this.title.setTitle(this.mainAsset.label + environment.pageTitleAppendix);
        this.metaService.updateTag(
            { property: 'description', content: this.mainAsset.teaser },
            'property="description"'
        );
        this.activatedRoute.url.subscribe((url: UrlSegment[]) => {
            this.metaService.updateTag(
                {
                    property: 'og:url',
                    name: 'https://' + window.location.host + '/' + url.join('/')
                },
                'property="og:url"'
            );
        });

        this.metaService.updateTag(
            {
                property: 'og:title',
                content: this.mainAsset.label + environment.pageTitleAppendix
            },
            'property="og:title"'
        );
        this.metaService.updateTag(
            { property: 'og:description', content: this.mainAsset.teaser },
            'property="og:description"'
        );
        this.metaService.updateTag(
            { property: 'og:image', content: this.mainAsset.images[0].url },
            'property="og:image"'
        );
        this.metaService.updateTag(
            { property: 'twitter:card', content: 'summary_large_image' },
            'property="twitter:card"'
        );

        if (this.routingHelperService.shouldAutoplay()) {
            this.autoplay = true;
        } else {
            // wenn kein autoplay, dann darf die seite eine agof text impression schicken
            this.analyticsService.trackEvent('AGOF_IMPRESSION', {
                category: 'AGOF_IMPRESSION',
                event: 'AGOF_IMPRESSION',
                gtmCustom: {
                    agof: {
                        cpFirstPart: this.agofService.createCategoryCode(
                            AgofEnum.LANGUAGE_GERMAN,
                            AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                            AgofEnum.CONTENT_CREATOR_EDITOR,
                            AgofEnum.HOMEPAGE_NO,
                            AgofEnum.DISTRIBUTION_ONLINE,
                            AgofEnum.DEVICE_NO_APP,
                            AgofEnum.PAID_NO,
                            AgofEnum.CONTENT_TOPIC_Sports
                        ),
                        cpChannel: AgofEnum.CHANNEL_DEFAULT,
                        coSecondPart: window.location.pathname,
                        survey: true
                    }
                }
            });
        }
        this.setContainerWidth();
    }

    public loadRelatedAssets() {
        this.assetService
            .getRelatedAssets(this.mainAsset, this.currentRelatedAssetPage + 1, 18)
            .subscribe((assets: Asset[]) => {
                this.currentRelatedAssetPage++;
                if (environment.appName !== 'handball') {
                    this.relatedAssets.push(...assets);
                    this.cdr.markForCheck();
                    return;
                }
                this.relatedAssets.push(
                    ...assets.filter((as: Asset) => {
                        if (as.section) {
                            return as.section.uuid === this.mainAsset.section.uuid;
                        }
                        return true;
                    })
                );
                this.cdr.markForCheck();
            });
    }

    public share(network) {
        switch (network) {
            case 'facebook':
                window.open('https://www.facebook.com/sharer.php?u=' + encodeURI(window.location.href));
                return;
            case 'twitter':
                window.open('https://twitter.com/share?url=' + encodeURI(window.location.href));
                return;
            case 'google':
                window.open('https://plus.google.com/share?url=' + encodeURI(window.location.href));
                return;
        }
    }

    public trackSubscriptionAdded($event: { call_name: string; value: string }) {
        this.analyticsService.trackEvent('Subscription Subscribe', {
            category: 'Subscription',
            noninteraction: false,
            event: 'Subscription Subscribe',
            label: $event.call_name,
            value: $event.value
        });
    }

    public trackSubscriptionRemoved($event: { call_name: string; value: string }) {
        this.analyticsService.trackEvent('Subscription Unsubscribe', {
            category: 'Subscription',
            noninteraction: false,
            event: 'Subscription Unsubscribe',
            label: $event.call_name,
            value: $event.value
        });
    }

    public reloadPlayer() {
        this.assetService.getAsset(this._assets[0].uuid).subscribe(asset => {
            this._assets = [];
            this.asset = asset;
        });
    }

    public startVideo(sourceUrl: string) {
        const newAsset = new Asset(this.mainAsset);
        newAsset.videos[0].url = sourceUrl;
        this.asset = newAsset;
    }
}
