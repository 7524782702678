import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from '../../../entities/menu-item.entity';
import { AnalyticsService } from '../../../services/analytics.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-nav-link',
    templateUrl: './nav-link.component.html'
})
export class NavLinkComponent {
    @Input() public menuItem: MenuItem;
    @Input() public isDropDown: boolean;
    @Input() public prefix = '';
    @Output() public click = new EventEmitter();

    constructor(public analyticsService: AnalyticsService, public router: Router) {}

    public linkClicked(event) {
        this.analyticsService.trackEvent('Navigation clicked', {
            category: 'Navigation Click',
            label: this.router.url,
            gtmCustom: {
                googleAnalyticsEventAction: this.menuItem.name
            }
        });
        this.click.emit(event);
    }
}
