import { VideoPlaylist } from '../entities/video-playlist.entity';
import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class PlaylistHandlerService {
    @Output() public activePlaylistIndex: EventEmitter<{
        index: number;
        play: boolean;
    }> = new EventEmitter();
    @Output() public playlist: EventEmitter<VideoPlaylist> = new EventEmitter();
    @Output() public sidePlaylistOpen: EventEmitter<boolean> = new EventEmitter();

    public setPlaylist(playlist: VideoPlaylist): void {
        this.playlist.emit(playlist);
    }

    public goToPlaylistIndex(index: number, play: boolean = false): void {
        this.activePlaylistIndex.emit({ index: index, play: play });
    }

    public toggleSidePlaylist(open: boolean): void {
        this.sidePlaylistOpen.emit(open);
    }
}
