import * as videojs from 'video.js';
import { EmbeddedViewRef } from '@angular/core';
import { CustomPlayer } from '../../../../../../../@types/CustomPlayer';
import { VjsOverlayPluginOptions } from '../../../../../../../@types/VjsOverlayPluginOptions';
import { environment } from '../../../../../environments/environment';
import { fromEvent, timer } from 'rxjs';
import { OverlayModalComponent } from '../overlay-modal/overlay-modal.component';
import { Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged } from 'rxjs/operators';

// @ts-ignore
const Plugin = window.videojs.getPlugin('plugin');

export default class VjsPluginOverlay extends Plugin {
    private options: VjsOverlayPluginOptions;
    private videojs;
    private player: CustomPlayer;
    private overlay: videojs.default.ModalDialog;

    private delayShow = 5000;
    private showDuration = 15000;

    constructor(player: CustomPlayer, options: VjsOverlayPluginOptions) {
        super(player, options);
        this.options = options;
        // @ts-ignore
        this.videojs = window.videojs;
        this.player = player;

        if (!['handball', 'sportdeutschland'].includes(environment.appName)) {
            return;
        }

        /**
         * Hide the teaser on this event
         */
        this.player.on('custom-overlay-plugin-hide', () => {
            if (this.overlay) {
                this.overlay.hide();
            }
        });

        const timeUpdate = new Subject();

        this.player.on('timeupdate', (e, data) => {
            timeUpdate.next(Math.round(this.player.currentTime()));
        });
        timeUpdate
            .asObservable()
            .pipe(distinctUntilChanged())
            .subscribe((time: number) => {
                const isZeroEvery15Minutes = time % 900;
                if (time > 0 && isZeroEvery15Minutes === 0) {
                    this.player.trigger('custom-overlay-plugin-show');
                }
            });

        /**
         * Show the teaser on this event
         */

        this.player.on('custom-overlay-plugin-show', () => {
            if (new Date() > new Date(1619733600000)) {
                const timr = timer(this.delayShow).subscribe(() => {
                    this.player.trigger('custom-overlay-plugin-shown');
                    this.showTeaser();
                    timr.unsubscribe();
                });
            }
        });

        this.player.on('custom-overlay-plugin-shown', () => {
            const timr = timer(this.showDuration).subscribe(() => {
                this.player.trigger('custom-overlay-plugin-hide');
                timr.unsubscribe();
            });
        });

        /**
         * show after ad block
         */
        this.player.one('ads-ad-ended', () => {
            this.player.trigger('custom-overlay-plugin-show');
        });

        /**
         * show after failed ad request
         */
        this.player.on('adtimeout', () => {
            this.player.trigger('custom-overlay-plugin-show');
        });

        /**
         * show after failed ad request
         */
        this.player.on('contentresumed', () => {
            this.player.trigger('custom-overlay-plugin-show');
        });

        this.player.on('nopreroll', () => {
            this.player.trigger('custom-overlay-plugin-show');
        });
    }

    public showTeaser() {
        const tpl: EmbeddedViewRef<any> = this.options.viewContainer.createEmbeddedView(this.options.modalTemplate, {
            asset: this.options.asset
        });

        if (this.overlay) {
            this.overlay.dispose();
        }

        this.overlay = this.player.createModal(tpl.rootNodes[0], {
            pauseOnOpen: false,
            uncloseable: true,
            fillAlways: true
        });
        this.overlay.addClass('vjs-custom-overlay-modal');
        this.player.el().classList.remove('vjs-controls-disabled');

        this.player.on('show', () => {
            this.player.el().classList.remove('vjs-controls-disabled');
        });
        this.overlay.show();
    }
}
