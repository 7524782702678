import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs/internal/observable/interval';
import { RawAssetFromApi } from '../../../../entities/raw-asset-from-api.entity';
import { RoutingHelperService } from '../../../../services/routing-helper.service';

@Component({
    selector: 'app-revolver-play',
    templateUrl: './revolver-play.component.html'
})
export class RevolverPlayComponent implements OnInit, OnDestroy {
    @Input() public backgroundImage: string;
    @Input() public nextAsset: RawAssetFromApi;
    @Output() public abort: EventEmitter<void> = new EventEmitter();
    public remainingSeconds = 10;
    public circle: any;
    private sub: Subscription;

    constructor(private routingHelperService: RoutingHelperService) {}

    public playNextMatch(routedWithoutUserInteraction = false) {
        this.routingHelperService.goToAssetPage(this.nextAsset, true, routedWithoutUserInteraction);
    }

    public stopCountDown() {
        this.sub.unsubscribe();
        this.abort.next();
    }

    public ngOnInit(): void {
        this.circle = document.querySelector('circle');
        const radius = this.circle.r.baseVal.value;
        const circumference = radius * 2 * Math.PI;
        this.circle.style.strokeDasharray = `${circumference} ${circumference}`;
        this.circle.style.strokeDashoffset = `${circumference}`;
        this.setProgress(100);
        this.sub = interval(1000).subscribe(val => {
            this.remainingSeconds -= 1;
            this.setProgress((this.remainingSeconds / 10) * 100);
            if (this.remainingSeconds < 1) {
                this.playNextMatch(true);
            }
        });
    }

    public ngOnDestroy() {
        this.sub.unsubscribe();
    }

    private setProgress(percent) {
        const radius = this.circle.r.baseVal.value;
        const circumference = radius * 2 * Math.PI;
        this.circle.style.strokeDashoffset = circumference - (percent / 100) * circumference;
    }
}
