import { HttpClient } from '@angular/common/http';
import { AssetsService } from './assets.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { LiveEvent } from '../../../entities/live-event.entity';
import { environment } from '../../../environments/environment';
import { RawLiveEventFromApi } from '../../../entities/raw-live-event-from-api.entity';
import { TagFilter } from '../../../entities/tag-filter.entity';
import { Asset } from '../../../entities/asset.entity';

@Injectable()
export class LiveEventsService extends ApiService {
    private endpoint = 'live_events';

    constructor(private assetsService: AssetsService, protected http: HttpClient) {
        super(http);
    }

    public getLiveAssets(tagFilters: TagFilter[] = [], itemsPerPage = 20, page = 1): Observable<Asset[]> {
        let params: { [key: string]: string } = {
            page: page.toString(),
            per_page: itemsPerPage.toString()
        };
        if (environment.liveEventsFilter.active === true) {
            tagFilters.push({
                callname: environment.liveEventsFilter.key,
                values: [environment.liveEventsFilter.value]
            });
        }
        for (const tag of tagFilters) {
            params = {
                ...params,
                ['tag_filters[][callname]']: tag.callname
            };
            for (const value of tag.values) {
                params = {
                    ...params,
                    ['tag_filters[][values][]']: value
                };
            }
        }

        return this.http
            .get<{ items: RawLiveEventFromApi[] }>(this.endpoint, { params })
            .pipe(
                map(response => response.items),
                map((liveEvents: RawLiveEventFromApi[]) => liveEvents.map(ev => new LiveEvent(ev))),
                switchMap(liveEvents => {
                    return forkJoin(
                        liveEvents.map(liveEvent =>
                            this.assetsService.getAsset(liveEvent.asset_uuid).pipe(
                                map(asset => {
                                    asset.liveEvent = liveEvent;
                                    return asset;
                                })
                            )
                        )
                    );
                })
            );
    }

    public getAmountOfCurrentLiveEvents(): Observable<number> {
        let params: { [key: string]: string } = {
            page: '1',
            per_page: '20',
            now: 'true'
        };
        if (environment.liveEventsFilter.active === true) {
            params = {
                ...params,
                ['tag_filters[][callname]']: environment.liveEventsFilter.key,
                ['tag_filters[][values][]']: environment.liveEventsFilter.value
            };
        }

        return this.http
            .get<{ items: RawLiveEventFromApi[] }>(this.endpoint, { params })
            .pipe(
                map(response => {
                    return response.items.filter((liveEvent: LiveEvent) => {
                        return liveEvent.now === true;
                    });
                }),
                map(response => response.length),
                catchError(this.handleError('getAmountOfCurrentLiveEvents', 0))
            );
    }

    public getLiveEvents(itemsPerPage = 20, page = 1, includeAsset: boolean = false): Observable<LiveEvent[]> {
        let params: { [key: string]: string } = {
            page: page.toString(),
            per_page: itemsPerPage.toString()
        };
        if (environment.liveEventsFilter.active === true) {
            params = {
                ...params,
                ['tag_filters[][callname]']: environment.liveEventsFilter.key,
                ['tag_filters[][values][]']: environment.liveEventsFilter.value
            };
        }
        return this.http
            .get<{ items: RawLiveEventFromApi[] }>(this.endpoint, { params })
            .pipe(
                map(response => response.items),
                map((liveEvents: RawLiveEventFromApi[]) => liveEvents.map(ev => new LiveEvent(ev))),
                switchMap(liveEvents => {
                    if (includeAsset) {
                        return forkJoin(
                            liveEvents.map(liveEvent =>
                                this.assetsService.getAsset(liveEvent.asset_uuid).pipe(
                                    catchError(() => {
                                        return of(null);
                                    }),
                                    map((asset: Asset | null) => {
                                        if (asset) {
                                            liveEvent.asset = asset;
                                        }

                                        return liveEvent;
                                    })
                                )
                            )
                        );
                    } else {
                        of(liveEvents);
                    }
                })
            );
    }

    public getLiveEventsForTagFilter(
        tagFilters: TagFilter[],
        itemsPerPage = 20,
        page = 1,
        includeAsset: boolean = false
    ): Observable<LiveEvent[]> {
        let params: { [key: string]: string } = {
            page: page.toString(),
            per_page: itemsPerPage.toString()
        };
        for (const tag of tagFilters) {
            params = {
                ...params,
                ['tag_filters[][callname]']: tag.callname
            };
            for (const value of tag.values) {
                params = {
                    ...params,
                    ['tag_filters[][values][]']: value
                };
            }
        }
        return this.http
            .get<{ items: RawLiveEventFromApi[] }>(this.endpoint, { params })
            .pipe(
                map(response => response.items),
                map((liveEvents: RawLiveEventFromApi[]) => liveEvents.map(ev => new LiveEvent(ev))),
                switchMap((liveEvents: LiveEvent[]) => {
                    if (!includeAsset) {
                        return of(liveEvents);
                    }
                    return forkJoin(
                        liveEvents.map((liveEvent: LiveEvent) =>
                            this.assetsService.getAsset(liveEvent.asset_uuid).pipe(
                                map((asset: Asset) => {
                                    liveEvent.asset = asset;
                                    return liveEvent;
                                })
                            )
                        )
                    );
                })
            );
    }
}
