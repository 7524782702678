import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { AgofEnum } from '../../../../enum/agof.enum';
import { AnalyticsService } from '../../../../services/analytics.service';
import { AgofService } from '../../../../services/agof.service';
import { SubscriptionService } from '../../../../services/api/methods/subscription.service';
import { Subscription } from '../../../../entities/subscription';
import { ConfirmationDialogService } from '../../../../services/confirmation-dialog.service';

@Component({
    selector: 'app-subscribed-channels',
    templateUrl: './subscribed-channels.component.html'
})
export class SubscribedChannelsComponent implements OnInit {
    public environment;

    public subscriptions: Subscription[];

    constructor(
        private title: Title,
        private trans: TranslateService,
        private analyticsService: AnalyticsService,
        private agofService: AgofService,
        private subscriptionService: SubscriptionService,
        private confirmationService: ConfirmationDialogService
    ) {
        this.environment = environment;
        moment.locale(this.environment.defaultLocale);

        trans
            .get('subscribedChannels.pageTitle')
            .subscribe(res => this.title.setTitle(res + environment.pageTitleAppendix));
    }

    public ngOnInit(): void {
        this.analyticsService.trackPageview();
        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        AgofEnum.HOMEPAGE_NO,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        AgofEnum.PAID_NO,
                        AgofEnum.CONTENT_TOPIC_Information_about_the_website_Service
                    ),
                    cpChannel: AgofEnum.CHANNEL_DEFAULT,
                    coSecondPart: window.location.pathname,
                    survey: true
                }
            }
        });
        this.subscriptionService.subscriptionStore$.subscribe(subscriptions => {
            this.subscriptions = subscriptions;
        });
    }

    public removeSubscription(sub: Subscription) {
        this.subscriptionService.delete$(sub).subscribe();
    }

    public onIntervalChange(e, sub: Subscription) {
        sub.interval_type = e;
        this.subscriptionService.update$(sub).subscribe();
    }

    public removeTagFilterValueFromSubscription(value: string, tagCallname: string, sub: Subscription) {
        const tagFilterToUpdate = sub.tag_filters.find(filter => filter.callname === tagCallname);
        tagFilterToUpdate.values = tagFilterToUpdate.values.filter(v => v !== value);

        this.subscriptionService.update$(sub).subscribe();
    }
}
