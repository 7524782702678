import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class PageVisibilityService {
    private static HIDDEN = 'hidden';
    private static MS_HIDDEN = 'msHidden';
    private static WEB_KIT_HIDDEN = 'webkitHidden';
    private onPageVisibleSource: Subject<void> = new Subject<void>();
    public $onPageVisible: Observable<void> = this.onPageVisibleSource.asObservable();
    private onPageHiddenSource: Subject<void> = new Subject<void>();
    public $onPageHidden: Observable<void> = this.onPageHiddenSource.asObservable();
    private onPageVisibilityChangeSource: Subject<boolean> = new Subject<boolean>();
    private hidden: string;
    private visibilityChange: string;

    constructor() {
        this.init();
        this.listenPageVisibility();
    }

    public isPageVisible(): boolean {
        return !this.isPageHidden();
    }

    public isPageHidden(): boolean {
        return document[this.hidden];
    }

    private init(): void {
        if (typeof document[PageVisibilityService.HIDDEN] !== 'undefined') {
            // Opera 12.10 and Firefox 18 and later support
            this.hidden = PageVisibilityService.HIDDEN;
            this.visibilityChange = 'visibilitychange';
        } else if (typeof document[PageVisibilityService.MS_HIDDEN] !== 'undefined') {
            this.hidden = PageVisibilityService.MS_HIDDEN;
            this.visibilityChange = 'msvisibilitychange';
        } else if (typeof document[PageVisibilityService.WEB_KIT_HIDDEN] !== 'undefined') {
            this.hidden = PageVisibilityService.WEB_KIT_HIDDEN;
            this.visibilityChange = 'webkitvisibilitychange';
        }
    }

    private listenPageVisibility(): void {
        document.addEventListener(
            this.visibilityChange,
            () => {
                if (this.isPageVisible()) {
                    this.onPageVisibilityChangeSource.next(true);
                    this.onPageVisibleSource.next();
                    return;
                }
                this.onPageVisibilityChangeSource.next(false);
                this.onPageHiddenSource.next();
            },
            { passive: true }
        );
    }
}
