import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { Alert, AlertType } from '../entities/alert.entity';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AlertNotificationsService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    constructor(private router: Router, private trans: TranslateService) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    public getAlert(): Observable<Alert> {
        return this.subject.asObservable();
    }

    public success(message: string, keepAfterRouteChange = false): void {
        this.alert(AlertType.Success, message, keepAfterRouteChange);
    }

    public error(message: string, keepAfterRouteChange = false): void {
        this.alert(AlertType.Error, message, keepAfterRouteChange);
    }

    public info(message: string, keepAfterRouteChange = false, buttonText: string = null, callback = null): void {
        this.alert(AlertType.Info, message, keepAfterRouteChange, buttonText, callback);
    }

    public warn(message: string, keepAfterRouteChange = false, buttonText: string = null, callback = null): void {
        this.alert(AlertType.Warning, message, keepAfterRouteChange, buttonText, callback);
    }

    public warnTranslated(
        messageKey: string,
        keepAfterRouteChange = false,
        buttonText: string = null,
        callback = null
    ): void {
        this.trans.get(messageKey).subscribe(
            message => {
                this.alert(AlertType.Warning, message, keepAfterRouteChange, buttonText, callback);
            },
            () => console.error('could not retrieve translation')
        );
    }

    public alert(
        type: AlertType,
        message: string,
        keepAfterRouteChange = false,
        buttonText: string = null,
        callback = null
    ): void {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<Alert>{
            type: type,
            message: message,
            callback: callback,
            buttonText: buttonText
        });
    }

    public clear(): void {
        // clear alerts
        this.subject.next();
    }
}
