import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../../entities/user.entity';
import { environment } from '../../../../environments/environment';
import { YouthProtectionPinService } from '../../../../services/api/methods/youth-protection-pin.service';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-change-youthprotection-pin',
    templateUrl: './change-youthprotection-pin.component.html',
    styleUrls: ['./change-youthprotection-pin.component.scss']
})
export class ChangeYouthprotectionPinComponent implements OnInit {
    @Output() public close = new EventEmitter();
    @Input() public me: User;
    public error = {
        password: null,
        pin: null
    };
    public newPin = '';
    public password = '';
    public environment;

    constructor(
        private youthProtectionService: YouthProtectionPinService,
        private translateService: TranslateService,
        private toastr: ToastrService
    ) {
        this.environment = environment;
    }

    public ngOnInit(): void {}

    public closeModal() {
        this.close.next(true);
    }

    public setPin() {
        this.error = { password: null, pin: null };

        if (this.password === '') {
            this.error.password = 'Bitte gebe dein Passwort an.';
            return;
        }
        if (this.newPin === '' || this.newPin.length !== 4) {
            this.error.pin = 'Bitte gib einen PIN an.';
            return;
        }
        this.youthProtectionService
            .changePinWithPassword(this.newPin, this.password)
            .pipe(
                switchMap(() =>
                    this.translateService.get([
                        'ageVerification.setYouthProtectionPin.messagePinActivated',
                        'toasts.successTitle'
                    ])
                )
            )
            .subscribe(
                res => {
                    this.toastr.success(
                        res['ageVerification.setYouthProtectionPin.messagePinActivated'],
                        res['toasts.successTitle']
                    );
                    this.closeModal();
                },
                (resp: HttpErrorResponse) => {
                    if (resp.error.error === 'AGE_VERIFICATION_EXPIRED') {
                        this.error.password = 'Bitte prüfe dein Passwort';
                        return;
                    }
                    if (resp.error.message.user && resp.error.message.user.length > 0) {
                        this.error.password = resp.error.message.user.join('. ');
                        return;
                    }

                    this.error = resp.error.message;
                    this.toastr.error(JSON.stringify(resp.error.message));
                }
            );
    }
}
