import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export abstract class ApiService {
    constructor(protected http: HttpClient) {}

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    protected handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            if (error.status !== 404 && error.status !== 401) {
                this.error(`${operation} failed: ${error.message}`);
            }

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    protected error(message: string) {
        if (!environment.production) {
            console.error('Api Service Error:' + message);
        }
    }
}
