import { Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class EmbeddedModeService {
    @Output() public embedded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    @Output() public syndication_origin: BehaviorSubject<string> = new BehaviorSubject(undefined);

    public enable(): void {
        this.embedded.next(true);
    }

    public disable(): void {
        this.embedded.next(false);
    }

    public setSyndicationOrigin(origin: string): void {
        this.syndication_origin.next(origin);
    }
}
