import { Page } from '../entities/page.entity';
import { Section } from '../entities/section.entity';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';
import { VastClient, VastResponse } from '../../../@types/vast-client';
import { AuthenticationService } from './api/methods/authentication.service';
import { of } from 'rxjs/internal/observable/of';
import { filter, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { EmbeddedModeService } from './embedded-mode.service';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsService } from './analytics.service';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { fromPromise } from 'rxjs/internal-compatibility';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Asset } from '../entities/asset.entity';
import { Tag } from '../entities/tag.entity';
import { AdblockDetectionService } from './adblock-detection.service';
import { AppName } from '../enum/app-name.enum';
import { ConsentService } from '../modules/consent/consent.service';

declare interface MyWindow extends Window {
    somtag?: any;
    VAST?: any;
    usercentrics?: any;
}

@Injectable()
export class SomtagService {
    public environment;
    public myWindow: MyWindow = <any>window;
    public authenticationService: AuthenticationService;

    public somtag: any = null;
    public VAST: any = null;
    public displayAdChannels: string[] = [];

    public loadDisplayAdsSource: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public deviceBreakpoint = 728;

    constructor(
        private auth: AuthenticationService,
        private analyticsService: AnalyticsService,
        private embeddedModeService: EmbeddedModeService,
        private consentService: ConsentService,
        private router: Router,
        private deviceService: DeviceDetectorService,
        private adblockDetectionService: AdblockDetectionService
    ) {
        this.environment = environment;
        this.somtag = this.myWindow.somtag;
        this.VAST = this.myWindow.VAST;

        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationStart) {
                this.loadDisplayAdsSource.next(false);
                this.displayAdChannels = [];
            }
        });
    }

    public initVideoAds(asset: Asset, longplay: boolean): Observable<{}> {
        return this.adsDisabled().pipe(
            takeWhile((adsDisabled: boolean) => {
                return !adsDisabled;
            }),
            switchMap(() => {
                return this.embeddedModeService.embedded;
            }),
            map((isEmbedded: boolean) => {
                const privChannels = <string[]>this.getSomChannelsForAsset(asset);
                if (isEmbedded) {
                    privChannels.push(this.embeddedModeService.syndication_origin.getValue());
                    privChannels.push('de_sportdeutschland.syndication');
                }

                return privChannels;
            }),
            map((privChannels: (Tag | string)[]) => {
                const keywords = this.getKeywordsForSom(asset);
                return {
                    enabled: true,
                    taxonomy: {
                        content: 'video',
                        channels: privChannels
                    },
                    keywords,
                    config: {
                        id: environment.ads.somtag.publisherId,
                        consent: {
                            optOutUncertifiedVendors: !this.consentService.allConsentsForSomtagGiven()
                        }
                    },
                    video: {
                        longplay: longplay,
                        blocks: {}
                    }
                };
            }),
            map((config: any) => {
                const device = {
                    mode: window.innerWidth <= this.deviceBreakpoint ? 'mobile' : 'desktop',
                    breakpoint: this.deviceBreakpoint
                };
                return { ...config, device };
            }),
            map((config: any) => {
                if (environment.appName === AppName.auto && asset && asset.channel) {
                    // sind die Kanäle im linken Menü z.B. „autodeuschlandtv_Daniel Abt“ oder „autodeuschlandtv_Autotester“
                    const syndication = {
                        content: 'autodeutschlandtv_' + asset.channel.values.toString(),
                        partner: 'Auto-Deutschland.tv'
                    };
                    return { ...config, syndication };
                }

                //  if (this.embeddedModeService.embedded.getValue() === true) {
                //      const syndication = {
                //         // content: 'autodeutschlandtv_' + asset.channel.values.toString(),
                //          partner: this.embeddedModeService.syndication_origin.getValue()
                //      };
                //      return { ...config, syndication };
                //  }
                return config;
            }),

            switchMap((config: any) => {
                console.log('SOMTAG init Video Ads WITH config', config);
                environment.ads.somtag.blocks.forEach(videoAd => (config.video.blocks[videoAd] = { enabled: true }));
                // @ts-ignore
                return fromPromise(this.somtag.cmd('init', config));
            })
        );
    }

    public setDisplayAdChannelsForSection(section?: Section): void {
        this.displayAdChannels = this.getSomChannelFromSection(section);
        this.initDisplayAds().subscribe(() => this.loadDisplayAdsSource.next(true));
    }

    public setDisplayAdChannelsForPage(page?: Page): void {
        this.displayAdChannels = this.getSomChannelFromPage(page);
        this.initDisplayAds().subscribe(() => this.loadDisplayAdsSource.next(true));
    }

    public setDisplayAdChannelsFromString(string: string = ''): void {
        this.displayAdChannels = [string];
        this.initDisplayAds().subscribe(() => this.loadDisplayAdsSource.next(true));
    }

    public setDisplayAdChannelsFromArray(array: string[]): void {
        this.displayAdChannels = array;
        this.initDisplayAds().subscribe(() => this.loadDisplayAdsSource.next(true));
    }

    public loadDisplayAds(): void {
        this.initDisplayAds().subscribe(() => this.loadDisplayAdsSource.next(true));
    }

    public getKeywordsForSom(asset: Asset): string[] {
        const keywords = [];

        asset.som_targeting_keywords.forEach((tag: string) => {
            keywords.push(tag);
        });

        if (environment.appName === AppName.auto) {
            if (asset.channel) {
                asset.channel.values.forEach((tag: string) => {
                    keywords.push(tag);
                });
            }

            asset.auto_marke.forEach((tag: string) => {
                keywords.push(tag);
            });
            asset.auto_modell.forEach((tag: string) => {
                keywords.push(tag);
            });
            asset.auto_typ.forEach((tag: string) => {
                keywords.push(tag);
            });
            asset.auto_krafstoff.forEach((tag: string) => {
                keywords.push(tag);
            });
            asset.auto_farbe.forEach((tag: string) => {
                keywords.push(tag);
            });
        }
        return keywords;
    }

    public reloadDisplayAds(): void {
        this.somtag.cmd('reloadDisplaySlots');
    }

    public initDisplayAds(): Observable<any> {
        console.log('initDisplayAds');
        return this.adsDisabled().pipe(
            takeWhile(adsDisabled => {
                return !adsDisabled;
            }),
            switchMap(() => this.consentService.initialized$),
            filter(i => !!i),
            take(1),
            tap(() => console.log('in initDisplayAds, consent initialized')),
            map(() => {
                return this.deviceService.isMobile();
            }),
            map((isMobile: boolean) => {
                const config = {
                    enabled: true,
                    taxonomy: {
                        channels: this.displayAdChannels
                    },
                    config: {
                        id: environment.ads.somtag.publisherId,
                        consent: {
                            optOutUncertifiedVendors: !this.consentService.allConsentsForSomtagGiven()
                        }
                    },
                    display: {
                        slots: {}
                    },
                    device: {
                        mode: isMobile ? 'mobile' : 'desktop', // values: 'mobile', 'desktop', 'auto'. default value. 'auto'
                        breakpoint: this.deviceBreakpoint // threshold value to switch between mobile and desktop
                        // when mode is 'auto'
                        // it is the last width which is considered as mobile
                    }
                };
                environment.ads.somtag.slots.forEach(ad => (config.display.slots[ad.slot] = { enabled: true }));
                return config;
            }),
            switchMap(config => {
                console.log('SOMTAG init display ads with config', config);
                const promise = this.somtag.cmd('init', config);
                if (!promise) {
                    this.adblockDetectionService.adBlockerDetected(true);
                    throw Error('Somtag Init Promise not defined. Seems like Adblocker is used');
                }
                return fromPromise(promise);
            })
        );
    }

    public loadDisplayAdForSlot(
        slot:
            | 'fullbanner2'
            | 'skyscraper1'
            | 'rectangle1'
            | 'mbanner1'
            | 'mbanner2'
            | 'inread1'
            | 'minread1'
            | 'performanceRectangle1'
            | 'promo2'
            | 'promo3'
            | 'promo4'
            | 'promo5',
        container: string
    ): Observable<{ ad: any; result: any; error: any }> {
        return this.adsDisabled().pipe(
            take(1),
            map(adsDisabled => {
                const subject = new Subject<{ ad: any; result: any; error: any }>();
                if (adsDisabled) {
                    subject.error('ads disabled');
                }
                return subject;
            }),
            map((subject: Subject<{ ad: any; result: any; error: any }>) => {
                const index = environment.ads.somtag.slots.findIndex(
                    (value: { slot: string; container: string }) => value.slot === slot
                );
                if (index === -1) {
                    subject.error('ad slot "' + slot + '" not enabled');
                    return subject;
                }
                return subject;
            }),
            map((subject: Subject<{ ad: any; result: any; error: any }>) => {
                if (!this.slotAllowedForCurrentDevice(slot)) {
                    subject.error('SOMTAG: prevented slot "' + slot + '" not loadable on current device width');
                }
                return subject;
            }),
            switchMap((subject: Subject<{ ad: any; result: any; error: any }>) => {
                console.log('insert ad ', container);
                this.somtag.cmd('insertAd', slot, { container: container }, (error, result) => {
                    if (error) {
                        subject.error(error);
                        return subject;
                    }
                    subject.next({
                        ad: { slot, container },
                        result: result,
                        error: error
                    });
                });
                return subject;
            })
        );
    }

    public loadVideoAds(
        block: string,
        amountOfClips: number | null,
        callback: (err: Error, vastResponses: (VastResponse | {})[], vastClient: VastClient) => void
    ): void {
        const vastClient = new this.VAST.VASTClient();
        if (!environment.ads.useAds) {
            return;
        }
        this.analyticsService.trackEvent('DebugAdsRequestVideoAds', {
            category: 'DebugAdsRequestVideoAds',
            event: 'DebugAdsRequestVideoAds',
            noninteraction: true,
            gtmCustom: {
                label: block
            }
        });

        // todo add amount of clips
        this.somtag.cmd('getVideoBlock', block, {}, function (err?, results?: string[]) {
            if (err || !results) {
                callback(err, null, vastClient);
                return;
            }
            const promises = [];
            results.forEach(result => {
                promises.push(vastClient.get(result, { withCredentials: true }));
            });
            const errorFreePromises = promises.map(promise => promise.catch(error => ({})));
            Promise.all(errorFreePromises).then(
                (response: (VastResponse | {})[]) => {
                    callback(null, response, vastClient);
                },
                error => {
                    console.error('SOMTAG error: rejected loading ', error);
                    callback(error, null, vastClient);
                }
            );
        });
    }

    public adsDisabled(): Observable<boolean> {
        if (this.router.url.includes('erlebe-kraft-gemeinschaft-live')) {
            console.info('ADS DISABLED for /erlebe-kraft-gemeinschaft-live');
            return of(true);
        }
        if (this.router.url.includes('gemeinsamfuerdensport')) {
            console.info('ADS DISABLED for /gemeinsamfuerdensport');
            return of(true);
        }
        if (this.router.url.includes('trikotkonfigurator')) {
            console.info('ADS DISABLED for /trikotkonfigurator');
            return of(true);
        }

        if (this.router.url.includes('handball-wm-2021-uebertragung')) {
            console.info('ADS DISABLED for /handball-wm-2021-uebertragung');
            return of(true);
        }
        if (this.router.url.includes('handball-wm-2021-tickets')) {
            console.info('ADS DISABLED for /handball-wm-2021-tickets ');
            return of(true);
        }
        if (this.router.url.includes('weihnachtsaktion-handball-wm')) {
            console.info('ADS DISABLED for /weihnachtsaktion-handball-wm');
            return of(true);
        }
        if (this.router.url.includes('/thw-kiel')) {
            console.info('ADS DISABLED for /thw-kiel');
            return of(true);
        }
        if (document.cookie.includes('sdtv_no_ads=true')) {
            return of(true);
        }
        if (environment.ads.useAds === false) {
            return of(true);
        }
        return this.auth.adsDisabledForUser();
    }

    private getSomChannelsForAsset(asset: Asset): string[] {
        const channels = [];
        asset.tags.forEach((tag: Tag) => {
            tag.values.forEach(value => {
                let channel = tag.callname + '_' + value;
                channel = channel
                    .toString()
                    .toLowerCase()
                    .replace(/\s+/g, '-') // Replace spaces with -
                    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
                    .replace(/\-\-+/g, '-') // Replace multiple - with single -
                    .replace(/^-+/, '') // Trim - from start of text
                    .replace(/-+$/, ''); // Trim - from end of text
                channels.push(channel);
            });
        });
        if (asset.contents_freely_accessible === false) {
            channels.push('paid_yes');
        } else {
            channels.push('paid_no');
        }
        return channels;
    }

    private addDefaultAppChannels(channels: string[]): string[] {
        switch (environment.appName) {
            case AppName.handball:
                channels.push('handball');
                break;
            case AppName.turn:
                channels.push('turn');
                break;
            case AppName.tischtennis:
                channels.push('tischtennis');
                break;
            case AppName.auto:
                channels.push('autodeutschlandtv');
                break;
        }
        return channels;
    }

    /**
     * used for display ads
     * @param section
     */
    private getSomChannelFromSection(section?: Section): string[] {
        const channels = this.addDefaultAppChannels([]);
        if (!section) {
            return channels;
        }

        // special case for hbl-2 "Kanal"
        if (environment.appName === AppName.handball || environment.appName === AppName.sportdeutschland) {
            const kanal = section.tag_filters.find((value: Tag, index: number) => {
                return value.callname === 'bereich';
            });
            if (kanal && kanal.values.includes('2. HBL')) {
                if (!channels.includes('handball')) {
                    channels.push('handball');
                }
                channels.push('hbl-2');
                return channels;
            }
            if (kanal && kanal.values.includes('DHB-Pokal')) {
                channels.push('dhb-pokal');
                return channels;
            }
        }
        channels.push(this.getDefaultChannelForSection(section));
        return channels;
    }

    private getDefaultChannelForSection(sec: Section): string {
        if (!sec.tag_filters) {
            return sec.permalink;
        }
        const sportart = sec.tag_filters.find((value: Tag) => {
            return value.callname === 'sportart';
        });

        if (sportart && sportart.values && sportart.values[0]) {
            return sportart.values[0].toLowerCase();
        }
        return sec.permalink;
    }

    /**
     * only used for display ads
     * @param page
     */
    private getSomChannelFromPage(page?: Page): string[] {
        const channels = this.addDefaultAppChannels([]);
        if (page.permalink) {
            // workaround for https://7sports.atlassian.net/browse/SD-287
            if (page.permalink === 'handball-wm-maenner-2021') {
                channels.push('handball', 'handball-wm-maenner-2021');
            } else if (page.permalink === 'barmer-2-basketball-bundesliga') {
                channels.push('basketball');
                channels.push('barmer-2');
            } else if (page.permalink === 'handball-em-maenner-2020' && environment.appName !== AppName.handball) {
                channels.push('handball');
            } else if (page.permalink === 'handball-wm-frauen-2019') {
                channels.push('handball');
            } else if (page.permalink === 'handball-em-maenner-2020') {
                channels.push('em-maenner');
            } else {
                channels.push(page.permalink);
            }
        } else {
            channels.push('other');
        }
        return channels;
    }

    private slotAllowedForCurrentDevice(
        slot:
            | 'fullbanner2'
            | 'skyscraper1'
            | 'rectangle1'
            | 'mbanner1'
            | 'mbanner2'
            | 'inread1'
            | 'minread1'
            | 'performanceRectangle1'
            | 'promo2'
            | 'promo3'
            | 'promo4'
            | 'promo5'
    ): boolean {
        if (window.innerWidth <= this.deviceBreakpoint) {
            // mobile
            switch (slot) {
                case 'fullbanner2':
                case 'skyscraper1':
                    return false;
            }
        } else {
            // desktop
            switch (slot) {
                case 'mbanner1':
                case 'mbanner2':
                    return false;
            }
        }

        return true;
    }
}
