import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imageSize'
})
export class ImageSizePipe implements PipeTransform {
    private regex = /(?:_(\d+)?xx?(\d+)?)?(\.[^\.]+(?:\?.*)?)$/;

    public transform(value: string, args?: { width: number; height: number }): any {
        if (value && args.width && args.height) {
            const width = Math.ceil(args.width);
            const height = Math.ceil(args.height);
            value.replace('http:', 'https:');
            return value.replace(this.regex, '_' + width + 'x' + height + '$3');
        } else {
            return value;
        }
    }
}
