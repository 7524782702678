import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable()
export class YouthProtectionPinService extends ApiService {
    private endpoint = 'youth_protection_pin';

    private STORAGE_KEY_NAME = 'youth_protection_session_expires_at';

    private youth_protection_session_expires_at: number = null;

    constructor(protected http: HttpClient, protected storage: StorageMap) {
        super(http);
    }

    /**
     * Only callable until 5 min after verify age
     * @param pin
     * @param pin_activity
     */
    public setPin(
        pin: string,
        pin_activity: 'active' | 'inactive'
    ): Observable<{ youth_protection_session_expires_at }> {
        return this.http
            .post<{ youth_protection_session_expires_at }>(this.endpoint, {
                pin: pin.toString(),
                pin_activity: pin_activity
            })
            .pipe(
                switchMap(res => {
                    return this.setYouthProtectionExpiration(res.youth_protection_session_expires_at);
                })
            );
    }

    public changePinWithPassword(
        pin: string,
        password: string
    ): Observable<{ youth_protection_session_expires_at: string }> {
        return this.http
            .post<{ youth_protection_session_expires_at: string }>(this.endpoint, {
                pin: pin.toString(),
                password: password
            })
            .pipe(
                switchMap(res => {
                    return this.setYouthProtectionExpiration(res.youth_protection_session_expires_at);
                })
            );
    }

    public verifyPin(pin: string): Observable<boolean> {
        const url = 'youth_protection_session';

        return this.http
            .post<{ youth_protection_session_expires_at }>(url, {
                pin: pin.toString()
            })
            .pipe(
                switchMap(res => {
                    return this.setYouthProtectionExpiration(res.youth_protection_session_expires_at);
                }),
                map(() => true)
            );
    }

    public extendYouthProtectionSession(): Observable<{ youth_protection_session_expires_at: string }> {
        const url = 'youth_protection_session';

        return this.hasYouthProtectionSession().pipe(
            switchMap((hasSession: boolean) => {
                if (!hasSession) {
                    // return throwError('no session');
                }
                return this.http.post<{ youth_protection_session_expires_at: string }>(url, {});
            }),
            switchMap(res => {
                return this.setYouthProtectionExpiration(res.youth_protection_session_expires_at);
            })
        );
    }

    public hasYouthProtectionSession(): Observable<boolean> {
        return this.storage.get(this.STORAGE_KEY_NAME).pipe(
            map(() => {
                return false;
            })
        );
    }

    private setYouthProtectionExpiration(
        expires_at: string
    ): Observable<{ youth_protection_session_expires_at: string }> {
        return this.storage.set(this.STORAGE_KEY_NAME, expires_at).pipe(
            map(() => {
                return { youth_protection_session_expires_at: expires_at };
            })
        );
    }
}
