import { environment } from './../../../../environments/environment';
import { TileType } from './../../../elements/asset-tile-slider/asset-tile-slider.component';
import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Playlist } from '../../../../entities/playlist.entity';
import { SyndicationPlayer } from '../../../../entities/syndication-player.entity';
import { AnalyticsService } from '../../../../services/analytics.service';
import { PlaylistsService } from '../../../../services/api/methods/playlists.service';
import { SyndicationPlayerService } from '../../../../services/api/methods/syndication-player.service';
import { EmbeddedModeService } from '../../../../services/embedded-mode.service';
import { PlayerComponent } from '../../../elements/player/player.component';
import { Asset } from '../../../../entities/asset.entity';
import { Meta } from '@angular/platform-browser';
import { ExternalScriptsLoaderService } from '../../../../services/external-scripts-loader.service';
import { AppName } from 'src/common/enum/app-name.enum';

@Component({
    selector: 'app-syndication-player',
    templateUrl: './syndication-player.component.html'
})
export class SyndicationPlayerComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
    public error = false;
    public showPlaylists = false;
    public activePlaylistIndex: number;
    public asset: Asset;
    public syndicationPlayer: SyndicationPlayer;
    public playlists: Playlist[];
    public embeddedOrigin: string;
    public showLogo: boolean;
    public TileType = TileType;
    public environment = environment;
    public AppName = AppName;
    @ViewChild('player') public player: PlayerComponent;
    @ViewChild('all', { static: true }) public all: ElementRef;
    private height: number;
    private preSelectedAssetID: string;

    constructor(
        private meta: Meta,
        private route: ActivatedRoute,
        private playlistsService: PlaylistsService,
        private analyticsService: AnalyticsService,
        private syndicationPlayerService: SyndicationPlayerService,
        private embeddedModeService: EmbeddedModeService,
        private externalScriptsLoaderService: ExternalScriptsLoaderService,
        private cdr: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this.embeddedModeService.enable();

        this.route.queryParams.subscribe(params => {
            if (params.origin) {
                this.embeddedOrigin = params.origin;
                this.embeddedModeService.setSyndicationOrigin(this.embeddedOrigin);

                if (this.embeddedOrigin === 'https://www.ran.de') {
                    this.loadMagFrameForProSiebenIntegration();
                }
            }
            this.analyticsService.trackEvent('SyndicationPlayerImpression', {
                category: 'SyndicationPlayerImpression',
                event: 'SyndicationPlayerImpression',
                noninteraction: false,
                gtmCustom: {
                    inSyndicationPlayer: true,
                    wholesaler: this.embeddedOrigin
                }
            });
            this.preSelectedAssetID = params.assetid;
        });

        this.syndicationPlayerService.getSyndicationPlayer(this.route.snapshot.paramMap.get('playerId')).subscribe(
            (syndicationPlayer: SyndicationPlayer) => {
                this.syndicationPlayer = syndicationPlayer;

                if (this.syndicationPlayer && this.syndicationPlayer.stylesheet) {
                    const head = document.getElementsByTagName('head')[0];
                    const style: HTMLStyleElement = document.createElement('style');
                    style.id = 'syndication-player-styles';
                    style.appendChild(document.createTextNode(this.syndicationPlayer.stylesheet));
                    head.appendChild(style);
                }

                this.playlists = [];

                this.syndicationPlayer.playlist_uuids.map((uuid: string, index) => {
                    this.playlistsService.getPlaylist(uuid, 1, 100).subscribe((playlist: Playlist) => {
                        if (this.preSelectedAssetID) {
                            const preSelectedAsset = playlist.assets.find(
                                asset => asset.uuid === this.preSelectedAssetID
                            );
                            if (preSelectedAsset) {
                                this.activePlaylistIndex = index;
                                this.asset = preSelectedAsset;
                            }
                        } else {
                            if (index === 0) {
                                this.activePlaylistIndex = index;
                                if (playlist.assets[0]) {
                                    this.asset = playlist.assets[0];
                                }
                            }
                        }
                        this.playlists.push(playlist);
                        this.setShowLogoFor(this.asset);
                    });
                });

                this.meta.updateTag({ name: 'robots', content: 'noindex,follow' }, 'name=robots');
                // @ts-ignore
                window.prerenderReady = true;
            },

            error => {
                console.error('SYNDICATION ERROR');
                console.error(error);
                this.error = true;
            }
        );
    }

    public updateHeight() {
        if (this.height !== this.all.nativeElement.clientHeight) {
            this.height = this.all.nativeElement.clientHeight;
            const data = JSON.parse(
                JSON.stringify({
                    documentHeight: this.all.nativeElement.clientHeight,
                    uuid: (this.syndicationPlayer || { uuid: '' }).uuid
                })
            );
            window.top.postMessage(data, this.embeddedOrigin);

            if (window.hasOwnProperty('magframe')) {
                const win = window as any;
                win.magframe('resize');
            }
        }
    }

    public ngOnDestroy() {
        this.embeddedModeService.disable();
        if (document.getElementById('syndication-player-styles')) {
            document.removeChild(document.getElementById('syndication-player-styles'));
        }
    }

    public ngAfterViewInit() {
        this.updateHeight();
    }

    public ngAfterViewChecked() {
        this.updateHeight();
    }

    // @TODO: Changing in assets in slider causes slow view update
    public playlistClicked(index: number) {
        this.activePlaylistIndex = index;
    }

    public setAsset(asset) {
        this.asset = null;
        this.cdr.detectChanges();
        this.asset = asset;
        this.setShowLogoFor(asset);
    }

    private loadMagFrameForProSiebenIntegration() {
        this.externalScriptsLoaderService.load(this.environment.externalScripts.p7iframe).subscribe(script => {
            const win = window as any;
            win.magframe =
                win.magframe ||
                function () {
                    win.magframe.data = win.magframe.data || [];
                    win.magframe.data.push(Array.prototype.slice.call(arguments));
                };
            win.magframe.on =
                win.magframe.on ||
                function (callback) {
                    win.magframe.callbacks = win.magframe.callbacks || [];
                    win.magframe.callbacks.push(callback);
                };
            win.magframe('handshake');
            win.magframe('resize');
        });
    }

    private setShowLogoFor(asset: Asset) {
        // syndi player wants watermark? add it!
        if (this.syndicationPlayer.watermark === true) {
            this.showLogo = true;
            return;
        }
        // otherwise let the asset devide
        if (asset.flags && asset.flags.includes('nologo')) {
            this.showLogo = false;
        } else {
            this.showLogo = true;
        }
    }
}
