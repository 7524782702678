import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';
import { AlertNotificationsService } from '../../../services/alert-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { RegisterValidators } from '../register-page/form/RegisterValidators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html'
})
export class PasswordResetComponent implements OnInit, OnDestroy {
    public tokenNotValidMessage: string;
    public tokenValid = false;
    public showUntouchedFieldErrors = false;

    public password: string;

    public form: FormGroup;

    private sub: any;
    private token: string;
    private redirectUrl = '/';

    constructor(
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private alertService: AlertNotificationsService,
        private router: Router,
        private analyticsService: AnalyticsService,
        private trans: TranslateService,
        private fb: FormBuilder
    ) {}

    public ngOnInit(): void {
        this.analyticsService.trackPageview();
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            password_confirmation: ['', [Validators.required, RegisterValidators.equalTo('password')]]
        });

        this.sub = this.route.params.subscribe(params => {
            this.token = params['token'];
            this.authenticationService.confirmPasswordResetToken(this.token).subscribe(
                (response: { message: string; status: number }) => {
                    if (response.status === 200) {
                        this.tokenValid = true;
                    } else {
                        this.tokenNotValidMessage = response.message;
                    }
                },
                err => {
                    console.error(err);
                    this.tokenNotValidMessage = err.error.error;
                }
            );
        });
    }

    public ngOnDestroy() {
        this.sub.unsubscribe();
    }

    public resetPassword() {
        if (!this.form.valid) {
            return;
        }
        this.authenticationService
            .passwordUpdate(
                this.token,
                this.form.value.email,
                this.form.value.password,
                this.form.value.password_confirmation
            )
            .subscribe(
                (response: { message: string; status: number }) => {
                    if (response.status === 200) {
                        this.alertService.success('Dein Passwort wurde geändert');
                        this.login();
                    } else {
                        this.alertService.error('Error: ' + response.message);
                    }
                },
                err => {
                    console.error(err);
                    this.alertService.error('Error: ' + err.error.message);
                }
            );
    }

    public login() {
        this.authenticationService.login(this.form.value.email, this.form.value.password).subscribe(
            (response: boolean) => {
                this.trans.get('login.successMessage').subscribe(res => this.alertService.success(res));
                this.redirectOrCloseWebView();
            },
            error => {
                this.trans.get('login.errorMessage').subscribe(mess => {
                    this.alertService.error(mess);
                });
            }
        );
    }

    public redirectOrCloseWebView() {
        this.router.navigateByUrl(this.redirectUrl);
    }
}
