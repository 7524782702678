import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { timer } from 'rxjs/internal/observable/timer';
import { Subject } from 'rxjs/internal/Subject';
import { debounce, distinctUntilChanged } from 'rxjs/operators';
import { MenuItem } from '../../../entities/menu-item.entity';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
    selector: 'app-nav-item',
    templateUrl: './nav-item.component.html'
})
export class NavItemComponent implements OnInit {
    public id: number;

    @Input() public menuItem: MenuItem;
    @Input() public index: number;
    @Input() public isDropDown: boolean;
    @Output() public width: Subject<number> = new Subject<number>();
    @Output() public isOpen: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('elem', { static: true }) public elem: ElementRef;
    @ViewChild('dropdown') public dropdown: any;

    private openEventStream: Subject<boolean> = new Subject<boolean>();
    private openedChildren = 0;
    private openedViaClick: boolean;
    private debounceTime = 10;

    constructor(public analyticsService: AnalyticsService) {
        this.openEventStream
            .pipe(
                debounce(() => timer(this.debounceTime)),
                distinctUntilChanged()
            )
            .subscribe((open: boolean) => {
                if (open) {
                    this.dropdown.show();
                    this.debounceTime = 200; // close slow
                    this.isOpen.emit(true);
                } else {
                    if (this.openedChildren === 0 && !this.openedViaClick) {
                        this.dropdown.hide();
                        this.debounceTime = 10; // open fast
                    }
                }
            });
    }

    public ngOnInit(): void {
        this.width.next(this.elem.nativeElement.offsetWidth);
    }

    public mouseoverOnContainer(event: MouseEvent) {
        this.mouseEvent(event, true);
    }

    public mouseoutOnContainer(event: MouseEvent) {
        this.mouseEvent(event, false);
    }

    public mouseoverOnMenu(event: MouseEvent) {
        this.mouseEvent(event, true);
    }

    public mouseoutOnMenu(event: MouseEvent) {
        this.mouseEvent(event, false);
    }

    public childIsOpened(isOpened, index) {
        if (isOpened) {
            this.openedChildren++;
        } else {
            this.openedChildren--;
        }
        this.mouseEvent(null, isOpened);
    }

    public onOpenChange(isOpen) {
        this.isOpen.emit(isOpen);
    }

    public clicked(event) {
        this.debounceTime = 500;
        event.preventDefault();
        event.stopImmediatePropagation();
        if (!this.dropdown.isOpen) {
            this.openedViaClick = true;
            this.dropdown.show();
        } else {
            this.dropdown.hide();
        }
        return false;
    }

    private mouseEvent(event: MouseEvent, show: boolean) {
        this.openEventStream.next(show);
    }
}
