import { Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';

@Injectable()
export class SidebarService {
    @Output() public isOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _isOpen = false;

    // must match the sass var $elements-sidebar-breakpoint
    private breakpoint = 1124;

    constructor(private router: Router) {
        if (!environment.header.useSideMenu) {
            return;
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth <= this.breakpoint && this._isOpen) {
                this.toggle();
            } else if (window.innerWidth >= this.breakpoint + 1 && !this._isOpen) {
                this.toggle();
            }
        });
        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth >= this.breakpoint &&
                !this._isOpen &&
                !environment.header.sideMenuInitiallyClosed
            ) {
                this.toggle();
            }
        });
    }

    public toggle(): void {
        if (!environment.header.useSideMenu) {
            return;
        }
        this._isOpen = !this._isOpen;
        this.isOpen.next(this._isOpen);
    }

    public close(): void {
        if (!environment.header.useSideMenu) {
            return;
        }
        this._isOpen = false;
        this.isOpen.next(this._isOpen);
    }
}
