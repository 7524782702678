import { Component, OnInit } from '@angular/core';
import { SectionsService } from '../../../../../common/services/api/methods/sections.service';
import { Section } from '../../../../../common/entities/section.entity';
import { AnalyticsService } from '../../../../../common/services/analytics.service';
import { AgofService } from '../../../../../common/services/agof.service';
import { AgofEnum } from '../../../../../common/enum/agof.enum';
import { SomtagService } from '../../../../../common/services/somtag.service';
import { map } from 'rxjs/operators';
import { Tag } from '../../../../../common/entities/tag.entity';

@Component({
    selector: 'app-channels-page',
    templateUrl: './channels-page.component.html'
})
export class ChannelsPageComponent implements OnInit {
    public sections: Section[];

    constructor(
        private sectionsService: SectionsService,
        private analyticsService: AnalyticsService,
        private agofService: AgofService,
        private somtagService: SomtagService
    ) {
        this.somtagService.setDisplayAdChannelsFromString('kanaele');
        sectionsService
            .getSections()
            .pipe(
                map((sections: Section[]) => {
                    return sections.sort((a, b) => {
                        if (a.title > b.title) {
                            return 1;
                        }
                        if (a.title < b.title) {
                            return -1;
                        }

                        return 0;
                    });
                })
            )
            .subscribe((sections: Section[]) => {
                this.sections = sections.filter(section => {
                    return (
                        section.tag_filters.findIndex((tag: Tag) => {
                            return tag.callname === 'bereich';
                        }) > -1
                    );
                });
            });
    }

    public ngOnInit(): void {
        this.analyticsService.trackPageview();

        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        AgofEnum.HOMEPAGE_NO,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        AgofEnum.PAID_NO,
                        AgofEnum.CONTENT_TOPIC_Sports
                    ),
                    cpChannel: AgofEnum.CHANNEL_DEFAULT,
                    coSecondPart: '/kanaele/',
                    survey: true
                }
            }
        });
        window['prerenderReady'] = true;
    }
}
