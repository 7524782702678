import { Component, Input } from '@angular/core';
import { Product } from '../../../entities/product.entity';

@Component({
    selector: 'app-product-price',
    templateUrl: './product-price.component.html'
})
export class ProductPriceComponent {
    @Input() public product: Product;
    @Input() public showPrice = false;

    constructor() {}
}
