export enum PlayerStateEnum {
    LOADING = 'LOADING',
    COUNTDOWN = 'COUNTDOWN',
    SUCCESS = 'SUCCESS',
    UNAUTHORIZED = 'UNAUTHORIZED',
    REVOLVERPLAY = 'REVOLVERPLAY',

    INVALID_PARAMS = 'INVALID_PARAMS',
    ASSET_NOT_AVAILABLE = 'ASSET_NOT_AVAILABLE',
    LIVESTREAM_NOT_AVAILABLE = 'LIVESTREAM_NOT_AVAILABLE',
    AGE_NOT_VERIFIED = 'AGE_NOT_VERIFIED',
    AGE_NON_COMPLIANT = 'AGE_NON_COMPLIANT',
    YOUTH_PROTECTION_PIN_NOT_SET = 'YOUTH_PROTECTION_PIN_NOT_SET',
    YOUTH_PROTECTION_PIN_EXPIRED = 'YOUTH_PROTECTION_PIN_EXPIRED',
    YOUTH_PROTECTION_SESSION_MISSING = 'YOUTH_PROTECTION_SESSION_MISSING',
    WITHOUT_SUCCESSFUL_PURCHASE = 'WITHOUT_SUCCESSFUL_PURCHASE',
    CONTENT_CURRENTLY_NOT_PURCHASED = 'CONTENT_CURRENTLY_NOT_PURCHASED',
    CONTENT_NOT_ALLOWED_FOR_REGION = 'CONTENT_NOT_ALLOWED_FOR_REGION',
    TOO_MANY_CONCURRENT_ACCESS_SESSIONS = 'TOO_MANY_CONCURRENT_ACCESS_SESSIONS',
    TOKEN_INVALID = 'TOKEN_INVALID',
    AD_BLOCKER_ENABLED = 'AD_BLOCKER_ENABLED',

    /** PlayerErrorEnum defines type of playerError */
    PLAYER_ERROR = 'PLAYER_ERROR',
    API_ERROR = 'API_ERROR'
}
