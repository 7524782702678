import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DonationService } from '../../../services/donation.service';

@Component({
    selector: 'app-donation-header',
    templateUrl: './donation-header.component.html'
})
export class DonationHeaderComponent implements OnInit {
    public environment = environment;

    public totalAmount: number;

    constructor(private donationService: DonationService) {}

    public ngOnInit(): void {
        this.donationService.getTotalAmount().subscribe(res => {
            this.totalAmount = res;
        });
    }
}
