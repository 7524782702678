import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent } from '../components/elements/confirmation-dialog/confirmation-dialog.component';
import { Observable } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Injectable()
export class ConfirmationDialogService {
    constructor(private modalService: BsModalService) {}

    public confirm(
        title: string,
        message: string,
        btnOkText: string = 'OK',
        btnCancelText: string = 'Abbrechen',
        dialogSize: 'sm' | 'lg' = 'sm'
    ): Observable<boolean> {
        const modalRef: BsModalRef = this.modalService.show(ConfirmationDialogComponent, {
            class: dialogSize === 'lg' ? 'modal-lg' : 'modal-sm',
            animated: false
        });
        const component: ConfirmationDialogComponent = modalRef.content;
        component.title = title;
        component.message = message;
        component.btnOkText = btnOkText;
        component.btnCancelText = btnCancelText;

        component.close.subscribe(() => modalRef.hide());

        return component.result;
    }
}
