import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shuffleArray'
})
export class ShuffleArrayPipe implements PipeTransform {
    constructor() {}

    public transform(value: [], args?: { options: [{ [key: string]: string | boolean }] }): [] {
        if (args) {
            if (args.options.find(e => e.key === 'shuffle' && e.value === true)) {
                return this.shuffle(value);
            }
            return value;
        } else {
            return this.shuffle(value);
        }
    }

    /**
     * Shuffles array in place. ES6 version
     * @param {Array} a items An array containing the items.
     */
    private shuffle(a: []): [] {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }
}
