import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthenticationService } from './methods/authentication.service';

/**
 * This interceptor adds the correct API endpoint to each request.
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private storage: StorageMap,
        public router: Router,
        private authenticationService: AuthenticationService
    ) {}

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // do not modify requests to frontend server
        if (req.url.includes(`${window.location.protocol}//${window.location.host}`)) {
            return next.handle(req);
        }

        // do not modify requests for translation files
        if (req.url.includes('i18n')) {
            return next.handle(req);
        }

        // do not modify requests for domains
        if (req.url.startsWith('http')) {
            return next.handle(req);
        }
        let url;

        const headers: { Accept: string } = {
            Accept: 'application/vnd.vidibus.v5.html+json'
        };
        let params: { access_token: string; rand?: string } = { access_token: 'true' };

        return this.storage.get<string>('access_token').pipe(
            switchMap((token: string) => {
                if (
                    req.method === 'POST' ||
                    req.method === 'post' ||
                    req.method === 'put' ||
                    req.method === 'PUT' ||
                    req.url === 'me' ||
                    req.url === 'access_token' ||
                    req.url.startsWith('orders') ||
                    req.url.startsWith('youth_protection') ||
                    req.url.startsWith('verify_age') ||
                    req.url.startsWith('subscribers') ||
                    req.url === 'authorize_content_access' ||
                    req.url === 'signup' ||
                    // is logged in and check ip
                    (req.url.startsWith('client') && token !== undefined)
                ) {
                    url = environment.api.endpoint;
                    // add authorization headers

                    if (token !== undefined) {
                        headers['Authorization'] = token;
                    }
                    params = {
                        access_token: 'true',
                        rand:
                            Math.random().toString(16).substr(2, 16) + '-' + new Date().getUTCMilliseconds().toString()
                    };
                } else {
                    url = environment.api.endpointUnauthenticated;

                    // need to add a parameter to bust the CORS Cache of the proxy
                    const corsParams = { cors_origin: window.location.origin };
                    req = req.clone({
                        setParams: corsParams
                    });
                }

                req = req.clone({
                    url: url + req.url,
                    setParams: params,
                    setHeaders: headers
                });

                return next.handle(req).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error.error instanceof ErrorEvent) {
                            // client-side playerError or network playerError
                        } else {
                            if (error.status === 401) {
                                this.storage.delete('me').subscribe();
                                this.storage.delete('access_token').subscribe();
                                //this.authenticationService.$isLoggedInEvent.emit(false);
                            }
                        }
                        return throwError(error);
                    })
                );
            })
        );
    }
}
