import { AfterContentChecked, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '../../../../services/analytics.service';

@Component({
    selector: 'app-big-sponsor-tile',
    templateUrl: './big-sponsor-tile.component.html'
})
export class BigSponsorTileComponent implements AfterContentChecked {
    public now: Date = new Date();
    public environment = environment;
    public isVisible = false;
    public link: string;
    public impressionSent = false;
    @ViewChild('containerElement', { read: ElementRef }) public containerElement: ElementRef;

    constructor(private analyticsService: AnalyticsService) {
        this.link =
            'https://bs.serving-sys.com/Serving/adServer.bs?cn=trd&pli=1076109348&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_68}&adid=1082195334&ord=' +
            new Date().getTime();
    }

    public ngAfterContentChecked(): void {
        if (
            this.isVisible === false &&
            this.containerElement &&
            this.containerElement.nativeElement.offsetParent != null
        ) {
            this.sendImpression();
            this.isVisible = true;
        }
    }

    public sendImpression() {
        if (this.impressionSent === false) {
            this.impressionSent = true;

            this.analyticsService.trackEvent('SponsoredTileImpression', {
                category: 'SponsoredTileImpression',
                event: 'SponsoredTileImpression',
                noninteraction: true,
                gtmCustom: {}
            });
        }
    }
}
