import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EpgEntry } from '../../../../../entities/epg-entry.entity';
import { EpgService } from '../../../../../services/api/methods/epg.service';
import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-epg',
    templateUrl: './epg.component.html'
})
export class EpgComponent implements OnInit {
    public environment = environment;
    public selectedDateString: string = moment().format('DD.MM.YY');
    public today = moment();
    public entries: EpgEntry[];
    public filteredEntries: EpgEntry[];
    @Output() public startVideo: EventEmitter<string> = new EventEmitter();
    @Output() public startLiveStream: EventEmitter<void> = new EventEmitter();
    public dayOptions: moment.Moment[];

    constructor(protected epsService: EpgService) {
        moment.locale(environment.i18n.defaultLocale);
        const days = [];
        for (let i = -4; i < 5; i++) {
            days.push(moment().add(i, 'd').format('DD.MM.YY'));
        }
        this.dayOptions = days;
    }

    public get selectedDate() {
        return moment(this.selectedDateString, 'DD.MM.YY');
    }

    public get in5Days() {
        return moment().add(4, 'd');
    }

    public get before5Days() {
        return moment().subtract(4, 'd');
    }

    public stepDay(day: number) {
        if (day === 0) {
            this.selectedDateString = moment().format('DD.MM.YY');
        } else {
            this.selectedDateString = this.selectedDate.add(day, 'd').format('DD.MM.YY');
        }
        this.filterEntries();
    }

    public changeDay(day: string) {
        this.filterEntries();
    }

    public ngOnInit(): void {
        this.epsService.getEpg().subscribe(
            (entries: EpgEntry[]) => {
                this.entries = entries.filter((entry: EpgEntry) => !entry.title.includes('BACK UP ASSET'));
                this.filterEntries();
            },
            () => {
                console.error('error getting epg');
                this.entries = [];
            }
        );
    }

    public isPast(entry: EpgEntry) {
        return moment(entry.ends_at) < moment();
    }

    public isOnAir(entry: EpgEntry) {
        return moment(entry.starts_at) < moment() && moment(entry.ends_at) > moment();
    }

    public filterEntries() {
        this.filteredEntries = this.entries
            .filter((entry: EpgEntry) => moment(entry.starts_at).isSame(this.selectedDate, 'day'))
            .sort((a: EpgEntry, b: EpgEntry) => {
                return moment(a.starts_at).diff(b.starts_at);
            });
    }
}
