import { VideoPlaylist } from '../../../../../entities/video-playlist.entity';
import { Component, Input } from '@angular/core';
import { PlaylistHandlerService } from '../../../../../services/playlist-handler.service';

@Component({
    selector: 'app-video-bottom-playlist',
    templateUrl: './video-bottom-playlist.component.html'
})
export class VideoBottomPlaylistComponent {
    public playlist: VideoPlaylist;
    public currentIndex: number;
    @Input() public showBottomPlaylist: boolean;

    constructor(private playlistHandlerService: PlaylistHandlerService) {
        this.playlistHandlerService.activePlaylistIndex.subscribe((val: { index: number; play: boolean }) => {
            this.currentIndex = val.index;
            const element = document.getElementById('video-bottom-playlist-video-' + val.index);
            if (element) {
                const parentOffsetTop = document.getElementById('video-bottom-playlist-content').offsetTop;
                document.getElementById('video-bottom-playlist-content').scrollTop =
                    element.offsetTop - parentOffsetTop;
            }
        });

        this.playlistHandlerService.playlist.subscribe(playlist => {
            this.playlist = playlist;
        });
    }

    public goToIndex(index: number, play: boolean = false): void {
        this.playlistHandlerService.goToPlaylistIndex(index, play);
    }
}
