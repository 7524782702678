import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    ViewChild
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { SidebarService } from '../../../services/sidebar.service';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';
import { NavigationEnd, Router } from '@angular/router';
import { timer } from 'rxjs';
import { LiveEventsService } from '../../../services/api/methods/live-events.service';
import { MenuService } from '../../../services/api/methods/menu.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { ProductsService } from '../../../services/api/methods/products.service';
import { AssetsService } from '../../../services/api/methods/assets.service';
import { takeWhile } from 'rxjs/operators';
import { User } from '../../../entities/user.entity';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements AfterViewInit, AfterViewChecked {
    @ViewChild('nav', { static: true }) public nav: ElementRef;

    public showLogin = environment.header.showLogin;
    public showLive = environment.header.showLive;
    public showSearch = environment.header.showSearch && !environment.header.useSearchbar;

    public height = 0;
    public environment = environment;
    public inApp = false;
    public loggedIn = false;
    public sideBarOpen: boolean;
    public liveEventsCount: number;
    public dataRefreshActive = true;
    public me: User;
    public hasTestVoucher = false;
    public isMultisite = ['handball', 'turn', 'tischtennis'].includes(environment.appName);

    public previousUrl;
    public currentUrl;

    constructor(
        public analyticsService: AnalyticsService,
        private menuService: MenuService,
        private productService: ProductsService,
        private assetsService: AssetsService,
        private authService: AuthenticationService,
        private router: Router,
        private toastr: ToastrService,
        private storage: StorageMap,
        private sidebarService: SidebarService,
        private liveEventsService: LiveEventsService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef
    ) {
        this.sidebarService.isOpen.subscribe(open => {
            if (this.sideBarOpen !== open) {
                this.sideBarOpen = open;
                this.cdr.markForCheck();
            }
        });

        this.authService.$isLoggedInEvent.subscribe(loggedIn => {
            this.loggedIn = loggedIn;
            this.cdr.markForCheck();
        });
        this.authService.isLoggedIn().subscribe(loggedIn => {
            this.loggedIn = loggedIn;
            this.cdr.markForCheck();
        });

        this.storage.get('me').subscribe((res: User) => {
            this.me = res;
            this.cdr.markForCheck();
        });

        // data which will be refreshed periodically
        timer(0, environment.liveEvents.refreshInterval * 2)
            .pipe(
                // subscription is closed as soon as dataRefreshActive is false
                takeWhile(() => this.dataRefreshActive)
            )
            .subscribe(() => {
                this.liveEventsService.getAmountOfCurrentLiveEvents().subscribe((numberOfCurrentLiveEvents: number) => {
                    if (this.liveEventsCount !== numberOfCurrentLiveEvents) {
                        this.liveEventsCount = numberOfCurrentLiveEvents;
                        this.cdr.markForCheck();
                    }
                });
            });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    @HostListener('window:resize')
    public ngAfterViewInit() {
        this.height = this.nav.nativeElement.offsetHeight;
        this.menuService.setMenuHeight(this.nav.nativeElement.offsetHeight);
    }

    public ngAfterViewChecked() {
        if (this.height && this.height !== this.nav.nativeElement.offsetHeight) {
            // use set timeout to prevent expression changed after changed
            setTimeout(() => this.ngAfterViewInit(), 2);
        }
    }

    public toggleSidebar() {
        this.trackLinkClick('Toggle Sidebar');
        this.sidebarService.toggle();
    }

    public trackLinkClick(linkName: string) {
        this.analyticsService.trackEvent('Navigation clicked', {
            category: 'Navigation Click',
            label: this.router.url,
            gtmCustom: {
                googleAnalyticsEventAction: linkName
            }
        });
    }

    public logout() {
        this.trackLinkClick('Logout');
        this.authService.logout().subscribe(
            () => {
                this.analyticsService.trackEvent('Logout', {
                    category: 'Logout',
                    label: this.router.url
                });
                this.router.navigateByUrl('/').then(() => {
                    this.translate.get('toasts.successTitle').subscribe(trans => {
                        this.toastr.success('Du wurdest abgemeldet.', trans);
                    });
                });
            },
            result => console.error('error logging out', result)
        );
    }
}
