export enum ObjectType {
    asset = 'asset',
    live_event = 'live_event',
    playlist = 'playlist',
    section = 'section',
    account = 'account',
    subscription = 'subscription',
    email = 'email',
    search = 'search',
    device_id = 'device_id',
    teaser = 'teaser'
}

export enum Action {
    hover = 'hover',
    click = 'click',
    tap = 'tap',
    play = 'play',
    pause = 'pause',
    view = 'view',
    playing = 'playing',
    seek = 'seek',
    fullscreen_change = 'fullscreen_change',
    search = 'search',
    purchase = 'purchase',
    create = 'create',
    update = 'update',
    delete = 'delete',
    sent = 'sent'
}

/** Used to track interactions with a collection of assets, but not with the assets in the collections itself. (e.g. click on more link)*/
export interface Teaser {
    title?: string;
    targetURL?: string;
}

export interface UserEvent {
    /**
     * Who? (anonymized)
     */
    user_id?: string; // database user uuid
    device_id?: string; // only for frontend hash of an unique device uuid https://github.com/Valve/fingerprintjs2
    device_type?: string; // user device like iphone/ipad
    // ip_hash?: string; // sha1 hash of users ip
    os?: string; // windows, macos, ios, android
    country?: string; // DE, AT, CH (ISO-3166-1)

    /**
     * When?
     */
    timestamp: number; //  microseconds since unix epoch

    /**
     * What happened ?
     *  etc
     */
    action: Action;

    /**
     * With what?
     */
    object_type: Object;
    object_id?: string; // the uuid of the object if available
    object?: JSON; //  full json of the object (for example the asset or the search  {keyword: 'sport', filters... )

    /**
     * Where did it happen?
     */
    source: 'web' | 'app' | 'server' | 'tv' | 'backend'; // backend is the admin backend
    url: string; // current url, can also be an API URL

    /**
     * asset related information (all optional)
     */
    position?: number; // current position in the video
    total_view_time?: number; // how many seconds the user has seen of the video
    ads?: boolean; // ads enabled?
    full_screen?: boolean;

    /**
     * Anything else?
     * what ever might be interesting (optional)
     */
    custom?: JSON;
}
