interface Consent {
    categorySlug: string;
    consent: { status: boolean };
    legitimateInterestConsent: { status: boolean };
}

interface Vendor {
    consent: { status: boolean };
    legitimateInterestConsent: Consent;
    name: string;
    id: string;
    type: 'tcf' | 'default';
}

export interface CMP {
    clear(): void;

    getBundleId(): string;

    getConsent(consent: string): Consent;

    getConsentStatus(id: string): boolean;

    getConsents(): Consent[];

    getControllerId(): string;

    getSettingsId(): string;

    getVendor(id: string): Vendor;

    getVendorStatus(id: string): boolean;

    getVendors(): Vendor[];

    hasCrossDomainPermission(): boolean;

    hasFailed(): boolean;

    hideBackdrop(): void;

    hideBanner(): void;

    isInitialized(): boolean;

    setModifiers(): void;

    showBackdrop(): void;

    showBanner(): void;

    showStep(stepConfig: { step: 'splash' | 'configuration' | 'vendors' }): void;
}

export enum CMP_EVENTS {
    /**
     * Emitted when the Usercentrics SDK is initialized and ready (i.e. when the data returned by other methods is safe to use and does not represent initial placeholder data).
     * Payload: { view: 'none' | 'configuration' | 'splash', controllerId: string, settingsId: string }
     */
    initialized = 'cmp:initialized',

    /**
     * Emitted when the Usercentrics SDK can’t be initialized, e.g. the user uses an ad-blocking extension in their browser.
     * Payload: { error: Error }
     */
    failed = 'cmp:failed',

    /**
     * Emitted when the Usercentrics SDK is set up.
     * Payload: { controllerId: string, settingsId: string }
     */
    settings = 'cmp:settings',

    /**
     * Emitted initially when the Usercentrics SDK is initialized and ready and every time consents change.
     * Payload: { data: Consent[] }
     */
    consents = 'cmp:consents',

    /**
     * Emitted initially when the Usercentrics SDK is initialized and ready and every time consents change.
     *  Payload: { data: Vendor[] }
     */
    vendors = 'cmp:vendors',

    /**
     * Emitted initially when user has already given their consent or as soon they give their consent.
     */
    confirmed = 'cmp:confirmed',

    /**
     *     Emitted when user saves their consent.
     */
    saved = 'cmp:saved',

    /**
     * Emitted when the UI view changes.

     Payload: { step: 'splash' | 'configuration' }
     */

    step = 'cmp:step',

    /**
     * Emitted when the user interacts with a tooltip (either opening or closing it).

     Payload: { key: string }
     */
    tooltip = 'cmp:tooltip',

    /**
     * Emitted when a tooltip is opened.
     * Payload: { key: string }
     */
    tooltipOpen = 'cmp:tooltipOpen',

    /**
     * Emitted when a tooltip is closed.

     Payload: { key: string }
     */
    tooltipClose = 'cmp:tooltipClose',

    /**
     * Emitted when the CMP banner is shown.
     */
    showBanner = 'cmp:showBanner',

    /**
     * Emitted when the CMP banner is hidden.
     */
    hideBanner = 'cmp:hideBanner',

    /**
     * Emitted when the dialog should show a preferred step.

     Payload: { step: 'configuration' | 'splash' }
     */
    showStep = 'cmp:showStep',

    /**
     * userActionRequired

     Emitted when user action (e.g. consent approval/configuration) is required from the CMP.
     */
    userActionRequired = 'cmp:userActionRequired',

    /**
     * Emitted when user action (e.g. consent approval/configuration) is not required from the CMP.
     */
    userActionNotRequired = 'cmp:userActionNotRequired',

    /**
     * Emitted when an URL is supposed to be shown to the user (e.g. within an in-app browser).

     Payload: { url: 'https://...', target: '_blank' }
     */
    openUrl = 'cmp:openUrl',

    /**
     * Emitted when the CMP and Usercentrics is supposed to be reset completely (e.g. because a user logged out and you want to remove any debris on that device). This will also clear the local storage.
     */
    clear = 'cmp:clear',

    /**
     *
     Emitted when the CMP and Usercentrics is reset completely. As operations with the local storage can fail at any time and this interferes with Usercentrics’ internal local storage, there is no way to 100% reliably tell whether the clearing was successful or not.
     */

    cleared = 'cmp:cleared',

    /**
     * Emitted when the configured Usercentrics property has TCF enabled.

     Payload: { data: TCFData }
     */

    tcfData = 'cmp:tcfData',

    /**
     * Emitted when the configured Usercentrics property has TCF enabled.

     Payload: { data: InAppTCData } (see InAppTCData: https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#inapptcdata)
     */
    inAppTCData = 'cmp:inAppTCData',

    /**
     * Emitted when the Usercentrics SDK cannot complete an async operation.

     Payload: { type: 'someFunctionName', error: Error }
     */
    error = 'cmp:error'
}
