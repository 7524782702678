export enum VideoState {
    notready = 'not-ready',
    started = 'started',
    paused = 'paused',
    ended = 'ended',
    next = 'next'
}

export enum AdState {
    started = 'started',
    ended = 'ended'
}
