import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PlayerStateEnum } from '../../../../enum/player-state.enum';
import { PlayerErrorEnum } from '../../../../enum/player-error.enum';
import { AuthenticationService } from '../../../../services/api/methods/authentication.service';

@Component({
    selector: 'app-player-error',
    templateUrl: './player-error.component.html'
})
export class PlayerErrorComponent implements OnInit, OnDestroy {
    public PlayerStateEnum = PlayerStateEnum;
    public PlayerErrorEnum = PlayerErrorEnum;
    @Input() public playerState: PlayerStateEnum;
    @Input() public backgroundImage: string;
    @Input() public playerError: PlayerErrorEnum;
    @Input() public errorMessage: string;

    @Output() public updateState: EventEmitter<void> = new EventEmitter();

    public animateButton = false;
    private timeout;
    private updateTimeout;
    private boundUnload = false;

    constructor(protected auth: AuthenticationService) {}

    public ngOnInit(): void {
        this.animateButton = false;

        if (this.playerState === PlayerStateEnum.API_ERROR) {
            if (!this.boundUnload) {
                this.boundUnload = true;
                window.addEventListener('beforeunload', this.beforeunload);
            }
        }
    }

    public animatePlayerStateUpdate(delay: number = 0) {
        this.animateButton = true;

        this.updateTimeout = setTimeout(() => {
            this.updateState.next();
        }, delay);

        this.timeout = setTimeout(() => {
            this.animateButton = false;
        }, 2000 + delay);
    }

    public ngOnDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }
    }

    private beforeunload(event) {
        // Cancel the event as stated by the standard.
        setTimeout(() => {
            alert('Bitte lade die Seite nicht neu. Dies überlastet die Server zusätzlich.');
        }, 10);
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
    }
}
