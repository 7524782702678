import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product } from '../../../entities/product.entity';

@Injectable()
export class ProductsService extends ApiService {
    private endpoint = 'products';

    public getProducts(): Observable<Product[]> {
        const url = this.endpoint;
        return this.http.get<{ products: Product[] }>(url).pipe(map(response => response.products));
    }

    public getProduct(uuid: string): Observable<Product> {
        return this.getProducts().pipe(map((value: Product[], index) => value.find(prod => prod.uuid === uuid)));
    }
}
