import { Injectable } from '@angular/core';
import {
    AGOF_CONTENT_CREATOR,
    AGOF_CONTENT_TOPIC,
    AGOF_CONTENT_TYPE,
    AGOF_DEVICE,
    AGOF_DISTRIBUTION,
    AGOF_HOMEPAGE,
    AGOF_LANGUAGE,
    AGOF_PAID
} from '../enum/agof.enum';

@Injectable()
export class AgofService {
    public environment;

    public createCategoryCode(
        lang: AGOF_LANGUAGE,
        contentType: AGOF_CONTENT_TYPE,
        contentCreator: AGOF_CONTENT_CREATOR,
        homepage: AGOF_HOMEPAGE,
        distribution: AGOF_DISTRIBUTION,
        device: AGOF_DEVICE,
        paid: AGOF_PAID,
        contentTopic: AGOF_CONTENT_TOPIC
    ): string {
        return lang + contentType + contentCreator + homepage + distribution + device + paid + contentTopic;
    }
}
