import { SidebarService } from '../services/sidebar.service';
import { VersionCheckService } from '../services/version-check.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { environment } from '../environments/environment';
import { InactivityService } from '../services/inactivity.service';
import { BrowserWarningService } from '../services/browser-warning.service';
import { EmbeddedModeService } from '../services/embedded-mode.service';
import { User } from '../entities/user.entity';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '../services/analytics.service';
import { MenuService } from '../services/api/methods/menu.service';
import { AuthenticationService } from '../services/api/methods/authentication.service';
import { AlertNotificationsService } from '../services/alert-notifications.service';
import { UserProfileService } from '../services/user-profile.service';
import { SubscriptionService } from '../services/api/methods/subscription.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { WebsocketService } from '../services/websocket.service';
import { ExternalScriptsLoaderService, ScriptModel } from '../services/external-scripts-loader.service';
import { ConsentService } from '../modules/consent/consent.service';
import { delay, filter, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    public environment = environment;
    public menuHeight: number;
    public sidebarVisible = false;

    public embeddedMode = false;
    public inAppMode = false;

    constructor(
        private translate: TranslateService,
        private inactivityService: InactivityService,
        private authenticationService: AuthenticationService,
        private alertNotificationsService: AlertNotificationsService,
        private analyticsService: AnalyticsService,
        private embeddedModeService: EmbeddedModeService,
        private storage: StorageMap,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private versionChecker: VersionCheckService,
        private menuService: MenuService,
        private sidebarService: SidebarService,
        private userProfileService: UserProfileService,
        private subscriptionService: SubscriptionService,
        private socketService: WebsocketService,
        private consentService: ConsentService,
        private externalScriptsLoaderService: ExternalScriptsLoaderService
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        registerLocaleData(localeDe, 'de');
        this.translate.setDefaultLang(this.environment.i18n.defaultLocale);
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use(this.environment.i18n.defaultLocale);
        if (this.environment.i18n.languages.length > 1) {
            this.translate.addLangs(this.environment.i18n.languages);
        }

        // stops tracking PageViews
        // angulartics2.settings.pageTracking.developerMode = true;

        this.inactivityService.init();
        this.versionChecker.initVersionCheck();
        BrowserWarningService.checkBrowser();
        this.menuService.menuHeight.subscribe((height: number) => {
            this.menuHeight = height;
        });
        this.sidebarService.isOpen.subscribe(isOpen => {
            this.sidebarVisible = isOpen;
        });

        this.userProfileService.init();
        this.socketService.connect();

        this.router.events
            .pipe(
                filter(e => {
                    return e instanceof NavigationEnd;
                }),
                switchMap(() => this.consentService.initialized$),
                filter(e => {
                    return e === true;
                }),
                delay(100)
            )
            .subscribe(e => {
                console.log('[CMPcustom]', window.location.pathname);
                if (
                    window.location.pathname.includes('impressum') ||
                    window.location.pathname.includes('cookiehinweise') ||
                    window.location.pathname.includes('datenschutz')
                ) {
                    console.log('[CMPcustom] on impressum / datenschutz: hide banner');
                    this.consentService.hideConsentBanner();
                }
            });
    }

    public toggleSidebar() {
        this.sidebarService.toggle();
    }

    public ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.UC_UI = {
            showSecondLayer: () => {
                this.consentService.showConfigurationStep();
            }
        };
        this.embeddedModeService.embedded.subscribe((embedded: boolean) => {
            this.embeddedMode = embedded;
            this.cdr.detectChanges();
        });

        this.analyticsService.trackEvent('InitPageLoad', {
            event: 'InitPageLoad',
            gtmCustom: {
                appName: environment.appName,
                environment: environment.envName,
                production: environment.production,
                domain: environment.tracking.customDomain ? environment.tracking.customDomain : window.location.hostname
            }
        });

        this.storage.get('me').subscribe((me: User | null) => {
            if (me && me.uuid) {
                this.analyticsService.setUserName(me.uuid);
            }
        });

        this.router.events.subscribe(evt => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });

        if (['tischtennis', 'turn', 'handball'].includes(environment.appName)) {
            this.authenticationService.isLoggedIn().subscribe(loggedIn => {
                if (loggedIn === false) {
                    this.alertNotificationsService.warnTranslated('home.plusNotification', true);
                }
            });
        }

        // fill subscription store
        this.subscriptionService.subscriptionData$().subscribe(
            () => {
                // console.log('subscription store filled'),
            },
            error => {
                // console.warn('subscriptions not filled', error)
            }
        );
    }
}
