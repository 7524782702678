import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-sport-icon',
    templateUrl: './sport-icon.component.html'
})
export class SportIconComponent {
    @Input() public sporttype: string;

    constructor() {}
}
