import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {
    @Input() public title: string;
    @Input() public message: string;
    @Input() public btnOkText: string;
    @Input() public btnCancelText: string;
    public result: EventEmitter<boolean> = new EventEmitter<boolean>();
    public close: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    public ngOnInit(): void {}

    public decline() {
        this.result.emit(false);
        this.close.emit();
    }

    public accept() {
        this.result.emit(true);
        this.close.emit();
    }

    public dismiss() {
        this.close.emit();
    }
}
