import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Asset } from '../../../entities/asset.entity';
import { ExtendedPageContent } from '../../../entities/extended-page-content';
import { Page } from '../../../entities/page.entity';
import { Section } from '../../../entities/section.entity';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../../services/analytics.service';
import { AssetsService } from '../../../services/api/methods/assets.service';
import { LiveEventsService } from '../../../services/api/methods/live-events.service';
import { PagesService } from '../../../services/api/methods/pages.service';
import { PermalinkService } from '../../../services/api/methods/permalink.service';
import { PlaylistsService } from '../../../services/api/methods/playlists.service';
import { SectionsService } from '../../../services/api/methods/sections.service';
import { CanonicalTagService } from '../../../services/canonical-tag.service';
import { SomtagService } from '../../../services/somtag.service';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'app-dynamic-page',
    templateUrl: './dynamic-page.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})
export class DynamicPageComponent implements OnInit {
    public pageType: 'extended_page' | 'page' | 'asset' | 'section' | 'serverside' | 'live' | 'not-found' | 'error';
    public asset: Asset;
    public section: Section;
    public page: Page;
    public environment;
    public extendedPageContents: ExtendedPageContent[];

    constructor(
        private permalinkService: PermalinkService,
        private sectionsService: SectionsService,
        private playlistsService: PlaylistsService,
        private liveEventsService: LiveEventsService,
        private pagesServices: PagesService,
        private assetsService: AssetsService,
        private pageTitle: Title,
        private router: Router,
        private somtagService: SomtagService,
        private analyticsService: AnalyticsService,
        private meta: Meta,
        private canonicalTagService: CanonicalTagService,
        private cdr: ChangeDetectorRef
    ) {
        this.environment = environment;
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    public ngOnInit(): void {
        // workaround to redirect /home to /
        if (window.location.pathname === '/home') {
            this.meta.updateTag(
                { property: 'prerender-status-code', content: '301' },
                'propery="prerender-status-code"'
            );
            this.meta.updateTag(
                {
                    property: 'prerender-header',
                    content: 'Location: ' + window.location.protocol + '//' + window.location.hostname + '/'
                },
                'propery="prerender-header"'
            );
            this.router.navigate(['/'], { replaceUrl: true });
        }
        // workaround to get homepage
        let permalink = window.location.pathname;
        if (permalink === '/') {
            permalink = '/home';
        }
        this.permalinkService.getLink(permalink).subscribe(
            result => {
                if (result.hasOwnProperty('asset')) {
                    this.createDynamicPageForAsset(result.asset, result.section);
                } else if (result.hasOwnProperty('section')) {
                    this.createDynamicPageForSection(result.section);
                } else if (result.hasOwnProperty('page')) {
                    this.createDynamicPageForPage(result.page);
                } else {
                    console.warn('no content found');
                    this.analyticsService.trackPageview();
                    this.pageType = 'not-found';
                    this.cdr.markForCheck();
                }
            },
            (error: HttpErrorResponse) => {
                if (error.status === 404) {
                    this.pageType = 'not-found';
                } else {
                    this.pageType = 'error';
                }
                this.analyticsService.trackPageview();
                this.cdr.markForCheck();
            }
        );
    }

    public playVideo(id) {
        let vid: HTMLVideoElement;

        if (document.getElementById(id)) {
            vid = <HTMLVideoElement>document.getElementById(id);
            vid.muted = true;
            vid.volume = 0;
            vid.setAttribute('muted', 'true');
            vid.play();
            vid.setAttribute('autoplay', 'true');
        }
    }

    private createDynamicPageForSection(section: Section) {
        if (this.willBeRedirectedToCorrectUrl(section.permalink)) {
            return;
        }
        this.section = section;
        this.pageType = 'section';
        this.cdr.markForCheck();
        this.analyticsService.trackPageview(this.section.title, this.section.title);
        this.somtagService.setDisplayAdChannelsForSection(section);
    }

    private createDynamicPageForAsset(asset: Asset, section?: Section) {
        let correctUrl;
        // calculate the correct url!
        if (asset.section && asset.section.permalink) {
            correctUrl = asset.section.permalink + '/' + asset.permalink;
        } else if (section && section.permalink) {
            correctUrl = section.permalink + '/' + asset.permalink;
        } else {
            correctUrl = asset.permalink;
        }

        if (this.willBeRedirectedToCorrectUrl(correctUrl)) {
            return;
        }
        this.asset = asset;
        this.pageType = 'asset';
        this.cdr.markForCheck();
        this.analyticsService.trackPageviewForAsset(this.asset);

        if (this.asset.section && this.asset.hasOwnProperty('section')) {
            this.sectionsService.getSection(this.asset.section.uuid).subscribe((sec: Section) => {
                this.section = sec;
                this.somtagService.setDisplayAdChannelsForSection(sec);
                this.cdr.markForCheck();
            });
        }
    }

    private createDynamicPageForPage(page: Page) {
        if (this.willBeRedirectedToCorrectUrl(page.permalink)) {
            return;
        }

        this.page = page;
        if (this.page.use_extended_page_contents === true && this.page.extended_page_contents) {
            this.pageType = 'extended_page';
            this.pageTitle.setTitle(this.page.title);
            this.extendedPageContents = this.page.extended_page_contents;
            this.analyticsService.trackPageview(this.page.title, this.page.title);
        } else {
            // normal old page

            if (this.page.redirect_url) {
                window.location.replace(this.page.redirect_url);
                return;
            }
            this.pageType = 'page';
            // no channel/format for pages
            this.analyticsService.trackPageview();
        }
        this.somtagService.setDisplayAdChannelsForPage(this.page);
    }

    private willBeRedirectedToCorrectUrl(permalink: string): boolean {
        if (permalink === 'home') {
            return false;
        }
        if (window.location.pathname !== '/' + permalink) {
            this.meta.updateTag(
                { property: 'prerender-status-code', content: '301' },
                'propery="prerender-status-code"'
            );
            this.meta.updateTag(
                {
                    property: 'prerender-header',
                    content:
                        'Location: ' + window.location.protocol + '://' + window.location.hostname + '/' + permalink
                },
                'propery="prerender-header"'
            );
            this.router.navigate([permalink], { replaceUrl: true });
            return true;
        }
        this.canonicalTagService.updateCanonicalURL(permalink);
        return false;
    }
}
