import { enableProdMode } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '../common/environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
        window['SILVERMINE_VIDEOJS_CHROMECAST_CONFIG'] = {
            preloadWebComponents: true
        };
    })
    .catch(err => console.error(err));
