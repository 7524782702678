import { Component, Input } from '@angular/core';
import { SocialLinkEntity } from '../../../entities/social-link.entity';

@Component({
    selector: 'app-social-links',
    templateUrl: './social-links.component.html'
})
export class SocialLinksComponent {
    @Input() public links: SocialLinkEntity[];

    constructor() {}
}
