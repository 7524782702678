import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'matchMinute'
})
export class MatchMinutePipe implements PipeTransform {
    public transform(value: string): string {
        const val: string[] = value.split(':');
        const number = parseInt(val[0], 10);
        return number + 1 + "'";
    }
}
