import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {SyndicationPlayerComponent} from '../../common/components/pages/embedded/syndication-player/syndication-player.component';
import {SomtagService} from '../../common/services/somtag.service';
// tslint:disable-next-line:max-line-length

const routes: Routes = [

    {path: 'sp/:playerId', component: SyndicationPlayerComponent},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: true,
            enableTracing: false, // !environment.production,
            onSameUrlNavigation: 'reload'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(protected router: Router, protected somtagService: SomtagService) {
    }
}
