import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { LocationStrategy } from '@angular/common';

@Directive({
    selector: 'a[appAdaptiveRouterLink]'
})
export class AdaptiveRouterLinkDirective extends RouterLinkWithHref implements OnChanges {
    private externalLink: string;

    constructor(router: Router, route: ActivatedRoute, locationStrategy: LocationStrategy, private el: ElementRef) {
        super(router, route, locationStrategy);
    }

    @Input() set appAdaptiveRouterLink(commands: string) {
        if (!commands) {
            return;
        }
        if (commands.startsWith('http')) {
            this.externalLink = commands;
        } else {
            this.routerLink = commands;
        }
    }

    public onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean): boolean {
        if (this.externalLink) {
            return true;
        } else {
            super.onClick(button, ctrlKey, metaKey, shiftKey);
            return false;
        }
    }

    public ngOnChanges(changes: {}): any {
        if (this.externalLink) {
            this.href = this.externalLink;
        } else {
            super.ngOnChanges(changes);
        }
    }
}
