import { EventEmitter, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, delay, map, share, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../../../entities/user.entity';
import { HttpCacheService } from '../http-cache.service';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { StorageMap } from '@ngx-pwa/local-storage';
import { environment } from '../../../environments/environment';
import { Asset } from '../../../entities/asset.entity';

@Injectable()
export class AuthenticationService extends ApiService {
    public pathToRedirectAfterLogin = environment.urlSlugs.dashboard;
    public $isLoggedInEvent: EventEmitter<boolean> = new EventEmitter();
    private country: string | null;
    public token: string | null = null;

    constructor(
        protected http: HttpClient,
        private storage: StorageMap,
        protected httpCache: HttpCacheService,
        private router: Router,
        private analyticsService: AnalyticsService,
        private injector: Injector,
        @Inject(PLATFORM_ID) platform: unknown
    ) {
        super(http);

        // detect network change to update country
        if (isPlatformBrowser(platform)) {
            fromEvent(window, 'offline').subscribe(() => {
                this.country = null;
            });
        }
    }

    public clearCache(): void {
        this.storage.delete('me').subscribe(() => {
            this.country = null;
        });
    }

    public adsDisabledForUser(): Observable<boolean> {
        return this.me().pipe(
            switchMap((me: User) => {
                if (me.no_billing && me.no_billing === true) {
                    console.warn('ADS DISABLED due to no_billing user');
                    return of(true);
                } else {
                    return this.userHasSubscriptionWhichRemovesAds();
                }
            }),
            catchError(() => {
                return of(false);
            })
        );
    }

    public getCountryCode(): Observable<{ country: string | null }> {
        if (this.country) {
            return of({ country: this.country });
        }
        return this.http.get<{ country?: string; key?: string; time?: string }>('client').pipe(
            map((response: { country?: string; key: string; time: string }) => {
                if (response.country) {
                    this.country = response.country;
                    return { country: response.country };
                }
                return { country: null };
            })
        );
    }

    public isLoggedIn(): Observable<boolean> {
        return this.me().pipe(
            map((me: User) => !!me),

            catchError((err: HttpErrorResponse) => {
                if (err.status < 500) {
                    return of(false);
                }

                console.error(err);
                throw err;
            })
        );
    }

    public register(
        data: Partial<User>
    ): Observable<{ message: string; email: string; password: string; error?: string }> {
        const url = 'signup';
        data.real_name = data.first_name + ' ' + data.last_name;

        return this.http
            .post<{ message: string; email: string; password: string; error?: string }>(url, data)
            .pipe(map(response => response));
    }

    public requestRegistrationConfirmationMail(email: string): Observable<{ message: string; error?: string }> {
        const url = 'send_registration_confirmation';
        return this.http
            .post<{ message: string; error?: string }>(url, { email: email })
            .pipe(map(response => response));
    }

    public confirmRegistrationEmail(token: string): Observable<{ confirmed: boolean; email: string }> {
        const url = 'confirm_user';
        return this.http.post<{ confirmed: boolean; email: string }>(url, {
            confirmation_token: token
        });
    }

    public passwordUpdate(
        token: string,
        email: string,
        password: string,
        passwordConfirmation: string
    ): Observable<{ message: string; error?: string }> {
        const url = 'passwords/update/' + token;

        return this.http.post<{ message: string; error?: string }>(url, {
            email,
            password,
            password_confirmation: passwordConfirmation
        });
    }

    public requestPasswordResetMail(email: string): Observable<{ message: string; error?: string }> {
        const url = 'passwords/new';
        return this.http.post<{ message: string; error?: string }>(url, {
            email: email,
            reset_password_url: '/sdf'
        });
    }

    public confirmPasswordResetToken(token: string): Observable<{ message: string; error?: string }> {
        const url = 'passwords/reset/' + token;
        return this.http.get<{ message: string; error?: string }>(url);
    }

    public login(email: string, password: string): Observable<boolean> {
        const url = 'access_token';
        return this.http
            .post<{
                access_token: string;
                me: User;
                client: { country: 'DE' | string };
            }>(url, {
                email: email,
                password: password
            })
            .pipe(
                tap((response: { access_token: string; me: User; client: { country: 'DE' | string } }) => {
                    this.httpCache.deleteCache();
                    this.analyticsService.setUserName(response.me.uuid);
                    this.analyticsService.trackEvent('Login', {
                        category: 'Login',
                        label: this.router.url
                    });
                }),
                switchMap(response => {
                    return this.storage.set('access_token', response.access_token).pipe(map(() => response));
                }),
                switchMap(response => {
                    return this.storage.set('me', response.me).pipe(map(() => response));
                }),
                delay(1000),
                switchMap(response => {
                    this.$isLoggedInEvent.next(true);
                    return of(true);
                })
            );
    }

    public loginWithToken(token: string): Observable<User> {
        this.clearCache();

        return this.me().pipe(
            map((userData: User) => {
                this.httpCache.deleteCache();
                this.storage.set('me', userData).subscribe();
                this.storage.set('access_token', token).subscribe();
                this.analyticsService.setUserName(userData.uuid);
                return userData;
            })
        );
    }

    public getToken(): string | undefined {
        return this.token;
    }

    public logout(): Observable<{ access_token: string } | string> {
        const url = 'access_token';
        return this.http.delete(url).pipe(
            map((response: { access_token: string }) => response),
            tap(() => {
                this.onLoggedOut();
            }),
            catchError(() => {
                this.clearCache();
                this.storage.delete('me').subscribe();
                this.httpCache.deleteCache();
                this.clearCache();
                this.storage.delete('access_token').subscribe();
                return '';
            })
        );
    }

    public me(): Observable<User> {
        return this.storage.get('access_token').pipe(
            map((token: string) => {
                if (token === undefined) {
                    throw new HttpErrorResponse({
                        error: 'unauthorized',
                        status: 401
                    });
                }
                this.token = token;
            }),
            switchMap(() => this.storage.get('me')),
            switchMap((user: User | null | undefined) => {
                if (user === null || user === undefined) {
                    return this.http.get<User>('me');
                }
                return of(user);
            }),
            switchMap((user: User) => {
                return this.storage.set('me', user).pipe(map(() => user));
            }),
            share()
        );
    }

    public deleteMe(): Observable<User> {
        const url = 'me';
        return this.http.delete<User>(url).pipe(
            tap(() => {
                this.onLoggedOut();
            })
        );
    }

    public updateMe(me: User): Observable<User> {
        const url = 'me';
        return this.http.put<User>(url, me).pipe(switchMap((response: User) => this.storage.set('me', response)));
    }

    public userHasSubscriptionOrPpvForAsset(asset: Asset): Observable<boolean> {
        return this.me().pipe(
            map((me: User) => {
                const tags = asset.products.values.map(tagName => {
                    return (
                        (me.current_pay_per_views[tagName] && me.current_pay_per_views[tagName].includes(asset.uuid)) ||
                        me.current_subscriptions.includes(tagName)
                    );
                });

                console.log(tags);
                return tags.includes(true);
            })
            /*catchError(e => {
                console.log('error ', e);
                return of(false);
            })*/
        );
    }

    public userHasSubscriptionWhichRemovesAds(): Observable<boolean> {
        return this.me().pipe(
            map((me: User) => {
                // on sportdeutschland/ handball no ads with freemium package
                if (me.current_subscriptions && me.current_subscriptions.includes('freemium')) {
                    console.info('ADS DISABLED  due  to subscription freemium');
                    return true;
                }
                return false;
            }),
            catchError(() => {
                return of(false);
            })
        );
    }

    private onLoggedOut(): void {
        this.storage.delete('me').subscribe();
        this.httpCache.deleteCache();
        this.clearCache();
        this.storage.delete('access_token').subscribe();
        this.$isLoggedInEvent.emit(false);
    }
}
