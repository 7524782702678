import { RoutingHelperService } from './../services/routing-helper.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Asset } from '../entities/asset.entity';
import { environment } from '../environments/environment';
import { AppName } from 'src/common/enum/app-name.enum';

@Pipe({
    name: 'assetSubtitle'
})
export class AssetSubtitlePipe implements PipeTransform {
    constructor(private routingHelperService: RoutingHelperService) {}

    public transform(asset: Asset): string {
        if (environment.appName === AppName.sportdeutschland) {
            if (asset.sportart && asset.sportart.values) {
                return asset.sportart.values[0];
            } else {
                return 'Sport';
            }
        } else {
            if (asset.channel && asset.channel.values) {
                return asset.channel.values[0];
            } else {
                return '';
            }
        }
    }
}
