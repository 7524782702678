import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../../../services/api/methods/search.service';
import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';

import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AgofEnum } from '../../../enum/agof.enum';
import { AgofService } from '../../../services/agof.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { SomtagService } from '../../../services/somtag.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {
    public environment = environment;
    public result: {
        items: RawAssetFromApi[];
        suggestions: string[];
        itemCount: number;
    };
    public loading = false;
    public q = '';

    @ViewChild('searchInput', { static: true }) public searchInput;

    constructor(
        private searchService: SearchService,
        public currentRoute: ActivatedRoute,
        private analyticsService: AnalyticsService,
        private somtagService: SomtagService,
        private agofService: AgofService,
        private router: Router,
        private toastr: ToastrService
    ) {}

    public ngOnInit(): void {
        this.somtagService.setDisplayAdChannelsFromString('suche');
        this.analyticsService.trackPageview();

        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        AgofEnum.HOMEPAGE_NO,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        AgofEnum.PAID_NO,
                        AgofEnum.CONTENT_TOPIC_Search_engines
                    ),
                    cpChannel: AgofEnum.CHANNEL_DEFAULT,
                    coSecondPart: '/suche/',
                    survey: true
                }
            }
        });
        // @ts-ignore
        window.prerenderReady = true;

        this.currentRoute.queryParams.subscribe(params => {
            this.q = params.q;
            this.search(params.q, params.trigger);
        });
    }

    public searchInputChanged(e) {
        if (e.key !== 'Enter') {
            return;
        }
        const q = (e.target as HTMLInputElement).value;

        const queryParams = { q, trigger: 'search-page-input' };

        this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge'
        });
    }

    public search(term: string, trigger?: string) {
        this.loading = true;
        this.searchService.search(term, 50, 1).subscribe(
            res => {
                this.result = res;
                this.loading = false;
            },
            err => {
                this.toastr.error('Suchergebnisse konnten nicht geladen werden.', 'Fehler');
                this.loading = false;
            }
        );
    }

    public suggestionClick(suggestion: string) {
        const queryParams = {
            q: suggestion,
            trigger: 'search-page-suggestions'
        };

        this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge'
        });
    }
}
