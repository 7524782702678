import { Observable, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import * as moment from 'moment';
import { CustomPlayer } from '../../../../../@types/CustomPlayer';

interface CheckResponse {
    inventory: {
        preroll: { url: string }[];
        presenting: { url: string }[];
        midroll: { url: string; segment_id: string; start: string; stop: string }[];
        postroll: { url: string }[];
    };
}

@Injectable()
export class LiveMidrollService extends ApiService {
    private subj: Subject<string> = new Subject();

    private alreadyTriggeredStartTimes: string[] = [];

    private static getDecimalSeconds(): number {
        const seconds = new Date().getSeconds();
        return Math.floor(seconds / 10);
    }

    public subscribeForMidroll(
        uuid: string,
        player: 'onsite' | 'embed' = 'onsite',
        videojs: CustomPlayer,
        frequency = 1000 * 5
    ): Observable<{ amount: number; segmentId: string }> {
        return timer(10, frequency).pipe(
            takeUntil(this.subj.pipe(filter(val => val === uuid))),
            switchMap(() => this.check(uuid, player, window.innerWidth > 768 ? 'desktop' : 'mobile'))
        );
    }

    public unsubscribe(assetUUID: string): void {
        this.subj.next(assetUUID);
    }

    private check(
        uuid: string,
        player: 'onsite' | 'embed' = 'onsite',
        client: 'desktop' | 'mobile' = window.innerWidth > 768 ? 'desktop' : 'mobile'
    ): Observable<{ amount: number; segmentId: string }> {
        const decimalSeconds = LiveMidrollService.getDecimalSeconds();
        const url =
            `assets/${uuid}/ads?player=${player}&client=${client}&decimal_seconds_cache_buster=` +
            decimalSeconds.toString();

        return this.http.get<CheckResponse>(url).pipe(
            filter(res => {
                if (res.inventory.midroll) {
                    return (
                        res.inventory.midroll.filter(mid => !this.alreadyTriggeredStartTimes.includes(mid.start))
                            .length > 0
                    );
                } else {
                    return false;
                }
            }),
            map(response => {
                this.alreadyTriggeredStartTimes = [
                    ...this.alreadyTriggeredStartTimes,
                    ...response.inventory.midroll.map(mid => mid.start)
                ];
                const startMoment = moment(response.inventory.midroll[0].start);
                const stopMoment = moment(response.inventory.midroll[0].stop);
                const seconds = moment.duration(stopMoment.diff(startMoment)).asSeconds();
                if (seconds >= 120) {
                    return { amount: 4, segmentId: response.inventory.midroll[0].segment_id };
                } else if (seconds >= 90) {
                    return { amount: 3, segmentId: response.inventory.midroll[0].segment_id };
                } else if (seconds >= 60) {
                    return { amount: 2, segmentId: response.inventory.midroll[0].segment_id };
                } else {
                    return { amount: 1, segmentId: response.inventory.midroll[0].segment_id };
                }
            }),
            catchError(this.handleError('checkForMidroll', { amount: 0, segmentId: '' }))
        );
    }
}
