import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppComponent } from '../../common/components/app.component';
import { AppCommonModule } from '../../common/app-common.module';
import { AppRoutingModule } from './app-routing.module';
import { SporttypesPageComponent } from './components/pages/sporttypes-page/sporttypes-page.component';
import { ChannelsPageComponent } from './components/pages/channels-page/channels-page.component';

@NgModule({
    declarations: [SporttypesPageComponent, ChannelsPageComponent],
    imports: [AppRoutingModule, AppCommonModule],
    bootstrap: [AppComponent]
})
export class AppModule {}
