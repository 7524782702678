import { ExtendedPageContent } from '../entities/extended-page-content';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
    name: 'epcMoreLink'
})
export class EPCMoreLinkPipe implements PipeTransform {
    public transform(content: ExtendedPageContent): Observable<string> {
        const link = content.visualization_options.find((option: { key: string; value: any }) => option.key === 'link');
        if (link) {
            return of(link.value);
        } else {
            return of('');
        }
    }
}
