import { Injectable, Output } from '@angular/core';
import { ApiService } from '../api.service';
import { MenuItem } from '../../../entities/menu-item.entity';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class MenuService extends ApiService {
    @Output() public totalHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    @Output() public menuHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private sideMenuEndpoint = 'side_menu_items';
    private footerEndpoint = 'footer_menu_items';
    private _menuHeight = 0;
    private _alertsHeight = 0;

    public setMenuHeight(height: number): void {
        this._menuHeight = height;
        this.emit();
    }

    public setAlertHeight(height: number): void {
        this._alertsHeight = height;
        this.emit();
    }

    public getSideMenu(): Observable<MenuItem[]> {
        return this.http
            .get<{ items: MenuItem[] }>(this.sideMenuEndpoint, {
                params: {
                    domain: environment.api.domain
                }
            })
            .pipe(
                map(response => response.items),
                map(items => items.map(item => this.optionallyAddPermalinkToItem(item))),
                catchError(this.handleError('getSideMenu', []))
            );
    }

    public getFooterLinks(): Observable<MenuItem[]> {
        return this.http
            .get<{ items: MenuItem[] }>(this.footerEndpoint, {
                params: {
                    domain: environment.api.domain
                }
            })
            .pipe(
                map(response => response.items),
                map(items => items.map(item => this.optionallyAddPermalinkToItem(item)))
            );
    }

    private emit(): void {
        this.menuHeight.next(this._menuHeight);
        this.totalHeight.next(this._menuHeight + this._alertsHeight);
    }

    private optionallyAddPermalinkToItem(item: MenuItem): MenuItem {
        const domain = item.domain || window.location.host;
        const replace = window.location.protocol + '//' + domain;
        if (item.submenu) {
            item.submenu = item.submenu.map(this.optionallyAddPermalinkToItem.bind(this));
        }
        if (item.url && item.url.includes(replace)) {
            return { ...item, permalink: item.url.replace(replace, '') };
        } else {
            return item;
        }
    }
}
