import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap } from 'rxjs/operators';
import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';
import { environment } from '../../../environments/environment';
import { AlertNotificationsService } from '../../../services/alert-notifications.service';
import { AnalyticsService } from '../../../services/analytics.service';
import { AssetsService } from '../../../services/api/methods/assets.service';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';
import { ExternalScriptsLoaderService } from '../../../services/external-scripts-loader.service';
import { Asset } from '../../../entities/asset.entity';
import { of } from 'rxjs/internal/observable/of';
import { DonationEntity } from '../../../entities/donation.entity';
import { DonationService } from '../../../services/donation.service';

declare let chckt: any;

@Component({
    selector: 'app-donation-checkout',
    templateUrl: './donation-checkout-page.component.html'
})
export class DonationCheckoutPageComponent implements OnInit {
    public environment;

    public asset: Asset;
    public amount: number;
    public donation: DonationEntity;
    public donationUuid: string;
    public showError = false;
    public hasReturnedFromAdyen = false;
    public acceptsToc = false;
    public acceptsRevocation = false;

    constructor(
        private authenticationService: AuthenticationService,
        private assetService: AssetsService,
        private donationService: DonationService,
        private route: ActivatedRoute,
        private alertNotificationService: AlertNotificationsService,
        private translate: TranslateService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private analyticsService: AnalyticsService,
        private scriptService: ExternalScriptsLoaderService,
        private toastr: ToastrService
    ) {
        this.environment = environment;
    }

    public ngOnInit(): void {
        this.analyticsService.trackPageview();

        const query = <{ donation: string; asset: string; amount: string; payload?: string; o?: string }>(
            this.route.snapshot.queryParams
        );

        if (query.donation && query.asset && query.donation) {
            this.amount = parseInt(query.amount, 10);
            this.donationUuid = query.donation;
            this.assetService.getAsset(query.asset).subscribe((asset: Asset) => {
                this.asset = asset;
                this.setupPayment(query.donation);
            });

            return;
        } else if (query.payload && query.donation) {
            this.hasReturnedFromAdyen = true;
            this.verifyPayment(query.payload, query.donation);
            return;
        } else {
            // no product or asset => redirect to home
            console.error('no donation and  asset or no payload and asset, redirect to home');
            this.router.navigateByUrl('/').then();
        }
    }

    public setupPayment(donationUuid: string) {
        this.donationService.setupPayment(donationUuid).subscribe(
            (donation: DonationEntity) => {
                this.donation = donation;
                this.paymentFormLoaded();
            },
            (err: HttpErrorResponse) => this.handleSetupPaymentError(err, donationUuid)
        );
    }

    public handleSetupPaymentError(err: HttpErrorResponse, donationUuid) {
        this.donationService.getDonation(donationUuid).subscribe(donation => {
            this.donation = donation;
            this.paymentFormLoaded();
        });
    }

    public paymentFormLoaded() {
        this.cdr.detectChanges();
        of(this.environment.adyen.mode)
            .pipe(
                map((mode: 'test' | 'live') => {
                    return (
                        'https://checkoutshopper-' +
                        mode +
                        '.adyen.com/checkoutshopper/assets/js/sdk/checkoutSDK.' +
                        this.environment.adyen.version +
                        '.min.js'
                    );
                }),
                switchMap(src => {
                    return this.scriptService.load({
                        src: src,
                        integrity: null,
                        name: 'Adyen',
                        usercentricsTemplateId: this.environment.adyen.usercentricsTemplateId
                    });
                })
            )
            .subscribe(
                () => {
                    // initiate the adyen script
                    chckt.checkout(JSON.stringify(this.donation.payment_response), '#payment-form', {
                        context: this.environment.adyen.mode,
                        useDefaultCSS: false,
                        consolidateCards: false,
                        translations: {
                            payButton: {
                                'de-DE': 'Spenden'
                            },
                            'payButton.formatted': {
                                'de-DE': 'Spenden %@'
                            }
                        }
                    });

                    chckt.hooks.onSubmitAction = (actionButton: HTMLButtonElement, extraData: any) => {
                        console.log('actionbtn', actionButton);
                        console.log('extraData', extraData);

                        // Hide the 'pay' & 'show more payment methods' buttons
                        chckt.toggleActionButton(false);
                        chckt.toggleShowMorePMsButton(false);

                        // Give all paymentMethod divs a disabled state
                        chckt.toggleEnableAllPaymentMethods(false);
                        // Block default functionality

                        this.donationService.startPayment(this.donationUuid).subscribe(
                            () => {
                                chckt.submitPaymentForm();
                            },
                            () => {
                                this.toastr.error('Die Zahlung konnte nicht gestartet werden.', 'Fehler');
                            }
                        );
                        return false;
                    };

                    chckt.hooks.beforeComplete = (node, paymentData) => {
                        // 'node' is a reference to the Checkout container HTML node.
                        // 'paymentData' is the result of the payment. Includes 'payload' variable,
                        // which you should submit to the server for the Checkout API /verify call.

                        this.donationService.verifyPayment(this.donationUuid, paymentData.payload).subscribe(
                            donation => {
                                this.showSuccessMessageAndRedirectToAssetPage(donation);
                            },
                            err => {
                                this.donationService
                                    .getDonation(this.donationUuid)
                                    .subscribe((donation: DonationEntity) => {
                                        this.showErrorMessageAndRestartPayment(
                                            JSON.stringify(err.error.message),
                                            donation
                                        );
                                    });
                            }
                        );

                        return false; // return false  to replace the default handling.
                    };
                },
                err => console.error(err)
            );
    }

    public verifyPayment(payload: string, donationUuid: string) {
        this.donationService.verifyPayment(donationUuid, payload).subscribe(
            (donation: DonationEntity) => {
                this.showSuccessMessageAndRedirectToAssetPage(donation);
            },
            err => {
                console.error('payment refused', err);
                console.log('donationUuid', donationUuid);

                this.donationService.getDonation(donationUuid).subscribe((donation: DonationEntity) => {
                    if (err.error.message.errorMessage && typeof err.error.message.errorMessage === 'string') {
                        this.showErrorMessageAndRestartPayment(err.error.message.errorMessage, donation);
                    } else {
                        this.showErrorMessageAndRestartPayment('Payment Error', donation);
                    }
                });
            }
        );
    }

    public showErrorMessageAndRestartPayment(errorMessage, donation: DonationEntity) {
        this.assetService.getAsset(donation.asset_uuid).subscribe((asset: RawAssetFromApi) => {
            this.router.navigateByUrl('/' + asset.permalink).then(() => {
                this.toastr.error(errorMessage, 'Fehler');
            });
        });
    }

    public showSuccessMessageAndRedirectToAssetPage(donation: DonationEntity) {
        this.assetService.getAsset(donation.asset_uuid).subscribe((asset: RawAssetFromApi) => {
            this.router.navigateByUrl('/' + asset.permalink).then(() => {
                this.toastr.success('Vielen Dank für deine Spende', 'Danke!');
            });
        });
    }
}
