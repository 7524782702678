import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Section } from '../../../entities/section.entity';
import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';
import { Asset } from '../../../entities/asset.entity';

@Injectable()
export class SectionsService extends ApiService {
    private endpoint = 'sections';

    public getSections(itemsPerPage = 500, page = 1): Observable<Section[]> {
        const url = this.endpoint + '?page=' + page + '&per_page=' + itemsPerPage;
        return this.http.get<{ items: Section[] }>(url).pipe(
            map(response => response.items),
            catchError(this.handleError('getSections', []))
        );
    }

    public getSection(uuid: string): Observable<Section> {
        const url = this.endpoint + '/' + uuid;
        return this.http.get<{ section: Section }>(url).pipe(
            map(response => response),
            catchError(this.handleError('getSection', null))
        );
    }

    public getAssetsForSectionID(
        uuid: string,
        itemsPerPage = 500,
        page = 1
    ): Observable<{ items: Asset[]; total: number }> {
        const url = this.endpoint + '/' + uuid + '/assets?page=' + page + '&per_page=' + itemsPerPage;

        return this.http.get<{ items: RawAssetFromApi[]; total: number }>(url).pipe(
            map(response => {
                const assets = response.items.map((asset: RawAssetFromApi) => new Asset(asset));
                return { total: response.total, items: assets };
            }),
            catchError(this.handleError('getAssetsForSectionID', { items: [], total: 0 }))
        );
    }
}
