import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Asset } from '../../../entities/asset.entity';
import { DonationService } from '../../../services/donation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DonationEntity } from '../../../entities/donation.entity';
import { NavigationExtras, Router } from '@angular/router';

@Component({
    selector: 'app-donation-button',
    templateUrl: './donation-button.component.html'
})
export class DonationButtonComponent implements OnInit {
    @Input() public asset: Asset;

    public environment = environment;

    public encodedTeamTitle: string;
    public currentAmount: string;
    public totalToPay = '30,00';

    public form: FormGroup;
    public showUntouchedFieldErrors = false;

    constructor(private donationService: DonationService, private router: Router, private formBuilder: FormBuilder) {}

    public ngOnInit(): void {
        const formData = {
            total_to_pay_in_cents: [3000, [Validators.required]],
            needs_receipt: [false, [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            first_name: [null, []],
            last_name: [null, []],
            company: [null, []],
            street: [null, []],
            housenumber: [null, []],
            zip: [null, []],
            city: [null, []],
            country: ['Deutschland', []],
            asset_uuid: [this.asset.uuid],
            data_transfer_confirmed: [false, [Validators.requiredTrue]]
        };

        this.form = this.formBuilder.group(formData);

        this.encodedTeamTitle = encodeURIComponent(this.asset.teams[0] || this.asset.label);
        this.donationService.getAssetAmount(this.asset).subscribe(res => {
            this.currentAmount = res.total_donated;
        });
    }

    public submit() {
        this.showUntouchedFieldErrors = true;
        if (!this.form.valid) {
            return;
        }
        this.donationService.createDonationObject(this.form.value).subscribe((donation: DonationEntity) => {
            console.log('donation created', donation);
            console.log('redirect to donation checkout');
            this.router.navigate(['/spenden'], <NavigationExtras>{
                queryParams: {
                    donation: donation.uuid,
                    asset: this.asset.uuid,
                    amount: this.form.get('total_to_pay_in_cents').value
                }
            });
        });
    }
}
