import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TileType } from '../../elements/asset-tile-slider/asset-tile-slider.component';
import { Asset } from '../../../entities/asset.entity';

@Component({
    selector: 'app-donation-bar',
    templateUrl: './donation-bar.component.html'
})
export class DonationBarComponent implements OnInit {
    @Input() public assets: Asset[];
    @Input() public showLink: boolean;

    public environment = environment;
    public TileType = TileType;
    public totalAmount: number;

    constructor() {}

    public ngOnInit(): void {}
}
