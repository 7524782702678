import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RawAssetFromApi } from '../../../../entities/raw-asset-from-api.entity';
import { AuthenticationService } from '../../../../services/api/methods/authentication.service';
import { User } from '../../../../entities/user.entity';
import { environment } from '../../../../environments/environment';
import { YouthProtectionPinService } from '../../../../services/api/methods/youth-protection-pin.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { Moment } from 'moment';
import { PlayerStateEnum } from '../../../../enum/player-state.enum';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { switchMap } from 'rxjs/operators';

enum YouthProtectionValues {
    child = 1,
    teen = 2,
    adult = 3
}

@Component({
    selector: 'app-youth-protection',
    templateUrl: './youth-protection.component.html'
})
export class YouthProtectionComponent implements OnInit {
    public environment = environment;
    public pin: string;
    public pinVerified = false;
    public error: string;
    public isVerifying = false;
    public lastTimeVerified: Moment = null;
    public needsPinValue: boolean;
    public playerStateEnum = PlayerStateEnum;
    @Input() public playerState: PlayerStateEnum;
    public asset: RawAssetFromApi;
    @Output() public updateState = new EventEmitter();
    private storageKeyLastTimeVerified = 'youth_protection_last_pin_verified_time';
    private videoCategory: YouthProtectionValues;
    private userCategory: YouthProtectionValues;

    constructor(
        protected authenticationService: AuthenticationService,
        protected youthProtectionService: YouthProtectionPinService,
        protected cdr: ChangeDetectorRef,
        protected storage: StorageMap,
        private activeRoute: ActivatedRoute
    ) {
        // default value for start
        this.userCategory = YouthProtectionValues.child;
        this.storage.get(this.storageKeyLastTimeVerified).subscribe((time: string) => {
            if (time) {
                this.lastTimeVerified = moment(time);
            }
        });
    }

    @Input('asset') set assetValue(asset) {
        this.asset = asset;
        this.authenticationService.me().subscribe(user => this.renderOverlayIfNeeded(user));
    }

    public ngOnInit(): void {
        this.activeRoute.url
            .pipe(switchMap((url: UrlSegment[]) => this.storage.set('redirectAfterSetPin', url[0])))
            .subscribe(() => {});

        this.authenticationService.me().subscribe(
            (me: User) => {
                this.renderOverlayIfNeeded(me);
            },
            error2 => {
                // not authenticated
                this.userCategory = YouthProtectionValues.child;
                this.renderOverlayIfNeeded(null);
            }
        );
    }

    public verifyPin(): void {
        this.error = '';
        if (!this.pin) {
            this.error = 'Bitte geben Sie den Pin ein';
            return;
        }
        this.isVerifying = true;
        this.youthProtectionService
            .verifyPin(this.pin)
            .pipe(switchMap(() => this.authenticationService.me()))
            .subscribe(
                (me: User) => {
                    this.pinVerified = true;
                    this.isVerifying = false;
                    this.renderOverlayIfNeeded(me);
                },
                (resp: HttpErrorResponse) => {
                    this.isVerifying = false;
                    if (resp.error && resp.error.message && resp.error.message.pin) {
                        this.error = resp.error.message.pin;
                    } else {
                        this.error = 'Konnte den Pin nicht überprüfen';
                    }

                    this.cdr.detectChanges();
                }
            );
        return;
    }

    private needsAgeVerification(me: User): boolean {
        return this.needsPin(me) || this.userCategory < this.videoCategory;
    }

    private needsPin(me: User): boolean {
        if (this.pinVerified === true) {
            return false;
        }

        if (me === null || me === undefined || me.birthdate === null) {
            return false;
        }

        if (me.youth_protection_pin_activity !== 'active') {
            return false;
        }
        const lastPinVerifiedTime = this.lastTimeVerified;
        if (lastPinVerifiedTime == null) {
            return true;
        }
        const secondsSinceVerify = -moment(lastPinVerifiedTime).diff(moment(), 'seconds');

        if (secondsSinceVerify < this.environment.youthProtection.secondsAfterWhichPinNeedsToBeEnteredAgain) {
            return false;
        }
        return true;
    }

    private createOverlay(me: User) {
        this.needsPinValue = this.needsPin(me);
        this.cdr.detectChanges();
    }

    private removeOverlay() {
        this.updateState.next(true);
        this.cdr.detectChanges();
    }

    private renderOverlayIfNeeded(me: User) {
        // detect video category
        if (this.asset && this.asset.youth_protection_category) {
            if (this.asset.youth_protection_category >= 18) {
                this.videoCategory = YouthProtectionValues.adult;
            } else if (this.asset.youth_protection_category >= 16) {
                this.videoCategory = YouthProtectionValues.teen;
            } else if (this.asset.youth_protection_category >= 12) {
                this.videoCategory = YouthProtectionValues.child;
            } else {
                this.videoCategory = YouthProtectionValues.child;
            }
        } else {
            // no category set => use child category
            console.warn('Missing asset propterty youth_protection_category ', this.asset);
            this.videoCategory = YouthProtectionValues.child;
        }

        this.userCategory = YouthProtectionValues.child;

        if (me && me.youth_protection_category) {
            if (me.youth_protection_category >= 18) {
                this.userCategory = YouthProtectionValues.adult;
            } else if (me.youth_protection_category >= 16) {
                this.userCategory = YouthProtectionValues.teen;
            } else if (me.youth_protection_category >= 12) {
                this.userCategory = YouthProtectionValues.child;
            } else {
                console.warn('Unknown USER Youth Protection Category detected, using child category');
                this.userCategory = YouthProtectionValues.child;
            }
        }

        if (this.needsAgeVerification(me)) {
            this.createOverlay(me);
        } else {
            this.removeOverlay();
        }
    }
}
