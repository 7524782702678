import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { SubscriptionTypeResponse } from '../../../entities/subscriptionType';
import { Subscription } from '../../../entities/subscription';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class SubscriptionService extends ApiService {
    /**
     * Observable which can be used to subscribe to the Subscription Store.
     * Returns all Subscriptions an user has. Should be just one with a various tag_filters
     */
    public subscriptionStore$: Observable<Subscription[]>;
    public errors$: Observable<string>;
    private prevState: Subscription[] = [];
    private subscriptionStateSubject: BehaviorSubject<Subscription[]>;
    private errorSubject: BehaviorSubject<string>;
    private endpointSubscriptions = 'subscribers'; // yes the api endpoint is called subscriber
    private endpointSubscriptionTypes = 'subscriptions'; // yes the api endpoint is called subscriptions
    private assetSubscriptionType: SubscriptionTypeResponse;

    constructor(protected http: HttpClient, private router: Router, private auth: AuthenticationService) {
        super(http);
        this.subscriptionStateSubject = new BehaviorSubject([]);
        this.errorSubject = new BehaviorSubject(null);

        this.subscriptionStore$ = this.subscriptionStateSubject.asObservable();
        this.errors$ = this.errorSubject.asObservable();

        this.setSubscriptionTypes();
        // Clear any state errors on navigation event
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.dismissError();
            }
        });
    }

    /**
     * Fetches the subscription data for the subscription store. Should be called once.
     * Further updates should made via update$ method.
     * @returns {Observable<Subscription[]>} Observable of subscription from the backend
     */
    public subscriptionData$(): Observable<Subscription[]> {
        return of(environment.subscribeFeature.subscribeFeatureEnabled).pipe(
            switchMap(enabled => (enabled ? of(true) : throwError('Subscriptions not enabled'))),
            switchMap(() => this.auth.isLoggedIn()),
            map(isLoggedIn => (isLoggedIn ? true : throwError('Not Logged In'))),
            switchMap(() => this.http.get<{ items: Subscription[] }>(this.endpointSubscriptions)),
            map(res => res.items),
            tap(subscriptions => {
                this.setSubscriptionStore(subscriptions);
            }),
            catchError(err => this.onError(err))
        );
    }

    /**
     * Updates the subscription in the backend and the subscription store.
     *  * @returns {Observable<Subscription>} Observable of subscription from the backend
     */
    public update$(subscription: Subscription): Observable<Subscription> {
        return defer(() => {
            // Deferred so that the observable will
            // only be created on subscription
            subscription.tag_filters = subscription.tag_filters.map(_filter => {
                if (!_filter.values || _filter.values.length === 0) {
                    return { ..._filter, _destroy: 1 };
                } else {
                    return _filter;
                }
            });

            // Make optimistic UI updates
            this.updateSubscription(subscription);

            const params: {} = {
                subscription: this.assetSubscriptionType.uuid,
                interval_type: subscription.interval_type,
                tag_filters: subscription.tag_filters
            };

            return this.http.put<Subscription>(this.endpointSubscriptions + '/' + subscription.uuid, params).pipe(
                map(res => res),
                tap(res => this.updateSubscription(res)),
                filter(res => res.tag_filters.length === 0),
                tap(res => {
                    this.delete$(res).subscribe();
                }),
                catchError(err => this.onError(err))
            );
        });
    }

    public create$(subscription: Subscription): Observable<Subscription> {
        return defer(() => {
            this.createSubscription(subscription);
            const params = {
                subscription: this.assetSubscriptionType.uuid,
                interval_type: 'week',
                tag_filters: subscription.tag_filters
            };
            return this.http.post<Subscription>(this.endpointSubscriptions, params).pipe(
                map(res => res),
                tap(res => this.updateSubscription(res)),
                catchError(err => this.onError(err))
            );
        });
    }

    public delete$(subscription: Subscription): Observable<{}> {
        return defer(() => {
            this.deleteSubscription(subscription);
            const params = {
                id: subscription.uuid
            };
            return this.http.delete<{}>(this.endpointSubscriptions + '/' + subscription.uuid, { params });
        });
    }

    private setPrevState() {
        this.prevState = this.subscriptionStateSubject.getValue();
    }

    /**
     * Sets the store
     * @param subscription the subscription, which should be set as the state of the subscription store
     */
    private setSubscriptionStore(subscription: Subscription[]) {
        this.setPrevState();
        this.subscriptionStateSubject.next(subscription);
        this.dismissError();
    }

    private createSubscription(subscription: Subscription) {
        this.setPrevState();
        const newState = [...this.prevState, subscription];
        this.subscriptionStateSubject.next(newState);
        this.dismissError();
    }

    private deleteSubscription(subscription: Subscription) {
        this.setPrevState();
        const newState = this.prevState.filter(sub => sub.uuid !== subscription.uuid);
        this.subscriptionStateSubject.next(newState);
        this.dismissError();
    }

    private stateError(errMsg: string) {
        this.errorSubject.next(errMsg);
        this.subscriptionStateSubject.next(this.prevState);
    }

    private dismissError() {
        this.errorSubject.next(null);
    }

    private onError(err: any) {
        const errorMsg = err.message ? err.message : err;
        this.stateError(errorMsg);
        return throwError(errorMsg);
    }

    /**
     * Updates the subscription in the store based on the current state and the passed subscription.
     * Does not update the subscription in the backend. Use update$ instead
     * @param subscription the subscription, which should be updated
     */
    private updateSubscription(subscription: Subscription) {
        this.setPrevState();
        const newState = this.prevState.map(current => {
            if (!current.uuid || current.uuid === subscription.uuid) {
                return { ...current, ...subscription };
            }
            return current;
        });
        this.subscriptionStateSubject.next(newState);
        this.dismissError();
    }

    private setSubscriptionTypes() {
        return this.auth
            .isLoggedIn()
            .pipe(
                map((isLoggedInd: boolean) => {
                    if (!isLoggedInd) {
                        throwError('Not Logged In');
                    }
                }),
                switchMap(() => {
                    return this.http.get<{ items: SubscriptionTypeResponse[] }>(this.endpointSubscriptionTypes);
                })
            )
            .subscribe(res => {
                this.assetSubscriptionType = res.items.find(type => type.type === 'AssetSubscription');
            });
    }
}
