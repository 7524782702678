import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
    public FNAME = '';
    public LNAME = '';
    public EMAIL = '';
    public result: null | { msg: string; result: 'success' | 'error' };

    constructor(private analyticsService: AnalyticsService) {}

    public ngOnInit(): void {
        this.analyticsService.trackPageview();
    }

    public submit() {}
}
