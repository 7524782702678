import { environment } from '../../../environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from '../../../entities/menu-item.entity';
import { MenuService } from '../../../services/api/methods/menu.service';
import { SidebarService } from '../../../services/sidebar.service';
import { AppName } from 'src/common/enum/app-name.enum';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
    public menuLeft: MenuItem[] = [];
    public footerMenuItems: MenuItem[] = [];
    public isOpen = false;
    public environment;
    public AppName = AppName;

    @Output() public collapsedEvent = new EventEmitter<boolean>();

    @Input() public menuHeight = 0;

    constructor(private menuService: MenuService, private sidebarService: SidebarService) {
        this.environment = environment;
        this.sidebarService.isOpen.subscribe(isOpen => {
            this.isOpen = isOpen;
        });
    }

    public ngOnInit(): void {
        this.menuService.getSideMenu().subscribe((items: MenuItem[]) => {
            this.menuLeft = items;
        });
        this.menuService.getFooterLinks().subscribe(links => {
            this.footerMenuItems = links;
        });
    }

    public onNavlinkClick() {
        if (environment.header.sideMenuInitiallyClosed) {
            this.sidebarService.close();
        }
    }
}
