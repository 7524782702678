import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateString'
})
export class TruncateStringPipe implements PipeTransform {
    constructor() {}

    public transform(value: string, args?: { characters: number }): string {
        if (value.length > args.characters) {
            return value.substr(0, args.characters + 1) + '…';
        } else {
            return value;
        }
    }
}
