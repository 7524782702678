import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
    public transform(value: any, args?: any): any {
        const date = moment(value);
        if (date.isSame(moment(), 'day')) {
            return `Heute ${date.format('H:mm')}`;
        } else if (date.isSame(moment().add(1, 'day'), 'day')) {
            return `Morgen ${date.format('H:mm')}`;
        } else if (date.isAfter(moment(), 'day')) {
            return `${super.transform(value)} ${date.format('H:mm')}`;
        } else {
            return super.transform(value);
        }
    }
}
