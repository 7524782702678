import { ToastrService } from 'ngx-toastr';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AgeVerificationRequest, AgeVerificationService } from '../../../services/api/methods/age-verification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../../../entities/user.entity';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { Duration, Moment } from 'moment';
import { Router, UrlSegment } from '@angular/router';
import { YouthProtectionPinService } from '../../../services/api/methods/youth-protection-pin.service';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../../services/analytics.service';
import { forkJoin } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-age-verification',
    templateUrl: './age-verification.component.html'
})
export class AgeVerificationComponent implements OnInit, OnDestroy {
    public data: AgeVerificationRequest = {
        id_country_code: '',
        id_type: '',
        id_number_block_1: '',
        id_number_block_2: '',
        id_number_validator: ''
    };
    public id_number_block_3: string;
    public error: any;
    public submitted = false;
    public success = false;
    public environment = environment;
    public newPin: string;

    /// PIN
    public ageVerificationTime: Moment;
    public ageVerificationTimeAgo: Duration;
    public me: User;
    public timedOut = false;
    public interval: any;
    /**
     * For testing only
     */
    protected adultData: AgeVerificationRequest = {
        id_country_code: 'D',
        id_type: 'national_id',
        id_number_block_1: 'LF083MPV30',
        id_number_block_2: '83083112108106D',
        id_number_validator: '4'
    };
    private redirectUrl: string;

    constructor(
        private ageVerificationService: AgeVerificationService,
        private storage: StorageMap,
        private authenticationService: AuthenticationService,
        private youthProtectionService: YouthProtectionPinService,
        private translateService: TranslateService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService
    ) {}

    public countryAndTypeChosen = () => this.data.id_country_code !== '' && this.data.id_type !== '';

    public ngOnInit(): void {
        this.analyticsService.trackPageview();

        this.authenticationService.me().subscribe(me => (this.me = me));
        this.storage.get('redirectAfterSetPin').subscribe((redirect: UrlSegment) => (this.redirectUrl = redirect.path));

        this.storage.get('age_verification_time').subscribe((valFromStorage: Moment) => {
            if (valFromStorage === null) {
                this.timedOut = true;
                this.ageVerificationTime = moment().subtract(1, 'day');
            } else {
                this.ageVerificationTime = moment(valFromStorage);
            }

            this.interval = setInterval(() => {
                this.ageVerificationTimeAgo = moment.duration(this.ageVerificationTime.diff(moment()));
                if (4 + this.ageVerificationTimeAgo.get('minutes') < 0) {
                    this.timedOut = true;
                    this.success = false;
                }
            }, 200);
        });
    }

    public setCountry(country) {
        this.data.id_country_code = country;
        if (country === 'CHE' && this.data.id_type === 'aufenthaltstitel') {
            this.data.id_type = 'national_id';
        }
    }

    public submit() {
        this.submitted = true;

        // wenn deutscher ausweis und block 3 hat 8 stellen dann ist es ein neuer ausweis
        // wenn 7 stellen ist es ein alter

        const dataToSubmit = { ...this.data };

        if (dataToSubmit.id_country_code === 'D' && dataToSubmit.id_type === 'national_id') {
            dataToSubmit.id_number_block_2 += this.id_number_block_3;
        }
        dataToSubmit.id_number_validator = dataToSubmit.id_number_validator.toString();
        dataToSubmit.id_number_block_2 = dataToSubmit.id_number_block_2.toString();
        dataToSubmit.id_number_block_1 = dataToSubmit.id_number_block_1.toString();

        this.ageVerificationService
            .verifyAge(dataToSubmit)
            .pipe(
                tap((resp: User) => {
                    this.success = true;
                    this.me = resp;
                    this.timedOut = false;
                    this.error = '';
                    this.ageVerificationTime = moment();
                    this.cdr.detectChanges();
                }),
                switchMap(() => this.storage.set('age_verification_time', moment().toISOString()))
            )
            .subscribe(
                () => {},
                (resp: HttpErrorResponse) => {
                    console.error(resp);
                    this.success = false;
                    this.error = resp.error.message;
                }
            );
    }

    /**
     * For testing only
     */
    public submitAdult() {
        this.data = this.adultData;
        //   this.submit();
    }

    /** PIN METHODS */

    public ngOnDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    public setPin() {
        this.error = {};
        if (!this.newPin) {
            return;
        }
        this.youthProtectionService
            .setPin(this.newPin, 'active')
            .pipe(
                switchMap(() =>
                    forkJoin([
                        this.translateService.get('ageVerification.setYouthProtectionPin.messagePinActivated'),
                        this.translateService.get('toasts.successTitle')
                    ])
                )
            )
            .subscribe(
                ([message, title]) => {
                    this.toastr.success(message, title);
                    if (this.redirectUrl !== undefined && this.redirectUrl !== null) {
                        this.router.navigateByUrl(this.redirectUrl);
                    } else {
                        this.router.navigateByUrl(
                            '/' + environment.urlSlugs.dashboard + '/' + environment.urlSlugs.dashboard
                        );
                    }
                    this.newPin = null;
                },
                (resp: HttpErrorResponse) => {
                    this.error = resp.error.message;
                }
            );
    }
}
