import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Asset } from '../../../../../entities/asset.entity';
import { Tag } from '../../../../../entities/tag.entity';
import { environment } from '../../../../../environments/environment';
import { SubscriptionService } from '../../../../../services/api/methods/subscription.service';
import { SubscriptionLike } from 'rxjs/internal/types';

@Component({
    selector: 'app-tag-cloud',
    templateUrl: './tag-cloud.component.html'
})
export class TagCloudComponent implements OnInit, OnDestroy {
    public environment = environment;

    public tags: {
        routerLink: string;
        name: string;
        callname: string;
        queryParams: any;
    }[] = [];
    private subscribeToSubscriptionUpdates: SubscriptionLike;

    constructor(private subscriptionService: SubscriptionService) {}

    private _asset: Asset;

    public get asset() {
        return this._asset;
    }

    @Input()
    public set asset(asset: Asset) {
        this._asset = asset;
        this.updateTags();
    }

    public updateTags() {
        const asset = this._asset;
        if (!asset || !asset.tags) {
            return;
        }
        this.tags = [];

        if (asset.sportart && asset.section) {
            asset.sportart.values.forEach(value => {
                this.addTag({
                    routerLink: '/' + asset.section.permalink,
                    name: value,
                    callname: asset.sportart.callname,
                    queryParams: {}
                });
            });
        }

        if (asset.channel && asset.section) {
            asset.channel.values.forEach(value => {
                this.addTag({
                    routerLink: '/' + asset.section.permalink,
                    name: value,
                    callname: asset.channel.callname,
                    queryParams: {}
                });
            });
        }

        asset.tags.forEach((tag: Tag, index: number) => {
            if (tag.label.indexOf('_') === 0) {
                return;
            }
            if (tag.callname === 'format' && asset.is_moment_of_the_year_clip) {
                return;
            }
            if (asset.channel && tag.callname === asset.channel.callname) {
                return;
            }
            if (asset.sportart && tag.callname === asset.sportart.callname) {
                return;
            }

            tag.values.forEach(value => {
                this.addTag({
                    routerLink: '/' + environment.urlSlugs.search,
                    name: value,
                    callname: tag.callname,
                    queryParams: { q: value }
                });
            });
        });
    }

    public ngOnDestroy() {}

    public ngOnInit(): void {}

    private addTag(tag: { routerLink: string; name: string; callname: string; queryParams: any }) {
        // do not allow double tags
        const foundIndex = this.tags.findIndex(value => value.name === tag.name);
        if (foundIndex < 0) {
            this.tags.push(tag);
        }
    }
}
