import { PlaylistHandlerService } from '../../../../../services/playlist-handler.service';
import { Component } from '@angular/core';
import { VideoPlaylist } from '../../../../../entities/video-playlist.entity';

@Component({
    selector: 'app-video-playlist',
    templateUrl: './video-playlist.component.html'
})
export class VideoPlaylistComponent {
    public opened = false;
    public playlist: VideoPlaylist;
    public currentIndex: number;

    constructor(private playlistHandlerService: PlaylistHandlerService) {
        this.playlistHandlerService.activePlaylistIndex.subscribe((value: { index: number; play: boolean }) => {
            this.currentIndex = value.index;
        });
        this.playlistHandlerService.playlist.subscribe(playlist => {
            this.playlist = playlist;
        });
        this.playlistHandlerService.sidePlaylistOpen.subscribe(opened => (this.opened = opened));
    }

    public goToIndex(index: number): void {
        this.playlistHandlerService.goToPlaylistIndex(index);
        this.closePlaylist();
    }

    public closePlaylist() {
        this.playlistHandlerService.toggleSidePlaylist(false);
    }
}
