import { Component, OnInit, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';
import { User } from '../../../entities/user.entity';
import { Order } from '../../../entities/order.entity';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';
import { switchMap } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
    public environment;
    public modalRef: BsModalRef;

    public me: User;
    public assets: RawAssetFromApi[] = [];

    /**
     * orders only contains orders with status payment_authorized
     */
    public orders: Order[];
    public orderInModal: Order;

    constructor(
        private title: Title,
        private modalService: BsModalService,
        private trans: TranslateService,
        private authenticationService: AuthenticationService,
        private storage: StorageMap,
        private activeRoute: ActivatedRoute
    ) {
        this.environment = environment;
        moment.locale(this.environment.defaultLocale);

        trans.get('dashboard.pageTitle').subscribe(res => this.title.setTitle(res + environment.pageTitleAppendix));

        this.loadMe();
    }

    public ngOnInit(): void {
        this.activeRoute.url
            .pipe(switchMap((url: UrlSegment[]) => this.storage.set('redirectAfterSetPin', url[0])))
            .subscribe();
        // TRACK PAGEVIEWS IN CHILD COMPONENTS
    }

    public loadMe() {
        this.authenticationService.me().subscribe((data: User) => {
            this.me = data;
        });
    }

    public openModal(template: TemplateRef<any>, order: Order) {
        this.orderInModal = order;
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    }

    public closeModal(): void {
        this.modalRef.hide();
    }
}
