import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '../../../../../services/analytics.service';
import { RoutingHelperService } from '../../../../../services/routing-helper.service';
import { environment } from '../../../../../environments/environment';
import { of } from 'rxjs/internal/observable/of';
import { delay, takeUntil } from 'rxjs/operators';
import { Action, ObjectType } from '../../../../../entities/user-event.entity';
import { Subject } from 'rxjs/internal/Subject';
import { UserProfileService } from '../../../../../services/user-profile.service';
import { Asset } from '../../../../../entities/asset.entity';

@Component({
    selector: 'app-related-asset',
    templateUrl: './related-asset.component.html'
})
export class RelatedAssetComponent implements OnInit {
    @Input() public asset: Asset;
    @Input() public trackingInfo?: {
        listTitle: string;
        index: string;
    };

    @Output() public click: EventEmitter<void> = new EventEmitter<void>();

    public environment = environment;
    public mouseLeave: Subject<void> = new Subject();
    public assetLink: string;

    constructor(
        private analyticsService: AnalyticsService,
        private userProfileService: UserProfileService,
        public routingHelper: RoutingHelperService
    ) {}

    public ngOnInit(): void {
        this.assetLink = this.routingHelper.getLinkFromAsset(this.asset);
    }

    public onAssetClick() {
        this.routingHelper.activateAutoplay();
        this.trackGoToAsset();
    }

    public onMouseEnter() {
        this.mouseLeave = new Subject();
        of({})
            .pipe(delay(this.userProfileService.delayHoverEvents), takeUntil(this.mouseLeave))
            .subscribe(() => {
                this.userProfileService.trackEvent(ObjectType.asset, Action.hover, this.asset);
            });
    }

    public onMouseLeave() {
        this.mouseLeave.next();
    }

    public trackGoToAsset() {
        this.click.next();
        this.analyticsService.trackEvent('Teaser Click', {
            category: 'Teaser Click',
            gtmCustom: {
                title: this.asset.label,
                targetURL: this.assetLink,
                listTitle: (this.trackingInfo || { listTitle: undefined }).listTitle,
                index: (this.trackingInfo || { index: undefined }).index
            }
        });
        this.userProfileService.trackEvent(ObjectType.asset, Action.click, this.asset);
    }
}
