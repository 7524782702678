import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { StorageMap } from '@ngx-pwa/local-storage';
import { User } from '../../../entities/user.entity';

export declare interface AgeVerificationRequest {
    id_country_code: string | 'D' | 'AUT' | 'CHE';
    id_type: string | 'national_id' | 'passport' | 'aufenthaltstitel';
    id_number_block_1: string;
    id_number_block_2: string;
    id_number_validator: string;
}

@Injectable()
export class AgeVerificationService extends ApiService {
    private endpoint = 'verify_age';

    constructor(protected http: HttpClient, public storage: StorageMap) {
        super(http);
    }

    public verifyAge(data: AgeVerificationRequest): Observable<User> {
        const url = this.endpoint;
        return this.http.post<{}>(url, data).pipe(
            switchMap((response: User) => {
                return this.storage.set('me', response).pipe(map(() => response));
            })
        );
    }
}
