import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Product } from '../../../entities/product.entity';
import { RawAssetFromApi } from '../../../entities/raw-asset-from-api.entity';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Order } from '../../../entities/order.entity';
import { environment } from '../../../environments/environment';
import { FullOrder } from '../../../entities/full-order.entity';
import { HttpClient } from '@angular/common/http';
import { HttpCacheService } from '../http-cache.service';

@Injectable()
export class OrderService extends ApiService {
    private endpoint = 'orders';

    constructor(protected http: HttpClient, protected httpCache: HttpCacheService) {
        super(http);
    }

    public createOrder(product: Product, asset: RawAssetFromApi, couponIds: string[] = []): Observable<Order> {
        const params: { client: 'Web'; products: { product_id: string; asset_id: string; coupons?: string[] }[] } = {
            client: 'Web',
            products: [
                {
                    product_id: product.uuid,
                    asset_id: asset.uuid
                }
            ]
        };

        if (couponIds && couponIds.length > 0) {
            params.products[0].coupons = couponIds;
        }

        return this.http.post<Order>(this.endpoint, params).pipe(map(response => response));
    }

    public getOrders(): Observable<FullOrder[]> {
        return this.http.get<{ orders: FullOrder[] }>(this.endpoint).pipe(map(response => response.orders));
    }

    public getOrderById(uuid: string): Observable<Order> {
        return this.http.get<Order>(this.endpoint + '/' + uuid).pipe(map(response => response));

        // return this.getOrders().pipe(map((response: Order[]) => response.find((order: Order) => order.uuid === uuid)));
    }

    public deleteOrder(order: Order): Observable<{ message: string; status: number }> {
        return this.http.delete<{ message: string; status: number }>(this.endpoint + '/' + order.uuid).pipe(
            map(response => response),
            tap(() => {
                this.httpCache.deleteCache();
            })
        );
    }

    public findExistingOrderByProduct(product: Product): Observable<Order> {
        return this.getOrders().pipe(
            map((response: Order[]) => {
                const order = response.find((ord: Order) => ord.products[0].uuid === product.uuid);
                if (order) {
                    return order;
                } else {
                    throw Error('order not found');
                }
            })
        );
    }

    public findExistingOrderByCoupon(coupons: string[]): Observable<Order> {
        return this.getOrders().pipe(
            map((response: Order[]) => {
                const order = response.find((ord: Order) => {
                    return ord.coupons[0].uuid === coupons[0];
                });
                if (order) {
                    return order;
                } else {
                    throw Error('order not found');
                }
            })
        );
    }

    public setupPayment(order: Order): Observable<Order> {
        const params = {
            client: 'Web',
            payment_step: 'setup_payment',
            provider: 'Adyen',
            return_path: environment.urlSlugs.checkout + '?o=' + order.uuid
        };
        return this.http
            .put<Order>(this.endpoint + '/' + order.uuid + '/payment', params)
            .pipe(map(response => response));
    }

    public startPayment(order: Order): Observable<Order> {
        const params = {
            client: 'Web',
            payment_step: 'start_payment',
            provider: 'Adyen',
            return_path: environment.urlSlugs.checkout + '?o=' + order.uuid
        };
        return this.http.put<Order>(this.endpoint + '/' + order.uuid + '/payment', params);
    }

    public verifyPayment(order: Order, payload: string): Observable<Order> {
        const params = {
            client: 'Web',
            payment_step: 'verify_payment',
            provider: 'Adyen',
            payload: payload
        };
        return this.http
            .put<Order>(this.endpoint + '/' + order.uuid + '/payment', params)
            .pipe(map((response: Order) => response));
    }
}
