import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Asset } from '../entities/asset.entity';
import { Observable } from 'rxjs';
import { DonationEntity } from '../entities/donation.entity';
import { map } from 'rxjs/operators';

@Injectable()
export class DonationService {
    constructor(private http: HttpClient) {}

    public getTotalAmount(): Observable<number> {
        return this.http.get<{ total_donated: string }>('donations').pipe(
            map(amount => {
                amount.total_donated = amount.total_donated.replace('€', '');
                amount.total_donated = amount.total_donated.replace('.', '');
                return parseFloat(amount.total_donated.replace(',', '.'));
            })
        );
    }

    public createDonationObject(donation: DonationEntity) {
        return this.http.post<DonationEntity>('donations', donation);
    }

    public getDonation(donationUUID: string) {
        return this.http.get<DonationEntity>('donations/' + donationUUID);
    }

    public getAssetAmount(asset: Asset): Observable<{ total_donated: string }> {
        return this.http.get<{ total_donated: string }>('assets/' + asset.uuid + '/donations');
    }

    public setupPayment(donationUuid: string): Observable<DonationEntity> {
        return this.http.put<DonationEntity>('donations/' + donationUuid + '/payment', {
            provider: 'Adyen',
            payment_step: 'setup_payment',
            client: 'Web',
            return_path: 'spenden?donation=' + donationUuid
        });
    }

    public startPayment(donationUuid: string): Observable<DonationEntity> {
        return this.http.put<DonationEntity>('donations/' + donationUuid + '/payment', {
            provider: 'Adyen',
            payment_step: 'start_payment',
            client: 'Web'
        });
    }

    public verifyPayment(donationUuid: string, payload: string): Observable<DonationEntity> {
        return this.http.put<DonationEntity>('donations/' + donationUuid + '/payment', {
            provider: 'Adyen',
            payment_step: 'verify_payment',
            client: 'Web',
            payload: payload
        });
    }
}
