import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-tile-teaser-grid',
    templateUrl: './tile-teaser-grid.component.html'
})
export class TileTeaserGridComponent implements OnInit {
    @Input() public title: string;
    @Input() public showMoreButton: boolean;

    @Input() public moreButtonTitle: string;
    @Input() public moreButtonDisabled: boolean;

    @Output() public moreButtonClick: EventEmitter<Event> = new EventEmitter<Event>();

    constructor(public router: Router) {}

    public ngOnInit(): void {}

    public moreButtonClickHandler(event) {
        this.moreButtonClick.next(event);
    }
}
