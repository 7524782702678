import { AnalyticsService } from './../../../../services/analytics.service';
import { Component, Input } from '@angular/core';
import { Section } from '../../../../entities/section.entity';

@Component({
    selector: 'app-small-section-tile',
    templateUrl: './small-section-tile.component.html'
})
export class SmallSectionTileComponent {
    @Input() public image: string;
    @Input() public permalink: string;
    @Input() public title: string;
    @Input() public assets_count: number;
    @Input() public trackingInfo: {
        listTitle: string;
        index: number;
    };

    constructor(private analyticsService: AnalyticsService) {}

    private _section: Section;

    public get section(): Section {
        return this._section;
    }

    @Input('section')
    public set section(section: Section) {
        this._section = section;
        this.image = section.image;
        this.permalink = section.permalink;
        this.title = section.title;
        this.assets_count = section.assets_count;
    }

    public track() {
        this.analyticsService.trackEvent('Teaser Click', {
            category: 'Teaser Click',
            gtmCustom: {
                title: this.title,
                targetURL: this.permalink,
                listTitle: (this.trackingInfo || { listTitle: undefined }).listTitle,
                index: (this.trackingInfo || { index: undefined }).index
            }
        });
    }
}
