import { Injectable } from '@angular/core';
import { User } from '../entities/user.entity';
import { AuthenticationService } from './api/methods/authentication.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { YouthProtectionPinService } from './api/methods/youth-protection-pin.service';
import { environment } from '../environments/environment';
import { AppName } from 'src/common/enum/app-name.enum';

@Injectable()
export class InactivityService {
    private lastActivityTimeStamp: number;
    private timer = null;
    private microsecondsAfterUserIsConsideredInactive = 15 * 60 * 1000;
    private activityObserver: BehaviorSubject<boolean> = new BehaviorSubject(true);

    public init(): void {
        window.addEventListener('scroll', () => this.pingActivity(), {
            passive: true
        });
        window.addEventListener('click', () => this.pingActivity(), {
            passive: true
        });
        window.addEventListener('touch', () => this.pingActivity(), {
            passive: true
        });
    }

    public pingActivity(): void {
        this.activityObserver.next(true);
    }

    private loginStateChanged(): void {
        if (this.authenticationService.isLoggedIn()) {
            this.authenticationService.me().subscribe(
                (me: User) => {
                    this.monitorUserInactivity(me);
                },
                () => {
                    this.stopMonitoringUserInactivity();
                }
            );
        } else {
            this.stopMonitoringUserInactivity();
        }
    }

    private monitorUserInactivity(me: User): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(
            () => this.sendActivityStatusToServer(),
            (me.youth_protection_session_activity_interval_in_seconds || 500) * 1000
        );
    }

    // users is considered inactive if there has been no

    private stopMonitoringUserInactivity(): void {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    // user interaction fot at least 15 minutes (this.microsecondsAfterUserIsConsideredInactive)
    private userIsActive(): boolean {
        return this.lastActivityTimeStamp >= Date.now() - this.microsecondsAfterUserIsConsideredInactive;
    }

    // gets called all 500 seconds
    private sendActivityStatusToServer(): void {
        return;

        // since ranfighting was killed we do not need this feature anymore
        /*this.authenticationService
            .isLoggedIn()
            .pipe(
                filter(loggedIn => loggedIn),
                filter(() => this.userIsActive()),
                switchMap(() => this.youthProtectionService.extendYouthProtectionSession())
            )
            .subscribe(() => {
                // user is active, extended YP PIN pin session
            });*/
    }

    constructor(
        private authenticationService: AuthenticationService,
        private youthProtectionService: YouthProtectionPinService
    ) {
        // only set the last activity timestamp every second
        this.activityObserver
            .asObservable()
            .pipe(debounceTime(1000))
            .subscribe(() => {
                this.lastActivityTimeStamp = Date.now();
            });

        this.authenticationService.$isLoggedInEvent.subscribe(() => this.loginStateChanged);
        this.loginStateChanged();
        this.init();
    }
}
