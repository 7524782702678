import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RawAssetFromApi } from '../entities/raw-asset-from-api.entity';
import { environment } from '../environments/environment';
import { AppName } from 'src/common/enum/app-name.enum';

@Injectable({
    providedIn: 'root'
})
export class RoutingHelperService {
    private autoplay = false;
    /**
     * All assets from these sections will be redirect to handball-deutschland.tv on sportdeutschland
     */
    private handballExclusiveUrls = [
        'wm-maenner-2019',
        'hbl-2',
        'hbf',
        'em-frauen-2018',
        '2018-ehf-cup',
        'wm-frauen-2017',
        'handball-royal',
        'tussies',
        'beachhandball-2'
    ];

    private tischtennisExclusiveUrls = ['ttbl', 'dttb-veranstaltungen', 'german-open'];

    private turnExclusiveUrls = [
        'geraetturnen',
        'trampolinturnen',
        'rhythmische-sportgymnastik',
        'weitere-events',
        'turnen-wm-2018'
    ];

    constructor(private router: Router) {}

    public goToAssetPage(asset: RawAssetFromApi, autoplay = false, routedWithoutUserInteraction = false): void {
        const link = this.getLinkFromAsset(asset);
        if (autoplay) {
            this.activateAutoplay();
        }
        if (link.startsWith('http')) {
            window.location.href = link;
        } else {
            this.router
                .navigateByUrl(link, {
                    state: {
                        routedWithoutUserInteraction
                    }
                })
                .then();
        }
    }

    public activateAutoplay(): void {
        this.autoplay = true;
    }

    public shouldAutoplay(): boolean {
        return this.autoplay;
    }

    public getLinkFromAsset(asset: RawAssetFromApi, forceAbsoluteSDTVURL: boolean = false): string {
        let link = '/';
        if (environment.appName === AppName.sportdeutschland) {
            if (asset.section && this.handballExclusiveUrls.includes(asset.section.permalink)) {
                link = 'https://handball-deutschland.tv/';
            } else if (asset.section && this.tischtennisExclusiveUrls.includes(asset.section.permalink)) {
                link = 'https://tischtennis-deutschland.tv/';
            } else if (asset.section && this.turnExclusiveUrls.includes(asset.section.permalink)) {
                link = 'https://turn-deutschland.tv/';
            } else if (forceAbsoluteSDTVURL) {
                link = 'https://sportdeutschland.tv/';
            }

            if (environment.envName === 'local') {
                // console.debug('You are in local env: Did not replace link for domain: ' + link);
                link = '/';
            }
        }

        if (asset.section && asset.section.permalink) {
            link += asset.section.permalink + '/';
        }
        if (asset.permalink) {
            link += asset.permalink;
        }
        return link;
    }
}
