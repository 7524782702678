import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ProductsService } from '../../../../services/api/methods/products.service';
import { environment } from '../../../../environments/environment';
import { NavigationExtras, Router } from '@angular/router';
import { Product } from '../../../../entities/product.entity';
import { PlayerStateEnum } from '../../../../enum/player-state.enum';
import { Asset } from '../../../../entities/asset.entity';
import { EmbeddedModeService } from '../../../../services/embedded-mode.service';

@Component({
    selector: 'app-paywall',
    templateUrl: './paywall.component.html'
})
export class PaywallComponent implements OnInit {
    @ViewChild('playerStage', { static: true }) public playerStage: ElementRef;

    @Input() public playerState: PlayerStateEnum;
    @Input() public asset: Asset;

    public environment: any;
    public allProducts: Product[];
    public availableProducts: Product[] = [];
    public error = '';

    constructor(
        protected productsService: ProductsService,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        private embeddedModeService: EmbeddedModeService
    ) {
        this.environment = environment;
    }

    public buyProduct(product: Product) {
        if (this.embeddedModeService.embedded.getValue()) {
            window.open(
                window.location.origin +
                    '/' +
                    environment.urlSlugs.checkout +
                    '?p=' +
                    product.uuid +
                    '&a=' +
                    this.asset.uuid
            );
        } else {
            this.router.navigate(['/' + environment.urlSlugs.checkout], <NavigationExtras>{
                queryParams: {
                    p: product.uuid,
                    a: this.asset.uuid
                }
            });
        }
    }

    public trackByFn(index, item: Product) {
        return item.uuid;
    }

    public ngOnInit(): void {
        this.renderPayWallIfNeeded();

        this.productsService.getProducts().subscribe((products: Product[]) => {
            this.allProducts = products;
            this.createAvailableProducts();
        });
    }

    private createAvailableProducts() {
        this.allProducts.forEach((product: Product) => {
            if (this.getAssetProducts().includes(product.tag)) {
                this.availableProducts.push(product);
            }
        });
        this.cdr.markForCheck();
    }

    private getAssetProducts(): string[] {
        if (!this.asset) {
            console.warn('Asset not yet defined', this.asset);
            return [];
        }
        if (!this.asset.products) {
            console.warn('No Products defined in asset. Asset is free to view', this.asset);
            return [];
        }
        return this.asset.products.values;
    }

    private renderPayWallIfNeeded(): void {
        const productsAllowed = this.getAssetProducts();

        if (productsAllowed.length === 0) {
            console.error(
                'No products exist which can be used to view this asset, but content is not freely accessible',
                this.asset
            );
            this.error = 'Dieses Video ist nicht öffentlich verfügbar.';
            this.cdr.markForCheck();
            return;
        }
        this.error = '';
        this.cdr.markForCheck();
    }
}
