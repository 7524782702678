import { SomtagService } from '../../../services/somtag.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-ad',
    templateUrl: './ad.component.html',
    styleUrls: ['./ad.component.scss']
})
export class AdComponent implements OnInit, OnDestroy {
    @Input() public format:
        | 'fullbanner2'
        | 'skyscraper1'
        | 'rectangle1'
        | 'mbanner1'
        | 'mbanner2'
        | 'inread1'
        | 'minread1'
        | 'performanceRectangle1'
        | 'promo2'
        | 'promo3'
        | 'promo4'
        | 'promo5';
    @Output() public height: EventEmitter<number> = new EventEmitter<number>();
    @Output() public width: EventEmitter<number> = new EventEmitter<number>();
    @Output() public disabled: EventEmitter<boolean> = new EventEmitter<boolean>();

    public environment = environment;
    private loadAdSubscription: Subscription;
    private loadInitialized: boolean = false;

    constructor(private somtagService: SomtagService) {}

    public ngOnInit(): void {
        this.loadAdSubscription = this.somtagService.loadDisplayAdsSource
            .pipe(
                filter((value: boolean) => value)
                // take(1)
            )
            .subscribe(
                () => {
                    this.load();
                },
                err => {
                    console.error('ad-component error on load ad. Format: ', this.format);
                    this.disabled.next(true);
                    this.height.emit(0);
                    this.width.emit(0);
                }
            );
    }

    public ngOnDestroy() {
        this.loadAdSubscription.unsubscribe();
    }

    public load() {
        if (this.loadInitialized) {
            console.error('prevented ad loading - ad already loaded');
            return;
        }
        this.loadInitialized = true;
        this.somtagService.loadDisplayAdForSlot(this.format, '#' + this.format).subscribe(
            (res: { ad: any; result: any; error: any }) => {
                if (res.error) {
                    this.height.emit(0);
                    this.width.emit(0);
                    this.disabled.emit(true);
                    console.error(res.error);
                    return;
                }

                if (res.result.data.adConfig) {
                    if (
                        res.result.data.adConfig.width !== 0 &&
                        res.result.data.adConfig.height !== 0 &&
                        res.result.data.adConfig.modifier !== 'fallback'
                    ) {
                        this.width.emit(+res.result.data.adConfig.width);
                        this.height.emit(+res.result.data.adConfig.height);
                    } else {
                        this.disabled.next(true);
                        this.width.emit(0);
                        this.height.emit(0);
                    }
                }
            },
            err => {
                this.height.emit(0);
                this.width.emit(0);
                this.disabled.emit(true);
            }
        );
    }
}
