import { Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoginFormComponent } from './login-form.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

@Injectable()
export class LoginFormService {
    private overlayRef: OverlayRef;

    private loginFormComponentPortal: ComponentPortal<LoginFormComponent>;

    constructor(private overlay: Overlay, private router: Router) {}

    public showLoginOverlay($loginEvent) {
        const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
        this.overlayRef = this.overlay.create({
            width: 320,
            disposeOnNavigation: true,
            panelClass: 'modal-content',
            positionStrategy: positionStrategy,
            backdropClass: 'cdk-overlay-dark-backdrop',
            hasBackdrop: true
        });
        this.loginFormComponentPortal = new ComponentPortal(LoginFormComponent);

        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.dispose();
        });
        this.overlayRef.attach(this.loginFormComponentPortal);
        const subsToRouterEvent = this.router.events.subscribe(() => {
            this.overlayRef.dispose();
        });
        const subsToLoginEvent = $loginEvent.subscribe(isLoggedIn => {
            if (isLoggedIn) {
                this.overlayRef.dispose();
            }
        });
        this.overlayRef.detachments().subscribe(() => {
            subsToLoginEvent.unsubscribe();
            subsToRouterEvent.unsubscribe();
        });
    }
}
