import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../services/analytics.service';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html'
})
export class ButtonComponent implements OnInit {
    @Input() public loading: boolean;
    @Input() public disabled: boolean;
    @Input() public classes: string[];
    @Input() public gtmEventName?: string;
    @Input() public gtmEventCategory?: string;
    @Input() public gtmEventLabel?: string;
    @Input() public gtmEventValue?: string;
    @Input() public gtmCustom?: any;

    constructor(private analyticsService: AnalyticsService) {}

    public ngOnInit(): void {}

    public clicked(e) {
        if (this.gtmEventName) {
            this.analyticsService.trackEvent(this.gtmEventName, {
                category: this.gtmEventCategory,
                label: this.gtmEventLabel,
                value: this.gtmEventValue,
                gtmCustom: this.gtmCustom
            });
        }
    }
}
