import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../../../services/api/methods/menu.service';
import { SomtagService } from '../../../services/somtag.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SidebarService } from '../../../services/sidebar.service';
import { AdContainerService } from './ad-container.service';

@Component({
    selector: 'app-ad-container',
    templateUrl: './ad-container.component.html',
    styleUrls: ['./ad-container.component.scss']
})
export class AdContainerComponent implements OnInit {
    @Input() public fullWidth = false;
    @Input() public fullbannerDisabled = false;

    @ViewChild('adContainerWrapper') public adContainerWrapper: ElementRef;

    public menuHeight: number;
    public skyWidth: number;
    public fullbannerWidth: number;
    public sidebarIsOpen: boolean;
    public marginContainerRight: number;
    public adContainerRight: number;
    public topBannerHeight = 0;
    public isMobile: boolean;
    public enoughPlaceForSkyscraper = true;

    public adsEnabled = true;
    public activeAds = {
        fullbanner: true,
        mbanner: true,
        skyscraper: true
    };
    public environment = environment;

    constructor(
        private somtagService: SomtagService,
        private menuService: MenuService,
        private cdr: ChangeDetectorRef,
        private activedRoute: ActivatedRoute,
        private sidebarService: SidebarService,
        private adContainerService: AdContainerService
    ) {
        this.isMobile = window.innerWidth <= this.somtagService.deviceBreakpoint;
        this.sidebarService.isOpen.subscribe((open: boolean) => (this.sidebarIsOpen = open));
    }

    public setHeight(height: number) {
        this.topBannerHeight = height;
        this.cdr.detectChanges();
    }

    public setFullbannerWidth(width: number) {
        this.fullbannerWidth = width;
        this.cdr.detectChanges();
    }

    public ngOnInit(): void {
        this.menuService.totalHeight.subscribe((height: number) => {
            this.menuHeight = height;
        });
        this.handleResize();
    }

    public handleSkyWidth(width: number) {
        this.skyWidth = width + 20;
        this.handleResize();
    }

    public handleDisabledAd(disabled: boolean, ad: string) {
        this.activeAds[ad] = !disabled;
    }

    @HostListener('window:resize')
    public handleResize() {
        if (!this.adContainerWrapper) {
            return;
        }
        const containerWidth = this.adContainerWrapper.nativeElement.clientWidth;

        let visibleWindowWidth = window.innerWidth;

        if (this.sidebarIsOpen) {
            visibleWindowWidth -= 235;
        }
        if (this.fullbannerWidth > 500) {
            this.enoughPlaceForSkyscraper = this.fullbannerWidth <= containerWidth;
        } else {
            this.enoughPlaceForSkyscraper = true;
        }

        const spaceOnRightSide = (visibleWindowWidth - containerWidth) / 2;

        /// magic number must match sass var $grid-breakpoints[xl]
        const sassGridBreakpointXL = 1300;
        if (window.innerWidth < sassGridBreakpointXL) {
            this.marginContainerRight = 0;
            this.adContainerRight = 0;
            this.cdr.detectChanges();
            this.adContainerService.sizeChange.emit();
            return;
        }
        if (spaceOnRightSide < this.skyWidth) {
            // container width must be reduced
            this.marginContainerRight = this.skyWidth - spaceOnRightSide;
            this.adContainerRight = 0;
            this.cdr.detectChanges();
            this.adContainerService.sizeChange.emit();
        } else {
            this.marginContainerRight = 0;

            // ad container should not be affixed to window right but nice next to the content
            this.adContainerRight = spaceOnRightSide - this.skyWidth;
            this.cdr.detectChanges();
            this.adContainerService.sizeChange.emit();
        }
    }
}
