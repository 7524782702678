import { TileType } from './../../../elements/asset-tile-slider/asset-tile-slider.component';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Playlist } from '../../../../entities/playlist.entity';
import { Section } from '../../../../entities/section.entity';
import { AgofEnum } from '../../../../enum/agof.enum';
import { environment } from '../../../../environments/environment';
import { AgofService } from '../../../../services/agof.service';
import { AnalyticsService } from '../../../../services/analytics.service';
import { LiveEventsService } from '../../../../services/api/methods/live-events.service';
import { PlaylistsService } from '../../../../services/api/methods/playlists.service';
import { SectionsService } from '../../../../services/api/methods/sections.service';
import { Asset } from '../../../../entities/asset.entity';
import { AppName } from 'src/common/enum/app-name.enum';

@Component({
    selector: 'app-section',
    templateUrl: './section.component.html'
})
export class SectionComponent implements OnInit, AfterViewInit {
    @Input() public section: Section;

    public allAssets: Asset[];
    public rectHeights: number[] = [];
    public rectWidths: number[] = [];
    public currentAllAssetsPage = 1;
    public openerPlaylist: Playlist;
    public definedPlaylists: Playlist[] = [];

    public liveAssets: Asset[];
    public environment = environment;
    public AppName = AppName;

    public TileType = TileType;

    constructor(
        private sectionsService: SectionsService,
        private liveEventsService: LiveEventsService,
        private analyticsService: AnalyticsService,
        private agofService: AgofService,
        private playlistService: PlaylistsService,
        private title: Title,
        private meta: Meta
    ) {}

    public ngAfterViewInit() {
        // @ts-ignore
        window.iFrameResize();
    }

    public ngOnInit(): void {
        this.title.setTitle(this.section.seo_title || this.section.title);
        this.meta.updateTag({ name: 'description', content: this.section.seo_description }, 'name=description');
        this.meta.updateTag({ name: 'keywords', content: this.section.seo_keywords }, 'name=keywords');
        this.meta.updateTag({ name: 'robots', content: this.section.seo_robots }, 'name=robots');

        if (this.section.tag_filters) {
            this.liveEventsService.getLiveAssets(this.section.tag_filters, 20, 1).subscribe((response: Asset[]) => {
                this.liveAssets = response;
            });
        }

        if (this.section.playlist_uuid) {
            const playlistUuids = this.section.playlist_uuid.split(',');
            playlistUuids.forEach((playlistUuid, index) => {
                this.playlistService.getPlaylist(playlistUuid.trim()).subscribe((playlist: Playlist) => {
                    if (index === 0) {
                        this.openerPlaylist = playlist;
                    } else {
                        this.definedPlaylists.push(playlist);
                    }
                });
            });
        }

        this.sectionsService
            .getAssetsForSectionID(this.section.uuid, 20, 1)
            .subscribe((res: { items: Asset[]; total: number }) => {
                this.allAssets = res.items;
                this.currentAllAssetsPage = 1;
            });

        this.analyticsService.trackEvent('AGOF_IMPRESSION', {
            category: 'AGOF_IMPRESSION',
            event: 'AGOF_IMPRESSION',
            gtmCustom: {
                agof: {
                    cpFirstPart: this.agofService.createCategoryCode(
                        AgofEnum.LANGUAGE_GERMAN,
                        AgofEnum.CONTENT_TYPE_IMAGE_TEXT,
                        AgofEnum.CONTENT_CREATOR_EDITOR,
                        AgofEnum.HOMEPAGE_NO,
                        AgofEnum.DISTRIBUTION_ONLINE,
                        AgofEnum.DEVICE_NO_APP,
                        AgofEnum.PAID_NO,
                        AgofEnum.CONTENT_TOPIC_Sports
                    ),
                    cpChannel: AgofEnum.CHANNEL_DEFAULT,
                    coSecondPart: window.location.pathname + '/',
                    survey: true
                }
            }
        });

        // @ts-ignore
        window.prerenderReady = true;
    }

    public loadMoreOfAllAssets() {
        if (this.section.assets_count > this.allAssets.length) {
            this.sectionsService
                .getAssetsForSectionID(this.section.uuid, 20, this.currentAllAssetsPage + 1)
                .subscribe((res: { items: Asset[]; total: number }) => {
                    res.items.forEach(asset => this.allAssets.push(asset));
                    this.currentAllAssetsPage++;
                });
        }
    }
}
