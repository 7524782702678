import { AbstractControl, ValidatorFn } from '@angular/forms';

export class RegisterValidators {
    public static equalTo(equalControlName): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control['_parent']) {
                return null;
            }

            if (!control['_parent'].controls[equalControlName]) {
                throw new TypeError('Form Control ' + equalControlName + ' does not exists.');
            }

            const controlMatch = control['_parent'].controls[equalControlName];

            return controlMatch.value === control.value
                ? null
                : {
                      equalTo: true
                  };
        };
    }

    public static equal(value): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            return value === control.value
                ? null
                : {
                      equal: true
                  };
        };
    }
}
