import * as videojs from 'video.js';

import { VjsSharePluginOptions } from '../../../../../../../@types/VjsSharePluginOptions';
import { EmbeddedViewRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';

// @ts-ignore
const Plugin = window.videojs.getPlugin('plugin');

export default class SharePlugin extends Plugin {
    private options: VjsSharePluginOptions;
    private videojs;
    private player: videojs.default.Player | any;
    private shareModalDialog: any;
    private shareButtonShown: boolean;

    constructor(player: videojs.default.Player, options: VjsSharePluginOptions) {
        // @ts-ignore
        super(player, options);
        this.options = options;
        // @ts-ignore
        this.videojs = window.videojs;
        this.shouldShowShareButton(environment.player.showShareButton);
    }

    public shouldShowShareButton(show: boolean) {
        if (this.options.asset.flags && this.options.asset.flags.includes('noshare')) {
            show = false;
        }
        show ? this.addShareButton() : this.removeShareButton();
    }

    public showShareModal() {
        const tpl: EmbeddedViewRef<any> = this.options.viewContainer.createEmbeddedView(
            this.options.shareModalTemplate,
            {
                asset: this.options.asset
            }
        );
        this.shareModalDialog = this.player.createModal(tpl.rootNodes[0]);
        document.getElementsByClassName('vjs-share-button')[0].classList.add('vjs-addOn-button-hide');
        this.shareModalDialog.on('modalclose', () => {
            document.getElementsByClassName('vjs-share-button')[0].classList.remove('vjs-addOn-button-hide');
        });
    }

    public hideShareModal() {
        if (this.shareModalDialog) {
            this.shareModalDialog.close();
        }
    }

    private removeShareButton() {
        if (this.shareButtonShown === false) {
            return;
        }
        this.shareButtonShown = false;
        this.hideShareModal();
        this.player.removeChild('ShareButton');
    }

    private addShareButton() {
        if (this.shareButtonShown === true) {
            return;
        }
        this.shareButtonShown = true;
        const Button = this.videojs.getComponent('Button');
        const ShareButton = this.videojs.extend(Button, {
            constructor: function () {
                Button.apply(this, arguments);
                this.addClass('vjs-share-button');
                this.addClass('fa');
                this.addClass('fa-share-alt');
                this.addClass('vjs-addOn-button');
                this.controlText('Share');
            },
            handleClick: event => {
                event.stopPropagation();
                this.options.cdr.detectChanges();
                this.showShareModal();
            }
        });
        this.videojs.registerComponent('ShareButton', ShareButton);
        this.addComponent('ShareButton');
    }

    private addComponent(component: string, toParent: any = this.player) {
        toParent.addChild(component, {});
    }
}
